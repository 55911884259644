import actionTypes from './actionTypes'

const actionCreators = {
	addNotification: (message) => ({
		type: actionTypes.ADD,
		payload: {
			message
		}
	}),

	removeNotification: (id) => ({
		type: actionTypes.REMOVE,
		meta: {
			id
		}
	})
}

export default actionCreators
