import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = {
	postcode: {
		flex: 1
	}
}

class Range extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		country: PropTypes.string.isRequired,
		startError: PropTypes.bool,
		endError: PropTypes.bool,
		change: PropTypes.func.isRequired
	}

	state = {
		start: '',
		end: ''
	}

	handleChangeStart = (event) => {
		const postcode = event.target.value

		const start = postcode.toUpperCase()
		const { end } = this.state

		this.setState({
			start
		})

		this.handleChange(start, end)
	}

	handleChangeEnd = (event) => {
		const postcode = event.target.value

		const { start } = this.state
		const end = postcode.toUpperCase()

		this.setState({
			end
		})

		this.handleChange(start, end)
	}

	handleChange = (start, end) => {
		const { change } = this.props

		change(start, end)
	}

	maxPostcodeLength = () => {
		const { country } = this.props

		if (country === 'NL') return '4'
		if (country === 'BE') return '4'
		if (country === 'DE') return '5'

		return null
	}

	render() {
		const { classes, startError, endError } = this.props
		const { start, end } = this.state

		const maxLength = this.maxPostcodeLength()

		let startErrorText
		if (startError) {
			startErrorText = i18n.t('app:postcodeadmin.Postcode.rangeErrorText')
		}

		let endErrorText
		if (endError) {
			endErrorText = i18n.t('app:postcodeadmin.Postcode.rangeErrorText')
		}

		return (
			<>
				<TextField
					label={i18n.t('app:postcodeadmin.Postcode.start')}
					className={classes.postcode}
					value={start}
					onChange={this.handleChangeStart}
					margin="normal"
					error={startError}
					helperText={startErrorText}
					inputProps={{
						maxLength
					}}
				/>
				<TextField
					label={i18n.t('app:postcodeadmin.Postcode.end')}
					className={classes.postcode}
					value={end}
					onChange={this.handleChangeEnd}
					margin="normal"
					error={endError}
					helperText={endErrorText}
					inputProps={{
						maxLength
					}}
				/>
			</>
		)
	}
}

export default withStyles(styles)(Range)
