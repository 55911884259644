import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	Collapse,
	IconButton,
	List,
	Paper,
	SvgIcon
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FilterFal, FilterFas, SearchFal } from '@oliverit/react-fontawesome'
import Placeholder from '../../Shared/Placeholder'
import ListContainer from './List'
import FilterContainer from './Filter'
import SearchContainer from './Search'
import WeekNavigatorContainer from './WeekNavigator'
import OrderSearchContainer from '../OrderSearch'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		display: 'flex',
		paddingTop: 16
	},
	filters: {
		display: 'flex',
		flexDirection: 'column'
	},
	contentContainer: {
		flex: 1,
		overflow: 'hidden',
		position: 'relative'
	},
	content: {
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	filterButton: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 16,
		paddingRight: 16
	}
}

class OrderSelection extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		hasActiveFilters: PropTypes.bool,
		isLoading: PropTypes.bool,
		selectOrder: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string,
		selectWeek: PropTypes.func.isRequired
	}

	state = {
		showFilters: false,
		showSearch: false
	}

	handleHideFilters = () => {
		this.setState({
			showFilters: false
		})
	}

	handleShowFilters = () => {
		this.setState({
			showFilters: true
		})
	}

	handleToggleFilters = () => {
		const { showFilters } = this.state
		this.setState({
			showFilters: !showFilters
		})
	}

	handleHideSearch = () => {
		this.hideSeachDialog()
	}

	handleShowSearch = () => {
		this.setState({
			showSearch: true
		})
	}

	handleSelectSearchResult = (order) => {
		const { selectOrder, selectWeek } = this.props
		selectOrder(order.id)
		selectWeek(order.week, order.year)
		this.hideSeachDialog()
	}

	hideSeachDialog() {
		this.setState({
			showSearch: false
		})
	}

	render() {
		const {
			classes,
			hasActiveFilters,
			isLoading,
			selectOrder,
			selectedOrderId
		} = this.props
		const { showFilters, showSearch } = this.state

		if (isLoading) {
			const placeholders = [...Array(11)].map((row, index) => (
				<Placeholder key={`orderlist-placeholder-${index}`} />
			))
			return (
				<Paper className={classes.root}>
					<div className={classes.contentContainer}>
						<List disablePadding className={classes.content}>
							{placeholders}
						</List>
					</div>
				</Paper>
			)
		}

		const searchDialog = (
			<Dialog fullWidth open={showSearch} onClose={this.handleHideSearch}>
				<OrderSearchContainer select={this.handleSelectSearchResult} />
			</Dialog>
		)

		const filters = (
			<Collapse in={showFilters}>
				<FilterContainer close={this.handleHideFilters} />
			</Collapse>
		)

		const filterIcon = hasActiveFilters ? <FilterFas /> : <FilterFal />

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<WeekNavigatorContainer />
					<div className={classes.filterButton}>
						<IconButton color="primary" onClick={this.handleShowSearch}>
							<SvgIcon>
								<SearchFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="primary" onClick={this.handleToggleFilters}>
							<SvgIcon>{filterIcon}</SvgIcon>
						</IconButton>
					</div>
				</div>
				<div className={classes.filters}>
					{filters}
					<SearchContainer />
				</div>
				<div className={classes.contentContainer}>
					<ListContainer
						selectedOrderId={selectedOrderId}
						selectOrder={selectOrder}
					/>
				</div>
				{searchDialog}
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(OrderSelection)
