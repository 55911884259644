import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import driversActionCreators from '../../../../redux/drivers/actionCreators'
import WeekNavigator from './WeekNavigator'

class WeekNavigatorContainer extends PureComponent {
	static propTypes = {
		selectedMoment: PropTypes.object.isRequired,
		selectCurrentWeek: PropTypes.func.isRequired,
		selectNextWeek: PropTypes.func.isRequired,
		selectPreviousWeek: PropTypes.func.isRequired
	}

	render() {
		const {
			selectedMoment,
			selectCurrentWeek,
			selectNextWeek,
			selectPreviousWeek
		} = this.props

		return (
			<WeekNavigator
				selectedMoment={selectedMoment}
				selectCurrentWeek={selectCurrentWeek}
				selectNextWeek={selectNextWeek}
				selectPreviousWeek={selectPreviousWeek}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		selectedMoment: driversSelectors.selectedMomentSelector(state.driversState)
	}),
	{
		selectCurrentWeek: driversActionCreators.selectCurrentWeek,
		selectNextWeek: driversActionCreators.selectNextWeek,
		selectPreviousWeek: driversActionCreators.selectPreviousWeek
	}
)

export default connector(WeekNavigatorContainer)
