import de from './de.json'
import en from './en.json'
import nl from './nl.json'

const locales = {
	de,
	en,
	nl
}

export default locales
