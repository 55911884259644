import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import actionCreators from '../redux/dateproposal/actionCreators'
import config from '../config'

const { webSocketUrl } = config

class DateProposalSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/dateproposal`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			// Date proposals always emit a result and never an error message
			// In case of errors on the server the result is simply empty
			this.socket.on('dateproposal_result', this.handleResult)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleResult = ({ orderKey, proposals }) => {
		this.store.dispatch(actionCreators.fetchSuccess(orderKey, proposals))
	}

	getProposal = (orderKey) => {
		this.store.dispatch(actionCreators.fetchRequest(orderKey))
		this.socket.emit('dateproposal_request', { orderKey })
	}
}

const dateProposalSocket = new DateProposalSocket()

export default dateProposalSocket
