import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/postcodes/actionCreators'
import PostcodeAvailability from './PostcodeAvailability'

class PostcodeAvailabilityContainer extends PureComponent {
	static propTypes = {
		country: PropTypes.string.isRequired,
		createPostcode: PropTypes.func.isRequired
	}

	render() {
		const { country, createPostcode } = this.props

		return (
			<PostcodeAvailability
				key={country}
				country={country}
				create={createPostcode}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		country: state.postcodesState.selectedCountry
	}),
	{
		createPostcode: actionCreators.createPostcode
	}
)

export default connector(PostcodeAvailabilityContainer)
