import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import driversActionCreators from '../../../../redux/drivers/actionCreators'
import WeekViewer from './WeekViewer'

class WeekViewerContainer extends PureComponent {
	static propTypes = {
		driverId: PropTypes.string.isRequired,
		driver: PropTypes.object.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		availabilityLoading: PropTypes.bool,
		fetchAvailability: PropTypes.func.isRequired,
		newItem: PropTypes.func.isRequired
	}

	componentDidMount() {
		this.loadAvailability()
	}

	componentDidUpdate(prevProps) {
		const { selectedMoment } = this.props
		const { selectedMoment: prevMoment } = prevProps

		// a new week is selected, load the availability for the new week
		if (selectedMoment.isoWeek() !== prevMoment.isoWeek()) {
			this.loadAvailability()
		}
	}

	loadAvailability = () => {
		const { driver, selectedMoment, availabilityLoading, fetchAvailability } =
			this.props

		if (driver && !availabilityLoading) {
			fetchAvailability(
				driver.id,
				selectedMoment.year(),
				selectedMoment.isoWeek()
			)
		}
	}

	render() {
		const { driver, selectedMoment, newItem } = this.props

		if (!driver) {
			return <div />
		}

		return (
			<WeekViewer
				driver={driver}
				selectedMoment={selectedMoment}
				newItem={newItem}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		driver: driversSelectors.findDriverByIdSelector(
			state.driversState,
			props.driverId
		),
		selectedMoment: driversSelectors.selectedMomentSelector(state.driversState),
		availabilityLoading: state.driversState.availabilityMeta.isLoading
	}),
	{
		fetchAvailability: driversActionCreators.fetchAvailability,
		newItem: driversActionCreators.newAvailabilityItem
	}
)

export default connector(WeekViewerContainer)
