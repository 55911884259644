import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	List as MaterialList,
	ListSubheader,
	Typography,
	Divider
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import ListItem from './ListItem'

const styles = {
	root: {
		paddingTop: 16,
		overflowY: 'scroll'
	},
	header: {
		display: 'flex',
		paddingRight: 32
	},
	column: {
		width: '30%'
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		postcodes: PropTypes.array.isRequired,
		removeItem: PropTypes.func.isRequired
	}

	handleRemoveItem = (id) => {
		const { removeItem } = this.props

		removeItem(id)
	}

	render() {
		const { classes, postcodes } = this.props

		if (postcodes.length === 0) {
			return (
				<div className={classes.root}>
					<Typography variant="body2">
						{i18n.t('app:postcodeadmin.Availability.empty')}
					</Typography>
				</div>
			)
		}

		const items = postcodes.map((postcode) => (
			<ListItem
				key={`${postcode.id}`}
				postcode={postcode}
				removeItem={this.handleRemoveItem}
			/>
		))

		return (
			<div className={classes.root}>
				<MaterialList disablePadding>
					<ListSubheader
						disableGutters
						disableSticky
						className={classes.header}
					>
						<span className={classes.column}>
							{i18n.t('app:postcodeadmin.Availability.code')}
						</span>
						<span className={classes.column}>
							{i18n.t('app:postcodeadmin.Availability.from')}
						</span>
						<span className={classes.column}>
							{i18n.t('app:postcodeadmin.Availability.till')}
						</span>
					</ListSubheader>
					<Divider />
					{items}
				</MaterialList>
			</div>
		)
	}
}

export default withStyles(styles)(List)
