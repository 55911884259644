import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import actionCreators from '../redux/orders/actionCreators'
import config from '../config'

const { webSocketUrl } = config

class OrdersSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/orders`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('order_created', this.handleCreated)
			this.socket.on('order_updated', this.handleUpdated)
			this.socket.on('order_deleted', this.handleDeleted)
			this.socket.on('order_tour_dates_updated', this.handleTourDatesUpdated)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
		// Fetch orders when the socket has connected
		this.store.dispatch(actionCreators.fetch())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleCreated = (order) => {
		this.store.dispatch(actionCreators.add(order))
	}

	handleUpdated = (order) => {
		this.store.dispatch(actionCreators.update(order))
	}

	handleDeleted = (order) => {
		this.store.dispatch(actionCreators.remove(order))
	}

	handleTourDatesUpdated = ({ planningTypeKey, orderTourDates }) => {
		// Convert list to a Map so the reducer can process the updates quickly
		const orderTourDatesMap = new Map()
		orderTourDates.forEach(({ orderKey, tourDate }) => {
			orderTourDatesMap.set(orderKey, tourDate)
		})
		this.store.dispatch(
			actionCreators.updateTourDates(planningTypeKey, orderTourDatesMap)
		)
	}
}

const ordersSocket = new OrdersSocket()

export default ordersSocket
