import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Costs from './Costs'
import Data from './Data'
import Depots from './Depots'
import Drivers from './Drivers'
import Header from './Header'
import PlanningContainer from './Planning'
import Properties from './Properties'
import DriverQualificationsContainer from './DriverQualifications'
import {
	extractProperties,
	extractReadOnlyProperties
} from '../../../utils/vehicles'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		position: 'absolute',
		overflowY: 'auto',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	columnLeft: {
		width: '55%',
		height: '100%'
	},
	columnRight: {
		width: '45%',
		height: '100%'
	}
}

class Vehicle extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props

		const properties = extractProperties(vehicle)
		const readOnlyProperties = extractReadOnlyProperties(vehicle)

		return (
			<Paper className={classes.root}>
				<Header vehicle={vehicle} />
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<div className={classes.columnLeft}>
							<Data vehicle={vehicle} />
							<Drivers vehicle={vehicle} />
							<Depots vehicle={vehicle} />
							<PlanningContainer
								planningTypeKey={vehicle.planningType}
								properties={properties}
							/>
							<DriverQualificationsContainer
								driverIds={[vehicle.firstDriver, vehicle.secondDriver]}
							/>
						</div>
						<div className={classes.columnRight}>
							<Costs vehicle={vehicle} />
							<Properties properties={readOnlyProperties} />
						</div>
					</div>
				</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Vehicle)
