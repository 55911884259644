import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	IconButton,
	ListItem as MaterialListItem,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	BoxesAltFal,
	ClockFal,
	SignOutAltFal,
	UserHeadsetFad
} from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'
import { formatCustomer } from '../../../../utils/customer'
import formatDuration from '../../../../utils/duration'
import { PLANNING_TYPE_MEERDAAGSE_RIT } from '../../../../utils/constants'

const styleSheet = {
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	},
	largeSeparator: {
		marginLeft: 10,
		marginRight: 10
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 4,
		marginRight: 16
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	}
}

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired,
		routeItem: PropTypes.object.isRequired,
		planningTypeKey: PropTypes.string.isRequired
	}

	render() {
		const { classes, select, routeItem, planningTypeKey } = this.props

		const formattedCustomer = formatCustomer(routeItem.customer)
		const formattedDuration = formatDuration(routeItem.servicePeriod)
		const isPlanningTypeMultiDay =
			planningTypeKey === PLANNING_TYPE_MEERDAAGSE_RIT

		const salesOrderText =
			routeItem.salesOrderDeliveryGroup &&
			routeItem.salesOrderDeliveryGroup !== '0'
				? `${routeItem.salesOrderNumber} (${routeItem.salesOrderDeliveryGroup})`
				: `${routeItem.salesOrderNumber}`

		const primary = (
			<>
				<div className={classes.primary}>
					<Typography variant="h6">{formattedCustomer.name}</Typography>
					<Typography variant="h6" className={classes.separator} />
					<Typography variant="h6" color="textSecondary">
						{salesOrderText}
					</Typography>
					<Typography variant="h6" className={classes.separator} />
				</div>
				<Typography variant="body2">
					{formattedCustomer.postCode} {formattedCustomer.city},{' '}
					{formattedCustomer.country}
				</Typography>
			</>
		)

		let dayTimeAppointment
		if (
			routeItem.characteristics &&
			!routeItem.characteristics.isUnconfirmed &&
			routeItem.appointmentDate &&
			(routeItem.characteristics.hasTimeAppointment || isPlanningTypeMultiDay)
		) {
			let dayAppointmentText
			if (
				isPlanningTypeMultiDay &&
				routeItem.characteristics &&
				!routeItem.characteristics.isUnconfirmed
			) {
				dayAppointmentText = moment
					.tz(routeItem.appointmentDate, 'Europe/Amsterdam')
					.format('dddd')
				dayAppointmentText = `${dayAppointmentText
					.charAt(0)
					.toUpperCase()}${dayAppointmentText.slice(1)}`
			}

			let timeAppointmentText
			if (
				routeItem.characteristics.hasTimeAppointment &&
				routeItem.appointmentTimeFrom &&
				routeItem.appointmentTimeTill
			) {
				const formattedAppointmentTimeFrom = formatDuration(
					moment.duration(routeItem.appointmentTimeFrom).asSeconds(),
					{ withSeconds: false }
				)
				const formattedAppointmentTimeTill = formatDuration(
					moment.duration(routeItem.appointmentTimeTill).asSeconds(),
					{ withSeconds: false }
				)
				timeAppointmentText = `${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill}`
			}

			const dayTimeAppointmentText = [
				dayAppointmentText,
				timeAppointmentText
			].join(' ')

			dayTimeAppointment = (
				<>
					<Typography variant="body2" className={classes.largeSeparator} />
					<SvgIcon color="disabled" className={classes.icon}>
						<UserHeadsetFad />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{dayTimeAppointmentText}
					</Typography>
				</>
			)
		}

		const secondary = (
			<div className={classes.secondary}>
				<div className={classes.label}>
					<SvgIcon color="disabled" className={classes.icon}>
						<ClockFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{formattedDuration}
					</Typography>
					<Typography variant="body2" className={classes.largeSeparator} />
					<SvgIcon color="disabled" className={classes.icon}>
						<BoxesAltFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{routeItem.volume}
					</Typography>
					{dayTimeAppointment}
				</div>
			</div>
		)

		return (
			<MaterialListItem button onClick={select}>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
				<ListItemSecondaryAction>
					<IconButton onClick={select} color="primary">
						<SvgIcon>
							<SignOutAltFal />
						</SvgIcon>
					</IconButton>
				</ListItemSecondaryAction>
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
