import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import PlanningTypeSelectionContainer from './PlanningTypeSelection'
import PlanningTypeContainer from './PlanningType'

const styleSheet = () => ({
	split: {
		display: 'flex',
		height: '100%'
	},
	list: {
		marginRight: 16,
		width: 600
	},
	details: {
		flex: 1
	}
})

class PlanningTypeAdmin extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectedPlanningTypeKey: PropTypes.string
	}

	render() {
		const { classes, selectedPlanningTypeKey } = this.props

		return (
			<div className={classes.split}>
				<div className={classes.list}>
					<PlanningTypeSelectionContainer
						selectedPlanningTypeKey={selectedPlanningTypeKey}
					/>
				</div>
				<div className={classes.details}>
					<PlanningTypeContainer
						selectedPlanningTypeKey={selectedPlanningTypeKey}
					/>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(PlanningTypeAdmin)
