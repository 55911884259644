import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { formatCustomer } from '../../../utils/customer'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 24
	}
}

class Language extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		customer: PropTypes.object.isRequired
	}

	render() {
		const { classes, customer } = this.props

		const formattedCustomer = formatCustomer(customer)

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.language')}
				</Typography>
				<Typography variant="body2">{formattedCustomer.language}</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Language)
