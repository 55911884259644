import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { default as i18n } from 'i18next'

class Depot extends PureComponent {
	static propTypes = {
		depot: PropTypes.object,
		label: PropTypes.string.isRequired,
		plant: PropTypes.string
	}

	render() {
		const { depot, label, plant } = this.props

		let description
		if (!depot) {
			description = i18n.t('app:vehicleplanning.Depot.empty')
		} else if (!plant) {
			description = `${i18n.t('app:vehicleplanning.Depot.unknown')} ${plant}`
		} else {
			description = depot.name ? depot.name : depot.plant
		}

		return (
			<>
				<Typography variant="caption" color="primary">
					{label}
				</Typography>
				<Typography variant="body2">{description}</Typography>
			</>
		)
	}
}

export default Depot
