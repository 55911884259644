import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import ordersActionCreators from '../../../../redux/orders/actionCreators'
import ordersSelectors from '../../../../redux/orders/selectors'
import WeekNavigator from './WeekNavigator'

class WeekNavigatorContainer extends PureComponent {
	static propTypes = {
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const {
			selectCurrentDate,
			selectNextDate,
			selectPreviousDate,
			selectedMoment
		} = this.props

		const now = moment.tz('Europe/Amsterdam')
		const isCurrentWeek =
			selectedMoment.isoWeek() === now.isoWeek() &&
			selectedMoment.year() === now.year()

		return (
			<WeekNavigator
				selectCurrentDate={selectCurrentDate}
				selectNextDate={selectNextDate}
				selectPreviousDate={selectPreviousDate}
				selectedMoment={selectedMoment}
				isCurrentWeek={isCurrentWeek}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		selectedMoment: ordersSelectors.selectedMomentSelector(state.ordersState)
	}),
	{
		selectCurrentDate: ordersActionCreators.selectCurrentDate,
		selectNextDate: ordersActionCreators.selectNextDate,
		selectPreviousDate: ordersActionCreators.selectPreviousDate
	}
)

export default connector(WeekNavigatorContainer)
