import actionTypes from './actionTypes'

const actionCreators = {
	confirmAppointment: (appointment) => ({
		type: actionTypes.CONFIRM_APPOINTMENT,
		payload: {
			appointment
		}
	}),

	confirmAppointmentFailure: (errorMessages) => ({
		type: actionTypes.CONFIRM_APPOINTMENT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	confirmAppointmentRequest: () => ({
		type: actionTypes.CONFIRM_APPOINTMENT_REQUEST
	}),

	confirmAppointmentSuccess: () => ({
		type: actionTypes.CONFIRM_APPOINTMENT_SUCCESS
	}),

	fetch: (id) => ({
		type: actionTypes.FETCH,
		meta: {
			id
		}
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (order) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			order
		}
	}),

	lock: () => ({
		type: actionTypes.LOCK
	}),

	lockFailure: (errorMessages) => ({
		type: actionTypes.LOCK_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	lockRequest: () => ({
		type: actionTypes.LOCK_REQUEST
	}),

	lockSuccess: () => ({
		type: actionTypes.LOCK_SUCCESS
	}),

	noAnswerAppointment: (appointment) => ({
		type: actionTypes.NOANSWER_APPOINTMENT,
		payload: {
			appointment
		}
	}),

	noAnswerAppointmentFailure: (errorMessages) => ({
		type: actionTypes.NOANSWER_APPOINTMENT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	noAnswerAppointmentRequest: () => ({
		type: actionTypes.NOANSWER_APPOINTMENT_REQUEST
	}),

	noAnswerAppointmentSuccess: () => ({
		type: actionTypes.NOANSWER_APPOINTMENT_SUCCESS
	}),

	postponeAppointment: (appointment) => ({
		type: actionTypes.POSTPONE_APPOINTMENT,
		payload: {
			appointment
		}
	}),

	postponeAppointmentFailure: (errorMessages) => ({
		type: actionTypes.POSTPONE_APPOINTMENT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	postponeAppointmentRequest: () => ({
		type: actionTypes.POSTPONE_APPOINTMENT_REQUEST
	}),

	postponeAppointmentSuccess: () => ({
		type: actionTypes.POSTPONE_APPOINTMENT_SUCCESS
	}),

	remove: () => ({
		type: actionTypes.REMOVE
	}),

	saveAppointment: (appointment) => ({
		type: actionTypes.SAVE_APPOINTMENT,
		payload: {
			appointment
		}
	}),

	saveAppointmentFailure: (errorMessages) => ({
		type: actionTypes.SAVE_APPOINTMENT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	saveAppointmentRequest: () => ({
		type: actionTypes.SAVE_APPOINTMENT_REQUEST
	}),

	saveAppointmentSuccess: () => ({
		type: actionTypes.SAVE_APPOINTMENT_SUCCESS
	}),

	takeOverLock: () => ({
		type: actionTypes.TAKEOVERLOCK
	}),

	takeOverLockFailure: (errorMessages) => ({
		type: actionTypes.TAKEOVERLOCK_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	takeOverLockRequest: () => ({
		type: actionTypes.TAKEOVERLOCK_REQUEST
	}),

	takeOverLockSuccess: () => ({
		type: actionTypes.TAKEOVERLOCK_SUCCESS
	}),

	unlock: () => ({
		type: actionTypes.UNLOCK
	}),

	unlockFailure: (errorMessages) => ({
		type: actionTypes.UNLOCK_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	unlockRequest: () => ({
		type: actionTypes.UNLOCK_REQUEST
	}),

	unlockSuccess: () => ({
		type: actionTypes.UNLOCK_SUCCESS
	}),

	update: (order) => ({
		type: actionTypes.UPDATE,
		payload: {
			order
		}
	}),

	updateTourDate: (tourDate) => ({
		type: actionTypes.UPDATE_TOURDATE,
		payload: {
			tourDate
		}
	}),

	fetchTimeSlots: (id) => ({
		type: actionTypes.FETCH_TIME_SLOTS,
		meta: {
			id
		}
	}),

	fetchTimeSlotsRequest: (id) => ({
		type: actionTypes.FETCH_TIME_SLOTS_REQUEST,
		meta: {
			id
		}
	}),

	fetchTimeSlotsSuccess: (timeSlots) => ({
		type: actionTypes.FETCH_TIME_SLOTS_SUCCESS,
		payload: {
			timeSlots
		}
	}),

	fetchTimeSlotsFailure: (errorMessages) => ({
		type: actionTypes.FETCH_TIME_SLOTS_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	})
}

export default actionCreators
