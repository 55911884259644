import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import escapeStringRegexp from '../../utils/escapeStringRegexp'
import { fields, localizedText } from '../../utils/localizedText'

// Shortcuts
const ordersSelector = (state) => state.orders
const filterSelector = (state) => state.filter
const selectedDateSelector = (state) => state.selectedDate

// Find orders matching the specified search query
const queryOrder = (order, query) => {
	const queryRegex = new RegExp(escapeStringRegexp(query), 'i')
	if (
		order.salesOrderNumber &&
		queryRegex.test(order.salesOrderNumber.toString())
	) {
		return true
	}
	if (order.orderKey && queryRegex.test(order.orderKey.toString())) {
		return true
	}
	if (order.customer) {
		if (
			order.customer.name &&
			queryRegex.test(order.customer.name.toString())
		) {
			return true
		}
		if (
			order.customer.street &&
			queryRegex.test(order.customer.street.toString())
		) {
			return true
		}
		if (
			order.customer.postCode &&
			queryRegex.test(order.customer.postCode.toString())
		) {
			return true
		}
	}
	return false
}

// Return the selected date as a moment
const selectedMomentSelector = createSelector(
	selectedDateSelector,
	(selectedDate) => moment.tz(selectedDate, 'Europe/Amsterdam')
)

// Filter the orders by selected week and year, and by user specified filters
const filteredOrdersSelector = createSelector(
	[selectedMomentSelector, filterSelector, ordersSelector],
	(selectedMoment, filter, orders) =>
		orders.filter((order) => {
			// First check for selected week and year so we can exit quickly without checking all the filters (so do not return true in this part, because we still have to run the user filters)
			// When the selected week is the current week, include all orders from previous weeks as well
			// Otherwise only include the orders for the selected week
			const now = moment.tz('Europe/Amsterdam')
			const isCurrentWeekSelected =
				selectedMoment.isoWeek() === now.isoWeek() &&
				selectedMoment.year() === now.year()
			const week = parseInt(order.week, 10)
			const year = parseInt(order.year, 10)
			if (isCurrentWeekSelected) {
				if (year > selectedMoment.year()) {
					// Year in the future
					return false
				}
				if (year === selectedMoment.year() && week > selectedMoment.isoWeek()) {
					// Same year, but a week in the future
					return false
				}
			} else if (
				week !== selectedMoment.isoWeek() ||
				year !== selectedMoment.year()
			) {
				// Week or year is not the same
				return false
			}

			// Next check the user filters
			// - Predefined filters first; these must all match
			if (!filter.showConfirmed) {
				// By default all confirmed and planned appointments are hidden.
				// This filter can be set to true to also show the confirmed and planned appointments.
				// So this switch does not toggle between confirmed and not-confirmed, but it toggles between not-confirmed/planned and both.
				if (order.isAppointmentConfirmed || order.isAppointmentPlanned) {
					return false
				}
			}
			if (filter.showNoTourDateOnly) {
				if (order.tourDate) {
					return false
				}
			}
			if (filter.contactDateBefore) {
				if (!order.contactDate) {
					return false
				}
				if (
					!moment
						.tz(order.contactDate, 'Europe/Amsterdam')
						.isBefore(
							moment.tz(filter.contactDateBefore, 'Europe/Amsterdam'),
							'day'
						)
				) {
					return false
				}
			}
			if (filter.tourDateFrom) {
				if (!order.tourDate) {
					return false
				}
				if (
					!moment
						.tz(order.tourDate, 'Europe/Amsterdam')
						.isBetween(
							moment.tz(filter.tourDateFrom, 'Europe/Amsterdam'),
							moment.tz(filter.tourDateTill, 'Europe/Amsterdam'),
							'day',
							'[]'
						)
				) {
					return false
				}
			}
			if (filter.customerLanguageKeys.length > 0) {
				if (!order.customer || !order.customer.language) {
					return false
				}
				if (
					!filter.customerLanguageKeys.some(
						(key) => key === order.customer.language.key
					)
				) {
					return false
				}
			}
			if (filter.orderTypeKeys.length > 0) {
				if (!order.orderType) {
					return false
				}
				if (!filter.orderTypeKeys.some((key) => key === order.orderType.key)) {
					return false
				}
			}
			if (filter.planningTypeKeys.length > 0) {
				if (!order.planningType) {
					return false
				}
				if (
					!filter.planningTypeKeys.some((key) => key === order.planningType.key)
				) {
					return false
				}
			}
			if (filter.priorityKeys.length > 0) {
				if (
					!order.priority ||
					(!order.priority.key && order.priority.key !== 0)
				) {
					return false
				}
				if (
					!filter.priorityKeys.some((key) => {
						if (key === 'none') {
							return order.priority.key === 0
						}
						return key === order.priority.key
					})
				) {
					return false
				}
			}
			if (filter.contactStatusKeys.length > 0) {
				if (!order.contactStatus) {
					return false
				}
				if (
					!filter.contactStatusKeys.some((key) => key === order.contactStatus)
				) {
					return false
				}
			}
			if (filter.routeKeys.length > 0) {
				if (!order.route) {
					return false
				}
				if (
					!filter.routeKeys.some((key) => {
						if (key === 'none') {
							return order.route.key === ''
						}
						return key === order.route.key
					})
				) {
					return false
				}
			}
			if (filter.userIds.length > 0) {
				if (!order.lock) {
					// We're filtering locked orders, so if it's not locked then it should not be visible
					return false
				}
				if (!filter.userIds.some((id) => id === order.lock.userId)) {
					return false
				}
			}
			if (filter.requirementKeys.length > 0) {
				if (!order.requirements || order.requirements.length === 0) {
					return false
				}
				if (
					!filter.requirementKeys.some((key) =>
						order.requirements.some((requirement) => requirement.key === key)
					)
				) {
					return false
				}
			}
			if (filter.shippingConditionKeys.length > 0) {
				if (!order.shippingCondition) {
					return false
				}
				if (
					!filter.shippingConditionKeys.some(
						(key) => key === order.shippingCondition.key
					)
				) {
					return false
				}
			}
			// - Search query last; is a match as soon as one check succeeds
			return queryOrder(order, filter.query)
		})
)

// The order list shows the filtered orders, sorted by order number
const orderListSelector = createSelector(
	[filteredOrdersSelector, (state, ownUserId) => ownUserId],
	(orders, ownUserId) => {
		// Sort the orders, but put the ones locked by the logged in user at the top
		const unsortedOrders = {
			lockedByOwnUser: [],
			notLockedByOwnUser: []
		}
		orders.reduce((acc, order) => {
			if (order.lock && order.lock.userId === ownUserId) {
				acc.lockedByOwnUser.push(order)
			} else {
				acc.notLockedByOwnUser.push(order)
			}
			return acc
		}, unsortedOrders)
		unsortedOrders.lockedByOwnUser.sort((a, b) =>
			a.salesOrderNumber.localeCompare(b.salesOrderNumber)
		)
		unsortedOrders.notLockedByOwnUser.sort((a, b) =>
			a.salesOrderNumber.localeCompare(b.salesOrderNumber)
		)
		return unsortedOrders.lockedByOwnUser.concat(
			unsortedOrders.notLockedByOwnUser
		)
	}
)

// Return a sorted unique list of customer languages found in the orders list
const availableCustomerLanguageKeys = createSelector(
	[ordersSelector],
	(orders) => {
		const languagesSet = new Set()
		orders.forEach((order) => {
			if (
				order.customer &&
				order.customer.language &&
				order.customer.language.key
			) {
				languagesSet.add(order.customer.language.key, {
					key: order.customer.language.key,
					// Get the localized description here, so we can sort it properly
					description: localizedText(
						fields.LANGUAGE,
						order.customer.language.key
					)
				})
				languagesSet.add(order.customer.language.key)
			}
		})
		return [...languagesSet.values()].sort((a, b) =>
			localizedText(fields.LANGUAGE, a).localeCompare(
				localizedText(fields.LANGUAGE, b)
			)
		)
	}
)

// Return a sorted unique list of order types found in the orders list
const availableOrderTypeKeys = createSelector([ordersSelector], (orders) => {
	const orderTypesSet = new Set()
	orders.forEach((order) => {
		if (order.orderType && order.orderType.key) {
			orderTypesSet.add(order.orderType.key)
		}
	})
	return [...orderTypesSet.values()].sort((a, b) =>
		localizedText(fields.ORDER_TYPE, a).localeCompare(
			localizedText(fields.ORDER_TYPE, b)
		)
	)
})

// Return a sorted unique list of planning types found in the orders list
const availablePlanningTypeKeys = createSelector([ordersSelector], (orders) => {
	const planningTypesSet = new Set()
	orders.forEach((order) => {
		if (order.planningType && order.planningType.key) {
			planningTypesSet.add(order.planningType.key)
		}
	})
	return [...planningTypesSet.values()].sort((a, b) =>
		localizedText(fields.PLANNING_TYPE, a).localeCompare(
			localizedText(fields.PLANNING_TYPE, b)
		)
	)
})

// Return a sorted unique list of priorities found in the orders list
const availablePriorityKeys = createSelector([ordersSelector], (orders) => {
	const prioritiesSet = new Set()
	orders.forEach((order) => {
		if (order.priority && order.priority.key) {
			prioritiesSet.add(order.priority.key)
		}
	})
	return [...prioritiesSet.values()].sort((a, b) =>
		localizedText(fields.PRIORITY, a).localeCompare(
			localizedText(fields.PRIORITY, b)
		)
	)
})

// Return a sorted unique list of contact statuses found in the orders list
const availableContactStatusKeys = createSelector(
	[ordersSelector],
	(orders) => {
		const contactStatusesSet = new Set()
		orders.forEach((order) => {
			if (order.contactStatus && order.contactStatus) {
				contactStatusesSet.add(order.contactStatus)
			}
		})
		return [...contactStatusesSet.values()].sort((a, b) =>
			localizedText(fields.CONTACT_STATUS, a).localeCompare(
				localizedText(fields.CONTACT_STATUS, b)
			)
		)
	}
)

// Return a sorted unique list of routes found in the orders list
const availableRouteKeys = createSelector([ordersSelector], (orders) => {
	const routesSet = new Set()
	orders.forEach((order) => {
		if (order.route && order.route.key) {
			routesSet.add(order.route.key)
		}
	})
	return [...routesSet.values()].sort((a, b) =>
		localizedText(fields.ROUTE, a).localeCompare(localizedText(fields.ROUTE, b))
	)
})

// Return a sorted unique list of lock users found in the orders list
const availableUsers = createSelector([ordersSelector], (orders) => {
	const usersMap = new Map()
	orders.forEach((order) => {
		if (order.lock && order.lock.userId) {
			usersMap.set(order.lock.userId, { ...order.lock })
		}
	})
	return [...usersMap.values()].sort((a, b) =>
		`${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
	)
})

const availableRequirementKeys = createSelector([ordersSelector], (orders) => {
	const requirementsSet = new Set()
	orders.forEach((order) => {
		if (order.requirements.length > 0) {
			order.requirements.forEach((requirement) =>
				requirementsSet.add(requirement.key)
			)
		}
	})
	return [...requirementsSet.values()].sort((a, b) =>
		localizedText(fields.REQUIREMENT, a).localeCompare(
			localizedText(fields.REQUIREMENT, b)
		)
	)
})

const availableShippingConditionKeys = createSelector(
	[ordersSelector],
	(orders) => {
		const shippingConditionsSet = new Set()
		orders.forEach((order) => {
			if (order.shippingCondition && order.shippingCondition.key) {
				shippingConditionsSet.add(order.shippingCondition.key, {
					...order.shippingCondition
				})
			}
		})
		return [...shippingConditionsSet.values()].sort((a, b) =>
			localizedText(fields.SHIPPING_CONDITION, a).localeCompare(
				localizedText(fields.SHIPPING_CONDITION, b)
			)
		)
	}
)

const hasActiveFilters = createSelector(
	[filterSelector],
	(filter) =>
		filter.showConfirmed === true ||
		filter.showNoTourDateOnly === true ||
		!!filter.contactDateBefore ||
		!!filter.contactDateTill ||
		!!filter.tourDateFrom ||
		!!filter.tourDateTill ||
		filter.contactStatusKeys.length > 0 ||
		filter.customerLanguageKeys.length > 0 ||
		filter.orderTypeKeys.length > 0 ||
		filter.planningTypeKeys.length > 0 ||
		filter.priorityKeys.length > 0 ||
		filter.routeKeys.length > 0 ||
		filter.userIds.length > 0 ||
		filter.requirementKeys.length > 0 ||
		filter.shippingConditionKeys.length > 0
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	availableContactStatusKeys,
	availableCustomerLanguageKeys,
	availableOrderTypeKeys,
	availablePlanningTypeKeys,
	availablePriorityKeys,
	availableRouteKeys,
	availableUsers,
	availableRequirementKeys,
	availableShippingConditionKeys,
	orderListSelector,
	hasActiveFilters,
	selectedMomentSelector
}

export default selectors
