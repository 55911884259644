// Updated orders are received through the websocket connection
// This means most order mutating sagas will not put redux success actions after successful API calls

import {
	all,
	call,
	fork,
	put,
	select,
	takeEvery,
	takeLatest
} from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiOrders from '../../services/apiOrders'
import actionTypes from './actionTypes'
import orderActionTypes from '../orders/actionTypes'
import sagaSelectors from './sagaSelectors'
import notificationsActionCreators from '../notifications/actionCreators'
import orderActionCreators from './actionCreators'
import { formatAppointmentForAPI } from '../../utils/appointment'

// Will fetch all orders for the selected week
function* fetch(action) {
	const { id } = action.meta
	yield put(orderActionCreators.fetchRequest())
	try {
		const order = yield call(apiOrders.get, id)
		yield put(orderActionCreators.fetchSuccess(order))
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:appointmentscheduler.Order.Error.fetch')
		yield put(
			orderActionCreators.fetchFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every fetch all action
function* watchFetch() {
	yield takeLatest(actionTypes.FETCH, fetch)
}

// Will confirm a order
function* confirmAppointment() {
	yield put(orderActionCreators.confirmAppointmentRequest())
	const order = yield select(sagaSelectors.getOrder)
	const appointment = formatAppointmentForAPI(order.appointment)
	try {
		yield call(apiOrders.confirmAppointment, order.id, appointment)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:appointmentscheduler.Order.Error.confirm')
		yield put(
			orderActionCreators.confirmAppointmentFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every confirm action
function* watchConfirmAppointment() {
	yield takeEvery(actionTypes.CONFIRM_APPOINTMENT, confirmAppointment)
}

// Will mark an appointment as "no answer"
function* noAnswerAppointment() {
	yield put(orderActionCreators.noAnswerAppointmentRequest())
	const order = yield select(sagaSelectors.getOrder)
	const appointment = formatAppointmentForAPI(order.appointment)
	try {
		yield call(apiOrders.noAnswerAppointment, order.id, appointment)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t(
			'app:appointmentscheduler.Order.Error.noAnswerAppointment'
		)
		yield put(
			orderActionCreators.noAnswerAppointmentFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every noAnswer appointment action
function* watchNoAnswerAppointment() {
	yield takeEvery(actionTypes.NOANSWER_APPOINTMENT, noAnswerAppointment)
}

// Will mark an appointment as "postponed"
function* postponeAppointment() {
	yield put(orderActionCreators.postponeAppointmentRequest())
	const order = yield select(sagaSelectors.getOrder)
	const appointment = formatAppointmentForAPI(order.appointment)
	try {
		yield call(apiOrders.postponeAppointment, order.id, appointment)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t(
			'app:appointmentscheduler.Order.Error.postponeAppointment'
		)
		yield put(
			orderActionCreators.postponeAppointmentFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every postpone appointment action
function* watchPostponeAppointment() {
	yield takeEvery(actionTypes.POSTPONE_APPOINTMENT, postponeAppointment)
}

// Will save an appointment
function* saveAppointment() {
	yield put(orderActionCreators.saveAppointmentRequest())
	const order = yield select(sagaSelectors.getOrder)
	const appointment = formatAppointmentForAPI(order.appointment)
	try {
		yield call(apiOrders.patchAppointment, order.id, appointment)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t(
			'app:appointmentscheduler.Order.Error.saveAppointment'
		)
		yield put(
			orderActionCreators.saveAppointmentFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every save appointment action
function* watchSaveAppointment() {
	yield takeEvery(actionTypes.SAVE_APPOINTMENT, saveAppointment)
}

// Will lock a order
function* lock() {
	yield put(orderActionCreators.lockRequest())
	const order = yield select(sagaSelectors.getOrder)
	try {
		yield call(apiOrders.lock, order.id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:appointmentscheduler.Order.Error.lock')
		yield put(
			orderActionCreators.lockFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every lock action
function* watchLock() {
	yield takeEvery(actionTypes.LOCK, lock)
}

// Every socket update is processed by this saga
// The selected order only needs to be removed if the provided order is the selected order
function* socketRemove(action) {
	const order = yield select(sagaSelectors.getOrder)
	if (order && order.id === action.payload.order.id) {
		yield put(orderActionCreators.remove())
	}
}

// Will handle every socket update action
function* watchSocketRemove() {
	yield takeEvery(orderActionTypes.SOCKET_REMOVE_ORDER, socketRemove)
}

// Every socket update is processed by this saga
// The selected order only needs to be updated if the provided order is the selected order
function* socketUpdate(action) {
	const order = yield select(sagaSelectors.getOrder)
	if (order && order.id === action.payload.order.id) {
		yield put(orderActionCreators.update(action.payload.order))
	}
}

// Will handle every socket update action
function* watchSocketUpdate() {
	yield takeEvery(orderActionTypes.SOCKET_UPDATE_ORDER, socketUpdate)
}

// The tour dates are updated in large batches by the planning run.
// The tour date of the selected order only needs to be updated if the selected order is included in the batch.
function* socketUpdateTourDates(action) {
	const order = yield select(sagaSelectors.getOrder)
	// The order tour dates Map contains all orders which were used in the latest planning-run.
	// If the order is not existing, then the tour date is unaffected (this requires a Map.has check,
	// because Map.get returns undefined, which is also the return value if a order could not be scheduled).
	// If the order exists, but has no value (value is undefined), then the order could not be scheduled
	// and the tour date must be cleared.
	// If the order exists with a value, then the order is scheduled and the tour date must be updated.
	if (order && action.payload.orderTourDatesMap.has(order.orderKey)) {
		const tourDate = action.payload.orderTourDatesMap.get(order.orderKey)
		yield put(orderActionCreators.updateTourDate(tourDate))
	}
}

// Will handle every socket update action
function* watchSocketUpdateTourDates() {
	yield takeEvery(
		orderActionTypes.SOCKET_UPDATE_ORDER_TOURDATES,
		socketUpdateTourDates
	)
}

// Will take over a lock
function* takeOverLock() {
	yield put(orderActionCreators.takeOverLockRequest())
	const order = yield select(sagaSelectors.getOrder)
	try {
		yield call(apiOrders.takeOverLock, order.id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t(
			'app:appointmentscheduler.Order.Error.takeOverLock'
		)
		yield put(
			orderActionCreators.takeOverLockFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every take over lock action
function* watchTakeOverLock() {
	yield takeEvery(actionTypes.TAKEOVERLOCK, takeOverLock)
}

// Will unlock a order
function* unlock() {
	yield put(orderActionCreators.unlockRequest())
	const order = yield select(sagaSelectors.getOrder)
	try {
		yield call(apiOrders.unlock, order.id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:appointmentscheduler.Order.Error.unlock')
		yield put(
			orderActionCreators.unlockFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every unlock action
function* watchUnlock() {
	yield takeEvery(actionTypes.UNLOCK, unlock)
}

// Will fetch all orders for the selected week
function* fetchTimeSlots(action) {
	const { id } = action.meta
	yield put(orderActionCreators.fetchTimeSlotsRequest())
	try {
		const availableDates = yield call(apiOrders.getUnvailableDates, id)
		yield put(orderActionCreators.fetchTimeSlotsSuccess(availableDates))
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t(
			'app:appointmentscheduler.Order.Error.fetchTimeSlots'
		)
		yield put(
			orderActionCreators.fetchTimeSlotsFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every fetch all action
function* watchFetchTimeSlots() {
	yield takeLatest(actionTypes.FETCH_TIME_SLOTS, fetchTimeSlots)
}

export default function* rootSaga() {
	yield all(
		[
			watchConfirmAppointment,
			watchFetch,
			watchLock,
			watchNoAnswerAppointment,
			watchPostponeAppointment,
			watchSaveAppointment,
			watchSocketRemove,
			watchSocketUpdate,
			watchSocketUpdateTourDates,
			watchTakeOverLock,
			watchUnlock,
			watchFetchTimeSlots
		].map((saga) => fork(saga))
	)
}
