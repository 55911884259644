import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ListEmpty from './ListEmpty'
import ListItem from './ListItem'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	contentContainer: {
		flex: 1,
		position: 'relative',
		overflow: 'scroll'
	},
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class ParkedList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		routeItems: PropTypes.array.isRequired,
		select: PropTypes.func.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired
	}

	handleSelect = (orderKey) => () => {
		const { select } = this.props
		select(orderKey)
	}

	render() {
		const { classes, routeItems, selectedPlanningTypeKey } = this.props

		if (routeItems.length === 0) {
			return (
				<div className={classes.root}>
					<div className={classes.contentContainer}>
						<div className={classes.content}>
							<ListEmpty />
						</div>
					</div>
				</div>
			)
		}

		const listItems = routeItems.map((routeItem) => (
			<ListItem
				key={`parkedrouteitem-${routeItem.orderKey}`}
				planningTypeKey={selectedPlanningTypeKey}
				routeItem={routeItem}
				select={this.handleSelect(routeItem.orderKey)}
			/>
		))
		return (
			<div className={classes.root}>
				<div className={classes.contentContainer}>
					<List disablePadding className={classes.content}>
						{listItems}
					</List>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(ParkedList)
