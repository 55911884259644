import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiPlannings from '../../services/apiPlannings'
import actionTypes from './actionTypes'
import notificationsActionCreators from '../notifications/actionCreators'
import planningsActionCreators from './actionCreators'
import planningsSocket from '../../sockets/planningsSocket'

// Will toggle the planning socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(planningsSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(planningsSocket.close)
	}
}

// Will fetch all plannings
function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(planningsActionCreators.fetchRequest())
		try {
			const plannings = yield call(apiPlannings.getAll)
			yield put(planningsActionCreators.fetchSuccess(plannings))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:planning.Planning.Error.fetch')
			yield put(
				planningsActionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch, socket].map((saga) => fork(saga)))
}
