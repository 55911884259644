import { default as i18n } from 'i18next'
import qs from 'qs'
import elementsFetch from '../utils/fetch'

const toursUrl = '/api/tours'
const tourReplacementsUrl = (planningTypeKey, fromDate) =>
	`/api/tours?${qs.stringify({ planningTypeKey, fromDate })}`
const tourUrl = (id) => `/api/tours/${id}`
const tourConfirmUrl = (id) => `/api/tours/${id}/confirmation`
const tourFinalizeUrl = (id) => `/api/tours/${id}/finalization`
const tourLockUrl = (id) => `/api/tours/${id}/lock`

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(toursUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const getReplacements = async (planningTypeKey, fromDate) => {
	let response
	try {
		response = await elementsFetch(
			tourReplacementsUrl(planningTypeKey, fromDate)
		)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const get = async (id) => {
	let response
	try {
		response = await elementsFetch(tourUrl(id))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const confirm = async (id) => {
	let response
	try {
		response = await elementsFetch(tourConfirmUrl(id), {
			method: 'POST'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const finalize = async (id) => {
	let response
	try {
		response = await elementsFetch(tourFinalizeUrl(id), {
			method: 'POST'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const lock = async (id) => {
	let response
	try {
		response = await elementsFetch(tourLockUrl(id), {
			method: 'POST'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const takeOverLock = async (id) => {
	let response
	try {
		response = await elementsFetch(tourLockUrl(id), {
			method: 'PUT'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const undoConfirm = async (id) => {
	let response
	try {
		response = await elementsFetch(tourConfirmUrl(id), {
			method: 'DELETE'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const unlock = async (id) => {
	let response
	try {
		response = await elementsFetch(tourLockUrl(id), {
			method: 'DELETE'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getAll,
	getReplacements,
	get,
	confirm,
	finalize,
	lock,
	takeOverLock,
	undoConfirm,
	unlock
}

export default api
