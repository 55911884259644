import actionTypes from './actionTypes'

const actionCreators = {
	fetch: (day, month, year) => ({
		type: actionTypes.FETCH,
		meta: {
			day,
			month,
			year
		}
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (vehicles) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			vehicles
		}
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	search: (query) => ({
		type: actionTypes.SEARCH,
		payload: {
			query
		}
	}),

	selectCurrentDate: () => ({
		type: actionTypes.SELECT_CURRENT_DATE
	}),

	selectNextDate: () => ({
		type: actionTypes.SELECT_NEXT_DATE
	}),

	selectPreviousDate: () => ({
		type: actionTypes.SELECT_PREVIOUS_DATE
	}),

	applyFilter: ({
		onlyUnavailable,
		onlyAvailable,
		selectedPlanningTypeKeys,
		selectedVehicleTypes
	}) => ({
		type: actionTypes.APPLY_FILTER,
		payload: {
			onlyUnavailable,
			onlyAvailable,
			planningTypeKeys: selectedPlanningTypeKeys,
			vehicleTypes: selectedVehicleTypes
		}
	}),

	clearFilter: () => ({
		type: actionTypes.CLEAR_FILTER
	}),

	save: (history, vehicle) => ({
		type: actionTypes.SAVE,
		payload: {
			history,
			vehicle
		}
	}),

	saveRequest: () => ({
		type: actionTypes.SAVE_REQUEST
	}),

	saveSuccess: (vehicle) => ({
		type: actionTypes.SAVE_SUCCESS,
		payload: {
			vehicle
		}
	}),

	saveFailure: (errorMessages) => ({
		type: actionTypes.SAVE_FAILURE,
		payload: {
			errorMessages
		}
	})
}

export default actionCreators
