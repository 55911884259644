import { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import {
	Collapse,
	FormHelperText,
	Grid,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import localizedDescription from '../../../../../utils/localizedDescription'
import {
	formatPeriodInput,
	formatPeriodToSeconds
} from '../../../../../utils/vehicles'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingBottom: 24,
			paddingTop: 16
		})
	},
	content: {
		paddingRight: 8,
		display: 'flex',
		flexDirection: 'column'
	},
	typeSelect: {
		width: '100%'
	},
	item: {
		paddingTop: 0,
		paddingBottom: 0
	}
})

class Planning extends PureComponent {
	static propTypes = {
		availablePlanningTypes: PropTypes.array.isRequired,
		changePlanning: PropTypes.func.isRequired,
		changeMaxPeriod: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		meta: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		customVehicle: PropTypes.object
	}

	constructor(props) {
		super()

		const { maxPeriodDays, maxPeriodHours, maxPeriodMinutes } =
			formatPeriodInput(props.customVehicle.maxPeriod)

		this.state = {
			planningTypeKey: props.planningType.planningTypeKey,
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled: props.customVehicle.maxPeriodEnabled
		}
	}

	handleChangePlanningType = (event) => {
		const planningTypeKey = event.target.value
		const { changePlanning } = this.props
		changePlanning({
			planningTypeKey
		})
	}

	handleChangeMaxPeriod = (
		maxPeriodDays,
		maxPeriodHours,
		maxPeriodMinutes,
		maxPeriodEnabled
	) => {
		const { changeMaxPeriod } = this.props
		const maxPeriod = formatPeriodToSeconds(
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes
		)
		changeMaxPeriod({
			maxPeriod,
			maxPeriodEnabled
		})
	}

	handleChangeMaxPeriodDays = (event) => {
		const { maxPeriodHours, maxPeriodMinutes, maxPeriodEnabled } = this.state
		const maxPeriodDays = event.target.value
		this.setState({ maxPeriodDays })
		this.handleChangeMaxPeriod(
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled
		)
	}

	handleChangeMaxPeriodHours = (event) => {
		const { maxPeriodDays, maxPeriodMinutes, maxPeriodEnabled } = this.state
		const maxPeriodHours = event.target.value
		this.setState({ maxPeriodHours })
		this.handleChangeMaxPeriod(
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled
		)
	}

	handleChangeMaxPeriodMinutes = (event) => {
		const { maxPeriodDays, maxPeriodHours, maxPeriodEnabled } = this.state
		const maxPeriodMinutes = event.target.value
		this.setState({ maxPeriodMinutes })
		this.handleChangeMaxPeriod(
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled
		)
	}

	handleChangeMaxPeriodEnabled = (event) => {
		const { customVehicle, planningType } = this.props
		const maxPeriodEnabled = Boolean(event.target.checked)
		const maxPeriod =
			customVehicle.maxPeriod ||
			planningType.planningParams.standardParams.restrictions.tourRestrictions
				.maxPeriod
		const { maxPeriodDays, maxPeriodHours, maxPeriodMinutes } =
			formatPeriodInput(maxPeriod)
		this.setState({
			maxPeriodEnabled,
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes
		})
		this.handleChangeMaxPeriod(
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled
		)
	}

	render() {
		const { availablePlanningTypes, classes, meta } = this.props
		const {
			planningTypeKey,
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled
		} = this.state
		const { planningTypeEmpty, maxPeriodEmpty } = meta

		const planningTypeItems = availablePlanningTypes.map((elem) => (
			<MenuItem key={elem.planningTypeKey} value={elem.planningTypeKey}>
				{localizedDescription(elem.descriptions)}
			</MenuItem>
		))
		const primary = (
			<Select
				className={classes.typeSelect}
				value={planningTypeKey}
				onChange={this.handleChangePlanningType}
			>
				{planningTypeItems}
			</Select>
		)

		let secondary
		if (planningTypeEmpty) {
			secondary = (
				<Typography variant="caption" color="error">
					{i18n.t('app:vehicleplanning.Planning.typeRequired')}
				</Typography>
			)
		}

		const dayMenuItems = []
		for (let day = 0; day <= 5; day += 1) {
			dayMenuItems.push(
				<MenuItem key={day} value={day}>
					{day.toString()}
				</MenuItem>
			)
		}

		const hourMenuItems = []
		for (let hour = 0; hour <= 23; hour += 1) {
			hourMenuItems.push(
				<MenuItem key={hour} value={hour}>
					{hour.toString()}
				</MenuItem>
			)
		}

		const minuteMenuItems = []
		for (let minute = 0; minute <= 55; minute += 5) {
			minuteMenuItems.push(
				<MenuItem key={minute} value={minute}>
					{minute.toString()}
				</MenuItem>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.content}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:vehicleplanning.Planning.title')}
					</Typography>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								variant="caption"
								color={planningTypeEmpty ? 'error' : 'primary'}
							>
								{i18n.t('app:vehicleplanning.Planning.type')}
							</Typography>
							<List disablePadding>
								<ListItem disableGutters className={classes.item}>
									<ListItemText
										primaryTypographyProps={{ variant: 'body2' }}
										primary={primary}
										secondary={secondary}
									/>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={12}>
							<List disablePadding>
								<ListItem disableGutters>
									<ListItemText
										disableTypography
										primary={
											<Typography
												variant="body2"
												color={
													maxPeriodEnabled && maxPeriodEmpty
														? 'error'
														: 'initial'
												}
											>
												{i18n.t('app:vehicleplanning.Vehicle.maxPeriod')}
											</Typography>
										}
									/>
									<ListItemSecondaryAction>
										<Switch
											color="primary"
											checked={maxPeriodEnabled}
											onChange={this.handleChangeMaxPeriodEnabled}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={12}>
							<Collapse in={maxPeriodEnabled}>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<TextField
											fullWidth
											select
											value={maxPeriodDays}
											error={maxPeriodEmpty}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{i18n.t(
															'app:planningtypeadmin.PlanningType.Details.maxPeriodDaysUnit'
														)}
													</InputAdornment>
												)
											}}
											onChange={this.handleChangeMaxPeriodDays}
										>
											{dayMenuItems}
										</TextField>
									</Grid>
									<Grid item xs={4}>
										<TextField
											fullWidth
											select
											value={maxPeriodHours}
											error={maxPeriodEmpty}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{i18n.t(
															'app:planningtypeadmin.PlanningType.Details.maxPeriodHoursUnit'
														)}
													</InputAdornment>
												)
											}}
											onChange={this.handleChangeMaxPeriodHours}
										>
											{hourMenuItems}
										</TextField>
									</Grid>
									<Grid item xs={4}>
										<TextField
											fullWidth
											select
											value={maxPeriodMinutes}
											error={maxPeriodEmpty}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{i18n.t(
															'app:planningtypeadmin.PlanningType.Details.maxPeriodMinutesUnit'
														)}
													</InputAdornment>
												)
											}}
											onChange={this.handleChangeMaxPeriodMinutes}
										>
											{minuteMenuItems}
										</TextField>
									</Grid>
									<Grid item xs={12}>
										<FormHelperText error={maxPeriodEmpty}>
											{i18n.t(
												'app:planningtypeadmin.PlanningType.Details.maxPeriodErrorText'
											)}
										</FormHelperText>
									</Grid>
								</Grid>
							</Collapse>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Planning)
