import { default as i18n } from 'i18next'
import qs from 'qs'
import config from '../config'
import elementsFetch from '../utils/fetch'

const ordersUrl = '/api/orders'
const orderSearchUrl = (searchQuery) =>
	`/api/orders/search?${qs.stringify(searchQuery)}`
const orderUrl = (id, language) =>
	`/api/orders/${id}?refresh=true&language=${language}`
const orderConfirmUrl = (id) => `/api/orders/${id}/confirmation`
const orderNoAnswerUrl = (id) => `/api/orders/${id}/noanswer`
const orderPostponeUrl = (id) => `/api/orders/${id}/postpone`
const orderLockUrl = (id) => `/api/orders/${id}/lock`
const availableDatesUrl = (id) => `/api/orders/${id}/timeslots`

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(ordersUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const search = async (query) => {
	let response
	try {
		response = await elementsFetch(orderSearchUrl({ query }))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const get = async (id) => {
	let response
	try {
		const language = config.userLanguage
		response = await elementsFetch(orderUrl(id, language))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const confirmAppointment = async (id, appointment) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(orderConfirmUrl(id), {
			method: 'POST',
			headers,
			body: JSON.stringify({
				appointment
			})
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const noAnswerAppointment = async (id, appointment) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(orderNoAnswerUrl(id), {
			method: 'POST',
			headers,
			body: JSON.stringify({
				appointment
			})
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const postponeAppointment = async (id, appointment) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(orderPostponeUrl(id), {
			method: 'POST',
			headers,
			body: JSON.stringify({
				appointment
			})
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const patchAppointment = async (id, appointment) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(orderUrl(id), {
			method: 'PATCH',
			headers,
			body: JSON.stringify({
				appointment
			})
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const lock = async (id) => {
	let response
	try {
		response = await elementsFetch(orderLockUrl(id), {
			method: 'POST'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const takeOverLock = async (id) => {
	let response
	try {
		response = await elementsFetch(orderLockUrl(id), {
			method: 'PUT'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const unlock = async (id) => {
	let response
	try {
		response = await elementsFetch(orderLockUrl(id), {
			method: 'DELETE'
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const getUnvailableDates = async (id) => {
	let response
	try {
		response = await elementsFetch(availableDatesUrl(id))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	confirmAppointment,
	getAll,
	get,
	lock,
	noAnswerAppointment,
	patchAppointment,
	postponeAppointment,
	search,
	takeOverLock,
	unlock,
	getUnvailableDates
}

export default api
