import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import WeekViewerContainer from './WeekViewer'
import AvailabilityItemDetailsContainer from './AvailabilityItemDetails'

const styleSheet = {
	root: {
		display: 'flex',
		height: '100%'
	},
	weekviewer: {
		flex: 1
	},
	itemdetails: {
		marginLeft: 16,
		width: 400
	}
}

class DriverEdit extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driverId: PropTypes.string.isRequired
	}

	render() {
		const { classes, driverId } = this.props

		return (
			<div className={classes.root}>
				<div className={classes.weekviewer}>
					<WeekViewerContainer driverId={driverId} />
				</div>
				<div className={classes.itemdetails}>
					<AvailabilityItemDetailsContainer driverId={driverId} />
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DriverEdit)
