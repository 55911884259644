import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import localizedDescription from '../../../utils/localizedDescription'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16
	}
}

class Requirements extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		requirements: PropTypes.array.isRequired
	}

	render() {
		const { classes, requirements } = this.props

		if (requirements.length === 0) {
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.requirements')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.noRequirements')}
					</Typography>
				</div>
			)
		}

		const items = [...requirements]
			.sort((a, b) =>
				localizedDescription(a.translations).localeCompare(
					localizedDescription(b.translations)
				)
			)
			.map((requirement, index) => (
				<div key={`requirement-${index}`}>
					<Typography variant="body2">
						{localizedDescription(requirement.translations)}
					</Typography>
				</div>
			))

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.requirements')}
				</Typography>
				{items}
			</div>
		)
	}
}

export default withStyles(styleSheet)(Requirements)
