// Updated orders are received through the websocket connection
// This means most order mutating sagas will not put redux success actions after successful API calls

import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiOrders from '../../services/apiOrders'
import actionTypes from './actionTypes'
import notificationsActionCreators from '../notifications/actionCreators'
import ordersActionCreators from './actionCreators'
import ordersSocket from '../../sockets/ordersSocket'

// Will toggle the orders socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(ordersSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(ordersSocket.close)
	}
}

// Will fetch all orders for the selected week
function* fetchAll() {
	yield put(ordersActionCreators.fetchRequest())
	try {
		const orders = yield call(apiOrders.getAll)
		yield put(ordersActionCreators.fetchSuccess(orders))
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:appointmentscheduler.Order.Error.fetch')
		yield put(
			ordersActionCreators.fetchFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every fetch all action
function* watchFetchAll() {
	yield takeLatest(actionTypes.FETCH, fetchAll)
}

// Will fetch all orders for the selected week
function* search(action) {
	const { query } = action.payload
	if (query.length < 3) {
		// Clear search when query is too short
		yield put(ordersActionCreators.searchSuccess([]))
	} else {
		yield put(ordersActionCreators.searchRequest(query))
		try {
			const orders = yield call(apiOrders.search, query)
			yield put(ordersActionCreators.searchSuccess(orders))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:appointmentscheduler.Order.Error.search')
			yield put(
				ordersActionCreators.searchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

// Will handle every fetch all action
function* watchSearch() {
	yield takeLatest(actionTypes.SEARCH, search)
}

export default function* rootSaga() {
	yield all([socket, watchFetchAll, watchSearch].map((saga) => fork(saga)))
}
