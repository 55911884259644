import jwtDecode from 'jwt-decode'
import { default as i18n } from 'i18next'

export const clearTokens = () => {
	localStorage.removeItem('tokens')
}

export const readAccessToken = () => {
	let accessToken = null
	const tokensJSON = localStorage.getItem('tokens')
	if (tokensJSON) {
		const tokens = JSON.parse(tokensJSON)
		accessToken = tokens.accessToken
	}
	return accessToken
}

export const readRefreshToken = () => {
	let refreshToken = null
	const tokensJSON = localStorage.getItem('tokens')
	if (tokensJSON) {
		const tokens = JSON.parse(tokensJSON)
		refreshToken = tokens.refreshToken
	}
	return refreshToken
}

export const storeTokens = (tokens) => {
	localStorage.setItem('tokens', JSON.stringify(tokens))
}

export const refreshRequired = () => {
	const accessTokenData = jwtDecode(readAccessToken())
	// The expiration timestamp
	const exp = new Date(accessTokenData.exp * 1000)
	// The current timestamp
	const now = new Date()
	// Amount of ms remaining until expiration
	const diff = exp - now
	// Refresh when amount of remaining ms is less than 5 minutes
	return diff < 300000
}

export const authorization = () => {
	const token = readAccessToken()
	if (!token) {
		throw new Error(i18n.t('app:elements.Api.Generic.tokenAccessMissing'))
	}
	return `Bearer ${token}`
}
