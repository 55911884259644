import { combineReducers } from 'redux'

import actionTypes from './actionTypes'

const initialPostcodesState = []
const postcodesReducer = (state = initialPostcodesState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialPostcodesState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.postcodes
		}

		case actionTypes.FETCH_FAILURE: {
			return initialPostcodesState
		}

		case actionTypes.CREATE_POSTCODE_SUCCESS: {
			return [action.payload.postcode, ...state]
		}

		case actionTypes.REMOVE_POSTCODE_SUCCESS: {
			const { id } = action.meta
			return state.filter((postcode) => postcode.id !== id)
		}

		default: {
			return state
		}
	}
}

const initialPostcodesMetaState = {
	isLoading: false
}
const postcodesMetaReducer = (state = initialPostcodesMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}

const initialSelectedCountryState = 'NL'
const selectedCountryReducer = (
	state = initialSelectedCountryState,
	action
) => {
	switch (action.type) {
		case actionTypes.SELECT_COUNTRY: {
			return action.payload.country
		}

		default: {
			return state
		}
	}
}

const initialSearchState = {
	code: '',
	date: null
}
const searchReducer = (state = initialSearchState, action) => {
	switch (action.type) {
		case actionTypes.SEARCH: {
			return {
				...state,
				...action.payload.search
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	postcodes: postcodesReducer,
	meta: postcodesMetaReducer,
	selectedCountry: selectedCountryReducer,
	search: searchReducer
})
