import { PureComponent } from 'react'
import { default as i18n } from 'i18next'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core'

class UpdateChecker extends PureComponent {
	state = {
		updateAvailable: false,
		isBreakingUpdate: false
	}

	componentDidMount() {
		// Only run this function on Netlify
		if (process.env.REACT_APP_ENV === 'production') {
			this.checkLatestAppVersion()
			// Check for new version every hour
			this.checkLatestAppVersionInterval = setInterval(
				this.checkLatestAppVersion,
				3600000
			)
		}
	}

	componentWillUnmount() {
		if (this.checkLatestAppVersionInterval) {
			clearInterval(this.checkLatestAppVersionInterval)
		}
	}

	checkLatestAppVersion = async () => {
		const response = await fetch('/.netlify/functions/checkLatestAppVersion')
		if (response.ok && response.status === 200) {
			const { latestAppVersion } = await response.json()
			const currentMajor = process.env.REACT_APP_VERSION.split('.')[0]
			const latestMajor = latestAppVersion.split('.')[0]
			const isBreakingUpdate = currentMajor !== latestMajor
			const updateAvailable = process.env.REACT_APP_VERSION !== latestAppVersion
			if (updateAvailable) {
				this.setState({
					updateAvailable,
					isBreakingUpdate
				})
			}
		}
	}

	handleCancelClick = () => {
		this.setState({
			updateAvailable: false
		})
	}

	handleAppRefresh = () => {
		window.location.reload(true)
	}

	render() {
		const { updateAvailable, isBreakingUpdate } = this.state

		let cancelButton
		if (!isBreakingUpdate) {
			cancelButton = (
				<Button variant="outlined" onClick={this.handleCancelClick}>
					{i18n.t('app:updateChecker.cancelButtonText')}
				</Button>
			)
		}

		const description = isBreakingUpdate
			? i18n.t('app:updateChecker.descriptionBreaking')
			: i18n.t('app:updateChecker.description')

		return (
			<Dialog open={updateAvailable}>
				<DialogTitle>{i18n.t('app:updateChecker.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{cancelButton}
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleAppRefresh}
					>
						{i18n.t('app:updateChecker.confirmButtonText')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default UpdateChecker
