import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import ConfirmDialog from '../Shared/ConfirmDialog'
import languages from '../../utils/languages'

const styleSheet = {
	root: {
		flexGrow: 1
	},
	section: {
		padding: 16,
		marginBottom: 16
	},
	version: {
		textAlign: 'right'
	}
}

class Settings extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		changeLanguage: PropTypes.func.isRequired
	}

	state = {
		languageMenuAnchorEl: null,
		selectedLanguage: null,
		showConfirmLanguageDialog: false
	}

	handleOpenLanguageMenu = (event) => {
		this.setState({
			languageMenuAnchorEl: event.currentTarget
		})
	}

	handleCloseLanguageMenu = () => {
		this.closeLanguageMenu()
	}

	handleSelectLanguage = (id) => {
		this.closeLanguageMenu()
		this.setState({
			selectedLanguage: id,
			showConfirmLanguageDialog: true
		})
	}

	closeLanguageMenu() {
		this.setState({
			languageMenuAnchorEl: null
		})
	}

	handleLanguageCancel = () => {
		this.setState({
			showConfirmLanguageDialog: false
		})
	}

	handleLanguageConfirm = () => {
		const { changeLanguage } = this.props
		const { selectedLanguage } = this.state
		this.setState({
			showConfirmLanguageDialog: false
		})
		if (selectedLanguage) {
			changeLanguage(selectedLanguage)
		}
	}

	render() {
		const { classes } = this.props
		const { languageMenuAnchorEl, showConfirmLanguageDialog } = this.state

		const languagesMenuItems = languages().map((language) => (
			<MenuItem
				key={`language-${language.id}`}
				onClick={() => this.handleSelectLanguage(language.id)}
			>
				{language.description}
			</MenuItem>
		))

		return (
			<div className={classes.root}>
				<Typography paragraph variant="h4">
					{i18n.t('app:settings.Main.title')}
				</Typography>
				<Paper className={classes.section}>
					<Typography variant="h5" paragraph>
						{i18n.t('app:settings.Language.title')}
					</Typography>
					<Typography variant="body2" paragraph>
						{i18n.t('app:settings.Language.description')}
					</Typography>
					<Button color="primary" onClick={this.handleOpenLanguageMenu}>
						{i18n.t('app:settings.Language.select')}
					</Button>
					<Menu
						anchorEl={languageMenuAnchorEl}
						open={Boolean(languageMenuAnchorEl)}
						onClose={this.handleCloseLanguageMenu}
					>
						{languagesMenuItems}
					</Menu>
				</Paper>
				<div className={classes.version}>
					<Typography paragraph variant="caption">
						v{process.env.REACT_APP_VERSION}
					</Typography>
				</div>
				<ConfirmDialog
					cancel={this.handleLanguageCancel}
					cancelButtonText={i18n.t(
						'app:settings.Language.ConfirmLanguageDialog.cancel'
					)}
					confirm={this.handleLanguageConfirm}
					confirmButtonText={i18n.t(
						'app:settings.Language.ConfirmLanguageDialog.confirm'
					)}
					descriptionText={i18n.t(
						'app:settings.Language.ConfirmLanguageDialog.description'
					)}
					isOpen={showConfirmLanguageDialog}
					titleText={i18n.t(
						'app:settings.Language.ConfirmLanguageDialog.title'
					)}
				/>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Settings)
