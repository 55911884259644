import { PureComponent } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import ToursMap from './ToursMap'

class ToursMapContainer extends PureComponent {
	state = {
		manualPlanningMode: false,
		mapChannel: new BroadcastChannel('map'),
		parkedRouteItems: [],
		planningTypes: [],
		selectedTourIds: [],
		tours: [],
		unscheduledOrders: []
	}

	componentDidMount() {
		// Handle incoming BroadcastChannel messages when ready
		const { mapChannel } = this.state
		mapChannel.onmessage = (message) => {
			switch (message.type) {
				case 'map-update-selectedtourids':
					this.handleUpdateSelectedTourIds(message.payload.selectedTourIds)
					break
				case 'map-update-tours':
					this.handleUpdateTours(message.payload.tours)
					break
				case 'map-update-planningtypes':
					this.handleUpdatePlanningTypes(message.payload.planningTypes)
					break
				case 'map-unscheduled-orders':
					this.handleUpdateUnscheduledOrders(message.payload.unscheduledOrders)
					break
				case 'map-parked-route-items':
					this.handleUpdateParkedRouteItems(message.payload.parkedRouteItems)
					break
				case 'map-set-manual-planning-mode':
					this.handleSetManualPlanningMode(message.payload.manualPlanningMode)
					break
				default:
					break
			}
		}
		// Request state when ready
		mapChannel.postMessage({ type: 'map-ready' })
	}

	componentWillUnmount() {
		const { mapChannel } = this.state
		mapChannel.close()
	}

	handleDeselect = (tourId) => {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'tour-deselected',
			payload: { tourId }
		})
	}

	handleSelect = (tourId) => {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'tour-selected',
			payload: { tourId }
		})
	}

	handleUpdateSelectedTourIds = (selectedTourIds) => {
		this.setState({
			selectedTourIds
		})
	}

	handleUpdateTours = (tours) => {
		// Also update the unscheduled orders
		const { unscheduledOrders } = this.state
		const selectedOrderNumbers = tours.reduce(
			(acc, tour) =>
				acc.concat(tour.route.map((routeItem) => routeItem.orderKey)),
			[]
		)
		const visibleUnscheduledOrders = unscheduledOrders.filter(
			(unscheduledOrder) =>
				!selectedOrderNumbers.includes(unscheduledOrder.orderKey)
		)

		this.setState({
			tours,
			unscheduledOrders: visibleUnscheduledOrders
		})
	}

	handleUpdatePlanningTypes = (planningTypes) => {
		this.setState({
			planningTypes
		})
	}

	handleSetManualPlanningMode = (manualPlanningMode) => {
		this.setState({
			manualPlanningMode,
			parkedRouteItems: []
		})
	}

	handleUpdateUnscheduledOrders = (unscheduledOrders) => {
		// Do not display the orders which are already selected
		const { tours } = this.state
		const selectedOrderNumbers = tours.reduce(
			(acc, tour) =>
				acc.concat(tour.route.map((routeItem) => routeItem.orderKey)),
			[]
		)
		const visibleUnscheduledOrders = unscheduledOrders.filter(
			(unscheduledOrder) =>
				!selectedOrderNumbers.includes(unscheduledOrder.orderKey)
		)
		this.setState({
			unscheduledOrders: visibleUnscheduledOrders
		})
	}

	handleUpdateParkedRouteItems = (parkedRouteItems) => {
		// Do not display parked route items which are not confirmed
		const visibleParkedRouteItems = parkedRouteItems.filter(
			(parkedRouteItem) =>
				parkedRouteItem?.characteristics?.isUnconfirmed === false
		)

		this.setState({
			parkedRouteItems: visibleParkedRouteItems
		})
	}

	render() {
		const {
			manualPlanningMode,
			parkedRouteItems,
			planningTypes,
			selectedTourIds,
			tours,
			unscheduledOrders
		} = this.state

		return (
			<ToursMap
				deselect={this.handleDeselect}
				manualPlanningMode={manualPlanningMode}
				parkedRouteItems={parkedRouteItems}
				planningTypes={planningTypes}
				select={this.handleSelect}
				selectedTourIds={selectedTourIds}
				tours={tours}
				unscheduledOrders={unscheduledOrders}
			/>
		)
	}
}

export default ToursMapContainer
