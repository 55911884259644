import { default as i18n } from 'i18next'
import { all, call, fork, put, select, take } from 'redux-saga/effects'

import apiExcludeServiceDates from '../../services/apiExcludedServiceDates'
import excludeServiceDatesSocket from '../../sockets/excludedServiceDatesSocket'
import notificationsActionCreators from '../notifications/actionCreators'
import actionCreators from './actionCreators'
import actionTypes from './actionTypes'

// Will toggle the unavailable proposal dates socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(excludeServiceDatesSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(excludeServiceDatesSocket.close)
	}
}

function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(actionCreators.fetchRequest())
		try {
			const excludeServiceDates = yield call(apiExcludeServiceDates.getAll)
			yield put(actionCreators.fetchSuccess(excludeServiceDates))
		} catch (error) {
			const errorMessage = i18n.t(
				'excludedServiceDateadmin:ExcludedServiceDates.Error.fetch'
			)
			yield put(actionCreators.fetchFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

function* add() {
	while (true) {
		yield take(actionTypes.ADD)
		yield put(actionCreators.addRequest())
		try {
			const selectedPlanningTypeKey = yield select(
				(state) => state.toursState.selectedPlanningTypeKey
			)
			const selectedDate = yield select(
				(state) => state.toursState.selectedDate
			)
			const excludeServiceDate = {
				planningTypeKey: selectedPlanningTypeKey,
				excludeDate: selectedDate
			}
			yield call(apiExcludeServiceDates.add, excludeServiceDate)
			yield put(actionCreators.addSuccess())
		} catch (error) {
			const errorMessage = i18n.t(
				'app:planning.Tours.Error.addExcludedServiceDateError'
			)
			yield put(actionCreators.addFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

function* remove() {
	while (true) {
		yield take(actionTypes.REMOVE)
		yield put(actionCreators.removeRequest())
		try {
			const selectedPlanningTypeKey = yield select(
				(state) => state.toursState.selectedPlanningTypeKey
			)
			const selectedDate = yield select(
				(state) => state.toursState.selectedDate
			)
			const excludeServiceDates = yield select(
				(state) => state.excludedServiceDatesState.excludedServiceDates
			)
			const selectedExcludedServiceDate = excludeServiceDates.find(
				(excludedServiceDate) =>
					excludedServiceDate.planningTypeKey === selectedPlanningTypeKey &&
					excludedServiceDate.excludeDate === selectedDate
			)
			yield call(apiExcludeServiceDates.remove, selectedExcludedServiceDate.id)
			yield put(actionCreators.removeSuccess())
		} catch (error) {
			const errorMessage = i18n.t(
				'app:planning.Tours.Error.removeExcludedServiceDateError'
			)
			yield put(actionCreators.removeFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([socket, fetch, add, remove].map((saga) => fork(saga)))
}
