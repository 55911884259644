import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	IconButton,
	ListItem as MaterialListItem,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	BoxesAltFal,
	CalendarTimesFal,
	ClockFal,
	SignOutAltFal,
	UserHeadsetFad
} from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'
import { formatCustomer } from '../../../../../utils/customer'
import formatDuration from '../../../../../utils/duration'
import { PLANNING_TYPE_MEERDAAGSE_RIT } from '../../../../../utils/constants'

const styleSheet = (theme) => ({
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	},
	largeSeparator: {
		marginLeft: 10,
		marginRight: 10
	},
	iconRed: {
		color: theme.palette.secondary.main
	},
	secondary: {
		display: 'flex',
		alignItems: 'center'
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 4,
		marginRight: 16
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired,
		selectedPlanningType: PropTypes.object.isRequired,
		unscheduledOrder: PropTypes.object.isRequired
	}

	render() {
		const { classes, select, selectedPlanningType, unscheduledOrder } =
			this.props

		const formattedCustomer = formatCustomer(unscheduledOrder.customer)
		const formattedDuration = formatDuration(unscheduledOrder.serviceTime)
		const isPlanningTypeMultiDay = Boolean(
			selectedPlanningType &&
				selectedPlanningType.planningTypeKey === PLANNING_TYPE_MEERDAAGSE_RIT
		)

		const salesOrderText =
			unscheduledOrder.salesOrderDeliveryGroup &&
			unscheduledOrder.salesOrderDeliveryGroup !== '0'
				? `${unscheduledOrder.salesOrderNumber} (${unscheduledOrder.salesOrderDeliveryGroup})`
				: `${unscheduledOrder.salesOrderNumber}`

		const primary = (
			<>
				<div className={classes.primary}>
					<Typography variant="h6">{formattedCustomer.name}</Typography>
					<Typography variant="h6" className={classes.separator} />
					<Typography variant="h6" color="textSecondary">
						{salesOrderText}
					</Typography>
					<Typography variant="h6" className={classes.separator} />
				</div>
				<Typography variant="body2">
					{formattedCustomer.postCode} {formattedCustomer.city},{' '}
					{formattedCustomer.country}
				</Typography>
			</>
		)

		const hasTimeAppointment = Boolean(
			moment.duration(unscheduledOrder.appointmentTimeFrom).asSeconds() >
				selectedPlanningType.orderAllowedFrom ||
				moment.duration(unscheduledOrder.appointmentTimeTill).asSeconds() <
					selectedPlanningType.orderAllowedTill
		)
		let dayTimeAppointment
		if (
			isPlanningTypeMultiDay ||
			(hasTimeAppointment &&
				unscheduledOrder.appointmentTimeFrom &&
				unscheduledOrder.appointmentTimeTill)
		) {
			let dayAppointmentText
			if (isPlanningTypeMultiDay) {
				dayAppointmentText = moment
					.tz(unscheduledOrder.appointmentDate, 'Europe/Amsterdam')
					.format('dddd')
				dayAppointmentText = `${dayAppointmentText
					.charAt(0)
					.toUpperCase()}${dayAppointmentText.slice(1)}`
			}

			let timeAppointmentText
			if (
				hasTimeAppointment &&
				unscheduledOrder.appointmentTimeFrom &&
				unscheduledOrder.appointmentTimeTill
			) {
				const formattedAppointmentTimeFrom = formatDuration(
					moment.duration(unscheduledOrder.appointmentTimeFrom).asSeconds(),
					{ withSeconds: false }
				)
				const formattedAppointmentTimeTill = formatDuration(
					moment.duration(unscheduledOrder.appointmentTimeTill).asSeconds(),
					{ withSeconds: false }
				)
				timeAppointmentText = `${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill}`
			}

			const dayTimeAppointmentText = [
				dayAppointmentText,
				timeAppointmentText
			].join(' ')

			dayTimeAppointment = (
				<>
					<Typography variant="body2" className={classes.largeSeparator} />
					<SvgIcon color="disabled" className={classes.icon}>
						<UserHeadsetFad />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{dayTimeAppointmentText}
					</Typography>
				</>
			)
		}

		const secondary = (
			<div className={classes.secondary}>
				<div className={classes.label}>
					<SvgIcon color="disabled" className={classes.icon}>
						<ClockFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{formattedDuration}
					</Typography>
					<Typography variant="body2" className={classes.largeSeparator} />
					<SvgIcon color="disabled" className={classes.icon}>
						<BoxesAltFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{unscheduledOrder.volume}
					</Typography>
					{dayTimeAppointment}
				</div>
			</div>
		)

		// Cannot select order when the appointment is not confirmed
		if (
			unscheduledOrder.requireAppointment &&
			!unscheduledOrder.isAppointmentConfirmed
		) {
			return (
				<MaterialListItem>
					<ListItemText disableTypography primary={primary} />
					<ListItemSecondaryAction>
						<IconButton disabled>
							<SvgIcon className={classes.iconRed}>
								<CalendarTimesFal />
							</SvgIcon>
						</IconButton>
					</ListItemSecondaryAction>
				</MaterialListItem>
			)
		}

		return (
			<MaterialListItem button onClick={select}>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
				<ListItemSecondaryAction>
					<IconButton onClick={select} color="primary">
						<SvgIcon>
							<SignOutAltFal />
						</SvgIcon>
					</IconButton>
				</ListItemSecondaryAction>
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
