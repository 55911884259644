import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Badge,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Paper,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import TourEditList from './TourEditList'
import TourEditControls from './TourEditControls'
import TourEditTargetList from './TourEditTargetList'
import ParkedList from './ParkedList'
import UnscheduledOrderSelection from './UnscheduledOrderSelection'

const styleSheet = {
	split: {
		display: 'flex',
		height: '100%'
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: 16,
		width: 600,
		height: '100%'
	},
	tabs: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		overflow: 'hidden'
	},
	details: {
		flex: 1
	},
	tab: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	tabBadge: {
		padding: '0 16px'
	},
	loadingDialogContent: {
		textAlign: 'center'
	},
	loadingProgress: {
		margin: 20
	}
}

class TourEdit extends PureComponent {
	static propTypes = {
		cancel: PropTypes.func.isRequired,
		cancelProcess: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		isModified: PropTypes.bool,
		optimize: PropTypes.func.isRequired,
		manualPlanningAction: PropTypes.func.isRequired,
		manualPlanningAddParkedRouteItem: PropTypes.func.isRequired,
		manualPlanningAddUnscheduledOrder: PropTypes.func.isRequired,
		manualPlanningDown: PropTypes.func.isRequired,
		manualPlanningMove: PropTypes.func.isRequired,
		manualPlanningRemove: PropTypes.func.isRequired,
		manualPlanningRemoveAllOrdersWithoutAppointment: PropTypes.func.isRequired,
		manualPlanningRemoveOrdersWithoutAppointment: PropTypes.func.isRequired,
		manualPlanningReverse: PropTypes.func.isRequired,
		manualPlanningUp: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		parkedRouteItems: PropTypes.array.isRequired,
		reduce: PropTypes.func.isRequired,
		save: PropTypes.func.isRequired,
		confirmSave: PropTypes.func.isRequired,
		showSaveDialog: PropTypes.bool.isRequired,
		closeSaveDialog: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedPlanningType: PropTypes.object.isRequired,
		startAtDepot: PropTypes.bool.isRequired,
		toggleStartAtDepot: PropTypes.func.isRequired,
		tours: PropTypes.array.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		validate: PropTypes.func.isRequired,
		unscheduledOrders: PropTypes.array.isRequired,
		unscheduledOrdersLoading: PropTypes.bool
	}

	state = {
		selectedOrderKey: null,
		selectedFromTourId: null,
		selectedTab: 'unscheduled',
		selectedUnscheduledOrderKeys: [],
		showTargetTours: false
	}

	handleChangeTab = (event, value) => {
		this.setState({
			selectedTab: value
		})
	}

	handleHideTargetTours = () => {
		this.setState({
			showTargetTours: false
		})
	}

	handleManualPlanningMove = (fromTourId, orderKey) => {
		this.setState({
			selectedOrderKey: orderKey,
			selectedFromTourId: fromTourId,
			showTargetTours: true,
			targetToursSelectCallback: this.handleManualPlanningMoveTargetSelect
		})
	}

	handleManualPlanningMoveTargetSelect = (toTourId) => {
		const { manualPlanningMove } = this.props
		const { selectedOrderKey, selectedFromTourId } = this.state
		manualPlanningMove(selectedFromTourId, toTourId, selectedOrderKey)
		this.setState({
			showTargetTours: false
		})
	}

	handleManualPlanningAddUnscheduledOrder = (unscheduledOrder) => {
		this.setState({
			selectedFromTourId: null,
			selectedUnscheduledOrder: unscheduledOrder,
			showTargetTours: true,
			targetToursSelectCallback:
				this.handleManualPlanningAddUnscheduledOrderTargetSelect
		})
	}

	handleManualPlanningAddUnscheduledOrderTargetSelect = (tourId) => {
		const { manualPlanningAddUnscheduledOrder } = this.props
		const { selectedUnscheduledOrder, selectedUnscheduledOrderKeys } =
			this.state
		const alreadySelected = selectedUnscheduledOrderKeys.some(
			(selectedUnscheduledOrderKey) =>
				selectedUnscheduledOrderKey === selectedUnscheduledOrder.orderKey
		)
		if (!alreadySelected) {
			manualPlanningAddUnscheduledOrder(tourId, selectedUnscheduledOrder)
			this.setState({
				selectedUnscheduledOrder: null,
				selectedUnscheduledOrderKeys: [
					...selectedUnscheduledOrderKeys,
					selectedUnscheduledOrder.orderKey
				],
				showTargetTours: false
			})
		}
	}

	handleManualPlanningAddParkedRouteItem = (parkedRouteItemOrderKey) => {
		this.setState({
			selectedFromTourId: null,
			selectedParkedRouteItemOrderKey: parkedRouteItemOrderKey,
			showTargetTours: true,
			targetToursSelectCallback:
				this.handleManualPlanningAddParkedRouteItemTargetSelect
		})
	}

	handleManualPlanningAddParkedRouteItemTargetSelect = (tourId) => {
		const { manualPlanningAddParkedRouteItem } = this.props
		const { selectedParkedRouteItemOrderKey } = this.state
		manualPlanningAddParkedRouteItem(tourId, selectedParkedRouteItemOrderKey)
		this.setState({
			selectedParkedRouteItemOrderKey: null,
			showTargetTours: false
		})
	}

	handleCancelProcess = () => {
		const { cancelProcess } = this.props
		cancelProcess()
	}

	render() {
		const {
			isModified,
			classes,
			cancel,
			isLoading,
			manualPlanningAction,
			manualPlanningDown,
			manualPlanningRemove,
			manualPlanningRemoveAllOrdersWithoutAppointment,
			manualPlanningRemoveOrdersWithoutAppointment,
			manualPlanningReverse,
			manualPlanningUp,
			metaTours,
			optimize,
			ownUserId,
			parkedRouteItems,
			reduce,
			save,
			confirmSave,
			showSaveDialog,
			closeSaveDialog,
			selectedMoment,
			selectedPlanningType,
			startAtDepot,
			toggleStartAtDepot,
			tours,
			tourNumbers,
			unscheduledOrders,
			unscheduledOrdersLoading,
			validate
		} = this.props
		const {
			selectedFromTourId,
			selectedParkedRouteItemOrderKey,
			selectedTab,
			selectedUnscheduledOrder,
			selectedUnscheduledOrderKeys,
			showTargetTours,
			targetToursSelectCallback
		} = this.state

		const loadingDialog = (
			<Dialog open={isLoading}>
				<DialogContent className={classes.loadingDialogContent}>
					<CircularProgress className={classes.loadingProgress} />
					<Typography variant="subtitle1">
						{i18n.t('app:planning.Manual.LoadingDialog.description')}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleCancelProcess} color="secondary">
						{i18n.t('app:planning.Manual.LoadingDialog.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		)

		const targetTours = selectedFromTourId
			? tours.filter((selectedTour) => selectedTour.id !== selectedFromTourId)
			: tours
		let selectedOrderRequirements
		if (showTargetTours) {
			if (
				targetToursSelectCallback ===
				this.handleManualPlanningAddParkedRouteItemTargetSelect
			) {
				// Selected a parked route item
				const selectedParkedRouteItem =
					parkedRouteItems.find(
						(parkedRouteItem) =>
							parkedRouteItem.orderKey === selectedParkedRouteItemOrderKey
					) || {}
				selectedOrderRequirements = selectedParkedRouteItem.requirements
			}
			if (
				targetToursSelectCallback ===
				this.handleManualPlanningAddUnscheduledOrderTargetSelect
			) {
				// Selected an unscheduled order
				selectedOrderRequirements = selectedUnscheduledOrder.requirements
			}
		}
		const targetToursDialog = (
			<Dialog
				fullWidth
				open={showTargetTours}
				onClose={this.handleHideTargetTours}
			>
				<TourEditTargetList
					select={targetToursSelectCallback}
					selectedOrderRequirements={selectedOrderRequirements}
					tourNumbers={tourNumbers}
					tours={targetTours}
				/>
			</Dialog>
		)

		// Do not display the orders which are already selected
		const visibleUnscheduledOrders = unscheduledOrdersLoading
			? []
			: unscheduledOrders.filter(
					(unscheduledOrder) =>
						!selectedUnscheduledOrderKeys.some(
							(selectedUnscheduledOrderKey) =>
								selectedUnscheduledOrderKey === unscheduledOrder.orderKey
						)
			  )

		const tabLabelUnscheduled =
			visibleUnscheduledOrders.length > 0 ? (
				<Badge
					className={classes.tabBadge}
					color="primary"
					max={9999}
					badgeContent={visibleUnscheduledOrders.length}
				>
					{i18n.t('app:planning.Manual.unscheduled')}
				</Badge>
			) : (
				i18n.t('app:planning.Manual.unscheduled')
			)
		const tabLabelParked =
			parkedRouteItems.length > 0 ? (
				<Badge
					className={classes.tabBadge}
					color="primary"
					max={9999}
					badgeContent={parkedRouteItems.length}
				>
					{i18n.t('app:planning.Manual.parked')}
				</Badge>
			) : (
				i18n.t('app:planning.Manual.parked')
			)

		let tabContent
		if (selectedTab === 'unscheduled') {
			tabContent = (
				<UnscheduledOrderSelection
					isLoading={unscheduledOrdersLoading}
					select={this.handleManualPlanningAddUnscheduledOrder}
					selectedPlanningType={selectedPlanningType}
					unscheduledOrders={visibleUnscheduledOrders}
				/>
			)
		}
		if (selectedTab === 'parked') {
			tabContent = (
				<ParkedList
					select={this.handleManualPlanningAddParkedRouteItem}
					routeItems={parkedRouteItems}
					selectedPlanningTypeKey={selectedPlanningType.planningTypeKey}
				/>
			)
		}

		const hasViolations = tours.some((tour) => {
			if (tour.violations && tour.violations.length > 0) {
				return true
			}
			return tour.route.some(
				(routItem) => routItem.violations && routItem.violations.length > 0
			)
		})

		const hasOrdersWithoutAppointment = tours.some((tour) =>
			tour.route.some(
				(routeItem) =>
					routeItem.characteristics && routeItem.characteristics.isUnconfirmed
			)
		)

		const isEmpty = !tours.some((tour) => tour.route.length > 0)

		return (
			<div className={classes.split}>
				<div className={classes.list}>
					<TourEditControls
						cancel={cancel}
						hasOrdersWithoutAppointment={hasOrdersWithoutAppointment}
						hasViolations={hasViolations}
						isEmpty={isEmpty}
						isModified={isModified}
						manualPlanningRemoveAllOrdersWithoutAppointment={
							manualPlanningRemoveAllOrdersWithoutAppointment
						}
						optimize={optimize}
						reduce={reduce}
						save={save}
						confirmSave={confirmSave}
						showSaveDialog={showSaveDialog}
						closeSaveDialog={closeSaveDialog}
						selectedMoment={selectedMoment}
						selectedPlanningType={selectedPlanningType}
						startAtDepot={startAtDepot}
						toggleStartAtDepot={toggleStartAtDepot}
						validate={validate}
					/>
					<Paper className={classes.tabs}>
						<Tabs
							onChange={this.handleChangeTab}
							indicatorColor="primary"
							textColor="primary"
							value={selectedTab}
							variant="fullWidth"
						>
							<Tab value="unscheduled" label={tabLabelUnscheduled} />
							<Tab value="parked" label={tabLabelParked} />
						</Tabs>
						{tabContent}
					</Paper>
				</div>
				<div className={classes.details}>
					<TourEditList
						isModified={isModified}
						manualPlanningAction={manualPlanningAction}
						manualPlanningDown={manualPlanningDown}
						manualPlanningMove={this.handleManualPlanningMove}
						manualPlanningRemove={manualPlanningRemove}
						manualPlanningRemoveOrdersWithoutAppointment={
							manualPlanningRemoveOrdersWithoutAppointment
						}
						manualPlanningReverse={manualPlanningReverse}
						manualPlanningUp={manualPlanningUp}
						metaTours={metaTours}
						ownUserId={ownUserId}
						tours={tours}
						tourNumbers={tourNumbers}
					/>
				</div>
				{targetToursDialog}
				{loadingDialog}
			</div>
		)
	}
}

export default withStyles(styleSheet)(TourEdit)
