import { PureComponent } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { pink, blueGrey, orange } from '@material-ui/core/colors'
import { default as i18n } from 'i18next'
import { formatCustomer } from '../../utils/customer'
import formatDuration from '../../utils/duration'

const styleSheet = {
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

const xMapWmsUrl = `https://xmap-${process.env.REACT_APP_PTV_URL}/WMS/WMS?xtok=${process.env.REACT_APP_PTV_TOKEN}`
const xMapTileUrl = `https://xmap-${process.env.REACT_APP_PTV_URL}/WMS/GetTile/xmap-silkysand-bg/{x}/{y}/{z}.png?xtok=${process.env.REACT_APP_PTV_TOKEN}`

class Map extends PureComponent {
	mapRef = null

	static propTypes = {
		classes: PropTypes.object.isRequired,
		deselect: PropTypes.func.isRequired,
		manualPlanningMode: PropTypes.bool,
		parkedRouteItems: PropTypes.array.isRequired,
		planningTypes: PropTypes.array.isRequired,
		select: PropTypes.func.isRequired,
		selectedTourIds: PropTypes.array.isRequired,
		tours: PropTypes.array.isRequired,
		unscheduledOrders: PropTypes.array.isRequired
	}

	state = {
		markers: [],
		polylines: []
	}

	setMapRef = (ref) => {
		this.mapRef = ref
	}

	componentDidMount() {
		this.renderMap()
	}

	componentDidUpdate(prevProps) {
		const {
			manualPlanningMode,
			selectedTourIds,
			tours,
			unscheduledOrders,
			parkedRouteItems
		} = this.props
		const { polylines } = this.state
		if (
			tours !== prevProps.tours ||
			manualPlanningMode !== prevProps.manualPlanningMode ||
			unscheduledOrders !== prevProps.unscheduledOrders ||
			parkedRouteItems !== prevProps.parkedRouteItems
		) {
			this.renderMap()
		} else if (selectedTourIds !== prevProps.selectedTourIds) {
			this.renderMapSelection(polylines)
		}
	}

	handleLineOrMarkerClick = (tourId) => () => {
		const { deselect, manualPlanningMode, select, selectedTourIds } = this.props
		// Do not allow (de)selection when in manual planning mode
		if (!manualPlanningMode) {
			if (selectedTourIds.some((selectedTourId) => selectedTourId === tourId)) {
				deselect(tourId)
			} else {
				select(tourId)
			}
		}
	}

	renderMap() {
		const {
			manualPlanningMode,
			planningTypes,
			selectedTourIds,
			tours,
			unscheduledOrders,
			parkedRouteItems
		} = this.props
		const { markers, polylines } = this.state

		// Create the PTV map if it doesn't exist yet
		if (!this.map) {
			// Render the map with PTV layers
			this.map = new window.L.Map(this.mapRef, {
				attributionControl: false,
				center: new window.L.LatLng(51.661128, 5.668836), // Centered on Swiss Sense HQ, Uden
				zoom: 10
			})

			this.background = window.L.tileLayer(xMapTileUrl, {
				maxZoom: 19,
				minZoom: 0,
				opacity: 1.0,
				noWrap: false
			}).addTo(this.map)

			this.labels = window.L.nonTiledLayer
				.wms(xMapWmsUrl, {
					maxZoom: 19,
					minZoom: 0,
					opacity: 1.0,
					layers: 'xmap-silkysand-fg',
					format: 'image/png',
					transparent: true,
					pane: 'tilePane',
					zIndex: 3
				})
				.addTo(this.map)
		}

		// Remove all existing markers and lines
		markers.forEach((marker) => this.map.removeLayer(marker))
		polylines.forEach((polyline) => this.map.removeLayer(polyline))

		// Draw lines
		if (tours && tours.length > 0) {
			const newMarkers = []
			const newPolylines = []

			let toursToDraw = tours
			if (manualPlanningMode) {
				// Only draw selected tours when in manual planning mode
				toursToDraw = tours.filter((tour) =>
					selectedTourIds.some((selectedTourId) => selectedTourId === tour.id)
				)
			}

			// Draw markers for depots (making sure not to render duplicates)
			const depotsToDrawMap = toursToDraw.reduce((acc, tour) => {
				if (tour.startDepot.coordinates) {
					const key = `${tour.startDepot.coordinates.lat}${tour.startDepot.coordinates.lng}`
					const depotToDraw = acc.get(key)
					if (!depotToDraw) {
						acc.set(key, tour.startDepot)
					}
				}
				if (tour.endDepot.coordinates) {
					const key = `${tour.endDepot.coordinates.lat}${tour.endDepot.coordinates.lng}`
					const depotToDraw = acc.get(key)
					if (!depotToDraw) {
						acc.set(key, tour.endDepot)
					}
				}
				return acc
			}, new window.Map()) // Use window because otherwise `Map` will resolve to the leaflet Map class...
			const depotsToDraw = [...depotsToDrawMap.values()]
			depotsToDraw.forEach((depotToDraw) => {
				const redIcon = new window.L.Icon({
					iconUrl: '/markers/marker-icon-2x-red.png',
					shadowUrl: '/markers/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				})
				const marker = window.L.marker(
					[depotToDraw.coordinates.lat, depotToDraw.coordinates.lng],
					{ icon: redIcon }
				).addTo(this.map)
				const popupContent = `
					<div style="font-size: 1.6em;">
						<p style="font-weight: bold; margin: 0;">${depotToDraw.name}</p>
					</div>`
				const popup = window.L.popup({ closeButton: false }).setContent(
					popupContent
				)
				marker.bindPopup(popup)
				marker.on('mouseover', () => {
					marker.openPopup()
				})
				marker.on('mouseout', () => {
					marker.closePopup()
				})
				newMarkers.push(marker)
			})

			// Draw lines for each tour
			toursToDraw.forEach((tour) => {
				const line = tour.route
					.filter((routeItem) => routeItem.coordinates)
					.map((routeItem) => [
						routeItem.coordinates.lat,
						routeItem.coordinates.lng
					])
				const strokeLine = window.L.polyline(line, {
					color: blueGrey[800],
					weight: 8
				})
					.addTo(this.map)
					.on('click', this.handleLineOrMarkerClick(tour.id))
				strokeLine.meta = { type: 'stroke', tourId: tour.id }
				newPolylines.push(strokeLine)
				const fillLine = window.L.polyline(line, {
					color: blueGrey[500],
					weight: 6
				})
					.addTo(this.map)
					.on('click', this.handleLineOrMarkerClick(tour.id))
				fillLine.meta = { type: 'fill', tourId: tour.id }
				newPolylines.push(fillLine)
			})

			// Draw markers for each tour
			toursToDraw.forEach((tour) => {
				tour.route
					.filter((routeItem) => routeItem.coordinates)
					.forEach((routeItem, index, routeItemsWithCoordinates) => {
						// Sometimes the second route item is at the same location as the first route item, all route items with the same coordinates as the first route item
						// are considered the first stop, and will get the enhanced marker. Else the first enhanced marked is overdrawed by a normal marker.
						const isFirstStop =
							routeItem.coordinates.lat ===
								routeItemsWithCoordinates[0].coordinates.lat &&
							routeItem.coordinates.lng ===
								routeItemsWithCoordinates[0].coordinates.lng
						let marker
						if (routeItem?.characteristics?.isUnconfirmed) {
							// blue icon for unconfirmed routeItems
							const blueIcon = new window.L.Icon({
								// first stop had a different marker
								iconUrl: isFirstStop
									? '/markers/marker-icon-2x-blue-first.png'
									: '/markers/marker-icon-2x-blue.png',
								shadowUrl: '/markers/marker-shadow.png',
								iconSize: [25, 41],
								iconAnchor: [12, 41],
								popupAnchor: [1, -34],
								shadowSize: [41, 41]
							})
							marker = window.L.marker(
								[routeItem.coordinates.lat, routeItem.coordinates.lng],
								{ icon: blueIcon }
							)
								.addTo(this.map)
								.on('click', this.handleLineOrMarkerClick(tour.id))
						} else {
							// green icon for confirmed routeItems
							const greenIcon = new window.L.Icon({
								// first stop had a different marker
								iconUrl: isFirstStop
									? '/markers/marker-icon-2x-green-first.png'
									: '/markers/marker-icon-2x-green.png',
								shadowUrl: '/markers/marker-shadow.png',
								iconSize: [25, 41],
								iconAnchor: [12, 41],
								popupAnchor: [1, -34],
								shadowSize: [41, 41]
							})
							marker = window.L.marker(
								[routeItem.coordinates.lat, routeItem.coordinates.lng],
								{ icon: greenIcon }
							)
								.addTo(this.map)
								.on('click', this.handleLineOrMarkerClick(tour.id))
						}
						let hasTimeAppointment = ''
						if (
							routeItem.characteristics.hasTimeAppointment &&
							routeItem.appointmentTimeFrom &&
							routeItem.appointmentTimeTill
						) {
							const formattedAppointmentTimeFrom = formatDuration(
								moment.duration(routeItem.appointmentTimeFrom).asSeconds(),
								{ withSeconds: false }
							)
							const formattedAppointmentTimeTill = formatDuration(
								moment.duration(routeItem.appointmentTimeTill).asSeconds(),
								{ withSeconds: false }
							)
							hasTimeAppointment = `<span style="color: ${orange[500]};">(${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill})</span>`
						}
						const salesOrderText =
							routeItem.salesOrderDeliveryGroup &&
							routeItem.salesOrderDeliveryGroup !== '0'
								? `${routeItem.salesOrderNumber} (${routeItem.salesOrderDeliveryGroup})`
								: `${routeItem.salesOrderNumber}`
						let popupContent
						if (routeItem.customer) {
							let customer
							if (routeItem.customer) {
								const formattedCustomer = formatCustomer(routeItem.customer)
								customer = `
								<p style="margin: 0;">${formattedCustomer.name}</p>
								<p style="margin: 0;">${formattedCustomer.street} ${formattedCustomer.houseNumber}</p>
								<p style="margin: 0;">${formattedCustomer.postCode} ${formattedCustomer.city}</p>`
							}
							popupContent = `
							<div style="font-size: 1.6em;">
								<p style="margin: 0;">${moment
									.unix(routeItem.startServiceTime)
									.tz('Europe/Amsterdam')
									.format('H:mm')} - ${moment
								.unix(routeItem.departureTime)
								.tz('Europe/Amsterdam')
								.format('H:mm')} ${hasTimeAppointment}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
								${customer}
							</div>`
						} else {
							popupContent = `
							<div style="font-size: 1.6em;">
								<p style="margin: 0;">${moment
									.unix(routeItem.startServiceTime)
									.tz('Europe/Amsterdam')
									.format('H:mm')} - ${moment
								.unix(routeItem.departureTime)
								.tz('Europe/Amsterdam')
								.format('H:mm')}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
							</div>`
						}
						const popup = window.L.popup({ closeButton: false }).setContent(
							popupContent
						)
						marker.bindPopup(popup)
						marker.on('mouseover', () => {
							marker.openPopup()
						})
						marker.on('mouseout', () => {
							marker.closePopup()
						})
						newMarkers.push(marker)
					})
			})

			// Draw markers for unscheduled orders
			if (unscheduledOrders && unscheduledOrders.length > 0) {
				unscheduledOrders.forEach((unscheduledOrder) => {
					const orangeIcon = new window.L.Icon({
						iconUrl: '/markers/marker-icon-2x-orange.png',
						shadowUrl: '/markers/marker-shadow.png',
						iconSize: [25, 41],
						iconAnchor: [12, 41],
						popupAnchor: [1, -34],
						shadowSize: [41, 41]
					})
					const marker = window.L.marker(
						[unscheduledOrder.coordinates.y, unscheduledOrder.coordinates.x],
						{ icon: orangeIcon }
					).addTo(this.map)
					const planningType = planningTypes.find(
						(pt) => pt.planningTypeKey === unscheduledOrder.planningType.key
					)
					const hasTimeAppointment = Boolean(
						planningType &&
							(moment
								.duration(unscheduledOrder.appointmentTimeFrom)
								.asSeconds() > planningType.orderAllowedFrom ||
								moment
									.duration(unscheduledOrder.appointmentTimeTill)
									.asSeconds() < planningType.orderAllowedTill)
					)
					let timeAppointment = ''
					if (
						hasTimeAppointment &&
						unscheduledOrder.appointmentTimeFrom &&
						unscheduledOrder.appointmentTimeTill
					) {
						const formattedAppointmentTimeFrom = formatDuration(
							moment.duration(unscheduledOrder.appointmentTimeFrom).asSeconds(),
							{ withSeconds: false }
						)
						const formattedAppointmentTimeTill = formatDuration(
							moment.duration(unscheduledOrder.appointmentTimeTill).asSeconds(),
							{ withSeconds: false }
						)
						timeAppointment = `<span style="color: ${orange[500]};">(${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill})</span>`
					}
					const salesOrderText =
						unscheduledOrder.salesOrderDeliveryGroup &&
						unscheduledOrder.salesOrderDeliveryGroup !== '0'
							? `${unscheduledOrder.salesOrderNumber} (${unscheduledOrder.salesOrderDeliveryGroup})`
							: `${unscheduledOrder.salesOrderNumber}`
					let popupContent
					if (unscheduledOrder.customer) {
						let customer
						if (unscheduledOrder.customer) {
							const formattedCustomer = formatCustomer(
								unscheduledOrder.customer
							)
							customer = `
								<p style="margin: 0;">${formattedCustomer.name}</p>
								<p style="margin: 0;">${formattedCustomer.street} ${formattedCustomer.houseNumber}</p>
								<p style="margin: 0;">${formattedCustomer.postCode} ${formattedCustomer.city}</p>`
						}
						popupContent = `
							<div style="font-size: 1.6em;">
								<p style="margin: 0;">${i18n.t(
									'app:planning.Tours.Summary.serviceDuration'
								)}: ${formatDuration(
							unscheduledOrder.serviceTime
						)} ${timeAppointment}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
								${customer}
							</div>`
					} else {
						popupContent = `
							<div style="font-size: 1.6em;">
								<p style="margin: 0;">${moment
									.unix(unscheduledOrder.startServiceTime)
									.tz('Europe/Amsterdam')
									.format('H:mm')} ${timeAppointment}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
							</div>`
					}
					const popup = window.L.popup({ closeButton: false }).setContent(
						popupContent
					)
					marker.bindPopup(popup)
					marker.on('mouseover', () => {
						marker.openPopup()
					})
					marker.on('mouseout', () => {
						marker.closePopup()
					})
					newMarkers.push(marker)
				})
			}

			// Draw markers for unscheduled orders
			if (parkedRouteItems && parkedRouteItems.length > 0) {
				parkedRouteItems.forEach((parkedRouteItem) => {
					const orangeIcon = new window.L.Icon({
						iconUrl: '/markers/marker-icon-2x-orange.png',
						shadowUrl: '/markers/marker-shadow.png',
						iconSize: [25, 41],
						iconAnchor: [12, 41],
						popupAnchor: [1, -34],
						shadowSize: [41, 41]
					})
					const marker = window.L.marker(
						[parkedRouteItem.coordinates.lat, parkedRouteItem.coordinates.lng],
						{ icon: orangeIcon }
					).addTo(this.map)
					let hasTimeAppointment = ''
					if (
						parkedRouteItem.characteristics.hasTimeAppointment &&
						parkedRouteItem.appointmentTimeFrom &&
						parkedRouteItem.appointmentTimeTill
					) {
						const formattedAppointmentTimeFrom = formatDuration(
							moment.duration(parkedRouteItem.appointmentTimeFrom).asSeconds(),
							{ withSeconds: false }
						)
						const formattedAppointmentTimeTill = formatDuration(
							moment.duration(parkedRouteItem.appointmentTimeTill).asSeconds(),
							{ withSeconds: false }
						)
						hasTimeAppointment = `<span style="color: ${orange[500]};">(${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill})</span>`
					}
					const salesOrderText =
						parkedRouteItem.salesOrderDeliveryGroup &&
						parkedRouteItem.salesOrderDeliveryGroup !== '0'
							? `${parkedRouteItem.salesOrderNumber} (${parkedRouteItem.salesOrderDeliveryGroup})`
							: `${parkedRouteItem.salesOrderNumber}`
					let popupContent
					if (parkedRouteItem.customer) {
						let customer
						if (parkedRouteItem.customer) {
							const formattedCustomer = formatCustomer(parkedRouteItem.customer)
							customer = `
								<p style="margin: 0;">${formattedCustomer.name}</p>
								<p style="margin: 0;">${formattedCustomer.street} ${formattedCustomer.houseNumber}</p>
								<p style="margin: 0;">${formattedCustomer.postCode} ${formattedCustomer.city}</p>`
						}
						popupContent = `
							<div style="font-size: 1.6em;">
								<p style="font-style: italic;">${i18n.t('app:planning.Manual.parked')}</p>
								<p style="margin: 0;">${i18n.t(
									'app:planning.Tours.Summary.serviceDuration'
								)}: ${formatDuration(
							parkedRouteItem.servicePeriod
						)} ${hasTimeAppointment}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
								${customer}
							</div>`
					} else {
						popupContent = `
							<div style="font-size: 1.6em;">
								<p style="font-style: italic;">${i18n.t('app:planning.Manual.parked')}</p>
								<p style="margin: 0;">${i18n.t(
									'app:planning.Tours.Summary.serviceDuration'
								)}: ${formatDuration(
							parkedRouteItem.servicePeriod
						)} ${hasTimeAppointment}</p>
								<p style="font-weight: bold; margin: 0;">${i18n.t(
									'app:planning.Tour.salesOrderNumber'
								)} ${salesOrderText}</p>
							</div>`
					}
					const popup = window.L.popup({ closeButton: false }).setContent(
						popupContent
					)
					marker.bindPopup(popup)
					marker.on('mouseover', () => {
						marker.openPopup()
					})
					marker.on('mouseout', () => {
						marker.closePopup()
					})
					newMarkers.push(marker)
				})
			}

			// Save markers and polylines in state so we can adjust them later
			this.setState({
				markers: newMarkers,
				polylines: newPolylines
			})

			// Render selection
			if (!manualPlanningMode) {
				this.renderMapSelection(newPolylines)
			}
		}
	}

	renderMapSelection(polylines) {
		const { selectedTourIds } = this.props

		// Highlight the lines for selected tours
		polylines.forEach((polyline) => {
			const isSelected = selectedTourIds.some(
				(selectedTourId) => selectedTourId === polyline.meta.tourId
			)
			if (isSelected) {
				const style =
					polyline.meta.type === 'fill'
						? { color: pink[500] }
						: { color: pink[800] }
				polyline.setStyle(style)
			} else {
				const style =
					polyline.meta.type === 'fill'
						? { color: blueGrey[500] }
						: { color: blueGrey[800] }
				polyline.setStyle(style)
			}
		})
	}

	render() {
		const { classes } = this.props
		return <div className={classes.root} ref={this.setMapRef} />
	}
}

export default withStyles(styleSheet)(Map)
