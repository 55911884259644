import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		padding: 16
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16
	}
}

class Column extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired,
		header: PropTypes.node
	}

	render() {
		const { classes, children, header } = this.props

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>{header}</div>
				<div className={classes.contentContainer}>
					<div className={classes.content}>{children}</div>
				</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Column)
