import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton, SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'
import { CalendarFal, TruckFal } from '@oliverit/react-fontawesome'
import { Link } from 'react-router-dom'
import { default as i18n } from 'i18next'

const styles = (theme) => ({
	green: {
		color: green[500]
	},
	header: {
		display: 'flex',
		...theme.mixins.gutters({
			paddingBottom: 16,
			paddingTop: 16
		})
	},
	iconGreen: {
		color: green[500],
		marginRight: 8
	},
	iconRed: {
		color: red[500],
		marginRight: 8
	},
	red: {
		color: red[500]
	},
	subHeader: {
		display: 'flex',
		...theme.mixins.gutters({
			paddingBottom: 16
		})
	},
	title: {
		flex: 1
	},
	editButton: {
		marginRight: 16
	}
})

class Header extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props
		const { available, licensePlate, vehicleId } = vehicle

		let subHeader
		if (available) {
			subHeader = (
				<div className={classes.subHeader}>
					<SvgIcon className={classes.iconGreen}>
						<TruckFal />
					</SvgIcon>
					<Typography variant="body2" className={classes.green}>
						{i18n.t('app:vehicleplanning.Vehicle.available')}
					</Typography>
				</div>
			)
		} else {
			subHeader = (
				<div className={classes.subHeader}>
					<SvgIcon className={classes.iconRed}>
						<TruckFal />
					</SvgIcon>
					<Typography variant="body2" className={classes.red}>
						{i18n.t('app:vehicleplanning.Vehicle.unavailable')}
					</Typography>
				</div>
			)
		}

		return (
			<div>
				<div className={classes.header}>
					<Typography variant="h5" className={classes.title}>
						{licensePlate}
					</Typography>
					<Button
						className={classes.editButton}
						variant="contained"
						color="primary"
						component={Link}
						to={`/vehicleplanning/${vehicleId}/edit`}
					>
						{i18n.t('app:vehicleplanning.Vehicle.edit')}
					</Button>
					<IconButton
						color="primary"
						component={Link}
						to={`/vehicleunavailability/${vehicleId}`}
					>
						<SvgIcon>
							<CalendarFal />
						</SvgIcon>
					</IconButton>
				</div>
				{subHeader}
			</div>
		)
	}
}

export default withStyles(styles)(Header)
