import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List as MaterialList } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List as VirtualizedList
} from 'react-virtualized'
import ListEmpty from './ListEmpty'
import ListItem from './ListItem'

const styleSheet = {
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		orders: PropTypes.array.isRequired,
		ownUserId: PropTypes.string.isRequired,
		selectOrder: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string,
		selectedMoment: PropTypes.object.isRequired
	}

	state = {
		cache: new CellMeasurerCache({
			defaultHeight: 96,
			fixedWidth: true
		}),
		previousOrders: [],
		previousSelectedMoment: null,
		scrollToTop: false
	}

	static getDerivedStateFromProps(props, state) {
		let scrollToTop = false
		if (props.orders !== state.previousOrders) {
			// Clear cache when the list changes, otherwise cells may be rendered with incorrect height
			state.cache.clearAll()
		}
		if (props.selectedMoment !== state.previousSelectedMoment) {
			scrollToTop = true
		}
		return {
			previousOrders: props.orders,
			previousSelectedMoment: props.selectedMoment,
			scrollToTop
		}
	}

	rowRenderer = ({ index, key, parent, style }) => {
		const { orders, ownUserId, selectOrder, selectedOrderId } = this.props
		const { cache } = this.state

		const order = orders[index]

		return (
			<CellMeasurer
				cache={cache}
				columnIndex={0}
				key={key}
				parent={parent}
				rowIndex={index}
			>
				<div key={key} style={style}>
					<ListItem
						key={`order-${order.id}`}
						order={order}
						isOwnUser={order.lock && order.lock.userId === ownUserId}
						isSelected={order.id === selectedOrderId}
						selectOrder={selectOrder}
					/>
				</div>
			</CellMeasurer>
		)
	}

	render() {
		const { classes, orders, selectedOrderId } = this.props
		const { cache, scrollToTop } = this.state

		if (orders.length === 0) {
			return (
				<div className={classes.content}>
					<ListEmpty />
				</div>
			)
		}

		// Find a selected order and if found make sure to scroll to it
		let selectedOrderIndex = orders.findIndex(
			(order) => order.id === selectedOrderId
		)
		if (selectedOrderIndex === -1 && scrollToTop) {
			selectedOrderIndex = 0
		}

		// Important: the VirtualizedList component does not automatically re-render when the props on
		// this component change. This means we have to pass these same props into VirtualizedList as
		// well, even though they are not used by the VirtualizedList component.

		return (
			<MaterialList disablePadding className={classes.content}>
				<AutoSizer>
					{({ height, width }) => (
						<VirtualizedList
							deferredMeasurementCache={cache}
							height={height}
							rowCount={orders.length}
							rowHeight={cache.rowHeight}
							rowRenderer={this.rowRenderer}
							scrollToIndex={selectedOrderIndex}
							tabIndex={null}
							width={width}
							orders={orders}
							selectedOrderId={selectedOrderId}
						/>
					)}
				</AutoSizer>
			</MaterialList>
		)
	}
}

export default withStyles(styleSheet)(List)
