import moment from 'moment-timezone'
import { formatAppointmentCustomer } from '../customer'

const isNumber = (prop) => prop && typeof prop === 'number'
const isString = (prop) => prop && typeof prop === 'string'
const allStrings = (obj) =>
	obj && typeof obj === 'object' && Object.values(obj).every(isString)
const allBooleans = (obj) =>
	obj &&
	typeof obj === 'object' &&
	Object.values(obj).every((bool) => bool && typeof bool === 'boolean')

export const formatAppointmentForAPI = (appointment) => ({
	...appointment,
	customer: formatAppointmentCustomer(appointment.customer)
})

export const copyAppointment = (order) => {
	const { appointment = {}, bijzonderheden: orderBijzonderheden = null } = order
	const {
		customer = null,
		floorNumber = null,
		note = null,
		bijzonderheden: appointmentBijzonderheden = null
	} = appointment

	const bijzonderheden = appointmentBijzonderheden || orderBijzonderheden

	let { characteristics = null } = appointment
	if (characteristics) {
		const {
			canElevatorBeUsed,
			canStairsBeUsed,
			canScaffoldBeUsed,
			canExternalElevatorBeUsed
		} = characteristics
		characteristics = {
			canElevatorBeUsed: allBooleans(canElevatorBeUsed)
				? { ...canElevatorBeUsed }
				: null,
			canStairsBeUsed: allBooleans(canStairsBeUsed)
				? { ...canStairsBeUsed }
				: null,
			canScaffoldBeUsed: allBooleans(canScaffoldBeUsed)
				? { ...canScaffoldBeUsed }
				: null,
			canExternalElevatorBeUsed: allBooleans(canExternalElevatorBeUsed)
				? { ...canExternalElevatorBeUsed }
				: null
		}
	}

	// Ignore deliveryDate and earliestDateProposal, because their previous values can be outdated after a PTV run
	return {
		deliveryDate: null,
		earliestDateProposal: null,
		customer: allStrings(customer) ? { ...customer } : null,
		floorNumber: isNumber(floorNumber) ? floorNumber : null,
		characteristics,
		bijzonderheden: isString(bijzonderheden) ? bijzonderheden : null,
		note: isString(note) ? note : null,
		isDatepickerDate: null
	}
}

export const getSuggestedDate = (order) => {
	if (order.tourDate) {
		// The order is planned in a tour by PTV, so we use the tour date as the date to suggest to the customer
		return moment.tz(order.tourDate, 'Europe/Amsterdam').startOf('day')
	}
	// The order is not yet planned, so we use the date which PTV will use to plan the order as the date to suggest to the customer
	return moment.tz(order.minimumDeliveryDate, 'Europe/Amsterdam').startOf('day')
}
