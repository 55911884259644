import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import DriverEdit from './DriverEdit'

class DriverEditContainer extends PureComponent {
	static propTypes = {
		driverId: PropTypes.string.isRequired
	}

	render() {
		const { driverId } = this.props

		return <DriverEdit driverId={driverId} />
	}
}

export default DriverEditContainer
