import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../../redux/vehiclescustom/selectors'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		vehicles: PropTypes.array.isRequired,
		selectedVehicleId: PropTypes.string
	}

	render() {
		const { vehicles, selectedVehicleId } = this.props

		return <List vehicles={vehicles} selectedVehicleId={selectedVehicleId} />
	}
}

const connector = connect((state) => ({
	vehicles: selectors.filteredVehiclesSearchSelector(
		state.vehiclesState,
		state.driversState
	)
}))

export default connector(ListContainer)
