import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import DriverEditContainer from './DriverEdit'
import DriverSelectionContainer from './DriverSelection'
import DriverContainer from './Driver'

const styleSheet = () => ({
	split: {
		display: 'flex',
		height: '100%'
	},
	list: {
		marginRight: 16,
		width: 600
	},
	details: {
		flex: 1
	}
})

class DriverAdmin extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectedDriverId: PropTypes.string,
		showEdit: PropTypes.bool
	}

	render() {
		const { classes, selectedDriverId, showEdit } = this.props

		if (showEdit) {
			return <DriverEditContainer driverId={selectedDriverId} />
		}

		return (
			<div className={classes.split}>
				<div className={classes.list}>
					<DriverSelectionContainer selectedDriverId={selectedDriverId} />
				</div>
				<div className={classes.details}>
					<DriverContainer selectedDriverId={selectedDriverId} />
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DriverAdmin)
