import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'

import HoursBar from './HoursBar'
import DayViewerContainer from '../DayViewer'
import { days } from '../../../../utils/drivers'

const styleSheet = {
	root: {
		flex: 1,
		width: '100%',
		height: '100%',
		paddingLeft: 16,
		paddingRight: 16,
		display: 'flex',
		flexDirection: 'column'
	},
	header: {
		height: 40,
		width: '100%',
		display: 'flex'
	},
	label: {
		flex: 1,
		textAlign: 'center'
	},
	emptyLabel: {
		width: 50
	},
	content: {
		display: 'flex',
		width: '100%',
		height: '100%',
		overflow: 'scroll'
	}
}

class WeekContent extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const { classes, selectedMoment } = this.props

		const dayViewers = days.map((day) => {
			// we create a new moment because the 'day' function modifies the moment object
			const dayMoment = moment
				.tz(selectedMoment, 'Europe/Amsterdam')
				.isoWeekday(day)
			return <DayViewerContainer key={day} dayMoment={dayMoment} />
		})

		const today = moment.tz('Europe/Amsterdam')
		const headers = days.map((day) => {
			// we create a new moment because the 'day' function modifies the moment object
			const dayMoment = moment
				.tz(selectedMoment, 'Europe/Amsterdam')
				.isoWeekday(day)
			const isToday = dayMoment.isSame(today, 'day')
			return (
				<div key={`header-${day}`} className={classes.label}>
					<Typography
						color={isToday ? 'primary' : 'textPrimary'}
						variant="body2"
					>
						{dayMoment.format('dd D')}
					</Typography>
				</div>
			)
		})

		// the empty "header" div is necessary for alignment with the dayviewer components

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<div className={classes.emptyLabel} />
					{headers}
				</div>
				<div className={classes.content}>
					<HoursBar />
					{dayViewers}
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(WeekContent)
