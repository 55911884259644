import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import orderActionCreators from '../../../redux/order/actionCreators'
import Order from './Order'

class OrderContainer extends PureComponent {
	static propTypes = {
		fetch: PropTypes.func.isRequired,
		fetchTimeSlots: PropTypes.func.isRequired,
		lock: PropTypes.func.isRequired,
		meta: PropTypes.object,
		order: PropTypes.object,
		selectedOrderId: PropTypes.string,
		takeOverLock: PropTypes.func.isRequired
	}

	// Set starting to prevent loading with an older orderState when switching orders
	state = {
		isStarting: true
	}

	static getDerivedStateFromProps(props, state) {
		if (state.isStarting && props.meta.isFetching) {
			return { isStarting: false }
		}
		return null
	}

	componentDidMount() {
		const { fetch, fetchTimeSlots, selectedOrderId } = this.props
		fetch(selectedOrderId)
		fetchTimeSlots(selectedOrderId)
	}

	handleStart = () => {
		const { lock } = this.props
		lock()
	}

	handleTakeOver = () => {
		const { takeOverLock } = this.props
		takeOverLock()
	}

	render() {
		const { order, meta } = this.props
		const { isStarting } = this.state

		// Is the order being updated?
		const isUpdating = meta ? meta.isUpdating : false

		const isLoading = isStarting || meta.isFetching || meta.isFetchingTimeSlots

		return (
			<Order
				order={order}
				isLoading={isLoading}
				isUpdating={isUpdating}
				start={this.handleStart}
				takeOver={this.handleTakeOver}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		order: state.orderState.order,
		meta: state.orderState.meta
	}),
	{
		fetch: orderActionCreators.fetch,
		fetchTimeSlots: orderActionCreators.fetchTimeSlots,
		lock: orderActionCreators.lock,
		takeOverLock: orderActionCreators.takeOverLock
	}
)

export default connector(OrderContainer)
