import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Collapse,
	Paper,
	List as MaterialList,
	IconButton,
	SvgIcon
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SyncFal, FilterFal, FilterFas } from '@oliverit/react-fontawesome'
import Placeholder from '../../Shared/Placeholder'
import DateNavigatorContainer from './DateNavigator'
import SearchContainer from './Search'
import FilterContainer from './Filter'
import ListContainer from './List'

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		display: 'flex',
		paddingTop: 16
	},
	search: {
		display: 'flex',
		flexDirection: 'column'
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	buttons: {
		...theme.mixins.gutters({
			display: 'flex',
			alignItems: 'center'
		})
	}
})

class VehicleSelection extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		refresh: PropTypes.func.isRequired,
		selectedVehicleId: PropTypes.string,
		hasActiveFilters: PropTypes.bool
	}

	state = {
		showFilters: false
	}

	handleToggleFilters = () => {
		const { showFilters } = this.state
		this.setState({ showFilters: !showFilters })
	}

	handleHideFilters = () => {
		this.setState({
			showFilters: false
		})
	}

	handleClickRefresh = () => {
		const { refresh } = this.props
		refresh()
	}

	render() {
		const { classes, isLoading, selectedVehicleId, hasActiveFilters } =
			this.props
		const { showFilters } = this.state

		let list
		if (isLoading) {
			const placeholders = [...Array(10)].map((row, index) => (
				<Placeholder key={`vehiclelist-placeholder-${index}`} />
			))
			list = <MaterialList disablePadding>{placeholders}</MaterialList>
		} else {
			list = <ListContainer selectedVehicleId={selectedVehicleId} />
		}

		const filterIcon = hasActiveFilters ? <FilterFas /> : <FilterFal />

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<DateNavigatorContainer />
					<div className={classes.buttons}>
						<IconButton color="primary" onClick={this.handleClickRefresh}>
							<SvgIcon>
								<SyncFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="primary" onClick={this.handleToggleFilters}>
							<SvgIcon>{filterIcon}</SvgIcon>
						</IconButton>
					</div>
				</div>
				<div className={classes.search}>
					<Collapse in={showFilters}>
						<FilterContainer close={this.handleHideFilters} />
					</Collapse>
					<SearchContainer />
				</div>
				<div className={classes.contentContainer}>{list}</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(VehicleSelection)
