import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		padding: 16
	}
}

class AddPrioritizedOrderRequirement extends PureComponent {
	static propTypes = {
		prioritizedOrderRequirements: PropTypes.array.isRequired,
		open: PropTypes.bool.isRequired,
		handleDialogClose: PropTypes.func.isRequired,
		handleDialogSave: PropTypes.func.isRequired
	}

	state = { requirement: 0, description: '' }

	handleChangeRequirement = (event) => {
		const requirement = Number(event.target.value.replace(/\D/, ''))
		this.setState({ requirement })
	}

	handleFocusRequirement = (event) => {
		// select all
		event.target.select()
	}

	handleChangeDescription = (event) => {
		const description = event.target.value
		this.setState({ description })
	}

	handleSaveClick = () => {
		const { handleDialogSave } = this.props
		const { requirement, description } = this.state
		handleDialogSave({ requirement, description })
		this.setState({ description: '', requirement: 0 })
	}

	handleCancelClose = () => {
		const { handleDialogClose } = this.props
		this.setState({ description: '', requirement: 0 })
		handleDialogClose()
	}

	render() {
		const { prioritizedOrderRequirements, open } = this.props
		const { requirement, description } = this.state
		const hasRequirementError =
			requirement < 0 ||
			requirement > 99 ||
			prioritizedOrderRequirements.some(
				(prioritizedOrderRequirement) =>
					prioritizedOrderRequirement.requirement === requirement
			)

		return (
			<Dialog
				open={open}
				onClose={this.handleCancelClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDialog.title'
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{i18n.t(
							'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDialog.content'
						)}
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						label={i18n.t(
							'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsReq'
						)}
						fullWidth
						value={requirement}
						onChange={this.handleChangeRequirement}
						onFocus={this.handleFocusRequirement}
						error={hasRequirementError}
						helperText={
							hasRequirementError
								? i18n.t(
										'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDialog.error'
								  )
								: ''
						}
					/>
					<TextField
						margin="dense"
						label={i18n.t(
							'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDescr'
						)}
						fullWidth
						value={description}
						onChange={this.handleChangeDescription}
						inputProps={{ maxLength: 60 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleCancelClose} color="primary">
						{i18n.t(
							'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDialog.cancel'
						)}
					</Button>
					<Button
						onClick={this.handleSaveClick}
						color="primary"
						disabled={hasRequirementError || description.length === 0}
					>
						{i18n.t(
							'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDialog.save'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(styleSheet)(AddPrioritizedOrderRequirement)
