const actionTypes = {
	FETCH: 'unavailableProposalDates/FETCH',
	FETCH_REQUEST: 'unavailableProposalDates/FETCH_REQUEST',
	FETCH_SUCCESS: 'unavailableProposalDates/FETCH_SUCCESS',
	FETCH_FAILURE: 'unavailableProposalDates/FETCH_FAILURE',
	ADD: 'unavailableProposalDates/ADD',
	ADD_REQUEST: 'unavailableProposalDates/ADD_REQUEST',
	ADD_SUCCESS: 'unavailableProposalDates/ADD_SUCCESS',
	ADD_FAILURE: 'unavailableProposalDates/ADD_FAILURE',
	REMOVE: 'unavailableProposalDates/REMOVE',
	REMOVE_REQUEST: 'unavailableProposalDates/REMOVE_REQUEST',
	REMOVE_SUCCESS: 'unavailableProposalDates/REMOVE_SUCCESS',
	REMOVE_FAILURE: 'unavailableProposalDates/REMOVE_FAILURE',
	SOCKET_ADD: 'unavailableProposalDates/SOCKET_ADD',
	SOCKET_CLOSE: 'unavailableProposalDates/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'unavailableProposalDates/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'unavailableProposalDates/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'unavailableProposalDates/SOCKET_OPEN',
	SOCKET_REMOVE: 'unavailableProposalDates/SOCKET_REMOVE'
}

export default actionTypes
