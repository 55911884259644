import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

import elementsFetch from '../utils/fetch'

const driversUrl = '/api/drivers'
const fetchAvailabilityByWeekUrl = (id, year, week) =>
	`/api/drivers/${id}/available/week/${year}/${week}`
const saveAvailabilityUrl = (id, year, week, day) =>
	`/api/drivers/${id}/available/${year}/${week}/${day}`

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(driversUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const getAvailability = async (id, year, week) => {
	let response
	try {
		response = await elementsFetch(fetchAvailabilityByWeekUrl(id, year, week))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const saveAvailability = async (id, day, availability) => {
	let response
	try {
		const dayMoment = moment.unix(day).tz('Europe/Amsterdam')
		const year = dayMoment.year()
		const month = dayMoment.month() + 1
		const dayOfMonth = dayMoment.date()

		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(
			saveAvailabilityUrl(id, year, month, dayOfMonth),
			{
				method: 'PUT',
				headers,
				body: JSON.stringify(availability)
			}
		)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getAll,
	getAvailability,
	saveAvailability
}

export default api
