import { combineReducers } from 'redux'
import moment from 'moment-timezone'

import actionTypes from './actionTypes'

const initialVehiclesState = []
const vehiclesReducer = (state = initialVehiclesState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SUCCESS: {
			return action.payload.vehicles
		}

		case actionTypes.FETCH_FAILURE: {
			return initialVehiclesState
		}

		case actionTypes.SAVE_SUCCESS: {
			const updatedVehicle = action.payload.vehicle
			return state.map((vehicle) =>
				vehicle.vehicleId === updatedVehicle.vehicleId
					? updatedVehicle
					: vehicle
			)
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}
const initialSearchState = {
	query: ''
}
const searchReducer = (state = initialSearchState, action) => {
	switch (action.type) {
		case actionTypes.SEARCH: {
			return {
				...state,
				query: action.payload.query
			}
		}

		default: {
			return state
		}
	}
}

const initialSelectedDateState = moment
	.tz('Europe/Amsterdam')
	.startOf('day')
	.unix()
const selectedDateReducer = (state = initialSelectedDateState, action) => {
	switch (action.type) {
		case actionTypes.SELECT_CURRENT_DATE: {
			return initialSelectedDateState
		}

		case actionTypes.SELECT_NEXT_DATE: {
			return moment.unix(state).tz('Europe/Amsterdam').add(1, 'day').unix()
		}

		case actionTypes.SELECT_PREVIOUS_DATE: {
			return moment.unix(state).tz('Europe/Amsterdam').subtract(1, 'day').unix()
		}

		default: {
			return state
		}
	}
}

const initialFilterState = {
	onlyUnavailable: false,
	onlyAvailable: false,
	planningTypeKeys: [],
	vehicleTypes: []
}
const filterReducer = (state = initialFilterState, action) => {
	switch (action.type) {
		case actionTypes.APPLY_FILTER: {
			return action.payload
		}
		case actionTypes.CLEAR_FILTER: {
			return {
				...state,
				onlyUnavailable: false,
				onlyAvailable: false,
				planningTypeKeys: [],
				vehicleTypes: []
			}
		}
		default: {
			return state
		}
	}
}

export default combineReducers({
	vehicles: vehiclesReducer,
	meta: metaReducer,
	search: searchReducer,
	selectedDate: selectedDateReducer,
	filter: filterReducer
})
