import { CssBaseline } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import authActionCreators from '../../redux/auth/actionCreators'
import dateProposalActionCreators from '../../redux/dateproposal/actionCreators'
import excludedServiceDatesActionCreators from '../../redux/excludedservicedates/actionCreators'
import manualPlanningsActionCreators from '../../redux/manualplannings/actionCreators'
import ordersActionCreators from '../../redux/orders/actionCreators'
import planningsActionCreators from '../../redux/plannings/actionCreators'
import planningTypesActionCreators from '../../redux/planningtypes/actionCreators'
import toursActionCreators from '../../redux/tours/actionCreators'
import unavailableProposalDatesActionCreators from '../../redux/unavailableproposaldates/actionCreators'
import userSelectors from '../../redux/user/selectors'
import AppointmentSchedulerContainer from '../AppointmentScheduler'
import DriverAdminContainer from '../DriverAdmin'
import ExportsContainer from '../Exports'
import PlanningContainer from '../Planning'
import PlanningTypeAdminContainer from '../PlanningTypeAdmin'
import PostcodeAdminContainer from '../PostcodeAdmin'
import SettingsContainer from '../Settings'
import ErrorBoundary from '../Shared/ErrorBoundary'
import ToursMapContainer from '../ToursMap'
import VehiclePlanningContainer from '../VehiclePlanning'
import VehicleUnavailabilityContainer from '../VehicleUnavailability'
import AppContent from './AppContent'
import AutoLoginContainer from './AutoLogin'
import LoginContainer from './Login'
import NoScopes from './NoScopes'
import NotificationContainer from './Notification'
import PasswordContainer from './Password'
import StartingContainer from './Starting'

const styleSheet = {
	root: {
		display: 'flex',
		overflow: 'hidden',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	'@global': {
		'::-webkit-scrollbar': {
			background: '#eee',
			height: 7,
			width: 7
		},
		'::-webkit-scrollbar-thumb': {
			background: '#ddd',
			borderRadius: 2
		}
	}
}

const AppRoute = ({ component: Component, logout, ...rest }) => (
	<Route
		{...rest}
		render={(routeProps) => (
			<AppContent logout={logout}>
				<Component {...routeProps} />
			</AppContent>
		)}
	/>
)

class AppContainer extends PureComponent {
	static propTypes = {
		autoLoginFailed: PropTypes.bool,
		classes: PropTypes.object.isRequired,
		closeDateProposalSocket: PropTypes.func.isRequired,
		closeOrdersSocket: PropTypes.func.isRequired,
		closePlanningsSocket: PropTypes.func.isRequired,
		closeToursSocket: PropTypes.func.isRequired,
		closeUnavailableProposalDatesSocket: PropTypes.func.isRequired,
		closeExcludedServiceDatesSocket: PropTypes.func.isRequired,
		fetchPlanningTypes: PropTypes.func.isRequired,
		fullName: PropTypes.string,
		isAutoLoggingIn: PropTypes.bool,
		loggedIn: PropTypes.bool,
		logout: PropTypes.func.isRequired,
		openDateProposalSocket: PropTypes.func.isRequired,
		openOrdersSocket: PropTypes.func.isRequired,
		openManualPlanningsSocket: PropTypes.func.isRequired,
		openPlanningsSocket: PropTypes.func.isRequired,
		openToursSocket: PropTypes.func.isRequired,
		openUnavailableProposalDatesSocket: PropTypes.func.isRequired,
		openExcludedServiceDatesSocket: PropTypes.func.isRequired,
		scopes: PropTypes.array.isRequired
	}

	componentDidUpdate(prevProps) {
		const {
			closeDateProposalSocket,
			closeOrdersSocket,
			closePlanningsSocket,
			closeToursSocket,
			closeUnavailableProposalDatesSocket,
			closeExcludedServiceDatesSocket,
			fetchPlanningTypes,
			fullName,
			loggedIn,
			openDateProposalSocket,
			openOrdersSocket,
			openManualPlanningsSocket,
			openPlanningsSocket,
			openToursSocket,
			openUnavailableProposalDatesSocket,
			openExcludedServiceDatesSocket
		} = this.props

		if (fullName !== prevProps.fullName) {
			if (fullName && loggedIn) {
				// User logged in
				// - Open websockets and fetch master data
				if (this.hasAppointmentschedulerScope()) {
					openDateProposalSocket()
					openOrdersSocket()
				}
				if (this.hasPlanningScope()) {
					fetchPlanningTypes()
					openManualPlanningsSocket()
					openPlanningsSocket()
					openToursSocket()
				}
				openUnavailableProposalDatesSocket()
				openExcludedServiceDatesSocket()
			} else {
				// User logged out
				// - Close websockets
				closeDateProposalSocket()
				closeOrdersSocket()
				closePlanningsSocket()
				closeToursSocket()
				closeUnavailableProposalDatesSocket()
				closeExcludedServiceDatesSocket()
			}
		}
	}

	hasAppointmentschedulerScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:appointmentscheduler')
	}

	hasPlanningScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:planning')
	}

	hasPlanningtypesAdminScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:planningtypesadmin')
	}

	hasDriversAdminScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:driversadmin')
	}

	hasPostcodesAdminScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:postcodesadmin')
	}

	hasVehiclePlanningScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'app:vehicleplanning')
	}

	render() {
		const { autoLoginFailed, classes, isAutoLoggingIn, loggedIn, logout } =
			this.props

		if (isAutoLoggingIn) {
			// App is starting up and trying to login automatically
			return (
				<ErrorBoundary>
					<CssBaseline />
					<StartingContainer />
				</ErrorBoundary>
			)
		}

		if (autoLoginFailed) {
			// Autologin failed, show retry view
			return (
				<ErrorBoundary>
					<CssBaseline />
					<AutoLoginContainer />
				</ErrorBoundary>
			)
		}

		if (!loggedIn) {
			// User is not logged in
			return (
				<ErrorBoundary>
					<CssBaseline />
					<Switch>
						<Route exact path="/password" component={PasswordContainer} />
						<Route component={LoginContainer} />
					</Switch>
				</ErrorBoundary>
			)
		}

		// User is logged in
		// Based on the user scopes:
		// - create corresponding routes
		// - determine the default application to launch when the app is first started
		const hasAppointmentschedulerScope = this.hasAppointmentschedulerScope()
		const hasPlanningScope = this.hasPlanningScope()
		const hasPlanningtypesAdminScope = this.hasPlanningtypesAdminScope()
		const hasDriversAdminScope = this.hasDriversAdminScope()
		const hasPostcodesAdminScope = this.hasPostcodesAdminScope()
		const hasVehiclePlanningScope = this.hasVehiclePlanningScope()
		let rootComponent = NoScopes
		if (hasAppointmentschedulerScope) {
			rootComponent = AppointmentSchedulerContainer
		} else if (hasPlanningScope) {
			rootComponent = PlanningContainer
		}

		return (
			<ErrorBoundary>
				<CssBaseline />
				<NotificationContainer />
				<div className={classes.root}>
					<Switch>
						<Route exact path="/map" component={ToursMapContainer} />
						<AppRoute
							exact
							path="/"
							component={rootComponent}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/afspraken"
							component={
								hasAppointmentschedulerScope
									? AppointmentSchedulerContainer
									: NoScopes
							}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/planning"
							component={hasPlanningScope ? PlanningContainer : NoScopes}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/exports"
							component={ExportsContainer}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/settings"
							component={SettingsContainer}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/postcodeadmin/:new?"
							component={
								hasPostcodesAdminScope ? PostcodeAdminContainer : NoScopes
							}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/driveradmin/:driverId?/:edit?"
							component={hasDriversAdminScope ? DriverAdminContainer : NoScopes}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/planningtypeadmin/:planningTypeKey?"
							component={
								hasPlanningtypesAdminScope
									? PlanningTypeAdminContainer
									: NoScopes
							}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/vehicleplanning/:id?/:edit?"
							component={
								hasVehiclePlanningScope ? VehiclePlanningContainer : NoScopes
							}
							logout={logout}
						/>
						<AppRoute
							exact
							path="/vehicleunavailability/:id?/:new?"
							component={
								hasVehiclePlanningScope
									? VehicleUnavailabilityContainer
									: NoScopes
							}
							logout={logout}
						/>
						<AppRoute component={rootComponent} logout={logout} />
					</Switch>
				</div>
			</ErrorBoundary>
		)
	}
}

const connector = connect(
	(state) => ({
		autoLoginFailed: state.userState.meta.autoLoginFailed,
		fullName: userSelectors.fullNameSelector(state.userState),
		isAutoLoggingIn: state.userState.meta.isAutoLoggingIn,
		loggedIn: state.userState.user
			? state.userState.user.userName &&
			  state.userState.user.userName.length > 0
			: false,
		scopes:
			state.userState.user && state.userState.user.scopes
				? state.userState.user.scopes
				: []
	}),
	{
		closeDateProposalSocket: dateProposalActionCreators.closeSocket,
		closeOrdersSocket: ordersActionCreators.closeSocket,
		closePlanningsSocket: planningsActionCreators.closeSocket,
		closeToursSocket: toursActionCreators.closeSocket,
		closeUnavailableProposalDatesSocket:
			unavailableProposalDatesActionCreators.closeSocket,
		closeExcludedServiceDatesSocket:
			excludedServiceDatesActionCreators.closeSocket,
		fetchPlanningTypes: planningTypesActionCreators.fetch,
		logout: authActionCreators.logout,
		openDateProposalSocket: dateProposalActionCreators.openSocket,
		openOrdersSocket: ordersActionCreators.openSocket,
		openManualPlanningsSocket: manualPlanningsActionCreators.openSocket,
		openPlanningsSocket: planningsActionCreators.openSocket,
		openToursSocket: toursActionCreators.openSocket,
		openUnavailableProposalDatesSocket:
			unavailableProposalDatesActionCreators.openSocket,
		openExcludedServiceDatesSocket:
			excludedServiceDatesActionCreators.openSocket
	}
)

export default withRouter(connector(withStyles(styleSheet)(AppContainer)))
