import { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	Grid,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	SvgIcon,
	Tooltip,
	Typography,
	Divider
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { amber, blue, green, grey, red } from '@material-ui/core/colors'
import {
	CheckCircleFal,
	CheckDoubleFad,
	CheckFad,
	CogFal,
	EraserFad,
	ExclamationTriangleFal,
	HandPointerFad,
	LockAltFal,
	RandomFad,
	SaveFad,
	TimesFal,
	InfoCircleFal,
	TruckMovingFal,
	UndoFad
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import clsx from 'clsx'
import formatDuration from '../../../utils/duration'
import { formatMoney } from '../../../utils/currency'
import { canConfirmTour, canFinalizeTour } from '../../../utils/tour'
import localizedDescription from '../../../utils/localizedDescription'
import { PLANNING_TYPE_MEERDAAGSE_RIT } from '../../../utils/constants'
import withPlanningStatus from '../../../hocs/withPlanningStatus'
import RouteItemList from './RouteItemList/RouteItemList'
import ConfirmDialog from '../../Shared/ConfirmDialog'

const styleSheet = (theme) => ({
	root: {
		marginBottom: 3
	},
	tourNumber: {
		marginTop: 16,
		marginBottom: 16
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 16
	},
	'@keyframes avatarbutton-hover': {
		'0%': {
			transform: 'rotate(45deg)'
		},
		'100%': {
			transform: 'rotate(0deg)'
		}
	},
	avatarButton: {
		cursor: 'pointer',
		animation: '$avatarbutton-hover .5s'
	},
	iconButton: {
		marginLeft: 4,
		marginRight: 4
	},
	iconGreen: {
		color: green[500]
	},
	licensePlate: {
		position: 'relative',
		display: 'inline-block',
		border: '2px solid black',
		borderRadius: 4,
		padding: '2px 12px 2px 32px',
		marginTop: 6,
		marginBottom: 6,
		fontWeight: 500
	},
	licensePlateBE: {
		backgroundColor: grey[50],
		color: red[900]
	},
	licensePlateDE: {
		backgroundColor: grey[50],
		color: theme.palette.text.primary
	},
	licensePlateNL: {
		backgroundColor: amber[300],
		color: theme.palette.text.primary
	},
	licensePlateUnknown: {
		backgroundColor: 'transparent',
		color: theme.palette.text.primary
	},
	licensePlateCountry: {
		position: 'absolute',
		display: 'inline-block',
		top: 0,
		left: 0,
		bottom: 0,
		width: 20,
		backgroundColor: blue.A700,
		color: '#fff',
		fontSize: '10px',
		lineHeight: '25px',
		textAlign: 'center'
	},
	'@keyframes cog-spin': {
		'0%': {
			transform: 'rotate(0deg)'
		},
		'100%': {
			transform: 'rotate(360deg)'
		}
	},
	cogs: {
		position: 'relative',
		marginTop: 16,
		marginBottom: 16
	},
	cog1: {
		width: '1.25em',
		textAlign: 'center',
		fontSize: '2em',
		animation: '$cog-spin 2s infinite linear',
		animationDuration: '4s'
	},
	cog2: {
		width: '1.25em',
		textAlign: 'center',
		animation: '$cog-spin 2s infinite linear',
		position: 'absolute',
		top: '-0.4em',
		right: '-0.8em',
		animationDirection: 'reverse',
		animationDuration: '2s'
	},
	cog3: {
		width: '1.25em',
		textAlign: 'center',
		animation: '$cog-spin 2s infinite linear',
		position: 'absolute',
		top: 'auto',
		right: '-0.8em',
		bottom: '-0.3em',
		animationDirection: 'reverse',
		animationDuration: '2s'
	},
	divider: {
		width: '90%',
		marginBottom: theme.spacing(1)
	},
	emptyTour: {
		marginTop: 8,
		marginBottom: 16
	},
	violationsIcon: {
		marginRight: 8
	},
	violationsWarning: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		paddingTop: 8,
		color: amber[700]
	},
	violationsSuccess: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		paddingTop: 8,
		color: green[500]
	},
	fromToShrinked: {
		width: 'calc(100% - 44px)' // Add some space for the updateStatusOptions
	},
	card: {
		display: 'block',
		position: 'relative'
	},
	cardHeader: {
		alignItems: 'start',
		paddingBottom: 0
	},
	cardHeaderShrinked: {
		width: 'calc(100% - 44px)' // Add some space for the updateStatusOptions
	},
	cardContent: {
		paddingBottom: 0
	},
	cardActions: {
		justifyContent: 'center'
	},
	updatingTour: {
		margin: 8
	},
	updateStatusOptions: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		top: theme.spacing(2),
		right: theme.spacing(2),
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius
	},
	updateStatusOptionsButtonTop: {
		borderRadius: '3px 3px 0 0',
		padding: theme.spacing(1)
	},
	updateStatusOptionsButtonMiddle: {
		borderRadius: 0,
		padding: theme.spacing(1)
	},
	updateStatusOptionsButtonBottom: {
		borderRadius: '0 0 3px 3px',
		padding: theme.spacing(1)
	},
	updateStatusOptionsButtonSelected: {
		backgroundColor:
			theme.palette.type === 'dark'
				? theme.palette.grey[800]
				: theme.palette.grey[200]
	},
	tourStats: {
		paddingTop: 8
	},
	skillsButton: {
		marginTop: -2
	},
	transportNumberText: {
		color: green[500]
	},
	transportNumberContainer: {
		height: '48px'
	}
})

class TourComparisonItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		deselect: PropTypes.func.isRequired,
		finalize: PropTypes.func.isRequired,
		manualPlanningAction: PropTypes.func.isRequired,
		manualPlanningDown: PropTypes.func.isRequired,
		manualPlanningIsModified: PropTypes.bool,
		manualPlanningMode: PropTypes.bool,
		manualPlanningMove: PropTypes.func.isRequired,
		manualPlanningRemove: PropTypes.func.isRequired,
		manualPlanningRemoveOrdersWithoutAppointment: PropTypes.func.isRequired,
		manualPlanningReverse: PropTypes.func.isRequired,
		manualPlanningUp: PropTypes.func.isRequired,
		metaTour: PropTypes.object,
		ownUserId: PropTypes.string.isRequired,
		number: PropTypes.number.isRequired,
		planningIsProcessing: PropTypes.bool, // Provided by `withPlanningStatus` HOC
		tour: PropTypes.object.isRequired,
		undoConfirm: PropTypes.func.isRequired
	}

	static defaultProps = {
		confirm: () => {},
		deselect: () => {},
		finalize: () => {},
		manualPlanningAction: () => {},
		manualPlanningDown: () => {},
		manualPlanningMove: () => {},
		manualPlanningRemove: () => {},
		manualPlanningRemoveOrdersWithoutAppointment: () => {},
		manualPlanningReverse: () => {},
		manualPlanningUp: () => {},
		undoConfirm: () => {}
	}

	state = {
		showDeselectButton: false,
		showSkills: false,
		showConfirmDialog: false
	}

	handleConfirm = () => {
		const { confirm, tour } = this.props
		confirm(tour.id)
	}

	handleDeselect = () => {
		const { deselect, tour } = this.props
		deselect(tour.id)
	}

	handleFinalize = () => {
		const { finalize, tour } = this.props
		finalize(tour.id)
	}

	handleOpenConfirmDialog = () => {
		this.setState({
			showConfirmDialog: true
		})
	}

	handleCloseConfirmDialog = () => {
		this.setState({
			showConfirmDialog: false
		})
	}

	handleFinalizeConfirmDialog = () => {
		this.setState({
			showConfirmDialog: false
		})
		this.handleFinalize()
	}

	handleUndoConfirm = () => {
		const { undoConfirm, tour } = this.props
		undoConfirm(tour.id)
	}

	handleTourNumberMouseEnter = () => {
		this.setState({
			showDeselectButton: true
		})
	}

	handleTourNumberMouseLeave = () => {
		this.setState({
			showDeselectButton: false
		})
	}

	handleSetManualPlanningAction = (selectedAction) => () => {
		const { manualPlanningAction, tour } = this.props
		manualPlanningAction(tour.id, selectedAction)
	}

	handleManualPlanningReverse = () => {
		const { manualPlanningReverse, tour } = this.props
		manualPlanningReverse(tour.id)
	}

	handleManualPlanningRemoveOrdersWithoutAppointment = () => {
		const { manualPlanningRemoveOrdersWithoutAppointment, tour } = this.props
		manualPlanningRemoveOrdersWithoutAppointment(tour.id)
	}

	handleOpenSkillsClick = () => {
		this.setState({ showSkills: true })
	}

	handleCloseSkillsClick = () => {
		this.setState({ showSkills: false })
	}

	render() {
		const {
			classes,
			manualPlanningDown,
			manualPlanningIsModified,
			manualPlanningMode,
			manualPlanningMove,
			manualPlanningRemove,
			manualPlanningUp,
			metaTour,
			number,
			ownUserId,
			planningIsProcessing,
			tour
		} = this.props
		const { showDeselectButton, showSkills, showConfirmDialog } = this.state

		const isUpdating = metaTour ? metaTour.isUpdating : false

		let tourNumber
		const tourNumberAvatar = <Avatar>{number}</Avatar>
		if (manualPlanningMode) {
			tourNumber = <div className={classes.tourNumber}>{tourNumberAvatar}</div>
		} else {
			const deselectButton = (
				<Avatar onClick={this.handleDeselect} className={classes.avatarButton}>
					<SvgIcon>
						<TimesFal />
					</SvgIcon>
				</Avatar>
			)
			tourNumber = (
				<div
					className={classes.tourNumber}
					onMouseEnter={this.handleTourNumberMouseEnter}
					onMouseLeave={this.handleTourNumberMouseLeave}
				>
					{showDeselectButton ? deselectButton : tourNumberAvatar}
				</div>
			)
		}

		let licensePlateCountryClassName
		let licensePlateCountryIndicator
		if (tour.vehicle.country === 'BE') {
			licensePlateCountryClassName = classes.licensePlateBE
			licensePlateCountryIndicator = (
				<span className={classes.licensePlateCountry}>B</span>
			)
		} else if (tour.vehicle.country === 'DE') {
			licensePlateCountryClassName = classes.licensePlateDE
			licensePlateCountryIndicator = (
				<span className={classes.licensePlateCountry}>D</span>
			)
		} else if (tour.vehicle.country === 'NL') {
			licensePlateCountryClassName = classes.licensePlateNL
			licensePlateCountryIndicator = (
				<span className={classes.licensePlateCountry}>NL</span>
			)
		} else {
			licensePlateCountryClassName = classes.licensePlateUnknown
			licensePlateCountryIndicator = (
				<span className={classes.licensePlateCountry}>?</span>
			)
		}

		const firstDriverName = tour.firstDriver
			? tour.firstDriver.name
			: i18n.t('app:planning.Tour.unknownDriver')
		const secondDriverName = tour.secondDriver
			? tour.secondDriver.name
			: i18n.t('app:planning.Tour.unknownDriver')

		let drivers
		if (firstDriverName && secondDriverName) {
			drivers = (
				<span>
					{firstDriverName}
					<br />
					{secondDriverName}
				</span>
			)
		} else if (firstDriverName) {
			drivers = <span>{firstDriverName}</span>
		} else if (secondDriverName) {
			drivers = <span>{secondDriverName}</span>
		}

		// Skills
		let skillRows = []
		// Vehicle qualifications
		if (tour.vehicle.qualifications && tour.vehicle.qualifications.length > 0) {
			const vehicleQualificationRows = tour.vehicle.qualifications.map(
				(qualification, index) => {
					let description = localizedDescription(qualification.translations)
					description =
						description === '??'
							? (description = `${i18n.t('app:planning.Tour.noDescription')} (${
									qualification.key
							  })`)
							: description
					return (
						<ListItem key={`vehicleQualification-${index}`}>
							<ListItemText primary={<span>{description}</span>} />
						</ListItem>
					)
				}
			)
			skillRows = skillRows.concat(
				<ListSubheader
					key="vehicleQualifications"
					color="inherit"
					inset
					disableSticky
				>
					{tour.vehicle.licensePlate}
				</ListSubheader>
			)
			skillRows = skillRows.concat(vehicleQualificationRows)
		}
		// First driver qualifications
		if (
			tour.firstDriver &&
			tour.firstDriver.qualifications &&
			tour.firstDriver.qualifications.length > 0
		) {
			const firstDriverQualificationRows = tour.firstDriver.qualifications.map(
				(qualification, index) => {
					let description = localizedDescription(qualification.translations)
					description =
						description === '??'
							? (description = `${i18n.t('app:planning.Tour.noDescription')} (${
									qualification.key
							  })`)
							: description
					return (
						<ListItem key={`firstDriverQualification-${index}`}>
							<ListItemText primary={<span>{description}</span>} />
						</ListItem>
					)
				}
			)
			skillRows = skillRows.concat(
				<ListSubheader
					key="firstDriverQualifications"
					color="inherit"
					inset
					disableSticky
				>
					{tour.firstDriver.name}
				</ListSubheader>
			)
			skillRows = skillRows.concat(firstDriverQualificationRows)
		}
		if (
			tour.secondDriver &&
			tour.secondDriver.qualifications &&
			tour.secondDriver.qualifications.length > 0
		) {
			const secondDriverQualificationRows =
				tour.secondDriver.qualifications.map((qualification, index) => {
					let description = localizedDescription(qualification.translations)
					description =
						description === '??'
							? (description = `${i18n.t('app:planning.Tour.noDescription')} (${
									qualification.key
							  })`)
							: description
					return (
						<ListItem key={`secondDriverQualification-${index}`}>
							<ListItemText primary={<span>{description}</span>} />
						</ListItem>
					)
				})
			skillRows = skillRows.concat(
				<ListSubheader
					key="secondDriverQualifications"
					color="inherit"
					inset
					disableSticky
				>
					{tour.secondDriver.name}
				</ListSubheader>
			)
			skillRows = skillRows.concat(secondDriverQualificationRows)
		}
		let skills
		if (skillRows.length > 0) {
			skills = (
				<>
					<Dialog
						onClose={this.handleCloseSkillsClick}
						open={showSkills}
						maxWidth="xs"
						fullWidth
					>
						<List>{skillRows}</List>
						<DialogActions>
							<Button onClick={this.handleCloseSkillsClick} color="primary">
								{i18n.t('app:generic.close')}
							</Button>
						</DialogActions>
					</Dialog>
					<Tooltip
						title={i18n.t('app:planning.Tour.showSkills')}
						placement="top"
					>
						<IconButton
							className={classes.skillsButton}
							size="small"
							onClick={this.handleOpenSkillsClick}
						>
							<SvgIcon fontSize="small">
								<InfoCircleFal />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</>
			)
		}

		// When a tour has no route items set the start depot and end depot as starting and ending city
		let cityFrom
		let cityTo
		if (tour.route.length === 0) {
			cityFrom =
				tour.startDepot && tour.startDepot.name
					? tour.startDepot.name
					: '??????'
			cityTo =
				tour.endDepot && tour.endDepot.name ? tour.endDepot.name : '??????'
		} else {
			const firstTransportPoint = tour.route[0]
			const lastTransportPoint = tour.route[tour.route.length - 1]
			cityFrom =
				firstTransportPoint.customer && firstTransportPoint.customer.city
					? firstTransportPoint.customer.city
					: '??????'
			cityTo =
				lastTransportPoint.customer && lastTransportPoint.customer.city
					? lastTransportPoint.customer.city
					: '??????'
		}

		// Check if confirm and/or finalize are enabled for this tour
		const disableConfirmButton = !canConfirmTour(tour, metaTour, ownUserId)
		const disableFinalizeButton = !canFinalizeTour(tour, metaTour, ownUserId)

		let updateStatusOptions
		let tourActionsPart
		if (manualPlanningMode) {
			// In manual planning mode we don't confirm/finalize directly with buttons, but we show a list of FormControlbuttons with these options instead
			const selectedAction = tour.action || 'save'

			if (
				tour.route.length === 0 &&
				tour.planningType.key !== PLANNING_TYPE_MEERDAAGSE_RIT
			) {
				// Empty tours for non multiple-days planning may be reserved
				updateStatusOptions = (
					<div className={classes.updateStatusOptions}>
						<Tooltip title={i18n.t('app:planning.Tour.save')} placement="left">
							<IconButton
								color={selectedAction === 'save' ? 'primary' : 'default'}
								onClick={this.handleSetManualPlanningAction('save')}
								className={
									selectedAction === 'save'
										? clsx(
												classes.updateStatusOptionsButtonTop,
												classes.updateStatusOptionsButtonSelected
										  )
										: classes.updateStatusOptionsButtonTop
								}
							>
								<SvgIcon>
									<SaveFad />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Divider />
						<Tooltip
							title={i18n.t('app:planning.Tour.reserve')}
							placement="left"
						>
							<div>
								<IconButton
									color={selectedAction === 'confirm' ? 'primary' : 'default'}
									disabled={disableConfirmButton}
									onClick={this.handleSetManualPlanningAction('confirm')}
									className={
										selectedAction === 'confirm'
											? clsx(
													classes.updateStatusOptionsButtonMiddle,
													classes.updateStatusOptionsButtonSelected
											  )
											: classes.updateStatusOptionsButtonMiddle
									}
								>
									<SvgIcon>
										<HandPointerFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
					</div>
				)
			} else {
				updateStatusOptions = (
					<div className={classes.updateStatusOptions}>
						<Tooltip title={i18n.t('app:planning.Tour.save')} placement="left">
							<IconButton
								color={selectedAction === 'save' ? 'primary' : 'default'}
								onClick={this.handleSetManualPlanningAction('save')}
								className={
									selectedAction === 'save'
										? clsx(
												classes.updateStatusOptionsButtonTop,
												classes.updateStatusOptionsButtonSelected
										  )
										: classes.updateStatusOptionsButtonTop
								}
							>
								<SvgIcon>
									<SaveFad />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Divider />
						<Tooltip
							title={i18n.t('app:planning.Tour.confirm')}
							placement="left"
						>
							<div>
								<IconButton
									color={selectedAction === 'confirm' ? 'primary' : 'default'}
									disabled={disableConfirmButton}
									onClick={this.handleSetManualPlanningAction('confirm')}
									className={
										selectedAction === 'confirm'
											? clsx(
													classes.updateStatusOptionsButtonMiddle,
													classes.updateStatusOptionsButtonSelected
											  )
											: classes.updateStatusOptionsButtonMiddle
									}
								>
									<SvgIcon>
										<CheckFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
						<Divider />
						<Tooltip
							title={i18n.t('app:planning.Tour.finalize')}
							placement="left"
						>
							<div>
								<IconButton
									color={
										selectedAction === 'finalize' ? 'secondary' : 'default'
									}
									disabled={disableFinalizeButton}
									onClick={this.handleSetManualPlanningAction('finalize')}
									className={
										selectedAction === 'finalize'
											? clsx(
													classes.updateStatusOptionsButtonBottom,
													classes.updateStatusOptionsButtonSelected
											  )
											: classes.updateStatusOptionsButtonBottom
									}
								>
									<SvgIcon>
										<CheckDoubleFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
					</div>
				)
			}

			tourActionsPart = (
				<Grid container justifyContent="center">
					<Grid item>
						<Tooltip
							title={i18n.t('app:planning.Tours.turnAround')}
							placement="top"
						>
							<IconButton
								className={classes.iconButton}
								onClick={this.handleManualPlanningReverse}
							>
								<SvgIcon>
									<RandomFad />
								</SvgIcon>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip
							title={i18n.t(
								'app:planning.Tours.removeOrdersWithoutAppointment'
							)}
							placement="top"
						>
							<div>
								<IconButton
									className={classes.iconButton}
									disabled={tour.route.every(
										(routeItem) =>
											routeItem.characteristics &&
											!routeItem.characteristics.isUnconfirmed
									)}
									onClick={
										this.handleManualPlanningRemoveOrdersWithoutAppointment
									}
								>
									<SvgIcon>
										<EraserFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
					</Grid>
				</Grid>
			)
		} else {
			// Show action buttons based on tour state
			let tourActions
			if (isUpdating) {
				// Tour is updating
				tourActions = (
					<CircularProgress className={classes.updatingTour} size={32} />
				)
			} else if (tour.isProcessing) {
				// Tour is being finalized
				tourActions = (
					<CircularProgress className={classes.updatingTour} size={32} />
				)
			} else if (tour.lock) {
				// Tour locked
				tourActions = (
					<Tooltip
						title={`${i18n.t('app:planning.Tour.lockedBy')} ${
							tour.lock.firstName
						} ${tour.lock.lastName}`}
						placement="top"
					>
						<div>
							<IconButton disabled>
								<SvgIcon color="primary">
									<LockAltFal />
								</SvgIcon>
							</IconButton>
						</div>
					</Tooltip>
				)
			} else if (tour.finalizedAt) {
				// Tour finalized
				tourActions = (
					<Grid
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
						className={classes.transportNumberContainer}
					>
						<Icon>
							<SvgIcon className={classes.iconGreen}>
								<TruckMovingFal />
							</SvgIcon>
						</Icon>
						<Typography
							className={classes.transportNumberText}
							variant="caption"
						>
							{i18n.t('app:planning.Tour.transportOrderNumber')}{' '}
							{tour.transportOrderNumber}
						</Typography>
					</Grid>
				)
			} else if (tour.confirmedAt && tour.route.length === 0) {
				tourActions = (
					<Tooltip
						title={i18n.t('app:planning.Tour.undoReserve')}
						placement="top"
					>
						<IconButton color="primary" onClick={this.handleUndoConfirm}>
							<SvgIcon>
								<UndoFad />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				)
			} else if (tour.confirmedAt) {
				tourActions = (
					<Fragment>
						<Tooltip
							title={i18n.t('app:planning.Tour.undoConfirm')}
							placement="top"
						>
							<IconButton color="primary" onClick={this.handleUndoConfirm}>
								<SvgIcon>
									<UndoFad />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip
							title={i18n.t('app:planning.Tour.finalize')}
							placement="top"
						>
							<div>
								<IconButton
									color="secondary"
									disabled={disableFinalizeButton}
									onClick={this.handleOpenConfirmDialog}
								>
									<SvgIcon>
										<CheckDoubleFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
					</Fragment>
				)
			} else if (planningIsProcessing) {
				// Planning run is active
				tourActions = (
					<div className={classes.cogs}>
						<SvgIcon className={classes.cog1}>
							<CogFal />
						</SvgIcon>
						<SvgIcon className={classes.cog2}>
							<CogFal />
						</SvgIcon>
						<SvgIcon className={classes.cog3}>
							<CogFal />
						</SvgIcon>
					</div>
				)
			} else if (tour.route.length === 0) {
				tourActions = (
					<Tooltip title={i18n.t('app:planning.Tour.reserve')} placement="top">
						<IconButton
							color="primary"
							disabled={disableConfirmButton}
							onClick={this.handleConfirm}
						>
							<SvgIcon>
								<HandPointerFad />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				)
			} else {
				tourActions = (
					<Fragment>
						<Tooltip
							title={i18n.t('app:planning.Tour.confirm')}
							placement="top"
						>
							<div>
								<IconButton
									color="primary"
									disabled={disableConfirmButton}
									onClick={this.handleConfirm}
								>
									<SvgIcon>
										<CheckFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
						<Tooltip
							title={i18n.t('app:planning.Tour.finalize')}
							placement="top"
						>
							<div>
								<IconButton
									color="secondary"
									disabled={disableFinalizeButton}
									onClick={this.handleOpenConfirmDialog}
								>
									<SvgIcon>
										<CheckDoubleFad />
									</SvgIcon>
								</IconButton>
							</div>
						</Tooltip>
					</Fragment>
				)
			}
			tourActionsPart = tourActions
		}

		// Validation violations
		let tourViolations
		if (tour.violations) {
			if (tour.violations.length > 0) {
				const violationRows = tour.violations.map((violation, index) => (
					<span key={`tour-violation-${index}`}>
						{localizedDescription(violation.descriptions)}
						<br />
					</span>
				))
				tourViolations = (
					<Tooltip title={<Fragment>{violationRows}</Fragment>}>
						<div className={classes.violationsWarning}>
							<Grid item container justifyContent="center" alignItems="center">
								<SvgIcon className={classes.violationsIcon}>
									<ExclamationTriangleFal />
								</SvgIcon>
								<Typography color="inherit" variant="subtitle1">
									{i18n.t('app:planning.Manual.hasViolationsTour')}
								</Typography>
							</Grid>
						</div>
					</Tooltip>
				)
			} else {
				tourViolations = (
					<div className={classes.violationsSuccess}>
						<Grid item container justifyContent="center" alignItems="center">
							<SvgIcon className={classes.violationsIcon}>
								<CheckCircleFal />
							</SvgIcon>
							<Typography color="inherit" variant="subtitle1">
								{i18n.t('app:planning.Manual.hasNoViolationsTour')}
							</Typography>
						</Grid>
					</div>
				)
			}
		}

		// When in modified manual planning mode we don't display the tour stats, because when a tour is modified these stats must be recalculated first
		let tourStats
		if (!manualPlanningMode || !manualPlanningIsModified) {
			const startTime = moment
				.unix(tour.startDepot.departureTime)
				.tz('Europe/Amsterdam')
				.format('H:mm')
			const endTime = moment
				.unix(tour.endDepot.departureTime)
				.tz('Europe/Amsterdam')
				.format('H:mm')
			const formattedDuration = formatDuration(tour.duration)
			const formattedDistance = `${Math.floor(tour.distance / 1000)} km`
			const formattedCost = formatMoney(tour.cost)
			tourStats = (
				<Grid className={classes.tourStats} container alignItems="center">
					<Grid item xs={4}>
						<Typography color="primary" variant="caption" component="p">
							{i18n.t('app:planning.Tour.Summary.duration')}
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body2" color="textSecondary" component="p">
							{formattedDuration} ({startTime} - {endTime})
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography color="primary" variant="caption" component="p">
							{i18n.t('app:planning.Tour.Summary.distance')}
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body2" color="textSecondary" component="p">
							{formattedDistance}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography color="primary" variant="caption" component="p">
							{i18n.t('app:planning.Tour.Summary.cost')}
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body2" color="textSecondary" component="p">
							{formattedCost}
						</Typography>
					</Grid>
				</Grid>
			)
		}

		return (
			<Card className={classes.root}>
				<div className={classes.card}>
					{updateStatusOptions}
					<CardHeader
						className={
							manualPlanningMode
								? clsx(classes.cardHeader, classes.cardHeaderShrinked)
								: classes.cardHeader
						}
						avatar={<Avatar>{tourNumber}</Avatar>}
						title={
							<Fragment>
								<Typography
									variant="subtitle2"
									className={clsx(
										classes.licensePlate,
										licensePlateCountryClassName
									)}
								>
									{licensePlateCountryIndicator}
									{tour.vehicle.licensePlate || '??????'}
								</Typography>
								{skills}
							</Fragment>
						}
						subheader={<span className={classes.driverName}>{drivers}</span>}
					/>
					<CardContent className={classes.cardContent}>
						<div className={manualPlanningMode ? classes.fromToShrinked : null}>
							<Typography variant="h5">{cityFrom}</Typography>
							<Typography variant="h5">{cityTo}</Typography>
						</div>
						{tourStats}
						{tourViolations}
					</CardContent>
					<CardActions className={classes.cardActions}>
						{tourActionsPart}
					</CardActions>
					<ConfirmDialog
						isOpen={showConfirmDialog}
						cancel={this.handleCloseConfirmDialog}
						cancelButtonText={i18n.t('app:planning.Tour.SaveDialog.cancel')}
						confirm={this.handleFinalizeConfirmDialog}
						confirmButtonText={i18n.t('app:planning.Tour.SaveDialog.confirm')}
						titleText={i18n.t('app:planning.Tour.SaveDialog.title')}
						descriptionText={i18n.t('app:planning.Tour.SaveDialog.description')}
					/>
				</div>
				<div className={classes.routeList}>
					<RouteItemList
						manualPlanningDown={manualPlanningDown}
						manualPlanningIsModified={manualPlanningIsModified}
						manualPlanningMode={manualPlanningMode}
						manualPlanningMove={manualPlanningMove}
						manualPlanningRemove={manualPlanningRemove}
						manualPlanningUp={manualPlanningUp}
						tour={tour}
					/>
				</div>
			</Card>
		)
	}
}

export default withPlanningStatus(withStyles(styleSheet)(TourComparisonItem))
