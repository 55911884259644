import actionTypes from './actionTypes'

const actionCreators = {
	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (plannings) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			plannings
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	}),

	update: (planningTypeKey, status, dates) => ({
		type: actionTypes.SOCKET_UPDATE_PLANNING_STATUS,
		payload: {
			status,
			dates
		},
		meta: {
			planningTypeKey
		}
	})
}

export default actionCreators
