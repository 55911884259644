const actionTypes = {
	FETCH: 'plannings/FETCH',
	FETCH_FAILURE: 'plannings/FETCH_FAILURE',
	FETCH_REQUEST: 'plannings/FETCH_REQUEST',
	FETCH_SUCCESS: 'plannings/FETCH_SUCCESS',
	SOCKET_CLOSE: 'plannings/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'plannings/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'plannings/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'plannings/SOCKET_OPEN',
	SOCKET_UPDATE_PLANNING_STATUS: 'plannings/SOCKET_UPDATE_PLANNING_STATUS'
}

export default actionTypes
