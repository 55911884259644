import {
	Button,
	Collapse,
	Divider,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	ListSubheader,
	List as MaterialList,
	ListItem as MaterialListItem,
	MenuItem,
	Slider,
	SvgIcon,
	Switch,
	TextField,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { PlusFal, TrashAltFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import { PropTypes } from 'prop-types'
import { PureComponent } from 'react'
import localizedDescription from '../../../utils/localizedDescription'
import {
	formatPlanningParams,
	formatPlanningParamsForApi
} from '../../../utils/planningtypes'
import AddRequirementDialog from './AddPrioritizedOrderRequirement'

const styleSheet = (theme) => ({
	confirmButton: {
		marginTop: 16
	},
	marginTop: {
		marginTop: 16
	},
	secondary: {
		color: theme.palette.text.secondary
	},
	slider: {
		paddingLeft: 16,
		paddingRight: 16,
		marginTop: 16
	}
})

class Details extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		confirm: PropTypes.func.isRequired,
		availableVehicleTypes: PropTypes.array.isRequired
	}

	state = {
		planningTypeKey: '',
		hasChanges: false,
		dateProposalDirectVelocity: 0,
		dateProposalDirectVelocityError: false,
		maxPeriodDays: 0,
		maxPeriodHours: 0,
		maxPeriodMinutes: 0,
		maxPeriodEnabled: false,
		maxPeriodError: false,
		maxDistance: 0,
		maxDistanceError: false,
		maxDrivingPeriodHours: 0,
		maxDrivingPeriodMinutes: 0,
		maxDrivingPeriodError: false,
		maxDrivingPeriodEnabled: false,
		maxDistanceBetweenTransportPointsEnabled: false,
		maxServicePeriodHours: 0,
		maxServicePeriodMinutes: 0,
		maxServicePeriodEnabled: false,
		maxServicePeriodError: false,
		returnVolume: 0,
		returnVolumeError: false,
		plannableDaysWithoutPriority: 1,
		plannableDaysMaximum: 1,
		plannableDaysMinimum: 1,
		startAtDepot: false,
		appointmentRequired: false,
		showNewPrioritizedOrderRequirementDialog: false,
		prioritizedOrderRequirements: [],
		drivingPeriodFactorsError: false,
		drivingPeriodFactors: [],
		serviceOrdersPriority: 2,
		serviceOrdersPriorityError: false
	}

	static getDerivedStateFromProps(props, state) {
		const { planningType } = props

		// a planning type is selected and it is not the same as the previous selected planning type
		if (
			planningType &&
			state.planningTypeKey !== planningType.planningTypeKey
		) {
			const formattedPlanningParams = formatPlanningParams(
				planningType.planningParams
			)

			return {
				planningTypeKey: planningType.planningTypeKey,
				hasChanges: false,
				dateProposalDirectVelocity:
					formattedPlanningParams.dateProposalDirectVelocity,
				dateProposalDirectVelocityError: false,
				maxPeriodDays: formattedPlanningParams.maxPeriodDays,
				maxPeriodHours: formattedPlanningParams.maxPeriodHours,
				maxPeriodMinutes: formattedPlanningParams.maxPeriodMinutes,
				maxPeriodEnabled: formattedPlanningParams.maxPeriodEnabled,
				maxPeriodError: false,
				maxDistance: formattedPlanningParams.maxDistance,
				maxDistanceBetweenTransportPointsEnabled:
					formattedPlanningParams.maxDistanceBetweenTransportPointsEnabled,
				maxDistanceError: false,
				maxDrivingPeriodError: false,
				maxDrivingPeriodHours: formattedPlanningParams.maxDrivingPeriodHours,
				maxDrivingPeriodMinutes:
					formattedPlanningParams.maxDrivingPeriodMinutes,
				maxDrivingPeriodEnabled:
					formattedPlanningParams.maxDrivingPeriodEnabled,
				maxServicePeriodHours: formattedPlanningParams.maxServicePeriodHours,
				maxServicePeriodMinutes:
					formattedPlanningParams.maxServicePeriodMinutes,
				maxServicePeriodEnabled:
					formattedPlanningParams.maxServicePeriodEnabled,
				maxServicePeriodError: false,
				plannableDaysWithoutPriority:
					formattedPlanningParams.plannableDaysWithoutPriority,
				plannableDaysMaximum: formattedPlanningParams.plannableDaysMaximum,
				plannableDaysMinimum: formattedPlanningParams.plannableDaysMinimum,
				returnVolume: formattedPlanningParams.returnVolume,
				returnVolumeError: false,
				startAtDepot: formattedPlanningParams.startAtDepot,
				appointmentRequired: formattedPlanningParams.appointmentRequired,
				prioritizedOrderRequirements:
					formattedPlanningParams.prioritizedOrderRequirements,
				drivingPeriodFactors: formattedPlanningParams.drivingPeriodFactors,
				serviceOrdersPriority: formattedPlanningParams.serviceOrdersPriority
			}
		}

		return null
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.planningType &&
			this.state.planningTypeKey === this.props.planningType.planningTypeKey
		) {
			if (
				prevState.maxPeriodDays !== this.state.maxPeriodDays ||
				prevState.maxPeriodHours !== this.state.maxPeriodHours ||
				prevState.maxPeriodMinutes !== this.state.maxPeriodMinutes
			) {
				// Validate max period
				const maxPeriodError = Boolean(
					this.state.maxPeriodDays === 0 &&
						this.state.maxPeriodHours === 0 &&
						this.state.maxPeriodMinutes === 0
				)
				this.setState({ maxPeriodError })
			}
			if (
				prevState.maxDrivingPeriodDays !== this.state.maxDrivingPeriodDays ||
				prevState.maxDrivingPeriodHours !== this.state.maxDrivingPeriodHours ||
				prevState.maxDrivingPeriodMinutes !== this.state.maxDrivingPeriodMinutes
			) {
				// Validate max period
				const maxDrivingPeriodError = Boolean(
					this.state.maxDrivingPeriodDays === 0 &&
						this.state.maxDrivingPeriodHours === 0 &&
						this.state.maxDrivingPeriodMinutes === 0
				)
				this.setState({ maxDrivingPeriodError })
			}
			if (
				prevState.maxServicePeriodDays !== this.state.maxServicePeriodDays ||
				prevState.maxServicePeriodHours !== this.state.maxServicePeriodHours ||
				prevState.maxServicePeriodMinutes !== this.state.maxServicePeriodMinutes
			) {
				// Validate max period
				const maxServicePeriodError = Boolean(
					this.state.maxServicePeriodDays === 0 &&
						this.state.maxServicePeriodHours === 0 &&
						this.state.maxServicePeriodMinutes === 0
				)
				this.setState({ maxServicePeriodError })
			}
			if (prevState.maxDistance !== this.state.maxDistance) {
				// Validate max distance
				const maxDistanceError = Boolean(this.state.maxDistance < 1)
				this.setState({ maxDistanceError })
			}
			if (prevState.returnVolume !== this.state.returnVolume) {
				// Validate return volume
				const returnVolumeError = Boolean(this.state.returnVolume < 1)
				this.setState({ returnVolumeError })
			}
			if (prevState.drivingPeriodFactors !== this.state.drivingPeriodFactors) {
				// Validate driving period factors
				const drivingPeriodFactorsError = Boolean(
					this.state.drivingPeriodFactors.some(
						(drivingPeriodFactor) =>
							!drivingPeriodFactor.drivingPeriodFactor ||
							drivingPeriodFactor.drivingPeriodFactor < 0
					)
				)
				this.setState({ drivingPeriodFactorsError })
			}
			if (
				prevState.serviceOrdersPriority !== this.state.serviceOrdersPriority
			) {
				// Validate service orders priority
				const validServiceOrdersPriority = [1, 2, 3]
				const serviceOrdersPriorityError = Boolean(
					!validServiceOrdersPriority.includes(this.state.serviceOrdersPriority)
				)
				this.setState({ serviceOrdersPriorityError })
			}
		}
	}

	handleChangeMaxPeriodDays = (event) => {
		const maxPeriodDays = event.target.value
		this.setState({ maxPeriodDays, hasChanges: true })
	}

	handleChangeMaxPeriodHours = (event) => {
		const maxPeriodHours = event.target.value
		this.setState({ maxPeriodHours, hasChanges: true })
	}

	handleChangeMaxPeriodMinutes = (event) => {
		const maxPeriodMinutes = event.target.value
		this.setState({ maxPeriodMinutes, hasChanges: true })
	}

	handleChangeMaxPeriodEnabled = (event) => {
		const isChecked = event.target.checked
		this.setState({ maxPeriodEnabled: isChecked, hasChanges: true })
	}

	handleChangeMaxDistance = (event) => {
		const maxDistance = event.target.value
			? parseInt(event.target.value, 10)
			: ''
		this.setState({ maxDistance, hasChanges: true })
	}

	handleChangeMaxDistanceBetweenTransportPointsEnabled = (event) => {
		const isChecked = event.target.checked
		this.setState({
			maxDistanceBetweenTransportPointsEnabled: isChecked,
			hasChanges: true
		})
	}

	handleChangeMaxDrivingPeriodHours = (event) => {
		const maxDrivingPeriodHours = event.target.value
		this.setState({ maxDrivingPeriodHours, hasChanges: true })
	}

	handleChangeMaxDrivingPeriodMinutes = (event) => {
		const maxDrivingPeriodMinutes = event.target.value
		this.setState({ maxDrivingPeriodMinutes, hasChanges: true })
	}

	handleChangeMaxDrivingPeriodEnabled = (event) => {
		const isChecked = event.target.checked
		this.setState({ maxDrivingPeriodEnabled: isChecked, hasChanges: true })
	}

	handleChangeMaxServicePeriodHours = (event) => {
		const maxServicePeriodHours = event.target.value
		this.setState({ maxServicePeriodHours, hasChanges: true })
	}

	handleChangeMaxServicePeriodMinutes = (event) => {
		const maxServicePeriodMinutes = event.target.value
		this.setState({ maxServicePeriodMinutes, hasChanges: true })
	}

	handleChangeMaxServicePeriodEnabled = (event) => {
		const isChecked = event.target.checked
		this.setState({ maxServicePeriodEnabled: isChecked, hasChanges: true })
	}

	handleChangePlannableDaysWithoutPriority = (event) => {
		const plannableDaysWithoutPriority = event.target.value
		this.setState({ plannableDaysWithoutPriority, hasChanges: true })
	}

	handleChangePlannableDaysMaximum = (event) => {
		const plannableDaysMaximum = event.target.value
		this.setState({ plannableDaysMaximum, hasChanges: true })
	}

	handleChangePlannableDaysMinimum = (event) => {
		const plannableDaysMinimum = event.target.value
		this.setState({ plannableDaysMinimum, hasChanges: true })
	}

	handleChangeReturnVolume = (event) => {
		const returnVolume = event.target.value
			? parseFloat(event.target.value)
			: ''
		this.setState({ returnVolume, hasChanges: true })
	}

	handleChangeDateProposalDirectVelocity = (event) => {
		const dateProposalDirectVelocity = event.target.value
			? parseFloat(event.target.value)
			: 0
		this.setState({ dateProposalDirectVelocity, hasChanges: true })
	}

	handleChangeDrivingPeriodFactor = (event, vehicleType) => {
		const newDrivingPeriodFactor = event.target.value
			? parseFloat(event.target.value)
			: ''
		const { drivingPeriodFactors } = this.state

		let newDrivingPeriodFactors
		// if vehicleType is in drivingPeriodFactors, update drivingPeriodFactor
		if (
			drivingPeriodFactors.some(
				(drivingPeriodFactor) => drivingPeriodFactor.vehicleType === vehicleType
			)
		) {
			newDrivingPeriodFactors = drivingPeriodFactors.map(
				(drivingPeriodFactor) => {
					if (drivingPeriodFactor.vehicleType === vehicleType) {
						return { vehicleType, drivingPeriodFactor: newDrivingPeriodFactor }
					}
					return drivingPeriodFactor
				}
			)
		} else {
			// if vehicleType is not in drivingPeriodFactors, add drivingPeriodFactor
			newDrivingPeriodFactors = drivingPeriodFactors.concat({
				vehicleType,
				drivingPeriodFactor: newDrivingPeriodFactor
			})
		}
		this.setState({
			drivingPeriodFactors: newDrivingPeriodFactors,
			hasChanges: true
		})
	}

	handleToggleStartAtDepot = () => {
		const { startAtDepot } = this.state
		this.setState({ startAtDepot: !startAtDepot, hasChanges: true })
	}

	handleToggleAppointmentRequired = () => {
		const { appointmentRequired } = this.state
		this.setState({
			appointmentRequired: !appointmentRequired,
			hasChanges: true
		})
	}

	handleToggleNewPrioritizedOrderRequirementDialog = () => {
		const { showNewPrioritizedOrderRequirementDialog } = this.state
		this.setState({
			showNewPrioritizedOrderRequirementDialog:
				!showNewPrioritizedOrderRequirementDialog
		})
	}

	handleNewPrioritizedOrderRequirement = (newRequirement) => {
		const { prioritizedOrderRequirements } = this.state
		this.setState({
			prioritizedOrderRequirements: prioritizedOrderRequirements
				.concat(newRequirement)
				.sort((a, b) => a.requirement - b.requirement),
			showNewPrioritizedOrderRequirementDialog: false,
			hasChanges: true
		})
	}

	handleRemovePrioritizedOrderRequirement =
		(removedPrioritizedOrderRequirement) => () => {
			const { prioritizedOrderRequirements } = this.state
			this.setState({
				prioritizedOrderRequirements: prioritizedOrderRequirements.filter(
					(prioritizedOrderRequirement) =>
						prioritizedOrderRequirement.requirement !==
						removedPrioritizedOrderRequirement.requirement
				),
				hasChanges: true
			})
		}

	handleChangeServiceOrdersPriority = (_event, newValue) => {
		const serviceOrdersPriority = newValue
		this.setState({ serviceOrdersPriority, hasChanges: true })
	}

	handleClickConfirm = () => {
		const { confirm, planningType } = this.props
		const {
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled,
			maxDistance,
			maxDistanceBetweenTransportPointsEnabled,
			maxDrivingPeriodHours,
			maxDrivingPeriodMinutes,
			maxDrivingPeriodEnabled,
			maxServicePeriodHours,
			maxServicePeriodMinutes,
			maxServicePeriodEnabled,
			plannableDaysWithoutPriority,
			plannableDaysMaximum,
			plannableDaysMinimum,
			returnVolume,
			startAtDepot,
			appointmentRequired,
			dateProposalDirectVelocity,
			prioritizedOrderRequirements,
			drivingPeriodFactors,
			serviceOrdersPriority
		} = this.state

		const planningParams = formatPlanningParamsForApi({
			dateProposalDirectVelocity,
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxPeriodEnabled,
			maxDistance,
			maxDrivingPeriodHours,
			maxDrivingPeriodMinutes,
			maxDrivingPeriodEnabled,
			maxDistanceBetweenTransportPointsEnabled,
			maxServicePeriodHours,
			maxServicePeriodMinutes,
			maxServicePeriodEnabled,
			plannableDaysWithoutPriority,
			plannableDaysMaximum,
			plannableDaysMinimum,
			returnVolume,
			startAtDepot,
			appointmentRequired,
			prioritizedOrderRequirements,
			drivingPeriodFactors,
			serviceOrdersPriority
		})

		confirm({
			...planningType,
			planningParams
		})

		this.setState({ hasChanges: false })
	}

	render() {
		const {
			maxPeriodDays,
			maxPeriodHours,
			maxPeriodMinutes,
			maxDistance,
			returnVolume,
			plannableDaysWithoutPriority,
			plannableDaysMaximum,
			plannableDaysMinimum,
			startAtDepot,
			appointmentRequired,
			maxDrivingPeriodHours,
			maxDrivingPeriodMinutes,
			maxServicePeriodHours,
			maxServicePeriodMinutes
		} = this.state
		const {
			hasChanges,
			maxPeriodError,
			maxDistanceError,
			returnVolumeError,
			maxPeriodEnabled,
			maxDistanceBetweenTransportPointsEnabled,
			maxDrivingPeriodEnabled,
			maxDrivingPeriodError,
			maxServicePeriodEnabled,
			maxServicePeriodError,
			dateProposalDirectVelocity,
			dateProposalDirectVelocityError,
			showNewPrioritizedOrderRequirementDialog,
			prioritizedOrderRequirements,
			drivingPeriodFactorsError,
			drivingPeriodFactors,
			serviceOrdersPriority,
			serviceOrdersPriorityError
		} = this.state
		const { planningType, classes, availableVehicleTypes } = this.props

		const description = localizedDescription(planningType.descriptions)

		const dayMenuItems = []
		for (let day = 0; day <= 5; day += 1) {
			dayMenuItems.push(
				<MenuItem key={day} value={day}>
					{day.toString()}
				</MenuItem>
			)
		}

		const hourMenuItems = []
		for (let hour = 0; hour <= 23; hour += 1) {
			hourMenuItems.push(
				<MenuItem key={hour} value={hour}>
					{hour.toString()}
				</MenuItem>
			)
		}

		const minuteMenuItems = []
		for (let minute = 0; minute <= 55; minute += 5) {
			minuteMenuItems.push(
				<MenuItem key={minute} value={minute}>
					{minute.toString()}
				</MenuItem>
			)
		}

		const plannableDaysMenuItems = []
		for (let day = 1; day <= 30; day += 1) {
			plannableDaysMenuItems.push(
				<MenuItem key={day} value={day}>
					{day.toString()}
				</MenuItem>
			)
		}

		const isConfirmButtonDisabled =
			!hasChanges ||
			maxPeriodError ||
			maxDistanceError ||
			returnVolumeError ||
			maxDrivingPeriodError ||
			maxServicePeriodError ||
			drivingPeriodFactorsError ||
			serviceOrdersPriorityError

		let orderRequirements
		if (prioritizedOrderRequirements.length === 0) {
			orderRequirements = (
				<Typography variant="body2" className={classes.marginTop}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsEmpty'
					)}
				</Typography>
			)
		} else {
			const orderRequirementItems = prioritizedOrderRequirements.map(
				(prioritizedOrderRequirement) => (
					<MaterialListItem
						divider
						disableGutters
						key={prioritizedOrderRequirement.requirement}
					>
						<Grid container alignItems="center">
							<Grid item xs={3}>
								<Typography variant="body2">
									{prioritizedOrderRequirement.requirement}
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">
									{prioritizedOrderRequirement.description}
								</Typography>
							</Grid>
							<Grid item container xs={2} justifyContent="flex-end">
								<IconButton
									size="small"
									color="secondary"
									onClick={this.handleRemovePrioritizedOrderRequirement(
										prioritizedOrderRequirement
									)}
								>
									<SvgIcon>
										<TrashAltFal />
									</SvgIcon>
								</IconButton>
							</Grid>
						</Grid>
					</MaterialListItem>
				)
			)
			orderRequirements = (
				<MaterialList disablePadding className={classes.marginTop}>
					<ListSubheader disableGutters disableSticky>
						<Grid container>
							<Grid item xs={3}>
								<Typography variant="body2" className={classes.subHeaderColor}>
									{i18n.t(
										'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsReq'
									)}
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Typography variant="body2" className={classes.subHeaderColor}>
									{i18n.t(
										'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsDescr'
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListSubheader>
					<Divider />
					{orderRequirementItems}
				</MaterialList>
			)
		}

		const drivingPeriodFactorItems = availableVehicleTypes.map(
			(availableVehicleType) => {
				const drivingPeriodFactorItem = drivingPeriodFactors.find(
					(drivingPeriodFactor) =>
						drivingPeriodFactor.vehicleType === availableVehicleType
				) || { drivingPeriodFactor: 1, vehicleType: availableVehicleType }
				const { drivingPeriodFactor } = drivingPeriodFactorItem
				return (
					<Grid
						item
						container
						xs={12}
						key={availableVehicleType}
						className={classes.marginTop}
					>
						<Grid item xs={6}>
							<Typography>{availableVehicleType}</Typography>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								value={drivingPeriodFactor}
								onChange={(event) =>
									this.handleChangeDrivingPeriodFactor(
										event,
										availableVehicleType
									)
								}
								type="number"
								inputProps={{
									step: '0.01'
								}}
								helperText={i18n.t(
									'app:planningtypeadmin.PlanningType.Details.drivingPeriodFactorHelperText'
								)}
								error={drivingPeriodFactor === '' || drivingPeriodFactor < 0}
							/>
						</Grid>
					</Grid>
				)
			}
		)

		return (
			<>
				<Typography variant="h5">{description}</Typography>
				<Grid container justifyContent="space-between" alignItems="center">
					{/* maxPeriod */}
					<Grid item className={classes.marginTop}>
						<Typography
							variant="body2"
							color={maxPeriodError ? 'error' : 'inherit'}
						>
							{i18n.t('app:planningtypeadmin.PlanningType.Details.maxPeriod')}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							color="primary"
							checked={maxPeriodEnabled}
							onChange={this.handleChangeMaxPeriodEnabled}
						/>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={maxPeriodEnabled}>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<TextField
										fullWidth
										select
										value={maxPeriodDays}
										error={maxPeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxPeriodDaysUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxPeriodDays}
									>
										{dayMenuItems}
									</TextField>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										select
										value={maxPeriodHours}
										error={maxPeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxPeriodHoursUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxPeriodHours}
									>
										{hourMenuItems}
									</TextField>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										select
										value={maxPeriodMinutes}
										error={maxPeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxPeriodMinutesUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxPeriodMinutes}
									>
										{minuteMenuItems}
									</TextField>
								</Grid>
								<Grid item xs={12}>
									<FormHelperText error={maxPeriodError}>
										{i18n.t(
											'app:planningtypeadmin.PlanningType.Details.maxPeriodErrorText'
										)}
									</FormHelperText>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
					{/* maxDistance */}
					<Grid item className={classes.marginTop}>
						<Typography
							variant="body2"
							color={maxDistanceError ? 'error' : 'inherit'}
						>
							{i18n.t('app:planningtypeadmin.PlanningType.Details.maxDistance')}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							color="primary"
							checked={maxDistanceBetweenTransportPointsEnabled}
							onChange={
								this.handleChangeMaxDistanceBetweenTransportPointsEnabled
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={maxDistanceBetweenTransportPointsEnabled}>
							<TextField
								fullWidth
								value={maxDistance}
								type="number"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											{i18n.t(
												'app:planningtypeadmin.PlanningType.Details.maxDistanceUnit'
											)}
										</InputAdornment>
									)
								}}
								onChange={this.handleChangeMaxDistance}
								margin="normal"
								error={maxDistanceError}
								helperText={i18n.t(
									'app:planningtypeadmin.PlanningType.Details.maxDistanceErrorText'
								)}
							/>
						</Collapse>
					</Grid>
					{/* maxDrivingPeriod */}
					<Grid item className={classes.marginTop}>
						<Typography
							variant="body2"
							color={maxDrivingPeriodError ? 'error' : 'inherit'}
						>
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.maxDrivingPeriod'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							color="primary"
							checked={maxDrivingPeriodEnabled}
							onChange={this.handleChangeMaxDrivingPeriodEnabled}
						/>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={maxDrivingPeriodEnabled}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										select
										value={maxDrivingPeriodHours}
										error={maxDrivingPeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxDrivingPeriodHoursUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxDrivingPeriodHours}
									>
										{hourMenuItems}
									</TextField>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										select
										value={maxDrivingPeriodMinutes}
										error={maxDrivingPeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxDrivingPeriodMinutesUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxDrivingPeriodMinutes}
									>
										{minuteMenuItems}
									</TextField>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
					{/* maxServicePeriod */}
					<Grid item className={classes.marginTop}>
						<Typography
							variant="body2"
							color={maxServicePeriodError ? 'error' : 'inherit'}
						>
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.maxServicePeriod'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							color="primary"
							checked={maxServicePeriodEnabled}
							onChange={this.handleChangeMaxServicePeriodEnabled}
						/>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={maxServicePeriodEnabled}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										select
										value={maxServicePeriodHours}
										error={maxServicePeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxServicePeriodHoursUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxServicePeriodHours}
									>
										{hourMenuItems}
									</TextField>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										select
										value={maxServicePeriodMinutes}
										error={maxServicePeriodError}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.maxServicePeriodMinutesUnit'
													)}
												</InputAdornment>
											)
										}}
										onChange={this.handleChangeMaxServicePeriodMinutes}
									>
										{minuteMenuItems}
									</TextField>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
					{/* returnVolume */}
					<Grid item className={classes.marginTop}>
						<Typography
							variant="body2"
							color={returnVolumeError ? 'error' : 'inherit'}
						>
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.returnVolume'
							)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={returnVolume}
							type="number"
							onChange={this.handleChangeReturnVolume}
							margin="normal"
							error={returnVolumeError}
							helperText={i18n.t(
								'app:planningtypeadmin.PlanningType.Details.returnVolumeErrorText'
							)}
						/>
					</Grid>
					{/* startAtDepot */}
					<Grid
						container
						item
						xs={12}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item className={classes.marginTop}>
							<Typography variant="body2">
								{i18n.t(
									'app:planningtypeadmin.PlanningType.Details.startAtDepot'
								)}
							</Typography>
						</Grid>
						<Grid item>
							<Switch
								color="primary"
								checked={startAtDepot}
								onChange={this.handleToggleStartAtDepot}
							/>
						</Grid>
					</Grid>
					{/* appointmentRequired */}
					<Grid item className={classes.marginTop}>
						<Typography variant="body2">
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.appointmentRequired'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							color="primary"
							checked={appointmentRequired}
							onChange={this.handleToggleAppointmentRequired}
						/>
					</Grid>
					{/* dateProposalDirectVelocity */}
					<Grid item xs={12}>
						<Collapse in={appointmentRequired}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										label={i18n.t(
											'app:planningtypeadmin.PlanningType.Details.dateProposalDirectVelocity'
										)}
										fullWidth
										value={dateProposalDirectVelocity}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{i18n.t(
														'app:planningtypeadmin.PlanningType.Details.dateProposalDirectVelocityUnit'
													)}
												</InputAdornment>
											)
										}}
										type="number"
										onChange={this.handleChangeDateProposalDirectVelocity}
										margin="normal"
										error={dateProposalDirectVelocityError}
										helperText={i18n.t(
											'app:planningtypeadmin.PlanningType.Details.dateProposalDirectVelocityErrorText'
										)}
									/>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
				</Grid>
				{/* WBKB */}
				<Typography variant="h6" className={classes.marginTop}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.WBKB')}
				</Typography>
				<TextField
					label={i18n.t(
						'app:planningtypeadmin.PlanningType.Details.plannableDaysWithoutPriority'
					)}
					fullWidth
					select
					value={plannableDaysWithoutPriority}
					margin="normal"
					onChange={this.handleChangePlannableDaysWithoutPriority}
				>
					{plannableDaysMenuItems}
				</TextField>
				<TextField
					label={i18n.t(
						'app:planningtypeadmin.PlanningType.Details.plannableDaysMaximum'
					)}
					fullWidth
					select
					value={plannableDaysMaximum}
					margin="normal"
					onChange={this.handleChangePlannableDaysMaximum}
				>
					{plannableDaysMenuItems}
				</TextField>
				<TextField
					label={i18n.t(
						'app:planningtypeadmin.PlanningType.Details.plannableDaysMinimum'
					)}
					fullWidth
					select
					value={plannableDaysMinimum}
					margin="normal"
					onChange={this.handleChangePlannableDaysMinimum}
				>
					{plannableDaysMenuItems}
				</TextField>
				{/* Vaardigheden met prioriteit */}
				<Grid
					container
					item
					justifyContent="space-between"
					alignItems="center"
					className={classes.marginTop}
				>
					<Grid item>
						<Typography variant="h6">
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.prioritizedOrderRequirementsTitle'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton
							size="small"
							color="primary"
							onClick={this.handleToggleNewPrioritizedOrderRequirementDialog}
						>
							<SvgIcon>
								<PlusFal />
							</SvgIcon>
						</IconButton>
					</Grid>
					<AddRequirementDialog
						prioritizedOrderRequirements={prioritizedOrderRequirements}
						open={showNewPrioritizedOrderRequirementDialog}
						handleDialogClose={
							this.handleToggleNewPrioritizedOrderRequirementDialog
						}
						handleDialogSave={this.handleNewPrioritizedOrderRequirement}
					/>
				</Grid>
				{orderRequirements}
				{/* Driving period factors */}
				<Grid container item className={classes.marginTop}>
					<Grid item xs={12}>
						<Typography variant="h6">
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.drivingPeriodFactorTitle'
							)}
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.marginTop}>
						<Typography variant="body2" className={classes.secondary}>
							{i18n.t('app:planningtypeadmin.PlanningType.Details.vehicleType')}
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.marginTop}>
						<Typography variant="body2" className={classes.secondary}>
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.drivingPeriodFactor'
							)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					{drivingPeriodFactorItems}
				</Grid>
				{/* Service orders priority */}
				<Grid container item className={classes.marginTop}>
					<Grid item xs={12}>
						<Typography variant="h6">
							{i18n.t(
								'app:planningtypeadmin.PlanningType.Details.serviceOrdersPriorityTitle'
							)}
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.slider}>
						<Slider
							value={serviceOrdersPriority}
							defaultValue={2}
							step={1}
							onChange={this.handleChangeServiceOrdersPriority}
							marks={[
								{
									value: 1,
									label: i18n.t(
										'app:planningtypeadmin.PlanningType.Details.serviceOrdersLowPriority'
									)
								},
								{
									value: 2,
									label: i18n.t(
										'app:planningtypeadmin.PlanningType.Details.serviceOrdersMediumPriority'
									)
								},
								{
									value: 3,
									label: i18n.t(
										'app:planningtypeadmin.PlanningType.Details.serviceOrdersHighPriority'
									)
								}
							]}
							min={1}
							max={3}
						/>
					</Grid>
				</Grid>
				<Button
					color="primary"
					variant="contained"
					disabled={isConfirmButtonDisabled}
					onClick={this.handleClickConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.confirm')}
				</Button>
			</>
		)
	}
}

export default withStyles(styleSheet)(Details)
