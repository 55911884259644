import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../../../redux/vehiclescustom/actionCreators'
import selectors from '../../../../redux/vehiclescustom/selectors'
import DateNavigator from './DateNavigator'

class DateNavigatorContainer extends PureComponent {
	static propTypes = {
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const {
			selectCurrentDate,
			selectNextDate,
			selectPreviousDate,
			selectedMoment
		} = this.props

		return (
			<DateNavigator
				selectCurrentDate={selectCurrentDate}
				selectNextDate={selectNextDate}
				selectPreviousDate={selectPreviousDate}
				selectedMoment={selectedMoment}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		selectedMoment: selectors.selectedMomentSelector(state.vehiclesState)
	}),
	{
		selectCurrentDate: actionCreators.selectCurrentDate,
		selectNextDate: actionCreators.selectNextDate,
		selectPreviousDate: actionCreators.selectPreviousDate
	}
)

export default connector(DateNavigatorContainer)
