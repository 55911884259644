import { createSelector } from 'reselect'

const userSelector = (userState) => userState.user

const fullNameSelector = createSelector(userSelector, (user) =>
	user ? `${user.firstName} ${user.lastName}` : null
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	fullNameSelector
}

export default selectors
