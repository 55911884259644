import { combineReducers } from 'redux'
import moment from 'moment-timezone'

import actionTypes from './actionTypes'

const initialDriversState = []
const driversReducer = (state = initialDriversState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SUCCESS: {
			return action.payload.drivers
		}

		case actionTypes.FETCH_FAILURE: {
			return initialDriversState
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}
const initialSearchState = {
	query: ''
}
const searchReducer = (state = initialSearchState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialSearchState
		}

		case actionTypes.SEARCH: {
			return {
				...state,
				query: action.payload.query
			}
		}

		case actionTypes.CLEAR_SEARCH: {
			return initialSearchState
		}

		default: {
			return state
		}
	}
}

const initialSelectedDateState = moment
	.tz('Europe/Amsterdam')
	.startOf('day')
	.unix()
const selectedDateReducer = (state = initialSelectedDateState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialSelectedDateState
		}

		case actionTypes.SELECT_CURRENT_WEEK: {
			return initialSelectedDateState
		}

		case actionTypes.SELECT_NEXT_WEEK: {
			return moment.unix(state).tz('Europe/Amsterdam').add(1, 'weeks').unix()
		}

		case actionTypes.SELECT_PREVIOUS_WEEK: {
			return moment
				.unix(state)
				.tz('Europe/Amsterdam')
				.subtract(1, 'weeks')
				.unix()
		}

		default: {
			return state
		}
	}
}

const initialAvailabilityState = []
const availabilityReducer = (state = initialAvailabilityState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialAvailabilityState
		}

		case actionTypes.FETCH_AVAILABILITY_SUCCESS: {
			return action.payload.availability
		}

		case actionTypes.FETCH_AVAILABILITY_FAILURE: {
			return initialAvailabilityState
		}

		case actionTypes.SAVE_AVAILABILITY_SUCCESS: {
			const { availability } = action.payload
			const { day } = action.meta

			const startOfDay = moment
				.unix(day)
				.tz('Europe/Amsterdam')
				.startOf('day')
				.unix()
			const endOfDay = moment
				.unix(day)
				.tz('Europe/Amsterdam')
				.endOf('day')
				.unix()

			// remove the availability on the modified day from the state
			const availabilityOfOtherDays = state.filter(
				({ from, till }) => till < startOfDay || endOfDay < from
			)

			// create the new availability by merging the remaining items with the new items for the modified day
			return [...availabilityOfOtherDays, ...availability]
		}

		default: {
			return state
		}
	}
}

const initialAvailabilityMetaState = {
	isLoading: false
}
const availabilityMetaReducer = (
	state = initialAvailabilityMetaState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_AVAILABILITY_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_AVAILABILITY_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_AVAILABILITY_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}

const initialSelectedAvailabilityItemState = null
const selectedAvailabilityItemReducer = (
	state = initialSelectedAvailabilityItemState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialSelectedAvailabilityItemState
		}

		case actionTypes.SELECT_AVAILABILITY_ITEM: {
			return action.payload.availabilityItem
		}

		case actionTypes.DESELECT_AVAILABILITY_ITEM: {
			return initialSelectedAvailabilityItemState
		}

		case actionTypes.NEW_AVAILABILITY_ITEM: {
			const { date } = action.payload
			return {
				date,
				from: null,
				till: null
			}
		}

		case actionTypes.FETCH_AVAILABILITY: {
			return initialSelectedAvailabilityItemState
		}

		case actionTypes.SAVE_AVAILABILITY_SUCCESS: {
			return initialSelectedAvailabilityItemState
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	drivers: driversReducer,
	meta: metaReducer,
	search: searchReducer,
	selectedDate: selectedDateReducer,
	availability: availabilityReducer,
	availabilityMeta: availabilityMetaReducer,
	selectedAvailabilityItem: selectedAvailabilityItemReducer
})
