import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton, MenuItem, Select, SvgIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { PlusFal } from '@oliverit/react-fontawesome'

import SearchContainer from '../Search'

const styles = {
	root: {
		display: 'flex',
		alignItems: 'baseline'
	},
	countrySelect: {
		width: 150
	}
}

class Header extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		country: PropTypes.string.isRequired,
		selectCountry: PropTypes.func.isRequired
	}

	handleCountryChange = (event) => {
		const { selectCountry } = this.props
		selectCountry(event.target.value)
	}

	render() {
		const { classes, country } = this.props

		const menuItems = [
			{ code: 'NL', description: 'Nederland' },
			{ code: 'BE', description: 'België' },
			{ code: 'DE', description: 'Duitsland' }
		].map((item) => (
			<MenuItem key={item.code} value={item.code}>
				{item.description}
			</MenuItem>
		))

		return (
			<div className={classes.root}>
				<div className={classes.countrySelect}>
					<Select value={country} onChange={this.handleCountryChange}>
						{menuItems}
					</Select>
				</div>
				<SearchContainer />
				<IconButton color="primary" component={Link} to="/postcodeadmin/new">
					<SvgIcon>
						<PlusFal />
					</SvgIcon>
				</IconButton>
			</div>
		)
	}
}

export default withStyles(styles)(Header)
