import actionTypes from './actionTypes'

const actionCreators = {
	add: (tour) => ({
		type: actionTypes.SOCKET_ADD_TOUR,
		payload: {
			tour
		}
	}),

	cleanMeta: (tourIds) => ({
		type: actionTypes.CLEAN_META,
		meta: {
			tourIds
		}
	}),

	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	confirm: (id) => ({
		type: actionTypes.CONFIRM,
		meta: {
			id
		}
	}),

	confirmFailure: (id, errorMessages) => ({
		type: actionTypes.CONFIRM_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	confirmMultiple: (ids) => ({
		type: actionTypes.CONFIRM_MULTIPLE,
		meta: {
			ids
		}
	}),

	confirmRequest: (id) => ({
		type: actionTypes.CONFIRM_REQUEST,
		meta: {
			id
		}
	}),

	confirmSuccess: (id) => ({
		type: actionTypes.CONFIRM_SUCCESS,
		meta: {
			id
		}
	}),

	deselect: (id) => ({
		type: actionTypes.TOUR_DESELECT,
		meta: {
			id
		}
	}),

	deselectAll: () => ({
		type: actionTypes.TOUR_DESELECT_ALL
	}),

	fetchAll: () => ({
		type: actionTypes.FETCH_ALL
	}),

	fetchAllFailure: (errorMessages) => ({
		type: actionTypes.FETCH_ALL_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchAllRequest: () => ({
		type: actionTypes.FETCH_ALL_REQUEST
	}),

	fetchAllSuccess: (tours) => ({
		type: actionTypes.FETCH_ALL_SUCCESS,
		payload: {
			tours
		}
	}),

	fetchReplacements: (planningTypeKey, fromDate) => ({
		type: actionTypes.FETCH_REPLACEMENTS,
		meta: {
			planningTypeKey,
			fromDate
		}
	}),

	fetchReplacementsFailure: (errorMessages, planningTypeKey, fromDate) => ({
		type: actionTypes.FETCH_REPLACEMENTS_FAILURE,
		meta: {
			planningTypeKey,
			fromDate
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchReplacementsRequest: (planningTypeKey, fromDate) => ({
		type: actionTypes.FETCH_REPLACEMENTS_REQUEST,
		meta: {
			planningTypeKey,
			fromDate
		}
	}),

	fetchReplacementsSuccess: (tours, planningTypeKey, fromDate) => ({
		type: actionTypes.FETCH_REPLACEMENTS_SUCCESS,
		meta: {
			planningTypeKey,
			fromDate
		},
		payload: {
			tours
		}
	}),

	finalize: (id) => ({
		type: actionTypes.FINALIZE,
		meta: {
			id
		}
	}),

	finalizeFailure: (id, errorMessages) => ({
		type: actionTypes.FINALIZE_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	finalizeMultiple: (ids) => ({
		type: actionTypes.FINALIZE_MULTIPLE,
		meta: {
			ids
		}
	}),

	finalizeRequest: (id) => ({
		type: actionTypes.FINALIZE_REQUEST,
		meta: {
			id
		}
	}),

	finalizeSuccess: (id) => ({
		type: actionTypes.FINALIZE_SUCCESS,
		meta: {
			id
		}
	}),

	lock: (id) => ({
		type: actionTypes.LOCK,
		meta: {
			id
		}
	}),

	lockFailure: (id, errorMessages) => ({
		type: actionTypes.LOCK_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	lockRequest: (id) => ({
		type: actionTypes.LOCK_REQUEST,
		meta: {
			id
		}
	}),

	lockSuccess: (id) => ({
		type: actionTypes.LOCK_SUCCESS,
		meta: {
			id
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	remove: (tour) => ({
		type: actionTypes.SOCKET_REMOVE_TOUR,
		payload: {
			tour
		}
	}),

	replaceMany: (planningTypeKey, tours) => ({
		type: actionTypes.SOCKET_REPLACE_TOURS,
		meta: {
			planningTypeKey
		},
		payload: {
			tours
		}
	}),

	select: (id) => ({
		type: actionTypes.TOUR_SELECT,
		meta: {
			id
		}
	}),

	selectMultiple: (ids) => ({
		type: actionTypes.TOUR_SELECT_MULTIPLE,
		meta: {
			ids
		}
	}),

	selectCurrentDate: () => ({
		type: actionTypes.SELECT_DATE_CURRENT
	}),

	selectPreviousDate: () => ({
		type: actionTypes.SELECT_DATE_PREVIOUS
	}),

	selectNextDate: () => ({
		type: actionTypes.SELECT_DATE_NEXT
	}),

	selectPlanningType: (planningTypeKey) => ({
		type: actionTypes.SELECT_PLANNING_TYPE,
		payload: {
			planningTypeKey
		}
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	}),

	takeOverLock: (id) => ({
		type: actionTypes.TAKEOVERLOCK,
		meta: {
			id
		}
	}),

	takeOverLockFailure: (id, errorMessages) => ({
		type: actionTypes.TAKEOVERLOCK_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	takeOverLockRequest: (id) => ({
		type: actionTypes.TAKEOVERLOCK_REQUEST,
		meta: {
			id
		}
	}),

	takeOverLockSuccess: (id) => ({
		type: actionTypes.TAKEOVERLOCK_SUCCESS,
		meta: {
			id
		}
	}),

	undoConfirm: (id) => ({
		type: actionTypes.UNDO_CONFIRM,
		meta: {
			id
		}
	}),

	undoConfirmFailure: (id, errorMessages) => ({
		type: actionTypes.UNDO_CONFIRM_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	undoConfirmRequest: (id) => ({
		type: actionTypes.UNDO_CONFIRM_REQUEST,
		meta: {
			id
		}
	}),

	undoConfirmSuccess: (id) => ({
		type: actionTypes.UNDO_CONFIRM_SUCCESS,
		meta: {
			id
		}
	}),

	unlock: (id) => ({
		type: actionTypes.UNLOCK,
		meta: {
			id
		}
	}),

	unlockFailure: (id, errorMessages) => ({
		type: actionTypes.UNLOCK_FAILURE,
		payload: {
			errorMessages
		},
		meta: {
			id
		},
		error: true
	}),

	unlockRequest: (id) => ({
		type: actionTypes.UNLOCK_REQUEST,
		meta: {
			id
		}
	}),

	unlockSuccess: (id) => ({
		type: actionTypes.UNLOCK_SUCCESS,
		meta: {
			id
		}
	}),

	update: (tour) => ({
		type: actionTypes.SOCKET_UPDATE_TOUR,
		payload: {
			tour
		}
	}),

	updateMany: (tours) => ({
		type: actionTypes.SOCKET_UPDATE_TOURS,
		payload: {
			tours
		}
	})
}

export default actionCreators
