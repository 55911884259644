import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../../../redux/drivers/actionCreators'
import Search from './Search'

class SearchContainer extends PureComponent {
	static propTypes = {
		search: PropTypes.func.isRequired,
		searchQuery: PropTypes.string.isRequired
	}

	render() {
		const { search, searchQuery } = this.props

		return <Search search={search} searchQuery={searchQuery} />
	}
}

const connector = connect(
	(state) => ({
		searchQuery: state.driversState.search.query
	}),
	{
		search: actionCreators.search
	}
)

export default connector(SearchContainer)
