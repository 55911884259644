import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	ListItem as MaterialListItem,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FileUserFal } from '@oliverit/react-fontawesome'

const styleSheet = {
	icon: {
		fontSize: 16,
		marginRight: 4
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 4,
		marginRight: 16
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	secondary: {
		display: 'flex',
		alignItems: 'center'
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	}
}

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driver: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired
	}

	handleClickItem = () => {
		const { driver, select } = this.props
		select(driver)
	}

	render() {
		const { classes, driver } = this.props

		const primary = (
			<>
				<div className={classes.primary}>
					<Typography variant="h6">{`${driver.firstName} ${driver.lastName}`}</Typography>
				</div>
			</>
		)

		const secondary = (
			<div className={classes.secondary}>
				<div className={classes.label}>
					<SvgIcon color="action" className={classes.icon}>
						<FileUserFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{driver.driverId}
					</Typography>
				</div>
			</div>
		)

		return (
			<MaterialListItem button onClick={this.handleClickItem}>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
