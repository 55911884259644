import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List as MaterialList } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List as VirtualizedList
} from 'react-virtualized'
import ListEmpty from './ListEmpty'
import ListItem from './ListItem'

const styleSheet = {
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		deselect: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		select: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedTourIds: PropTypes.array.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		tours: PropTypes.array.isRequired
	}

	state = {
		cache: new CellMeasurerCache({
			defaultHeight: 96,
			fixedWidth: true
		}),
		previousTours: [],
		previousSelectedMoment: null,
		scrollToTop: false
	}

	static getDerivedStateFromProps(props, state) {
		let scrollToTop = false
		if (props.tours !== state.previousTours) {
			// Clear cache when the list changes, otherwise cells may be rendered with incorrect height
			state.cache.clearAll()
		}
		if (props.selectedMoment !== state.previousSelectedMoment) {
			scrollToTop = true
		}
		return {
			previousTours: props.tours,
			previousSelectedMoment: props.selectedMoment,
			scrollToTop
		}
	}

	rowRenderer = ({ index, key, parent, style }) => {
		const {
			deselect,
			metaTours,
			ownUserId,
			select,
			selectedTourIds,
			tourNumbers,
			tours
		} = this.props
		const { cache } = this.state

		const tour = tours[index]
		const metaTour = metaTours.find((meta) => meta.id === tour.id)
		const number = tourNumbers.find(
			(tourNumber) => tourNumber.id === tour.id
		).number

		return (
			<CellMeasurer
				cache={cache}
				columnIndex={0}
				key={key}
				parent={parent}
				rowIndex={index}
			>
				<div key={key} style={style}>
					<ListItem
						deselect={deselect}
						key={`tour-${tour.id}`}
						isOwnUser={tour.lock && tour.lock.userId === ownUserId}
						isSelected={selectedTourIds.some(
							(selectedTourId) => selectedTourId === tour.id
						)}
						metaTour={metaTour}
						number={number}
						select={select}
						tour={tour}
					/>
				</div>
			</CellMeasurer>
		)
	}

	render() {
		const { classes, metaTours, selectedTourIds, tours } = this.props
		const { cache, scrollToTop } = this.state

		if (tours.length === 0) {
			return (
				<div className={classes.content}>
					<ListEmpty />
				</div>
			)
		}

		// Scroll to top?
		const selectedTourIndex = scrollToTop ? 0 : -1

		// Important: the VirtualizedList component does not automatically re-render when the props on
		// this component change. This means we have to pass these same props into VirtualizedList as
		// well, even though they are not used by the VirtualizedList component.

		return (
			<MaterialList disablePadding className={classes.content}>
				<AutoSizer>
					{({ height, width }) => (
						<VirtualizedList
							deferredMeasurementCache={cache}
							height={height}
							rowCount={tours.length}
							rowHeight={cache.rowHeight}
							rowRenderer={this.rowRenderer}
							scrollToIndex={selectedTourIndex}
							tabIndex={null}
							width={width}
							metaTours={metaTours}
							selectedTourIds={selectedTourIds}
							tours={tours}
						/>
					)}
				</AutoSizer>
			</MaterialList>
		)
	}
}

export default withStyles(styleSheet)(List)
