import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 32
		})
	},
	address: {
		paddingTop: 16
	}
})

class Address extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driver: PropTypes.object.isRequired
	}

	render() {
		const { classes, driver } = this.props
		const { address, startPoint } = driver

		let addressItem
		if (!address) {
			addressItem = (
				<div className={classes.address}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:driveradmin.Driver.Address.title')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:driveradmin.Driver.Address.noAddress')}
					</Typography>
				</div>
			)
		} else {
			addressItem = (
				<div className={classes.address}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:driveradmin.Driver.Address.title')}
					</Typography>
					<Typography variant="body2">
						{address.street} {address.houseNum}
					</Typography>
					<Typography variant="body2">
						{address.postcode} {address.city}, {address.country}
					</Typography>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:driveradmin.Driver.Address.title')}
				</Typography>
				<Typography variant="caption" color="primary">
					{i18n.t('app:driveradmin.Driver.Address.startPoint')}
				</Typography>
				<Typography variant="body2">
					{startPoint ? i18n.t('app:generic.yes') : i18n.t('app:generic.no')}
				</Typography>
				{addressItem}
			</div>
		)
	}
}

export default withStyles(styleSheet)(Address)
