import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Header from './Header'
import ListContainer from './List'
import UnavailabilityItemContainer from './UnavailabilityItem'

const styles = (theme) => ({
	root: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	left: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	},
	right: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16,
			marginLeft: 16,
			width: 400
		})
	}
})

class VehicleUnavailability extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		showNew: PropTypes.bool,
		vehicleId: PropTypes.string.isRequired
	}

	render() {
		const { classes, showNew, vehicleId } = this.props

		let rightContainer = null
		if (showNew) {
			rightContainer = (
				<Paper className={classes.right}>
					<UnavailabilityItemContainer vehicleId={vehicleId} />
				</Paper>
			)
		}

		return (
			<div className={classes.root}>
				<Paper className={classes.left}>
					<Header vehicleId={vehicleId} />
					<ListContainer vehicleId={vehicleId} />
				</Paper>
				{rightContainer}
			</div>
		)
	}
}

export default withStyles(styles)(VehicleUnavailability)
