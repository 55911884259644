import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	},
	item: {
		paddingBottom: 12
	}
})

class Properties extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		properties: PropTypes.array.isRequired
	}

	render() {
		const { classes, properties } = this.props

		const items = properties.map((property) => (
			<div key={property.key} className={classes.item}>
				<Typography variant="caption" color="primary">
					{property.label}
				</Typography>
				<Typography variant="body2">{property.value}</Typography>
			</div>
		))

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Vehicle.properties')}
				</Typography>
				{items}
			</div>
		)
	}
}

export default withStyles(styles)(Properties)
