import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import excludedServiceDatesActionCreators from '../../../../redux/excludedservicedates/actionCreators'
import excludedServiceDatesSelectors from '../../../../redux/excludedservicedates/selectors'
import unavailableProposalDatesActionCreators from '../../../../redux/unavailableproposaldates/actionCreators'
import unavailableProposalDatesSelectors from '../../../../redux/unavailableproposaldates/selectors'
import HeaderButtons from './HeaderButtons'

class HeaderButtonsContainer extends PureComponent {
	static propTypes = {
		addUnavailableProposalDate: PropTypes.func.isRequired,
		handleToggleSummary: PropTypes.func.isRequired,
		isUnavailableProposalDate: PropTypes.bool.isRequired,
		isUnavailableProposalDateButtonAvailable: PropTypes.bool.isRequired,
		removeUnavailableProposalDate: PropTypes.func.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		showSummary: PropTypes.bool.isRequired
	}

	render() {
		const {
			addUnavailableProposalDate,
			handleToggleSummary,
			isUnavailableProposalDate,
			isUnavailableProposalDateButtonAvailable,
			removeUnavailableProposalDate,
			selectedPlanningTypeKey,
			showSummary,
			addExcludedServiceDate,
			isExcludedServiceDate,
			isExcludedServiceDateButtonAvailable,
			removeExcludedServiceDate
		} = this.props

		return (
			<HeaderButtons
				handleAddUnavailableProposalDate={addUnavailableProposalDate}
				handleRemoveUnavailableProposalDate={removeUnavailableProposalDate}
				handleToggleSummary={handleToggleSummary}
				isUnavailableProposalDate={isUnavailableProposalDate}
				isUnavailableProposalDateButtonAvailable={
					isUnavailableProposalDateButtonAvailable
				}
				selectedPlanningTypeKey={selectedPlanningTypeKey}
				showSummary={showSummary}
				handleAddExcludedServiceDate={addExcludedServiceDate}
				handleRemoveExcludedServiceDate={removeExcludedServiceDate}
				isExcludedServiceDate={isExcludedServiceDate}
				isExcludedServiceDateButtonAvailable={
					isExcludedServiceDateButtonAvailable
				}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		isUnavailableProposalDate:
			unavailableProposalDatesSelectors.isUnavailableProposalDateSelector(
				state.toursState,
				state.unavailableProposalDatesState
			),
		isUnavailableProposalDateButtonAvailable:
			unavailableProposalDatesSelectors.isUnavailableProposalDateButtonAvailableSelector(
				state.unavailableProposalDatesState
			),
		isExcludedServiceDate:
			excludedServiceDatesSelectors.isExcludedServiceDateSelector(
				state.toursState,
				state.excludedServiceDatesState
			),
		isExcludedServiceDateButtonAvailable:
			excludedServiceDatesSelectors.isExcludedServiceDateButtonAvailableSelector(
				state.excludedServiceDatesState
			),
		selectedPlanningTypeKey: state.toursState.selectedPlanningTypeKey
	}),
	{
		addUnavailableProposalDate: unavailableProposalDatesActionCreators.add,
		removeUnavailableProposalDate:
			unavailableProposalDatesActionCreators.remove,
		addExcludedServiceDate: excludedServiceDatesActionCreators.add,
		removeExcludedServiceDate: excludedServiceDatesActionCreators.remove
	}
)

export default connector(HeaderButtonsContainer)
