import { Box, Grid, IconButton, SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarFal,
	ChevronLeftFal,
	ChevronRightFal
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

const styleSheet = {
	root: {},
	day: {
		textAlign: 'center'
	}
}

class DayNavigator extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isCurrentDay: PropTypes.bool,
		isUnavailableProposalDate: PropTypes.bool.isRequired,
		isExcludedServiceDate: PropTypes.bool.isRequired,
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	handleSelectCurrentDate = () => {
		const { selectCurrentDate } = this.props
		selectCurrentDate()
	}

	handleSelectNextDate = () => {
		const { selectNextDate } = this.props
		selectNextDate()
	}

	handleSelectPreviousDate = () => {
		const { selectPreviousDate } = this.props
		selectPreviousDate()
	}

	render() {
		const {
			classes,
			isCurrentDay,
			isUnavailableProposalDate,
			selectedMoment,
			isExcludedServiceDate
		} = this.props

		const date = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.format('dddd D MMM Y')

		let unavailableProposalDateText
		if (isUnavailableProposalDate) {
			unavailableProposalDateText = (
				<Typography variant="caption" color="secondary">
					{i18n.t('app:planning.Tours.unavailableProposalDate')}
				</Typography>
			)
		}

		let excludedServiceDateText
		if (isExcludedServiceDate) {
			excludedServiceDateText = (
				<Typography variant="caption" color="secondary">
					{i18n.t('app:planning.Tours.excludedServiceDate')}
				</Typography>
			)
		}

		return (
			<Box className={classes.root}>
				<Grid container alignItems="center">
					<Grid item xs={5}>
						<IconButton
							disabled={isCurrentDay}
							color="primary"
							onClick={this.handleSelectPreviousDate}
						>
							<SvgIcon>
								<ChevronLeftFal />
							</SvgIcon>
						</IconButton>
						<IconButton
							disabled={isCurrentDay}
							color="primary"
							onClick={this.handleSelectCurrentDate}
						>
							<SvgIcon>
								<CalendarFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="primary" onClick={this.handleSelectNextDate}>
							<SvgIcon>
								<ChevronRightFal />
							</SvgIcon>
						</IconButton>
					</Grid>
					<Grid
						item
						xs={7}
						className={classes.day}
						container
						direction="column"
					>
						<Typography variant="h6">{date}</Typography>
						{unavailableProposalDateText}
						{excludedServiceDateText}
					</Grid>
				</Grid>
			</Box>
		)
	}
}

export default withStyles(styleSheet)(DayNavigator)
