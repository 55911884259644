import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import actionCreators from '../redux/tours/actionCreators'
import config from '../config'

const { webSocketUrl } = config

class ToursSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/tours`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('tour_created', this.handleCreated)
			this.socket.on('tour_updated', this.handleUpdated)
			this.socket.on('tour_deleted', this.handleDeleted)
			this.socket.on('tours_replaced', this.handleReplacedMany)
			this.socket.on('tours_updated', this.handleUpdatedMany)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
		// Fetch all tours when the socket has connected
		this.store.dispatch(actionCreators.fetchAll())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleCreated = (tour) => {
		this.store.dispatch(actionCreators.add(tour))
	}

	handleUpdated = (tour) => {
		this.store.dispatch(actionCreators.update(tour))
	}

	handleReplacedMany = ({ planningTypeKey, fromDate }) => {
		this.store.dispatch(
			actionCreators.fetchReplacements(planningTypeKey, fromDate)
		)
	}

	handleUpdatedMany = ({ tours }) => {
		this.store.dispatch(actionCreators.updateMany(tours))
	}

	handleDeleted = (tour) => {
		this.store.dispatch(actionCreators.remove(tour))
	}
}

const toursSocket = new ToursSocket()

export default toursSocket
