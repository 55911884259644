import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import Amounts from '../OrderEdit/Summary/Amounts'
import Customer from '../OrderEdit/Summary/Customer'
import DeliveryDate from '../OrderEdit/Summary/DeliveryDate'
import Characteristics from '../OrderEdit/Summary/Characteristics'
import Note from '../OrderEdit/Summary/Note'
import Remarks from '../OrderEdit/Summary/Remarks'
import { appointmentCustomer } from '../../../utils/customer'

const styleSheet = {
	root: {
		padding: 16
	}
}

class Appointment extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const customer = appointmentCustomer(order)

		return (
			<div className={classes.root}>
				<Typography variant="h5" paragraph>
					{i18n.t('app:appointmentscheduler.Order.appointment')}
				</Typography>
				<Customer customer={customer} />
				<Amounts order={order} />
				<DeliveryDate appointment={order.appointment} />
				<Characteristics appointment={order.appointment} />
				<Remarks appointment={order.appointment} />
				<Note appointment={order.appointment} />
			</div>
		)
	}
}

export default withStyles(styleSheet)(Appointment)
