import moment from 'moment-timezone'
import { createSelector } from 'reselect'

// Return if the selected planning date is excluded
const isExcludedServiceDateSelector = createSelector(
	[
		(toursState) => toursState.selectedPlanningTypeKey,
		(toursState) => toursState.selectedDate,
		(toursState, excludedServiceDatesState) => {
			return excludedServiceDatesState.excludedServiceDates
		}
	],
	(selectedPlanningTypeKey, selectedDate, excludedServiceDates) => {
		return excludedServiceDates.some(
			(excludedServiceDate) =>
				excludedServiceDate.planningTypeKey === selectedPlanningTypeKey &&
				excludedServiceDate.excludeDate === selectedDate
		)
	}
)

// Return if the button can be used
const isExcludedServiceDateButtonAvailableSelector = createSelector(
	[(excludedServiceDatesState) => excludedServiceDatesState.meta],
	(meta) => meta.isConnected && !meta.isSaving && !meta.isLoading
)

const excludedServiceMomentsForOrderSelector = createSelector(
	[
		(ordersState) => ordersState.orders,
		(ordersState, selectedOrderId) => selectedOrderId,
		(ordersState, selectedOrderId, excludedServiceDatesState) =>
			excludedServiceDatesState.excludedServiceDates
	],
	(orders, selectedOrderId, excludedServiceDates) => {
		const selectedOrder = orders.find((order) => order.id === selectedOrderId)
		if (selectedOrder) {
			return excludedServiceDates.reduce((acc, excludedServiceDate) => {
				if (
					excludedServiceDate.planningTypeKey === selectedOrder.planningType.key
				) {
					return acc.concat(
						moment.tz(excludedServiceDate.excludeDate, 'Europe/Amsterdam')
					)
				}
				return acc
			}, [])
		}
		return []
	}
)

const selectors = {
	isExcludedServiceDateSelector,
	isExcludedServiceDateButtonAvailableSelector,
	excludedServiceMomentsForOrderSelector
}

export default selectors
