import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import authActionCreators from '../../../redux/auth/actionCreators'
import userActionCreators from '../../../redux/user/actionCreators'
import Login from './Login'

class LoginContainer extends PureComponent {
	static propTypes = {
		clearUserError: PropTypes.func.isRequired,
		errorMessages: PropTypes.object,
		isLoggingIn: PropTypes.bool,
		login: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { clearUserError } = this.props
		clearUserError()
	}

	render() {
		const { errorMessages, isLoggingIn, login, logout } = this.props

		return (
			<Login
				errorMessages={errorMessages}
				isLoggingIn={isLoggingIn}
				login={login}
				logout={logout}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		errorMessages: state.userState.meta.errorMessages,
		isLoggingIn: state.userState.meta.isLoggingIn
	}),
	{
		clearUserError: userActionCreators.clearUserError,
		login: authActionCreators.login,
		logout: authActionCreators.logout
	}
)

export default connector(LoginContainer)
