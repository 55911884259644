import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	root: {
		paddingBottom: 8,
		paddingRight: 12,
		width: '100%'
	},
	depotSelect: {
		width: '100%'
	},
	item: {
		paddingTop: 0,
		paddingBottom: 0
	}
}

class Depot extends PureComponent {
	static propTypes = {
		availableDepots: PropTypes.array.isRequired,
		change: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		depot: PropTypes.object,
		error: PropTypes.bool,
		errorText: PropTypes.string,
		label: PropTypes.string.isRequired
	}

	handleChangeDepot = (event) => {
		const { change } = this.props
		change(event.target.value)
	}

	render() {
		const { availableDepots, classes, depot, error, errorText, label } =
			this.props

		const depotMenuItems = availableDepots.map((elem) => (
			<MenuItem key={elem.plant} value={elem.plant}>
				{elem.name}
			</MenuItem>
		))
		const primary = (
			<Select
				className={classes.depotSelect}
				value={depot ? depot.plant : ''}
				onChange={this.handleChangeDepot}
			>
				{depotMenuItems}
			</Select>
		)

		let secondary
		if (errorText) {
			secondary = (
				<Typography variant="caption" color={error ? 'error' : 'textPrimary'}>
					{errorText}
				</Typography>
			)
		}

		return (
			<div className={classes.root}>
				<Typography variant="caption" color={error ? 'error' : 'primary'}>
					{label}
				</Typography>
				<List disablePadding>
					<ListItem disableGutters className={classes.item}>
						<ListItemText
							primaryTypographyProps={{ variant: 'body2' }}
							primary={primary}
							secondary={secondary}
						/>
					</ListItem>
				</List>
			</div>
		)
	}
}

export default withStyles(styles)(Depot)
