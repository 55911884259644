import { PureComponent } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import cloneDeep from 'clone-deep'
import toursSelectors from '../../../redux/tours/selectors'
import manualPlanningActionCreators from '../../../redux/manualplannings/actionCreators'
import unscheduledOrdersSelectors from '../../../redux/unscheduledorders/selectors'
import unscheduledOrdersActionCreators from '../../../redux/unscheduledorders/actionCreators'
import TourEdit from './TourEdit'
import { ORDER_TYPE_SERVICE } from '../../../utils/constants'
import arraymove from '../../../utils/arraymove'

class TourEditContainer extends PureComponent {
	static propTypes = {
		cancel: PropTypes.func.isRequired,
		fetchUnscheduledOrders: PropTypes.func.isRequired,
		finish: PropTypes.func.isRequired,
		metaOptimizations: PropTypes.array,
		metaReductions: PropTypes.array,
		metaTours: PropTypes.array,
		metaValidations: PropTypes.array,
		optimizations: PropTypes.array.isRequired,
		optimize: PropTypes.func.isRequired,
		ownUserId: PropTypes.string.isRequired,
		planningTypes: PropTypes.array.isRequired,
		reduce: PropTypes.func.isRequired,
		reductions: PropTypes.array.isRequired,
		save: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		selectedTours: PropTypes.array.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		unscheduledOrders: PropTypes.array.isRequired,
		unscheduledOrdersLoading: PropTypes.bool,
		validate: PropTypes.func.isRequired,
		validations: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		// Create a simplified copy of the selected tours, which we will use for editing
		const tours = cloneDeep(props.selectedTours)
		const selectedPlanningType = props.planningTypes.find(
			(planningType) =>
				planningType.planningTypeKey === props.selectedPlanningTypeKey
		)
		this.state = {
			isModified: false,
			manualPlanningOptimizeId: null,
			manualPlanningReduceId: null,
			manualPlanningValidateId: null,
			mapChannel: new BroadcastChannel('map'),
			parkedRouteItems: [],
			startAtDepot:
				selectedPlanningType &&
				selectedPlanningType.planningParams &&
				selectedPlanningType.planningParams.startAtDepot
					? selectedPlanningType.planningParams.startAtDepot
					: false,
			saveConfirmed: false,
			showSaveConfirmationDialog: false,
			tours
		}
		// Send tours to the map window
		this.sendTours(tours)
	}

	componentDidMount() {
		// Fetch the list of unscheduled orders
		const { fetchUnscheduledOrders } = this.props
		fetchUnscheduledOrders()
	}

	componentWillUnmount() {
		const { mapChannel } = this.state
		mapChannel.close()
	}

	componentDidUpdate(prevProps, prevState) {
		const { optimizations, reductions, unscheduledOrders, validations } =
			this.props
		const {
			manualPlanningOptimizeId,
			manualPlanningReduceId,
			manualPlanningValidateId,
			parkedRouteItems,
			tours,
			saveConfirmed
		} = this.state
		const previousTours = prevState.tours
		// Update map with unscheduled orders
		if (unscheduledOrders !== prevProps.unscheduledOrders) {
			this.sendUnscheduledOrders(unscheduledOrders)
		}
		if (saveConfirmed !== prevState.saveConfirmed) {
			if (saveConfirmed) {
				this.handleSave()
				this.setState({
					saveConfirmed: false
				})
			}
		}
		// Update map with parked orders
		if (parkedRouteItems !== prevState.parkedRouteItems) {
			this.sendParkedRouteItems(parkedRouteItems)
		}
		// If there are manual planning id's we need to update the tours in state with the new tours received
		// from the optimization or validation
		// We have to make sure this is only done once, otherwise we'll end up with an infinite loop,
		// so after we update the tours we also clear the manual planning id
		if (manualPlanningOptimizeId) {
			// Get the tours from the optimize state
			const currentOptimization = optimizations.find(
				(optimization) => optimization.id === manualPlanningOptimizeId
			)
			if (currentOptimization) {
				const optimizedTours = cloneDeep(currentOptimization.tours)
				const updatedParkedRouteItems = [...parkedRouteItems]
				if (currentOptimization.unscheduledOrderKeys) {
					// Move the route items which correspond to the unscheduled orders into the parked list
					currentOptimization.unscheduledOrderKeys.forEach(
						(unscheduledOrderKey) => {
							// Find the route item for this order number in the previous tours list (which was sent to the server for optimization)
							const originalTour = previousTours.find((previousTour) =>
								previousTour.route.some(
									(routeItem) => routeItem.orderKey === unscheduledOrderKey
								)
							)
							if (originalTour) {
								const unscheduledRouteItem = originalTour.route.find(
									(routeItem) => routeItem.orderKey === unscheduledOrderKey
								)
								if (unscheduledRouteItem) {
									updatedParkedRouteItems.push(unscheduledRouteItem)
								}
							}
						}
					)
				}
				this.setState({
					isModified: false,
					manualPlanningOptimizeId: null,
					parkedRouteItems: updatedParkedRouteItems,
					tours: optimizedTours
				})
				// And don't forget to update the map
				this.sendTours(optimizedTours)
			}
		} else if (manualPlanningReduceId) {
			// Get the tours from the optimize state
			const currentReduction = reductions.find(
				(reduction) => reduction.id === manualPlanningReduceId
			)
			if (currentReduction) {
				const optimizedTours = cloneDeep(currentReduction.tours)
				const updatedParkedRouteItems = [...parkedRouteItems]
				if (currentReduction.unscheduledOrderKeys) {
					// Move the route items which correspond to the unscheduled orders into the parked list
					currentReduction.unscheduledOrderKeys.forEach(
						(unscheduledOrderKey) => {
							// Find the route item for this order number in the previous tours list (which was sent to the server for reduction)
							const originalTour = previousTours.find((previousTour) =>
								previousTour.route.some(
									(routeItem) => routeItem.orderKey === unscheduledOrderKey
								)
							)
							if (originalTour) {
								const unscheduledRouteItem = originalTour.route.find(
									(routeItem) => routeItem.orderKey === unscheduledOrderKey
								)
								if (unscheduledRouteItem) {
									updatedParkedRouteItems.push(unscheduledRouteItem)
								}
							}
						}
					)
				}
				this.setState({
					isModified: false,
					manualPlanningReduceId: null,
					parkedRouteItems: updatedParkedRouteItems,
					tours: optimizedTours
				})
				// And don't forget to update the map
				this.sendTours(optimizedTours)
			}
		} else if (manualPlanningValidateId) {
			// Get the tours from the validation state
			const currentValidation = validations.find(
				(validation) => validation.id === manualPlanningValidateId
			)
			if (currentValidation) {
				const validatedTours = cloneDeep(currentValidation.tours)
				this.setState({
					isModified: false,
					manualPlanningValidateId: null,
					tours: validatedTours
				})
				// And don't forget to update the map
				this.sendTours(validatedTours)
			}
		} else if (previousTours !== tours) {
			// No manual planning id's, but the tours have changed, so we have to update the map
			this.sendTours(tours)
		}
	}

	sendTours(tours) {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-update-tours',
			payload: { tours }
		})
	}

	sendUnscheduledOrders(unscheduledOrders) {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-unscheduled-orders',
			payload: { unscheduledOrders }
		})
	}

	sendParkedRouteItems(parkedRouteItems) {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-parked-route-items',
			payload: { parkedRouteItems }
		})
	}

	handleManualPlanningAction = (tourId, action) => {
		const { tours } = this.state
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				return {
					...tour,
					action
				}
			}
			return tour
		})
		this.setState({
			tours: editedTours
		})
	}

	handleManualPlanningAddParkedRouteItem = (
		tourId,
		selectedParkedRouteItemOrderKey
	) => {
		const { parkedRouteItems, tours } = this.state
		const selectedParkedRouteItem = parkedRouteItems.find(
			(parkedRouteItem) =>
				parkedRouteItem.orderKey === selectedParkedRouteItemOrderKey
		)
		let editedTours
		let updatedParkedRouteItems = parkedRouteItems
		if (selectedParkedRouteItem) {
			editedTours = tours.map((tour) => {
				if (tour.id === tourId) {
					const editedRoute = [...tour.route, selectedParkedRouteItem]
					return {
						...tour,
						action: 'save', // Reset action when something changes
						route: editedRoute
					}
				}
				return tour
			})
			updatedParkedRouteItems = parkedRouteItems.filter(
				(parkedRouteItem) =>
					parkedRouteItem.orderKey !== selectedParkedRouteItemOrderKey
			)
		}
		this.setState({
			isModified: true,
			parkedRouteItems: updatedParkedRouteItems,
			tours: editedTours
		})
	}

	handleManualPlanningAddUnscheduledOrder = (tourId, unscheduledOrder) => {
		const { planningTypes, selectedPlanningTypeKey } = this.props
		const { tours } = this.state
		const {
			orderKey,
			customer,
			salesOrderNumber,
			serviceTime,
			volume,
			appointmentDate,
			appointmentTimeFrom,
			appointmentTimeTill
		} = unscheduledOrder
		const selectedPlanningType = planningTypes.find(
			(planningType) => planningType.planningTypeKey === selectedPlanningTypeKey
		)
		// Convert unscheduled order to route item (on tours this is already done by the server)
		const characteristics = {
			hasExternalElevator: Boolean(
				unscheduledOrder.appointment &&
					unscheduledOrder.appointment.characteristics &&
					unscheduledOrder.appointment.characteristics.canExternalElevatorBeUsed
			),
			hasRetour: Boolean(
				unscheduledOrder.volumeRetour || unscheduledOrder.weightRetour
			),
			hasScaffold: Boolean(
				unscheduledOrder.appointment &&
					unscheduledOrder.appointment.characteristics &&
					unscheduledOrder.appointment.characteristics.canScaffoldBeUsed
			),
			hasTimeAppointment: Boolean(
				moment.duration(unscheduledOrder.appointmentTimeFrom).asSeconds() >
					selectedPlanningType.orderAllowedFrom ||
					moment.duration(unscheduledOrder.appointmentTimeTill).asSeconds() <
						selectedPlanningType.orderAllowedTill
			),
			isService: unscheduledOrder.orderType.key === ORDER_TYPE_SERVICE,
			isUnconfirmed: !unscheduledOrder.isAppointmentConfirmed
		}
		const coordinates = unscheduledOrder.coordinates
			? {
					lat: unscheduledOrder.coordinates.y,
					lng: unscheduledOrder.coordinates.x
			  }
			: null
		const routeItem = {
			coordinates,
			orderKey,
			customer,
			salesOrderNumber,
			servicePeriod: serviceTime,
			volume,
			appointmentDate,
			appointmentTimeFrom,
			appointmentTimeTill,
			characteristics
		}
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				const editedRoute = [...tour.route, routeItem]
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		this.setState({
			isModified: true,
			tours: editedTours
		})
	}

	handleManualPlanningDown = (tourId, orderKey) => {
		const { tours } = this.state
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				const editedRoute = [...tour.route]
				const fromIndex = editedRoute.findIndex(
					(routeItem) => routeItem.orderKey === orderKey
				)
				if (fromIndex > -1) {
					// Moving down means adding 1 to the current index
					let toIndex = fromIndex + 1
					if (toIndex > editedRoute.length - 1) {
						toIndex = editedRoute.length - 1
					} // Prevent overflow
					arraymove(editedRoute, fromIndex, toIndex)
				}
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		this.setState({
			isModified: true,
			tours: editedTours
		})
	}

	handleManualPlanningReverse = (tourId) => {
		const { tours } = this.state
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				const editedRoute = [...tour.route].reverse()
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		this.setState({
			isModified: true,
			tours: editedTours
		})
	}

	handleManualPlanningRemoveAllOrdersWithoutAppointment = () => {
		// Removing means removing the orders from the tours and placing it into the parked orders list
		const { parkedRouteItems, tours } = this.state
		let allRemovedRouteItems = []
		const editedTours = tours.map((tour) => {
			// Remove route items which are not confirmed
			const removedRouteItems = tour.route.filter(
				(routeItem) =>
					routeItem.characteristics && routeItem.characteristics.isUnconfirmed
			)
			allRemovedRouteItems = allRemovedRouteItems.concat(removedRouteItems)
			const editedRoute = tour.route.filter(
				(routeItem) =>
					routeItem.characteristics && !routeItem.characteristics.isUnconfirmed
			)
			if (tour.route.length !== editedRoute.length) {
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		const updatedParkedRouteItems = [...parkedRouteItems]
		allRemovedRouteItems.forEach((removedRouteItem) => {
			if (
				removedRouteItem &&
				!parkedRouteItems.some(
					(parkedRouteItem) =>
						parkedRouteItem.orderKey === removedRouteItem.orderKey
				)
			) {
				// Drop the violations property
				const { violations, ...newParkedRouteItem } = removedRouteItem
				updatedParkedRouteItems.push(newParkedRouteItem)
			}
		})
		this.setState({
			isModified: true,
			parkedRouteItems: updatedParkedRouteItems,
			tours: editedTours
		})
	}

	handleManualPlanningRemoveOrdersWithoutAppointment = (tourId) => {
		// Removing means removing the orders from the tour and placing it into the parked orders list
		const { parkedRouteItems, tours } = this.state
		let removedRouteItems = []
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				// Remove route items which are not confirmed
				removedRouteItems = tour.route.filter(
					(routeItem) =>
						routeItem.characteristics && routeItem.characteristics.isUnconfirmed
				)
				const editedRoute = tour.route.filter(
					(routeItem) =>
						routeItem.characteristics &&
						!routeItem.characteristics.isUnconfirmed
				)
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		const updatedParkedRouteItems = [...parkedRouteItems]
		removedRouteItems.forEach((removedRouteItem) => {
			if (
				removedRouteItem &&
				!parkedRouteItems.some(
					(parkedRouteItem) =>
						parkedRouteItem.orderKey === removedRouteItem.orderKey
				)
			) {
				// Drop the violations property
				const { violations, ...newParkedRouteItem } = removedRouteItem
				updatedParkedRouteItems.push(newParkedRouteItem)
			}
		})
		this.setState({
			isModified: true,
			parkedRouteItems: updatedParkedRouteItems,
			tours: editedTours
		})
	}

	handleManualPlanningMove = (fromTourId, toTourId, orderKey) => {
		const { tours } = this.state
		let editedTours = tours
		const fromTour = tours.find((tour) => tour.id === fromTourId)
		if (fromTour) {
			const routeItemToMove = fromTour.route.find(
				(routeItem) => routeItem.orderKey === orderKey
			)
			if (routeItemToMove) {
				editedTours = tours.map((tour) => {
					// Remove the route item from the fromTour
					if (tour.id === fromTourId) {
						return {
							...tour,
							action: 'save', // Reset action when something changes
							route: tour.route.filter(
								(routeItem) => routeItem.orderKey !== orderKey
							)
						}
					}
					// and add it to the toTour
					if (tour.id === toTourId) {
						return {
							...tour,
							action: 'save', // Reset action when something changes
							route: [...tour.route, routeItemToMove]
						}
					}
					return tour
				})
			}
		}
		this.setState({
			isModified: true,
			tours: editedTours
		})
	}

	handleManualPlanningRemove = (tourId, orderKey) => {
		// Removing means removing the order from the tour and placing it into the parked orders list
		const { parkedRouteItems, tours } = this.state
		let removedRouteItem
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				removedRouteItem = tour.route.find(
					(routeItem) => routeItem.orderKey === orderKey
				)
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: tour.route.filter(
						(routeItem) => routeItem.orderKey !== orderKey
					)
				}
			}
			return tour
		})
		const updatedParkedRouteItems = [...parkedRouteItems]
		if (
			removedRouteItem &&
			!parkedRouteItems.some(
				(parkedRouteItem) =>
					parkedRouteItem.orderKey === removedRouteItem.orderKey
			)
		) {
			// Drop the violations property
			const { violations, ...newParkedRouteItem } = removedRouteItem
			updatedParkedRouteItems.push(newParkedRouteItem)
		}
		this.setState({
			isModified: true,
			parkedRouteItems: updatedParkedRouteItems,
			tours: editedTours
		})
	}

	handleManualPlanningUp = (tourId, orderKey) => {
		const { tours } = this.state
		const editedTours = tours.map((tour) => {
			if (tour.id === tourId) {
				const editedRoute = [...tour.route]
				const fromIndex = editedRoute.findIndex(
					(routeItem) => routeItem.orderKey === orderKey
				)
				if (fromIndex > -1) {
					// Moving up means subtracting 1 from the current index
					let toIndex = fromIndex - 1
					if (toIndex < 0) {
						toIndex = 0
					} // Prevent overflow
					arraymove(editedRoute, fromIndex, toIndex)
				}
				return {
					...tour,
					action: 'save', // Reset action when something changes
					route: editedRoute
				}
			}
			return tour
		})
		this.setState({
			isModified: true,
			tours: editedTours
		})
	}

	handleToggleStartAtDepot = () => {
		const { startAtDepot } = this.state
		this.setState({ startAtDepot: !startAtDepot })
	}

	handleOptimize = () => {
		const { optimize } = this.props
		const { tours, startAtDepot } = this.state
		const manualPlanningOptimizeId = uuidv4()
		this.setState({
			manualPlanningOptimizeId,
			manualPlanningReduceId: null,
			manualPlanningValidateId: null
		})
		const optimizeInput = tours.map((tour) => ({
			id: tour.id,
			route: tour.route.map((routeItem) => routeItem.orderKey)
		}))
		optimize(manualPlanningOptimizeId, optimizeInput, startAtDepot)
	}

	handleReduce = () => {
		const { reduce } = this.props
		const { tours, startAtDepot } = this.state
		const manualPlanningReduceId = uuidv4()
		this.setState({
			manualPlanningOptimizeId: null,
			manualPlanningReduceId,
			manualPlanningValidateId: null
		})
		const reduceInput = tours.map((tour) => ({
			id: tour.id,
			route: tour.route.map((routeItem) => routeItem.orderKey)
		}))
		reduce(manualPlanningReduceId, reduceInput, startAtDepot)
	}

	handleValidate = () => {
		const { validate } = this.props
		const { tours, startAtDepot } = this.state
		const manualPlanningValidateId = uuidv4()
		this.setState({
			manualPlanningOptimizeId: null,
			manualPlanningReduceId: null,
			manualPlanningValidateId
		})
		const validateInput = tours.map((tour) => ({
			id: tour.id,
			route: tour.route.map((routeItem) => routeItem.orderKey)
		}))
		validate(manualPlanningValidateId, validateInput, startAtDepot)
	}

	handleSave = () => {
		const { finish, save } = this.props
		const { tours, saveConfirmed } = this.state
		const saveInput = tours.reduce(
			(acc, tour) => {
				// Group by selection save action and drop properties which do not need to be saved (violations)
				const { action, violations, ...tourInput } = tour
				tourInput.route = tourInput.route.map((routeItem) => {
					const { violations: routeItemViolations, ...routeItemInput } =
						routeItem
					return routeItemInput
				})
				const selectedAction = action || 'save'
				acc[selectedAction].push(tourInput)
				return acc
			},
			{
				save: [],
				confirm: [],
				finalize: []
			}
		)
		if (saveInput.finalize.length >= 1 && !saveConfirmed) {
			this.setState({
				showSaveConfirmationDialog: true
			})
		} else {
			save(saveInput)
			finish()
		}
	}

	handleSaveConfirmation = () => {
		this.setState({
			showSaveConfirmationDialog: false,
			saveConfirmed: true
		})
	}

	handleCloseSaveConfirmationDialog = () => {
		this.setState({
			showSaveConfirmationDialog: false
		})
	}

	handleCancelProcess = () => {
		// Cancel both optimization and validation
		// This is done by simply clearing the id's. Any running process results which may still come in through the socket will be ignored.
		this.setState({
			manualPlanningOptimizeId: null,
			manualPlanningReduceId: null,
			manualPlanningValidateId: null
		})
	}

	render() {
		const {
			cancel,
			metaOptimizations,
			metaReductions,
			metaTours,
			metaValidations,
			ownUserId,
			planningTypes,
			selectedMoment,
			selectedPlanningTypeKey,
			tourNumbers,
			unscheduledOrders,
			unscheduledOrdersLoading
		} = this.props
		const {
			isModified,
			manualPlanningOptimizeId,
			manualPlanningReduceId,
			manualPlanningValidateId,
			parkedRouteItems,
			startAtDepot,
			showSaveConfirmationDialog,
			tours
		} = this.state

		const selectedPlanningType = planningTypes.find(
			(planningType) => planningType.planningTypeKey === selectedPlanningTypeKey
		)

		// Check if we're waiting for an optimization or validation
		let isLoading = false
		if (manualPlanningOptimizeId) {
			const currentMetaOptimization = metaOptimizations.find(
				(metaOptimization) => metaOptimization.id === manualPlanningOptimizeId
			)
			isLoading = currentMetaOptimization.isLoading
		}
		if (manualPlanningReduceId) {
			const currentMetaReduction = metaReductions.find(
				(metaReduction) => metaReduction.id === manualPlanningReduceId
			)
			isLoading = currentMetaReduction.isLoading
		}
		if (manualPlanningValidateId) {
			const currentMetaValidation = metaValidations.find(
				(metaValidation) => metaValidation.id === manualPlanningValidateId
			)
			isLoading = currentMetaValidation.isLoading
		}

		return (
			<TourEdit
				cancel={cancel}
				cancelProcess={this.handleCancelProcess}
				isLoading={isLoading}
				isModified={isModified}
				manualPlanningAction={this.handleManualPlanningAction}
				manualPlanningAddParkedRouteItem={
					this.handleManualPlanningAddParkedRouteItem
				}
				manualPlanningAddUnscheduledOrder={
					this.handleManualPlanningAddUnscheduledOrder
				}
				manualPlanningDown={this.handleManualPlanningDown}
				manualPlanningMove={this.handleManualPlanningMove}
				manualPlanningRemove={this.handleManualPlanningRemove}
				manualPlanningRemoveAllOrdersWithoutAppointment={
					this.handleManualPlanningRemoveAllOrdersWithoutAppointment
				}
				manualPlanningRemoveOrdersWithoutAppointment={
					this.handleManualPlanningRemoveOrdersWithoutAppointment
				}
				manualPlanningReverse={this.handleManualPlanningReverse}
				manualPlanningUp={this.handleManualPlanningUp}
				metaTours={metaTours}
				optimize={this.handleOptimize}
				ownUserId={ownUserId}
				parkedRouteItems={parkedRouteItems}
				reduce={this.handleReduce}
				save={this.handleSave}
				confirmSave={this.handleSaveConfirmation}
				showSaveDialog={showSaveConfirmationDialog}
				closeSaveDialog={this.handleCloseSaveConfirmationDialog}
				selectedMoment={selectedMoment}
				selectedPlanningType={selectedPlanningType}
				startAtDepot={startAtDepot}
				toggleStartAtDepot={this.handleToggleStartAtDepot}
				tourNumbers={tourNumbers}
				tours={tours}
				unscheduledOrders={unscheduledOrders}
				unscheduledOrdersLoading={unscheduledOrdersLoading}
				validate={this.handleValidate}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		metaOptimizations: state.manualPlanningsState.metaOptimizations,
		metaReductions: state.manualPlanningsState.metaReductions,
		metaTours: state.toursState.metaTours,
		metaValidations: state.manualPlanningsState.metaValidations,
		optimizations: state.manualPlanningsState.optimizations,
		ownUserId: state.userState.user.id,
		planningTypes: state.planningTypesState.planningTypes,
		reductions: state.manualPlanningsState.reductions,
		selectedMoment: toursSelectors.selectedMomentSelector(state.toursState),
		selectedPlanningTypeKey: state.toursState.selectedPlanningTypeKey,
		selectedTours: toursSelectors.selectedToursSelector(state.toursState),
		tourNumbers: toursSelectors.tourListNumbersSelector(state.toursState),
		unscheduledOrders: unscheduledOrdersSelectors.unscheduledOrderListSelector(
			state.unscheduledOrdersState,
			state.toursState
		),
		unscheduledOrdersLoading: state.unscheduledOrdersState.meta.isLoading,
		validations: state.manualPlanningsState.validations
	}),
	{
		fetchUnscheduledOrders: unscheduledOrdersActionCreators.fetch,
		optimize: manualPlanningActionCreators.fetchOptimization,
		reduce: manualPlanningActionCreators.fetchReduction,
		save: manualPlanningActionCreators.save,
		validate: manualPlanningActionCreators.fetchValidation
	}
)

export default connector(TourEditContainer)
