import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Switch,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ExclamationFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'

const styles = {
	addressBlock: {
		display: 'flex',
		flexDirection: 'column'
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	},
	unknownAdressBlock: {
		display: 'flex',
		alignItems: 'center'
	}
}

class StartLocation extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		address: PropTypes.object,
		startAtDepot: PropTypes.bool,
		toggleStartAtHome: PropTypes.func.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	handleToggleStartAtHome = () => {
		const { toggleStartAtHome } = this.props
		toggleStartAtHome()
	}

	render() {
		const { address, classes, startAtDepot, vehicle } = this.props
		const { startAtDriverHome } = vehicle

		// a vehicle can only start at a home address if it has 1 driver and does not start at the depot
		const startAtHomeDisabled = !!vehicle.secondDriver || startAtDepot

		let addressItem = null
		if (startAtDriverHome) {
			if (address) {
				addressItem = (
					<div className={classes.addressBlock}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Depots.homeAddress')}
						</Typography>
						<Typography variant="body2">{`${address.street} ${address.houseNum}`}</Typography>
						<Typography variant="body2">{`${address.postcode} ${address.city}`}</Typography>
					</div>
				)
			} else {
				addressItem = (
					<span className={classes.unknownAdressBlock}>
						<SvgIcon className={classes.icon} color="secondary">
							<ExclamationFal />
						</SvgIcon>{' '}
						<Typography variant="body2" color="secondary">
							{i18n.t('app:vehicleplanning.Depots.unknownHomeAddress')}
						</Typography>
					</span>
				)
			}
		}

		return (
			<>
				<List disablePadding>
					<ListItem
						button
						disabled={startAtHomeDisabled}
						onClick={this.handleToggleStartAtHome}
						disableGutters
					>
						<ListItemText
							disableTypography
							primary={
								<Typography variant="body2">
									{i18n.t('app:vehicleplanning.Depots.startAtDriverHome')}
								</Typography>
							}
						/>
						<ListItemSecondaryAction>
							<Switch
								color="primary"
								checked={startAtDriverHome}
								disabled={startAtHomeDisabled}
								onChange={this.handleToggleStartAtHome}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				{addressItem}
			</>
		)
	}
}

export default withStyles(styles)(StartLocation)
