import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import numeral from 'numeral'
import { selectedCharacteristic } from '../../../../utils/callscript'

const styleSheet = {
	root: {
		paddingTop: 32
	}
}

class Characteristics extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired
	}

	render() {
		const { classes, appointment } = this.props

		const hasFloorNumber =
			appointment.floorNumber && appointment.floorNumber !== null

		let floorNumberText
		if (hasFloorNumber) {
			floorNumberText =
				appointment.floorNumber === 0 ? (
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.firstFloor')}
					</Typography>
				) : (
					<Typography variant="body2">{`${numeral(
						appointment.floorNumber
					).format('0o')} ${i18n.t(
						'app:appointmentscheduler.Order.floor'
					)}`}</Typography>
				)
		}

		let characteristicsText
		switch (selectedCharacteristic(appointment)) {
			case 'externalelevator': {
				characteristicsText = (
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.withExternalElevator')}
					</Typography>
				)
				break
			}
			case 'scaffold': {
				characteristicsText = (
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.withScaffold')}
					</Typography>
				)
				break
			}
			case 'stairs': {
				characteristicsText = (
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.withStairs')}
					</Typography>
				)
				break
			}
			case 'elevator': {
				characteristicsText = (
					<Typography variant="body2">
						{i18n.t('app:appointmentscheduler.Order.withElevator')}
					</Typography>
				)
				break
			}
			default: {
				break
			}
		}

		if (floorNumberText || characteristicsText) {
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.characteristics')}
					</Typography>
					{floorNumberText}
					{characteristicsText}
				</div>
			)
		}

		return null
	}
}

export default withStyles(styleSheet)(Characteristics)
