import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppointmentScheduler from './AppointmentScheduler'
import ordersSelectors from '../../redux/orders/selectors'

class AppointmentSchedulerContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired,
		selectedOrder: PropTypes.object,
		selectedMoment: PropTypes.object.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			selectedOrderId: null
		}
	}

	handleSelectOrder = (orderId) => {
		this.setState({
			selectedOrderId: orderId
		})
	}

	render() {
		return (
			<AppointmentScheduler
				selectOrder={this.handleSelectOrder}
				selectedOrderId={this.state.selectedOrderId}
			/>
		)
	}
}

const connector = connect((state) => ({
	selectedMoment: ordersSelectors.selectedMomentSelector(state.ordersState)
}))

export default connector(AppointmentSchedulerContainer)
