import {
	CircularProgress,
	Grid,
	IconButton,
	SvgIcon,
	Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	LockFal,
	LockFas,
	MapFal,
	SigmaFal,
	SigmaFas,
	WrenchFal,
	WrenchFas
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { PLANNING_TYPE_MEERDAAGSE_RIT } from '../../../../utils/constants'

const styleSheet = {
	root: {}
}

class HeaderButtons extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		handleAddUnavailableProposalDate: PropTypes.func.isRequired,
		handleRemoveUnavailableProposalDate: PropTypes.func.isRequired,
		handleToggleSummary: PropTypes.func.isRequired,
		isUnavailableProposalDate: PropTypes.bool.isRequired,
		isUnavailableProposalDateButtonAvailable: PropTypes.bool.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		showSummary: PropTypes.bool.isRequired,
		handleAddExcludedServiceDate: PropTypes.func.isRequired,
		handleRemoveExcludedServiceDate: PropTypes.func.isRequired,
		isExcludedServiceDate: PropTypes.bool.isRequired,
		isExcludedServiceDateButtonAvailable: PropTypes.bool.isRequired
	}

	render() {
		const {
			classes,
			handleAddUnavailableProposalDate,
			handleRemoveUnavailableProposalDate,
			handleToggleSummary,
			isUnavailableProposalDate,
			isUnavailableProposalDateButtonAvailable,
			selectedPlanningTypeKey,
			showSummary,
			handleAddExcludedServiceDate,
			handleRemoveExcludedServiceDate,
			isExcludedServiceDate,
			isExcludedServiceDateButtonAvailable
		} = this.props

		let toggleUnavailableProposalDateButton
		if (selectedPlanningTypeKey !== PLANNING_TYPE_MEERDAAGSE_RIT) {
			// Only planning types which are not multi-day can be set as unavailable (due to PTV planning with categories)
			if (!isUnavailableProposalDateButtonAvailable) {
				toggleUnavailableProposalDateButton = (
					<IconButton disabled color="primary">
						<CircularProgress size={24} />
					</IconButton>
				)
			} else if (isUnavailableProposalDate) {
				toggleUnavailableProposalDateButton = (
					<Tooltip
						title={i18n.t(
							'app:planning.Tours.disableUnavailableProposalDateTooltip'
						)}
						placement="top"
					>
						<IconButton
							color="primary"
							onClick={handleRemoveUnavailableProposalDate}
						>
							<SvgIcon>
								<LockFas />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				)
			} else {
				toggleUnavailableProposalDateButton = (
					<Tooltip
						title={i18n.t(
							'app:planning.Tours.enableUnavailableProposalDateTooltip'
						)}
						placement="top"
					>
						<IconButton
							color="primary"
							onClick={handleAddUnavailableProposalDate}
						>
							<SvgIcon>
								<LockFal />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				)
			}
		}

		let toggleExcludedServiceDateButton
		if (!isExcludedServiceDateButtonAvailable) {
			toggleExcludedServiceDateButton = (
				<IconButton disabled color="primary">
					<CircularProgress size={24} />
				</IconButton>
			)
		} else if (isExcludedServiceDate) {
			toggleExcludedServiceDateButton = (
				<Tooltip
					title={i18n.t('app:planning.Tours.disableExcludedServiceDateTooltip')}
					placement="top"
				>
					<IconButton color="primary" onClick={handleRemoveExcludedServiceDate}>
						<SvgIcon color="error">
							<WrenchFas />
						</SvgIcon>
					</IconButton>
				</Tooltip>
			)
		} else {
			toggleExcludedServiceDateButton = (
				<Tooltip
					title={i18n.t('app:planning.Tours.enableExcludedServiceDateTooltip')}
					placement="top"
				>
					<IconButton color="primary" onClick={handleAddExcludedServiceDate}>
						<SvgIcon>
							<WrenchFal />
						</SvgIcon>
					</IconButton>
				</Tooltip>
			)
		}

		const summaryIcon = showSummary ? <SigmaFas /> : <SigmaFal />

		return (
			<Grid className={classes.root} justifyContent="flex-end" container>
				{toggleUnavailableProposalDateButton}
				{toggleExcludedServiceDateButton}
				<IconButton color="primary" onClick={handleToggleSummary}>
					<SvgIcon>{summaryIcon}</SvgIcon>
				</IconButton>
				<IconButton color="primary" href="/map" target="_blank">
					<SvgIcon>
						<MapFal />
					</SvgIcon>
				</IconButton>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(HeaderButtons)
