import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Switch,
	TextField
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	floorNumberInput: {
		alignItems: 'center'
	},
	textFieldFloorNumber: {
		flex: 1
	},
	confirmButton: {
		marginTop: 16
	}
}

class FloorNumber extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		setFloorNumber: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		let floorNumber = 0
		if (
			props.appointment.floorNumber !== null &&
			props.appointment.floorNumber !== undefined
		) {
			floorNumber = props.appointment.floorNumber
		} else if (
			props.order.appointment.floorNumber !== null &&
			props.order.appointment.floorNumber !== undefined
		) {
			floorNumber = props.order.appointment.floorNumber
		} else if (props.order.floorNumber) {
			floorNumber = props.order.floorNumber
		}
		this.state = {
			floorNumber,
			floorNumberError: false
		}
	}

	handleChangeFloorNumber = (event) => {
		const floorNumberInput = event.target.value
		if (floorNumberInput === '') {
			this.setState({
				floorNumber: '',
				floorNumberError: false
			})
		} else if (/^\d+$/.test(floorNumberInput) && floorNumberInput.length < 4) {
			this.setState({
				floorNumber: parseInt(floorNumberInput, 10),
				floorNumberError: false
			})
		}
	}

	handleToggleFirstFloor = () => {
		const { floorNumber } = this.state
		this.setState({
			floorNumber: floorNumber === 0 ? 1 : 0
		})
	}

	handleConfirm = () => {
		const { setFloorNumber } = this.props
		const { floorNumber } = this.state
		if (/^\d+$/.test(floorNumber)) {
			setFloorNumber(floorNumber)
		} else {
			this.setState({
				floorNumberError: true
			})
		}
	}

	render() {
		const { classes } = this.props
		const { floorNumber, floorNumberError } = this.state

		let floorNumberInput
		if (floorNumber !== 0) {
			let floorNumberErrorText
			if (floorNumberError) {
				floorNumberErrorText = i18n.t(
					'app:appointmentscheduler.Order.Edit.DeliveryFloorNumber.floorNumberErrorText'
				)
			}
			floorNumberInput = (
				<div className={classes.floorNumberInput}>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryFloorNumber.floorNumber'
						)}
						className={classes.textFieldFloorNumber}
						value={floorNumber}
						onChange={this.handleChangeFloorNumber}
						margin="normal"
						error={floorNumberError}
						helperText={floorNumberErrorText}
					/>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<List disablePadding>
					<ListItem button disableGutters onClick={this.handleToggleFirstFloor}>
						<ListItemText
							primary={i18n.t(
								'app:appointmentscheduler.Order.Edit.DeliveryFloorNumber.firstFloor'
							)}
							primaryTypographyProps={{ variant: 'body2' }}
						/>
						<ListItemSecondaryAction>
							<Switch
								checked={floorNumber === 0}
								color="primary"
								onChange={this.handleToggleFirstFloor}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				{floorNumberInput}
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(FloorNumber)
