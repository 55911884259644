import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../../../redux/depots/selectors'
import Depot from './Depot'

class DepotContainer extends PureComponent {
	static propTypes = {
		availableDepots: PropTypes.array.isRequired,
		change: PropTypes.func.isRequired,
		depot: PropTypes.object,
		error: PropTypes.bool,
		errorText: PropTypes.string,
		label: PropTypes.string.isRequired,
		plant: PropTypes.string
	}

	render() {
		const { availableDepots, change, depot, error, errorText, label } =
			this.props

		return (
			<Depot
				availableDepots={availableDepots}
				change={change}
				depot={depot}
				error={error}
				errorText={errorText}
				label={label}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	depot: selectors.findDepotByPlantSelector(state.depotsState, props.plant),
	availableDepots: selectors.availableDepotsSelector(state.depotsState)
}))

export default connector(DepotContainer)
