import config from '../../config'

const momentLocale = () => {
	switch (config.userLanguage) {
		case 'nl': {
			return 'nl'
		}
		case 'de': {
			return 'de'
		}
		case 'en': {
			return 'en'
		}
		default: {
			return 'nl'
		}
	}
}

const numeralLocale = () => {
	switch (config.userLanguage) {
		case 'nl': {
			return 'nl-nl'
		}
		case 'de': {
			return 'de'
		}
		case 'en': {
			return 'en'
		}
		default: {
			return 'nl-nl'
		}
	}
}

export { momentLocale, numeralLocale }
