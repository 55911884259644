import { default as i18n } from 'i18next'

import elementsFetch from '../utils/fetch'

const vehiclesUrl = '/api/vehiclescustom'
const vehiclesByDayUrl = (day, month, year) =>
	`/api/vehiclescustom/available/${year}/${month}/${day}`

const getByDay = async (day, month, year) => {
	let response
	try {
		response = await elementsFetch(vehiclesByDayUrl(day, month, year))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const save = async (vehicle) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(vehiclesUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify(vehicle)
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getByDay,
	save
}

export default api
