import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ListContainer from './List'
import SearchContainer from './Search'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: 800
	},
	search: {
		display: 'flex',
		flexDirection: 'column'
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	}
}

class DriverSearch extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		excludedDrivers: PropTypes.array,
		select: PropTypes.func.isRequired
	}

	render() {
		const { classes, excludedDrivers, select } = this.props

		return (
			<div className={classes.root}>
				<div className={classes.search}>
					<SearchContainer />
				</div>
				<div className={classes.contentContainer}>
					<ListContainer excluded={excludedDrivers} select={select} />
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DriverSearch)
