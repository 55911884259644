import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import authActionCreators from '../../../redux/auth/actionCreators'
import Starting from './Starting'

class StartingContainer extends PureComponent {
	static propTypes = {
		logout: PropTypes.func.isRequired
	}

	render() {
		const { logout } = this.props

		return <Starting logout={logout} />
	}
}

const connector = connect(null, {
	logout: authActionCreators.logout
})

export default connector(StartingContainer)
