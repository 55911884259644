import { default as i18n } from 'i18next'

import elementsFetch from '../utils/fetch'

const excludedServiceDatesUrl = '/api/excludedservicedates'
const deleteUnavailableProposalDateUrl = (id) =>
	`/api/excludedservicedates/${id}`

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(excludedServiceDatesUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const add = async (excludedServiceDate) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(excludedServiceDatesUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify(excludedServiceDate)
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const remove = async (id) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(deleteUnavailableProposalDateUrl(id), {
			method: 'DELETE',
			headers
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getAll,
	add,
	remove
}

export default api
