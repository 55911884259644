import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	IconButton,
	List,
	ListItem,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { EditFal, TrashAltFal } from '@oliverit/react-fontawesome'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import DriverSearchContainer from '../../../DriverSearch'

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: 8
	},
	text: {
		flex: 1
	},
	item: {
		paddingTop: 0,
		paddingBottom: 0
	}
}

class Driver extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		change: PropTypes.func.isRequired,
		error: PropTypes.bool,
		errorText: PropTypes.string,
		excludedDrivers: PropTypes.array,
		driver: PropTypes.object,
		remove: PropTypes.func.isRequired
	}

	state = {
		showEdit: false
	}

	handleEditDriver = () => {
		this.setState({
			showEdit: true
		})
	}

	handleDeleteDriver = () => {
		const { remove } = this.props
		remove()
	}

	handleHideEdit = () => {
		this.setState({
			showEdit: false
		})
	}

	handleSelectDriver = (driver) => {
		const { change } = this.props

		this.setState({
			showEdit: false
		})

		change(driver.driverId)
	}

	render() {
		const { classes, driver, error, errorText, excludedDrivers } = this.props
		const { showEdit } = this.state

		let primary
		if (driver) {
			primary = (
				<Typography variant="body2">{`${driver.firstName} ${driver.lastName}`}</Typography>
			)
		} else {
			primary = (
				<Typography variant="body2">
					{i18n.t('app:vehicleplanning.Driver.empty')}
				</Typography>
			)
		}

		let secondary
		if (errorText) {
			secondary = (
				<Typography variant="caption" color={error ? 'error' : 'textPrimary'}>
					{errorText}
				</Typography>
			)
		}

		const searchDialog = (
			<Dialog fullWidth open={showEdit} onClose={this.handleHideEdit}>
				<DriverSearchContainer
					excludedDrivers={excludedDrivers}
					select={this.handleSelectDriver}
				/>
			</Dialog>
		)

		return (
			<div className={classes.root}>
				<div className={classes.text}>
					<Typography variant="caption" color={error ? 'error' : 'primary'}>
						{i18n.t('app:vehicleplanning.Driver.title')}
					</Typography>
					<List disablePadding>
						<ListItem disableGutters className={classes.item}>
							<ListItemText primary={primary} secondary={secondary} />
						</ListItem>
					</List>
				</div>
				<IconButton color="primary" onClick={this.handleEditDriver}>
					<SvgIcon>
						<EditFal />
					</SvgIcon>
				</IconButton>
				<IconButton color="secondary" onClick={this.handleDeleteDriver}>
					<SvgIcon>
						<TrashAltFal />
					</SvgIcon>
				</IconButton>
				{searchDialog}
			</div>
		)
	}
}

export default withStyles(styles)(Driver)
