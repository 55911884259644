import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styleSheet = {
	root: {
		paddingTop: 32
	}
}

class DeliveryDate extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired
	}

	render() {
		const { classes, appointment } = this.props

		if (appointment.deliveryDate) {
			const from = moment.tz(appointment.deliveryDate.from, 'Europe/Amsterdam')
			const till = moment.tz(appointment.deliveryDate.till, 'Europe/Amsterdam')
			let deliveryDate
			if (from.hour() !== 0 || till.hour() !== 23) {
				deliveryDate = (
					<>
						<Typography variant="body1">
							{from.format('dddd D MMMM Y')}
						</Typography>
						<Typography variant="body1">
							{from.format('H:mm')} - {till.format('H:mm')}
						</Typography>
					</>
				)
			} else {
				deliveryDate = (
					<Typography variant="body1">
						{from.format('dddd D MMMM Y')}
					</Typography>
				)
			}

			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.deliveryDate')}
					</Typography>
					{deliveryDate}
				</div>
			)
		}

		return null
	}
}

export default withStyles(styleSheet)(DeliveryDate)
