import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Tooltip,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { CheckFal, InfoCircleFal, TimesFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import { fields, localizedText } from '../../../utils/localizedText'

const styleSheet = (theme) => ({
	root: {},
	header: {
		padding: 16
	},
	iconGreen: {
		color: green[500]
	},
	iconRed: {
		color: theme.palette.secondary.main
	}
})

class TourEditTargetList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired,
		selectedOrderRequirements: PropTypes.array,
		tourNumbers: PropTypes.array.isRequired,
		tours: PropTypes.array.isRequired
	}

	state = {
		isRequirementsOpen: false
	}

	handleSelect = (tourId) => () => {
		const { select } = this.props
		select(tourId)
	}

	handleOpenRequirementsClick = () => {
		this.setState({ isRequirementsOpen: true })
	}

	handleCloseRequirementsClick = () => {
		this.setState({ isRequirementsOpen: false })
	}

	render() {
		const { classes, selectedOrderRequirements, tourNumbers, tours } =
			this.props
		const { isRequirementsOpen } = this.state

		const tourItems = tours.map((tour) => {
			// A tour contains the qualifications of the vehicle, the first driver and the second driver
			const qualifications = [
				...(tour.vehicle && tour.vehicle.qualifications
					? tour.vehicle.qualifications
					: []),
				...(tour.firstDriver && tour.firstDriver.qualifications
					? tour.firstDriver.qualifications
					: []),
				...(tour.secondDriver && tour.secondDriver.qualifications
					? tour.secondDriver.qualifications
					: [])
			]

			// Requirements
			let matchedQualifications
			if (selectedOrderRequirements && selectedOrderRequirements.length > 0) {
				let hasAllRequirements = true
				const requirementRows = selectedOrderRequirements.map(
					(requirement, index) => {
						const requirementDescription = localizedText(
							fields.REQUIREMENT,
							requirement.key
						)
						const hasRequirement = qualifications.some(
							(qualification) => qualification.key === requirement.key
						)
						let requirementIcon
						if (hasRequirement) {
							requirementIcon = (
								<IconButton disabled edge="end">
									<SvgIcon className={classes.iconGreen} edge="end">
										<CheckFal />
									</SvgIcon>
								</IconButton>
							)
						} else {
							hasAllRequirements = false
							requirementIcon = (
								<IconButton disabled edge="end">
									<SvgIcon className={classes.iconRed} edge="end">
										<TimesFal />
									</SvgIcon>
								</IconButton>
							)
						}
						return (
							<ListItem key={`requirement-${index}`}>
								<ListItemText primary={requirementDescription} />
								<ListItemSecondaryAction>
									{requirementIcon}
								</ListItemSecondaryAction>
							</ListItem>
						)
					}
				)
				matchedQualifications = (
					<ListItemSecondaryAction>
						<Dialog
							onClose={this.handleCloseRequirementsClick}
							open={isRequirementsOpen}
						>
							<List>{requirementRows}</List>
							<DialogActions>
								<Button
									onClick={this.handleCloseRequirementsClick}
									color="primary"
								>
									{i18n.t('app:generic.close')}
								</Button>
							</DialogActions>
						</Dialog>
						<Tooltip
							title={i18n.t('app:planning.Tour.showRequirements')}
							placement="top"
						>
							<IconButton
								className={classes.requirementsButton}
								onClick={this.handleOpenRequirementsClick}
							>
								<SvgIcon color={hasAllRequirements ? 'inherit' : 'error'}>
									<InfoCircleFal />
								</SvgIcon>
							</IconButton>
						</Tooltip>
					</ListItemSecondaryAction>
				)
			}

			const firstDriverName = tour.firstDriver
				? tour.firstDriver.name
				: i18n.t('app:planning.Tour.unknownDriver')
			const secondDriverName = tour.secondDriver
				? tour.secondDriver.name
				: i18n.t('app:planning.Tour.unknownDriver')
			const number = tourNumbers.find(
				(tourNumber) => tourNumber.id === tour.id
			).number
			return (
				<ListItem
					key={`targettour-${tour.id}`}
					button
					onClick={this.handleSelect(tour.id)}
				>
					<ListItemAvatar>
						<Avatar>{number}</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={tour.vehicle.licensePlate || '??????'}
						secondary={`${firstDriverName} & ${secondDriverName}`}
						primaryTypographyProps={{ variant: 'body2' }}
					/>
					{matchedQualifications}
				</ListItem>
			)
		})

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">
						{i18n.t('app:planning.Manual.selectTargetTour')}
					</Typography>
				</div>
				<List disablePadding>{tourItems}</List>
			</div>
		)
	}
}

export default withStyles(styleSheet)(TourEditTargetList)
