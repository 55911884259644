import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import driversActionCreators from '../../../../redux/drivers/actionCreators'
import DayViewer from './DayViewer'

class DayViewerContainer extends PureComponent {
	static propTypes = {
		dayMoment: PropTypes.object.isRequired,
		availability: PropTypes.array,
		deselect: PropTypes.func.isRequired
	}

	render() {
		const { dayMoment, availability, deselect } = this.props

		return (
			<DayViewer
				dayMoment={dayMoment}
				availability={availability}
				deselect={deselect}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		availability: driversSelectors.findAvailabiltyByDaySelector(
			state.driversState,
			props.dayMoment
		)
	}),
	{
		deselect: driversActionCreators.deselectAvailabilityItem
	}
)

export default connector(DayViewerContainer)
