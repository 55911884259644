import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import escapeStringRegexp from '../../utils/escapeStringRegexp'

import localizedDescription from '../../utils/localizedDescription'

const driversSelector = (state) => state.drivers
const searchSelector = (state) => state.search
const selectedDateSelector = (state) => state.selectedDate
const availabilitySelector = (state) => state.availability
const selectedAvailabilityItemSelector = (state) =>
	state.selectedAvailabilityItem

const parseDriverId = (driverId) => {
	const intValue = Number.parseInt(driverId, 10)

	if (Number.isNaN(intValue)) {
		return null
	}

	return intValue
}

const findDriverByIdSelector = createSelector(
	[driversSelector, (state, driverId) => parseDriverId(driverId)],
	(drivers, driverId) => drivers.find((driver) => driver.driverId === driverId)
)

const findAddressByDriverIdSelector = createSelector(
	findDriverByIdSelector,
	(driver) => (driver ? driver.address : null)
)

const queryDriver = (driver, query) => {
	const queryRegex = new RegExp(escapeStringRegexp(query), 'i')
	if (driver.driverId && queryRegex.test(driver.driverId.toString())) {
		return true
	}
	if (driver.firstName && queryRegex.test(driver.firstName.toString())) {
		return true
	}
	if (driver.lastName && queryRegex.test(driver.lastName.toString())) {
		return true
	}
	// test combination of first and last name
	if (
		driver.firstName &&
		driver.lastName &&
		queryRegex.test(
			`${driver.firstName.toString()} ${driver.lastName.toString()}`
		)
	) {
		return true
	}
	return false
}

const compareByFullname = (a, b) => {
	const fullNameA = `${a.firstName} ${a.lastName}`
	const fullNameB = `${b.firstName} ${b.lastName}`

	return fullNameA.localeCompare(fullNameB)
}

// returns the found drivers based on the search query
// result sorted by `firstname + lastname'
const driversListSelector = createSelector(
	[searchSelector, driversSelector],
	(search, drivers) => {
		const foundDrivers = drivers.filter((driver) =>
			queryDriver(driver, search.query)
		)
		return foundDrivers.sort(compareByFullname)
	}
)

// return the found drivers based on the search query
// needs a minimum search query
// option to exclude certain drivers based on their id
// result sorted by `firstname + lastname'
const driversSearchSelector = createSelector(
	[searchSelector, driversSelector, (state, excluded = []) => excluded],
	(search, drivers, excluded) => {
		// Require minimal search input (we don't want to display the entire drivers list)
		if (!search.query || search.query.length < 2) {
			return []
		}

		const foundDrivers = drivers.filter((driver) => {
			// excluded drivers should never be returned
			if (excluded.includes(driver.driverId)) {
				return false
			}
			return queryDriver(driver, search.query)
		})

		return foundDrivers.sort(compareByFullname)
	}
)

const selectedMomentSelector = createSelector(
	selectedDateSelector,
	(selectedDate) => moment.unix(selectedDate).tz('Europe/Amsterdam')
)

const filterAvailabilityByDay = (availability, day) =>
	availability.filter((item) => {
		const start = moment.tz(day, 'Europe/Amsterdam').startOf('day').unix()
		const end = moment.tz(day, 'Europe/Amsterdam').endOf('day').unix()

		// only keep items that are between start and end, thus on the requested day
		return start <= item.from && item.till <= end
	})

const findAvailabiltyByDaySelector = createSelector(
	[availabilitySelector, (state, dayMoment) => dayMoment],
	(availability, dayMoment) => filterAvailabilityByDay(availability, dayMoment)
)

const findAvailabiltyForSelectedDaySelector = createSelector(
	[availabilitySelector, selectedAvailabilityItemSelector],
	(availability, availabilityItem) => {
		if (!availabilityItem) {
			return []
		}

		const dayMoment = moment.unix(availabilityItem.date).tz('Europe/Amsterdam')
		return filterAvailabilityByDay(availability, dayMoment)
	}
)

const findNotSelectedAvailabiltyForSelectedDaySelector = createSelector(
	[findAvailabiltyForSelectedDaySelector, selectedAvailabilityItemSelector],
	(availability, availabilityItem) =>
		availability.filter(
			(item) =>
				item.from !== availabilityItem.from &&
				item.till !== availabilityItem.till
		)
)

const qualificationsByDriverIdsSelector = createSelector(
	[driversSelector, (state, driverIds) => driverIds],
	(drivers, driverIds) => {
		const selectedDrivers = drivers.filter((driver) =>
			driverIds.includes(driver.driverId)
		)
		const qualifications = selectedDrivers.reduce(
			(qualificationsAcc, driver) => [
				...qualificationsAcc,
				...driver.qualifications
			],
			[]
		)
		const descriptions = qualifications.map((qualification) =>
			localizedDescription(qualification.translations)
		)
		// use a Set to remove duplicate entries from the result
		return [...new Set(descriptions)].sort()
	}
)

const selectors = {
	driversListSelector,
	driversSearchSelector,
	findAddressByDriverIdSelector,
	findAvailabiltyByDaySelector,
	findAvailabiltyForSelectedDaySelector,
	findDriverByIdSelector,
	findNotSelectedAvailabiltyForSelectedDaySelector,
	qualificationsByDriverIdsSelector,
	selectedMomentSelector
}

export default selectors
