import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		paddingTop: 32
	}
}

class Remarks extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired
	}

	render() {
		const { classes, appointment } = this.props

		if (appointment.bijzonderheden !== null) {
			const remarks = appointment.bijzonderheden
				? appointment.bijzonderheden
				: i18n.t('app:appointmentscheduler.Order.noRemarks')
			const remarksLines = remarks.split(/\r?\n/).map((remarksLine, key) => (
				<span key={`remark-${key}`}>
					{remarksLine}
					<br />
				</span>
			))
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.remarks')}
					</Typography>
					<Typography variant="body2">{remarksLines}</Typography>
				</div>
			)
		}

		return null
	}
}

export default withStyles(styleSheet)(Remarks)
