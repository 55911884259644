import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PLANNING_STATUS_IN_PROGRESS } from '../utils/constants'

const connector = connect((state) => ({
	plannings: state.planningsState.plannings,
	planningsLoading: state.planningsState.meta.isLoading,
	selectedPlanningTypeKey: state.toursState.selectedPlanningTypeKey,
	selectedDate: state.toursState.selectedDate
}))

const withPlanningStatus = (WrappedComponent) => {
	class PlanningStatusHOC extends PureComponent {
		static propTypes = {
			plannings: PropTypes.array.isRequired,
			planningsLoading: PropTypes.bool,
			selectedPlanningTypeKey: PropTypes.string.isRequired,
			selectedDate: PropTypes.string.isRequired
		}

		render() {
			const {
				plannings,
				planningsLoading,
				selectedPlanningTypeKey,
				selectedDate,
				...remainingProps
			} = this.props

			// Set `planningIsProcessing` to true when the plannings are still loading (just to be safe) or when the planning status is "in progress"
			const selectedPlanning = plannings.find(
				(planning) => planning.planningTypeKey === selectedPlanningTypeKey
			)
			const planningIsProcessing =
				planningsLoading ||
				(selectedPlanning &&
					selectedPlanning.status === PLANNING_STATUS_IN_PROGRESS &&
					selectedPlanning.dates.includes(selectedDate))

			return (
				<WrappedComponent
					planningIsProcessing={planningIsProcessing}
					{...remainingProps}
				/>
			)
		}
	}
	return connector(PlanningStatusHOC)
}

export default withPlanningStatus
