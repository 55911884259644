import actionTypes from './actionTypes'

const actionCreators = {
	autoLogin: () => ({
		type: actionTypes.AUTOLOGIN
	}),

	autoLoginRetry: () => ({
		type: actionTypes.AUTOLOGIN
	}),

	autoLoginRequest: () => ({
		type: actionTypes.AUTOLOGIN_REQUEST
	}),

	autoLoginNoToken: () => ({
		type: actionTypes.AUTOLOGIN_NOTOKEN
	}),

	autoLoginFailure: (errorMessages) => ({
		type: actionTypes.AUTOLOGIN_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	login: (userName, password) => ({
		type: actionTypes.LOGIN,
		payload: {
			credentials: {
				userName,
				password
			}
		}
	}),

	loginRequest: () => ({
		type: actionTypes.LOGIN_REQUEST
	}),

	loginCancelled: () => ({
		type: actionTypes.LOGIN_CANCELLED
	}),

	loginFailure: (errorMessages) => ({
		type: actionTypes.LOGIN_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	loginSuccess: (user) => ({
		type: actionTypes.LOGIN_SUCCESS,
		payload: {
			user
		}
	}),

	logout: (history) => ({
		type: actionTypes.LOGOUT,
		payload: {
			history
		}
	})
}

export default actionCreators
