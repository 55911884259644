import { default as i18n } from 'i18next'

// Create a separate i18next instance for the callscript
export const i18nCallScript = i18n.createInstance()

// Helper functions for the call script flow

// Languages
export const languages = () =>
	[
		{ id: 'de', description: i18n.t('app:settings.Language.lngGerman') },
		{ id: 'nl', description: i18n.t('app:settings.Language.lngDutch') }
	].sort((a, b) => a.description.localeCompare(b.description))

// Elevator
export const isElevatorAnswered = (appointment) =>
	appointment &&
	appointment.characteristics &&
	appointment.characteristics !== null &&
	appointment.characteristics.canElevatorBeUsed !== null

export const isElevatorAvailable = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics !== null
	) {
		const { canElevatorBeUsed } = appointment.characteristics
		if (
			canElevatorBeUsed !== null &&
			canElevatorBeUsed.isPresent &&
			canElevatorBeUsed.isLargeEnough
		) {
			return true
		}
	}
	return false
}

export const elevatorAnswers = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics.canElevatorBeUsed !== null
	) {
		const isLargeEnough =
			appointment.characteristics.canElevatorBeUsed.isLargeEnough !== null
				? appointment.characteristics.canElevatorBeUsed.isLargeEnough
				: true
		const isPresent =
			appointment.characteristics.canElevatorBeUsed.isPresent !== null
				? appointment.characteristics.canElevatorBeUsed.isPresent
				: true
		return {
			isLargeEnough,
			isPresent
		}
	}
	return null
}

// Stairs
export const isStairsAnswered = (appointment) =>
	appointment &&
	appointment.characteristics &&
	appointment.characteristics !== null &&
	appointment.characteristics.canStairsBeUsed !== null

export const isStairsAvailable = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics !== null
	) {
		const { canStairsBeUsed } = appointment.characteristics
		if (
			canStairsBeUsed !== null &&
			canStairsBeUsed.isPresent &&
			canStairsBeUsed.isLargeEnough
		) {
			return true
		}
	}
	return false
}

export const stairsAnswers = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics.canStairsBeUsed !== null
	) {
		const isLargeEnough =
			appointment.characteristics.canStairsBeUsed.isLargeEnough !== null
				? appointment.characteristics.canStairsBeUsed.isLargeEnough
				: true
		const isPresent =
			appointment.characteristics.canStairsBeUsed.isPresent !== null
				? appointment.characteristics.canStairsBeUsed.isPresent
				: true
		return {
			isLargeEnough,
			isPresent
		}
	}
	return null
}

// Scaffold
export const isScaffoldAnswered = (appointment) =>
	appointment &&
	appointment.characteristics &&
	appointment.characteristics !== null &&
	appointment.characteristics.canScaffoldBeUsed !== null

export const isScaffoldAvailable = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics !== null
	) {
		const { canScaffoldBeUsed } = appointment.characteristics
		if (
			canScaffoldBeUsed !== null &&
			canScaffoldBeUsed.fitsThroughWindow &&
			canScaffoldBeUsed.canBePlaced
		) {
			return true
		}
	}
	return false
}

export const scaffoldAnswers = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics.canScaffoldBeUsed !== null
	) {
		const fitsThroughWindow =
			appointment.characteristics.canScaffoldBeUsed.fitsThroughWindow !== null
				? appointment.characteristics.canScaffoldBeUsed.fitsThroughWindow
				: true
		const canBePlaced =
			appointment.characteristics.canScaffoldBeUsed.canBePlaced !== null
				? appointment.characteristics.canScaffoldBeUsed.canBePlaced
				: true
		return {
			fitsThroughWindow,
			canBePlaced
		}
	}
	return null
}

// External Elevator
export const isExternalElevatorAnswered = (appointment) =>
	appointment &&
	appointment.characteristics &&
	appointment.characteristics !== null &&
	appointment.characteristics.canExternalElevatorBeUsed !== null

export const isExternalElevatorAvailable = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics !== null
	) {
		const { canExternalElevatorBeUsed } = appointment.characteristics
		if (
			canExternalElevatorBeUsed !== null &&
			canExternalElevatorBeUsed.fitsThroughWindow &&
			canExternalElevatorBeUsed.canBePlaced
		) {
			return true
		}
	}
	return false
}

export const externalElevatorAnswers = (appointment) => {
	if (
		appointment &&
		appointment.characteristics &&
		appointment.characteristics.canExternalElevatorBeUsed !== null
	) {
		const fitsThroughWindow =
			appointment.characteristics.canExternalElevatorBeUsed
				.fitsThroughWindow !== null
				? appointment.characteristics.canExternalElevatorBeUsed
						.fitsThroughWindow
				: true
		const canBePlaced =
			appointment.characteristics.canExternalElevatorBeUsed.canBePlaced !== null
				? appointment.characteristics.canExternalElevatorBeUsed.canBePlaced
				: true
		return {
			fitsThroughWindow,
			canBePlaced
		}
	}
	return null
}

// Characteristics
export const selectedCharacteristic = (appointment) => {
	if (isExternalElevatorAvailable(appointment)) {
		return 'externalelevator'
	}
	if (isScaffoldAvailable(appointment)) {
		return 'scaffold'
	}
	if (isStairsAvailable(appointment)) {
		return 'stairs'
	}
	if (isElevatorAvailable(appointment)) {
		return 'elevator'
	}
	return 'none'
}

export const isCharacteristicsFlowComplete = (appointment) => {
	if (appointment && appointment.floorNumber !== null) {
		if (appointment.floorNumber > 0) {
			// When the floor number is greater than 0, at least one of the characteristics must be fully set to true
			return (
				isElevatorAvailable(appointment) ||
				isStairsAvailable(appointment) ||
				isScaffoldAvailable(appointment) ||
				isExternalElevatorAvailable(appointment)
			)
		}
		// Floor number is 0 which means characteristics are not required, so they are complete
		return true
	}
	return false
}

// Call script
export const determineCallScriptState = (appointment) => {
	// The code below will move through the entire calling script flow and will use the available appointment data to determine at which
	// step the call script should start (step) and which steps have been completed (flow) in order to reach that step.
	// - Start with order date selection
	let step = 'date'
	const flow = []
	if (appointment) {
		const { deliveryDate, customer, floorNumber, bijzonderheden } = appointment
		if (deliveryDate) {
			// - Order date was selected, show address form
			step = 'address'
			flow.push('date')
		}
		if (customer) {
			// - Address was confirmed, show floor number form
			step = 'floornumber'
			flow.push('address')
		}
		if (floorNumber > 0) {
			// - Floor number was set to greater than 0 (not the first floor), show elevator form
			step = 'elevator'
			flow.push('floornumber')
		} else if (floorNumber === 0) {
			// - Floor number set to the first floor (0), show remarks form
			step = 'remarks'
			flow.push('floornumber')
		}
		if (isElevatorAnswered(appointment)) {
			if (isElevatorAvailable(appointment)) {
				// - Elevator can be used, show remarks form
				step = 'remarks'
				flow.push('elevator')
			} else {
				// - Elevator cannot be used, show stairs form when the floor number is 4 or lower, otherwise skip to external elevator form
				if (floorNumber > 4) {
					step = 'externalelevator'
				} else {
					step = 'stairs'
				}
				flow.push('elevator')
			}
		}
		if (isStairsAnswered(appointment)) {
			if (isStairsAvailable(appointment)) {
				// - Stairs can be used, show remarks form
				step = 'remarks'
				flow.push('stairs')
			} else {
				// - Stairs cannot be used, show external elevator form when the floor number is greater than 1, otherwise show scaffold form
				step = floorNumber > 1 ? 'externalelevator' : 'scaffold'
				flow.push('stairs')
			}
		}
		if (isScaffoldAnswered(appointment)) {
			if (isScaffoldAvailable(appointment)) {
				// - Scaffold can be used, show remarks form
				step = 'remarks'
				flow.push('scaffold')
			} else {
				// - Scaffold cannot be used, show abort form
				step = 'abort'
				flow.push('scaffold')
			}
		}
		if (isExternalElevatorAnswered(appointment)) {
			if (isExternalElevatorAvailable(appointment)) {
				// - External elevator can be used, show remarks form
				step = 'remarks'
				flow.push('externalelevator')
			} else {
				// - External elevator cannot be used, show abort form
				step = 'abort'
				flow.push('externalelevator')
			}
		}
		if (isCharacteristicsFlowComplete(appointment)) {
			// Only continue checking the workflow when the characteristics are completed
			if (bijzonderheden !== null) {
				// - Remarks were completed, show confirm form
				step = 'confirm'
				flow.push('remarks')
			}
		}
	}
	return { step, flow }
}
