import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const styleSheet = {
	'@keyframes placeholder-pulse': {
		'0%': {
			opacity: 0.6
		},
		'50%': {
			opacity: 1
		},
		'100%': {
			opacity: 0.6
		}
	},
	root: {
		flex: 1,
		margin: 24
	},
	row: {
		animation: '$placeholder-pulse 1.5s infinite',
		backgroundColor: grey[100],
		height: 16,
		margin: '8px 0'
	}
}

class Placeholder extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		rowCount: PropTypes.number,
		widths: PropTypes.array
	}

	static defaultProps = {
		rowCount: 3,
		widths: [90, 100, 70]
	}

	render() {
		const { classes, rowCount, widths } = this.props

		const rows = [...Array(rowCount)].map((row, index) => {
			const width = `${widths[(index + widths.length) % widths.length]}%`
			return (
				<div
					key={`placeholder-row-${index}`}
					className={classes.row}
					style={{ width }}
				/>
			)
		})

		return <div className={classes.root}>{rows}</div>
	}
}

export default withStyles(styleSheet)(Placeholder)
