import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { default as i18n } from 'i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const styleSheet = {
	root: {
		flex: 1,
		paddingTop: 100
	},
	resetPasswordPaper: {
		margin: '0 auto',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
		width: 400
	},
	resetPasswordButton: {
		marginBottom: 30,
		width: '100%'
	},
	explanation: {
		marginTop: 20,
		textAlign: 'left'
	},
	showResetButton: {
		marginBottom: 10,
		width: '100%'
	},
	toLoginButton: {
		width: '100%'
	},
	progress: {
		marginTop: 20
	},
	userNameTextField: {
		marginTop: 20,
		marginBottom: 30,
		width: '100%'
	},
	usernamePinTextField: {
		marginTop: 20,
		width: '100%'
	},
	pinTextField: {
		marginTop: 0,
		width: '100%'
	},
	passwordTextField: {
		marginTop: 0,
		marginBottom: 30,
		width: '100%'
	},
	logo: {
		width: '100%'
	},
	errorMessage: {
		color: red[400],
		marginBottom: 30
	},
	resettingText: {
		marginTop: 16,
		marginBottom: 30
	},
	cancelButton: {
		width: '100%'
	}
}

class ResetPassword extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		errorMessages: PropTypes.object,
		newPasswordReset: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired
	}

	state = {
		userName: '',
		password: '',
		pin: '',
		showResetForm: false,
		resetting: false
	}

	handleChangeUserName = (event) => {
		this.setState({
			userName: event.target.value
		})
	}

	handleChangePin = (event) => {
		this.setState({
			pin: event.target.value
		})
	}

	handleChangePassword = (event) => {
		this.setState({
			password: event.target.value
		})
	}

	handleShowReset = () => {
		this.setState({
			showResetForm: true
		})
	}

	handleNewPasswordReset = () => {
		const { newPasswordReset } = this.props
		const { userName } = this.state
		newPasswordReset(userName)
		this.setState({
			showResetForm: true
		})
	}

	handleResetPassword = () => {
		const { resetPassword } = this.props
		const { userName, password, pin } = this.state
		resetPassword(userName, password, pin)
		this.setState({
			resetting: true
		})
	}

	render() {
		const { resetting, showResetForm } = this.state

		if (resetting) {
			return this.renderResetting()
		}

		if (showResetForm) {
			return this.renderResetPasswordForm()
		}

		return this.renderNewPasswordResetForm()
	}

	renderResetting() {
		const { classes } = this.props

		return (
			<Grid
				container
				align="center"
				justifyContent="center"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.resetPasswordPaper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<CircularProgress className={classes.progress} size={50} />
						<Typography variant="body2" className={classes.resettingText}>
							{i18n.t('app:elements.Login.resetting')}
						</Typography>
						<Button
							variant="contained"
							component={Link}
							to="/"
							className={classes.cancelButton}
						>
							{i18n.t('app:elements.Login.cancelReset')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}

	renderNewPasswordResetForm() {
		const { classes, errorMessages } = this.props
		const { userName } = this.state

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" className={classes.errorMessage}>
					{errorMessages.userMessage}
				</Typography>
			)
		}

		return (
			<Grid
				container
				align="center"
				direction="column"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.resetPasswordPaper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<div className={classes.explanation}>
							<Typography variant="body2" paragraph>
								{i18n.t('app:elements.Login.resetExplanation1')}
							</Typography>
							<Typography variant="body2">
								{i18n.t('app:elements.Login.resetExplanation2')}
							</Typography>
						</div>
						<TextField
							label={i18n.t('app:elements.Login.userName')}
							className={classes.userNameTextField}
							value={userName}
							onChange={this.handleChangeUserName}
							margin="normal"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							className={classes.resetPasswordButton}
							onClick={this.handleNewPasswordReset}
						>
							{i18n.t('app:elements.Login.newReset')}
						</Button>
						<Button
							variant="contained"
							className={classes.showResetButton}
							onClick={this.handleShowReset}
						>
							{i18n.t('app:elements.Login.resetHavePin')}
						</Button>
						<Button
							variant="contained"
							component={Link}
							to="/"
							className={classes.toLoginButton}
						>
							{i18n.t('app:elements.Login.toLogin')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}

	renderResetPasswordForm() {
		const { classes, errorMessages } = this.props
		const { userName, pin, password } = this.state

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" className={classes.errorMessage}>
					{errorMessages.userMessage}
				</Typography>
			)
		}

		return (
			<Grid
				container
				align="center"
				direction="column"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.resetPasswordPaper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<TextField
							label={i18n.t('app:elements.Login.userName')}
							className={classes.usernamePinTextField}
							value={userName}
							onChange={this.handleChangeUserName}
							margin="normal"
						/>
						<TextField
							label={i18n.t('app:elements.Login.pin')}
							className={classes.pinTextField}
							value={pin}
							onChange={this.handleChangePin}
							margin="normal"
						/>
						<TextField
							label={i18n.t('app:elements.Login.password')}
							className={classes.passwordTextField}
							type="password"
							value={password}
							onChange={this.handleChangePassword}
							margin="normal"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							className={classes.resetPasswordButton}
							onClick={this.handleResetPassword}
						>
							{i18n.t('app:elements.Login.reset')}
						</Button>
						<Button
							variant="contained"
							component={Link}
							to="/"
							className={classes.toLoginButton}
						>
							{i18n.t('app:elements.Login.toLogin')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(ResetPassword)
