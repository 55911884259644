import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import DepotsContainer from './Depots'
import Drivers from './Drivers'
import PlanningContainer from './Planning'
import Properties from './Properties'

const styleSheet = {
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		overflowY: 'scroll',
		height: '100%',
		width: '100%'
	}
}

class Edit extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		changeDepots: PropTypes.func.isRequired,
		changeDrivers: PropTypes.func.isRequired,
		changePlanning: PropTypes.func.isRequired,
		changeProperties: PropTypes.func.isRequired,
		changeMaxPeriod: PropTypes.func.isRequired,
		customVehicle: PropTypes.object.isRequired,
		meta: PropTypes.object.isRequired
	}

	handleChangeDepots = (depots) => {
		const { changeDepots } = this.props
		changeDepots(depots)
	}

	handleChangeDrivers = (drivers) => {
		const { changeDrivers } = this.props
		changeDrivers(drivers)
	}

	handleChangePlanning = (planning) => {
		const { changePlanning } = this.props
		changePlanning(planning)
	}

	handleChangeProperties = (properties) => {
		const { changeProperties } = this.props
		changeProperties(properties)
	}

	handleChangeMaxPeriod = (period) => {
		const { changeMaxPeriod } = this.props
		changeMaxPeriod(period)
	}

	render() {
		const { classes, customVehicle, meta } = this.props

		return (
			<Paper className={classes.root}>
				<Drivers
					vehicle={customVehicle}
					meta={meta}
					change={this.handleChangeDrivers}
				/>
				<DepotsContainer
					vehicle={customVehicle}
					meta={meta}
					change={this.handleChangeDepots}
				/>
				<PlanningContainer
					customVehicle={customVehicle}
					planningTypeKey={customVehicle.planningType}
					meta={meta}
					changePlanning={this.handleChangePlanning}
					changeMaxPeriod={this.handleChangeMaxPeriod}
				/>
				<Properties
					vehicle={customVehicle}
					meta={meta}
					change={this.handleChangeProperties}
				/>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Edit)
