import actionTypes from './actionTypes'

const actionCreators = {
	add: (order) => ({
		type: actionTypes.SOCKET_ADD_ORDER,
		payload: {
			order
		}
	}),

	clearFilter: () => ({
		type: actionTypes.FILTER_CLEAR
	}),

	clearSearch: () => ({
		type: actionTypes.SEARCH_CLEAR
	}),

	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (orders) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			orders
		}
	}),

	filter: (
		contactDateBefore,
		contactStatusKeys,
		customerLanguageKeys,
		orderTypeKeys,
		planningTypeKeys,
		priorityKeys,
		tourDateFrom,
		tourDateTill,
		routeKeys,
		userIds,
		requirementKeys,
		shippingConditionKeys,
		showConfirmed,
		showNoTourDateOnly
	) => ({
		type: actionTypes.FILTER,
		payload: {
			contactDateBefore,
			contactStatusKeys,
			customerLanguageKeys,
			orderTypeKeys,
			planningTypeKeys,
			priorityKeys,
			tourDateFrom,
			tourDateTill,
			routeKeys,
			showConfirmed,
			showNoTourDateOnly,
			userIds,
			requirementKeys,
			shippingConditionKeys
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	remove: (order) => ({
		type: actionTypes.SOCKET_REMOVE_ORDER,
		payload: {
			order
		}
	}),

	search: (query) => ({
		type: actionTypes.SEARCH,
		payload: {
			query
		}
	}),

	searchFailure: (errorMessages) => ({
		type: actionTypes.SEARCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	searchRequest: (query) => ({
		type: actionTypes.SEARCH_REQUEST,
		payload: {
			query
		}
	}),

	searchSuccess: (orders) => ({
		type: actionTypes.SEARCH_SUCCESS,
		payload: {
			orders
		}
	}),

	searchInWeek: (query) => ({
		type: actionTypes.SEARCH_IN_WEEK,
		payload: {
			query
		}
	}),

	selectCurrentDate: () => ({
		type: actionTypes.SELECT_DATE_CURRENT
	}),

	selectPreviousDate: () => ({
		type: actionTypes.SELECT_DATE_PREVIOUS
	}),

	selectNextDate: () => ({
		type: actionTypes.SELECT_DATE_NEXT
	}),

	selectWeek: (week, year) => ({
		type: actionTypes.SELECT_WEEK,
		payload: {
			week,
			year
		}
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	}),

	update: (order) => ({
		type: actionTypes.SOCKET_UPDATE_ORDER,
		payload: {
			order
		}
	}),

	updateTourDates: (planningTypeKey, orderTourDatesMap) => ({
		type: actionTypes.SOCKET_UPDATE_ORDER_TOURDATES,
		payload: {
			planningTypeKey,
			orderTourDatesMap
		}
	})
}

export default actionCreators
