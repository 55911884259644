import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ordersActionCreators from '../../../../redux/orders/actionCreators'
import ordersSelectors from '../../../../redux/orders/selectors'
import Filter from './Filter'

class FilterContainer extends PureComponent {
	static propTypes = {
		applyFilter: PropTypes.func.isRequired,
		availableContactStatusKeys: PropTypes.array.isRequired,
		availableCustomerLanguageKeys: PropTypes.array.isRequired,
		availableOrderTypeKeys: PropTypes.array.isRequired,
		availablePlanningTypeKeys: PropTypes.array.isRequired,
		availablePriorityKeys: PropTypes.array.isRequired,
		availableRouteKeys: PropTypes.array.isRequired,
		availableUsers: PropTypes.array.isRequired,
		availableRequirementKeys: PropTypes.array.isRequired,
		availableShippingConditionKeys: PropTypes.array.isRequired,
		clearFilter: PropTypes.func.isRequired,
		close: PropTypes.func.isRequired,
		selectedContactDateBefore: PropTypes.string,
		selectedContactStatusKeys: PropTypes.array.isRequired,
		selectedCustomerLanguageKeys: PropTypes.array.isRequired,
		selectedOrderTypeKeys: PropTypes.array.isRequired,
		selectedPlanningTypeKeys: PropTypes.array.isRequired,
		selectedPriorityKeys: PropTypes.array.isRequired,
		selectedTourDateFrom: PropTypes.string,
		selectedTourDateTill: PropTypes.string,
		selectedRouteKeys: PropTypes.array.isRequired,
		selectedUserIds: PropTypes.array.isRequired,
		selectedRequirementKeys: PropTypes.array.isRequired,
		selectedShippingConditionKeys: PropTypes.array.isRequired,
		showConfirmed: PropTypes.bool,
		showNoTourDateOnly: PropTypes.bool
	}

	handleApplyFilter = ({
		selectedContactDateBefore,
		selectedContactStatusKeys,
		selectedCustomerLanguageKeys,
		selectedOrderTypeKeys,
		selectedPlanningTypeKeys,
		selectedPriorityKeys,
		selectedTourDateFrom,
		selectedTourDateTill,
		selectedRouteKeys,
		selectedUserIds,
		selectedRequirementKeys,
		selectedShippingConditionKeys,
		showConfirmed,
		showNoTourDateOnly
	}) => {
		const { applyFilter } = this.props
		applyFilter(
			selectedContactDateBefore,
			selectedContactStatusKeys,
			selectedCustomerLanguageKeys,
			selectedOrderTypeKeys,
			selectedPlanningTypeKeys,
			selectedPriorityKeys,
			selectedTourDateFrom,
			selectedTourDateTill,
			selectedRouteKeys,
			selectedUserIds,
			selectedRequirementKeys,
			selectedShippingConditionKeys,
			showConfirmed,
			showNoTourDateOnly
		)
	}

	handleClearFilter = () => {
		const { close, clearFilter } = this.props
		clearFilter()
		close()
	}

	render() {
		const {
			availableContactStatusKeys,
			availableCustomerLanguageKeys,
			availableOrderTypeKeys,
			availablePlanningTypeKeys,
			availablePriorityKeys,
			availableRouteKeys,
			availableUsers,
			availableRequirementKeys,
			availableShippingConditionKeys,
			close,
			selectedContactDateBefore,
			selectedContactStatusKeys,
			selectedCustomerLanguageKeys,
			selectedOrderTypeKeys,
			selectedPlanningTypeKeys,
			selectedPriorityKeys,
			selectedTourDateFrom,
			selectedTourDateTill,
			selectedRouteKeys,
			selectedUserIds,
			selectedRequirementKeys,
			selectedShippingConditionKeys,
			showConfirmed,
			showNoTourDateOnly
		} = this.props

		return (
			<Filter
				availableContactStatusKeys={availableContactStatusKeys}
				availableCustomerLanguageKeys={availableCustomerLanguageKeys}
				availableOrderTypeKeys={availableOrderTypeKeys}
				availablePlanningTypeKeys={availablePlanningTypeKeys}
				availablePriorityKeys={availablePriorityKeys}
				availableRouteKeys={availableRouteKeys}
				availableUsers={availableUsers}
				availableRequirementKeys={availableRequirementKeys}
				availableShippingConditionKeys={availableShippingConditionKeys}
				applyFilter={this.handleApplyFilter}
				clearFilter={this.handleClearFilter}
				closeFilter={close}
				selectedContactDateBefore={selectedContactDateBefore}
				selectedContactStatusKeys={selectedContactStatusKeys}
				selectedCustomerLanguageKeys={selectedCustomerLanguageKeys}
				selectedOrderTypeKeys={selectedOrderTypeKeys}
				selectedPlanningTypeKeys={selectedPlanningTypeKeys}
				selectedPriorityKeys={selectedPriorityKeys}
				selectedTourDateFrom={selectedTourDateFrom}
				selectedTourDateTill={selectedTourDateTill}
				selectedRouteKeys={selectedRouteKeys}
				selectedUserIds={selectedUserIds}
				selectedRequirementKeys={selectedRequirementKeys}
				selectedShippingConditionKeys={selectedShippingConditionKeys}
				showConfirmed={showConfirmed}
				showNoTourDateOnly={showNoTourDateOnly}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		availableContactStatusKeys: ordersSelectors.availableContactStatusKeys(
			state.ordersState
		),
		availableCustomerLanguageKeys:
			ordersSelectors.availableCustomerLanguageKeys(state.ordersState),
		availableOrderTypeKeys: ordersSelectors.availableOrderTypeKeys(
			state.ordersState
		),
		availablePlanningTypeKeys: ordersSelectors.availablePlanningTypeKeys(
			state.ordersState
		),
		availablePriorityKeys: ordersSelectors.availablePriorityKeys(
			state.ordersState
		),
		availableRouteKeys: ordersSelectors.availableRouteKeys(state.ordersState),
		availableUsers: ordersSelectors.availableUsers(state.ordersState),
		availableRequirementKeys: ordersSelectors.availableRequirementKeys(
			state.ordersState
		),
		availableShippingConditionKeys:
			ordersSelectors.availableShippingConditionKeys(state.ordersState),
		selectedContactDateBefore: state.ordersState.filter.contactDateBefore,
		selectedContactStatusKeys: state.ordersState.filter.contactStatusKeys,
		selectedCustomerLanguageKeys: state.ordersState.filter.customerLanguageKeys,
		selectedOrderTypeKeys: state.ordersState.filter.orderTypeKeys,
		selectedPlanningTypeKeys: state.ordersState.filter.planningTypeKeys,
		selectedPriorityKeys: state.ordersState.filter.priorityKeys,
		selectedTourDateFrom: state.ordersState.filter.tourDateFrom,
		selectedTourDateTill: state.ordersState.filter.tourDateTill,
		selectedRouteKeys: state.ordersState.filter.routeKeys,
		selectedUserIds: state.ordersState.filter.userIds,
		selectedRequirementKeys: state.ordersState.filter.requirementKeys,
		selectedShippingConditionKeys:
			state.ordersState.filter.shippingConditionKeys,
		showConfirmed: state.ordersState.filter.showConfirmed,
		showNoTourDateOnly: state.ordersState.filter.showNoTourDateOnly
	}),
	{
		applyFilter: ordersActionCreators.filter,
		clearFilter: ordersActionCreators.clearFilter
	}
)

export default connector(FilterContainer)
