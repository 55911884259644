import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton, SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarFal,
	ChevronLeftFal,
	ChevronRightFal
} from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			display: 'flex',
			alignItems: 'center',
			flex: 1
		})
	},
	date: {
		marginLeft: 16
	}
})

class DateNavigator extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	handleSelectCurrentDate = () => {
		const { selectCurrentDate } = this.props
		selectCurrentDate()
	}

	handleSelectNextDate = () => {
		const { selectNextDate } = this.props
		selectNextDate()
	}

	handleSelectPreviousDate = () => {
		const { selectPreviousDate } = this.props
		selectPreviousDate()
	}

	render() {
		const { classes, selectedMoment } = this.props

		const date = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.format('dddd D MMM Y')

		const today = moment.tz('Europe/Amsterdam').startOf('day')
		const isTodaySelected = selectedMoment.isSame(today)

		return (
			<div className={classes.root}>
				<IconButton
					disabled={isTodaySelected}
					color="primary"
					onClick={this.handleSelectPreviousDate}
				>
					<SvgIcon>
						<ChevronLeftFal />
					</SvgIcon>
				</IconButton>
				<IconButton
					disabled={isTodaySelected}
					color="primary"
					onClick={this.handleSelectCurrentDate}
				>
					<SvgIcon>
						<CalendarFal />
					</SvgIcon>
				</IconButton>
				<IconButton color="primary" onClick={this.handleSelectNextDate}>
					<SvgIcon>
						<ChevronRightFal />
					</SvgIcon>
				</IconButton>
				<div className={classes.date}>
					<Typography variant="h6">{date}</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DateNavigator)
