import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../redux/postcodes/selectors'
import actionCreators from '../../../redux/postcodes/actionCreators'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		postcodes: PropTypes.array.isRequired,
		removeItem: PropTypes.func.isRequired
	}

	render() {
		const { postcodes, removeItem } = this.props

		return <List postcodes={postcodes} removeItem={removeItem} />
	}
}

const connector = connect(
	(state) => ({
		postcodes: selectors.searchPostcodesSelector(state.postcodesState)
	}),
	{
		removeItem: actionCreators.removePostcode
	}
)

export default connector(ListContainer)
