import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		paddingLeft: 16,
		paddingRight: 16
	},
	empty: {
		textAlign: 'center',
		width: 400
	},
	slapen: {
		paddingTop: 80,
		paddingBottom: 30,
		width: 100
	}
}

class ListEmpty extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	render() {
		const { classes } = this.props

		return (
			<div className={classes.root}>
				<div className={classes.empty}>
					<img src="/slapen.png" className={classes.slapen} alt="" />
					<Typography variant="subtitle1">
						{i18n.t('app:planning.Tours.empty')}
					</Typography>
					<Typography variant="caption">
						{i18n.t('app:planning.Tours.emptyDescription')}
					</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(ListEmpty)
