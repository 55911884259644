import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton, SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarFal,
	ChevronLeftFal,
	ChevronRightFal
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styleSheet = {
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	week: {
		marginLeft: 16
	}
}

class WeekNavigator extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectCurrentWeek: PropTypes.func.isRequired,
		selectNextWeek: PropTypes.func.isRequired,
		selectPreviousWeek: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	handleSelectCurrentWeek = () => {
		const { selectCurrentWeek } = this.props
		selectCurrentWeek()
	}

	handleSelectNextWeek = () => {
		const { selectNextWeek } = this.props
		selectNextWeek()
	}

	handleSelectPreviousWeek = () => {
		const { selectPreviousWeek } = this.props
		selectPreviousWeek()
	}

	render() {
		const { classes, selectedMoment } = this.props

		const from = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.startOf('isoWeek')
			.format('D MMM Y')
		const to = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.endOf('isoWeek')
			.format('D MMM Y')

		const currentWeek = moment.tz('Europe/Amsterdam').isoWeek()
		const selectedWeek = selectedMoment.isoWeek()
		const isCurrentWeekSelected = selectedWeek === currentWeek

		return (
			<div className={classes.root}>
				<IconButton
					disabled={isCurrentWeekSelected}
					color="primary"
					onClick={this.handleSelectPreviousWeek}
				>
					<SvgIcon>
						<ChevronLeftFal />
					</SvgIcon>
				</IconButton>
				<IconButton
					disabled={isCurrentWeekSelected}
					color="primary"
					onClick={this.handleSelectCurrentWeek}
				>
					<SvgIcon>
						<CalendarFal />
					</SvgIcon>
				</IconButton>
				<IconButton color="primary" onClick={this.handleSelectNextWeek}>
					<SvgIcon>
						<ChevronRightFal />
					</SvgIcon>
				</IconButton>
				<div className={classes.week}>
					<Typography variant="h6">
						{i18n.t('app:driveradmin.DriverEdit.WeekNavigator.week')}{' '}
						{selectedWeek}
					</Typography>
					<Typography variant="caption">
						{from} - {to}
					</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(WeekNavigator)
