import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {}

class Abort extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		return (
			<Typography variant="body2">
				{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryAbort.help')}
			</Typography>
		)
	}
}

export default withStyles(styleSheet)(Abort)
