import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../redux/vehiclescustom/selectors'
import Vehicle from './Vehicle'

class VehicleContainer extends PureComponent {
	static propTypes = {
		selectedVehicleId: PropTypes.string,
		vehicle: PropTypes.object
	}

	render() {
		const { vehicle } = this.props

		if (!vehicle) {
			return <div />
		}
		return <Vehicle vehicle={vehicle} />
	}
}

const connector = connect((state, props) => ({
	vehicle: selectors.findVehicleByIdSelector(
		state.vehiclesState,
		state.driversState,
		props.selectedVehicleId
	)
}))

export default connector(VehicleContainer)
