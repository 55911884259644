import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton, SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarFal,
	ChevronLeftFal,
	ChevronRightFal
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styleSheet = {
	root: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		paddingLeft: 16,
		paddingRight: 16
	},
	week: {
		marginLeft: 16
	}
}

class WeekNavigator extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		isCurrentWeek: PropTypes.bool
	}

	handleSelectCurrentDate = () => {
		const { selectCurrentDate } = this.props
		selectCurrentDate()
	}

	handleSelectNextDate = () => {
		const { selectNextDate } = this.props
		selectNextDate()
	}

	handleSelectPreviousDate = () => {
		const { selectPreviousDate } = this.props
		selectPreviousDate()
	}

	render() {
		const { classes, selectedMoment, isCurrentWeek } = this.props

		const from = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.day(1)
			.format('D MMM Y')
		const to = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.day(7)
			.format('D MMM Y')

		return (
			<div className={classes.root}>
				<IconButton
					disabled={isCurrentWeek}
					color="primary"
					onClick={this.handleSelectPreviousDate}
				>
					<SvgIcon>
						<ChevronLeftFal />
					</SvgIcon>
				</IconButton>
				<IconButton
					disabled={isCurrentWeek}
					color="primary"
					onClick={this.handleSelectCurrentDate}
				>
					<SvgIcon>
						<CalendarFal />
					</SvgIcon>
				</IconButton>
				<IconButton color="primary" onClick={this.handleSelectNextDate}>
					<SvgIcon>
						<ChevronRightFal />
					</SvgIcon>
				</IconButton>
				<div className={classes.week}>
					<Typography variant="h6">
						{i18n.t('app:appointmentscheduler.Orders.week')}{' '}
						{selectedMoment.isoWeek()}
					</Typography>
					<Typography variant="caption">
						{from} - {to}
					</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(WeekNavigator)
