import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		planningTypes: PropTypes.array.isRequired,
		selectedPlanningTypeKey: PropTypes.string
	}

	render() {
		const { planningTypes, selectedPlanningTypeKey } = this.props

		return (
			<List
				planningTypes={planningTypes}
				selectedPlanningTypeKey={selectedPlanningTypeKey}
			/>
		)
	}
}

const connector = connect((state) => ({
	planningTypes: state.planningTypesState.planningTypes
}))

export default connector(ListContainer)
