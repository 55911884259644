import { createSelector } from 'reselect'
import moment from 'moment-timezone'

// Return if the selected planning date is unavailable
const isUnavailableProposalDateSelector = createSelector(
	[
		(toursState) => toursState.selectedPlanningTypeKey,
		(toursState) => toursState.selectedDate,
		(toursState, unavailableProposalDatesState) =>
			unavailableProposalDatesState.unavailableProposalDates
	],
	(selectedPlanningTypeKey, selectedDate, unavailableProposalDates) =>
		unavailableProposalDates.some(
			(unavailableProposalDate) =>
				unavailableProposalDate.planningTypeKey === selectedPlanningTypeKey &&
				unavailableProposalDate.unavailableDate === selectedDate
		)
)

// Return if the button can be used
const isUnavailableProposalDateButtonAvailableSelector = createSelector(
	[(unavailableProposalDatesState) => unavailableProposalDatesState.meta],
	(meta) => meta.isConnected && !meta.isSaving && !meta.isLoading
)

const unavailableProposalMomentsForOrderSelector = createSelector(
	[
		(ordersState) => ordersState.orders,
		(ordersState, selectedOrderId) => selectedOrderId,
		(ordersState, selectedOrderId, unavailableProposalDatesState) =>
			unavailableProposalDatesState.unavailableProposalDates
	],
	(orders, selectedOrderId, unavailableProposalDates) => {
		const selectedOrder = orders.find((order) => order.id === selectedOrderId)
		if (selectedOrder) {
			return unavailableProposalDates.reduce((acc, unavailableProposalDate) => {
				if (
					unavailableProposalDate.planningTypeKey ===
					selectedOrder.planningType.key
				) {
					return acc.concat(
						moment.tz(
							unavailableProposalDate.unavailableDate,
							'Europe/Amsterdam'
						)
					)
				}
				return acc
			}, [])
		}
		return []
	}
)

const selectors = {
	isUnavailableProposalDateSelector,
	isUnavailableProposalDateButtonAvailableSelector,
	unavailableProposalMomentsForOrderSelector
}

export default selectors
