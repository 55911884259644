import { createSelector } from 'reselect'

const depotsSelector = (state) => state.depots

const compareByName = (a, b) => a.name.localeCompare(b.name)

const findDepotByPlantSelector = createSelector(
	[depotsSelector, (state, plant) => plant],
	(depots, plant) => depots.find((depot) => depot.plant === plant)
)

const availableDepotsSelector = createSelector(depotsSelector, (depots) =>
	depots.sort(compareByName)
)

const selectors = {
	availableDepotsSelector,
	findDepotByPlantSelector
}

export default selectors
