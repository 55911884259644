import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { selectedCharacteristic } from '../../../../utils/callscript'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	confirmButton: {
		marginTop: 16
	}
}

class Confirm extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { appointment, classes, confirm } = this.props

		const deliveryDate = moment
			.tz(appointment.deliveryDate.from, 'Europe/Amsterdam')
			.format('dddd D MMMM Y')

		const floorNumberText =
			appointment.floorNumber === 0
				? i18n.t('app:appointmentscheduler.Order.Edit.OrderConfirm.firstFloor')
				: `${numeral(appointment.floorNumber).format('0o')} ${i18n.t(
						'app:appointmentscheduler.Order.Edit.OrderConfirm.floor'
				  )}`

		let characteristicsText
		switch (selectedCharacteristic(appointment)) {
			case 'externalelevator': {
				characteristicsText = i18n.t(
					'app:appointmentscheduler.Order.Edit.OrderConfirm.withExternalElevator'
				)
				break
			}
			case 'scaffold': {
				characteristicsText = i18n.t(
					'app:appointmentscheduler.Order.Edit.OrderConfirm.withScaffold'
				)
				break
			}
			case 'stairs': {
				characteristicsText = i18n.t(
					'app:appointmentscheduler.Order.Edit.OrderConfirm.withStairs'
				)
				break
			}
			case 'elevator': {
				characteristicsText = i18n.t(
					'app:appointmentscheduler.Order.Edit.OrderConfirm.withElevator'
				)
				break
			}
			default: {
				break
			}
		}

		const summary = (
			<Typography variant="body2">
				{i18n.t('app:appointmentscheduler.Order.Edit.OrderConfirm.intro')}
				<strong>{deliveryDate}</strong>
				{i18n.t('app:appointmentscheduler.Order.Edit.OrderConfirm.on')}
				<strong>
					{floorNumberText}
					{characteristicsText}
				</strong>
				.
			</Typography>
		)

		return (
			<div className={classes.root}>
				{summary}
				<Button
					color="primary"
					variant="contained"
					onClick={confirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirmFinal')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Confirm)
