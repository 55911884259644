import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursActionCreators from '../../redux/tours/actionCreators'
import toursSelectors from '../../redux/tours/selectors'
import unscheduledOrdersActionCreators from '../../redux/unscheduledorders/actionCreators'
import withPlanningStatus from '../../hocs/withPlanningStatus'
import Planning from './Planning'

class PlanningContainer extends PureComponent {
	static propTypes = {
		fetchUnscheduledOrders: PropTypes.func.isRequired,
		lock: PropTypes.func.isRequired,
		planningIsProcessing: PropTypes.bool, // Provided by `withPlanningStatus` HOC
		selectedTours: PropTypes.array.isRequired,
		unlock: PropTypes.func.isRequired
	}

	componentDidMount() {
		// Fetch the list of unscheduled orders
		const { fetchUnscheduledOrders } = this.props
		fetchUnscheduledOrders()
	}

	componentDidUpdate(prevProps) {
		const { fetchUnscheduledOrders, planningIsProcessing } = this.props
		if (prevProps.planningIsProcessing && !planningIsProcessing) {
			// Planning processing is completed, reload the unscheduled orders
			fetchUnscheduledOrders()
		}
	}

	render() {
		const { lock, selectedTours, unlock } = this.props

		return (
			<Planning lock={lock} selectedTours={selectedTours} unlock={unlock} />
		)
	}
}

const connector = connect(
	(state) => ({
		selectedTours: toursSelectors.selectedToursSelector(state.toursState)
	}),
	{
		fetchUnscheduledOrders: unscheduledOrdersActionCreators.fetch,
		lock: toursActionCreators.lock,
		unlock: toursActionCreators.unlock
	}
)

export default withPlanningStatus(connector(PlanningContainer))
