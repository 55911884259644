import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import VehicleSelectionContainer from './VehicleSelection'
import VehicleContainer from './Vehicle'
import VehicleEditContainer from './VehicleEdit'

const styles = () => ({
	root: {
		display: 'flex',
		height: '100%'
	},
	list: {
		marginRight: 16,
		width: 600
	},
	details: {
		flex: 1
	}
})

class VehiclePlanning extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		refresh: PropTypes.func.isRequired,
		selectedVehicleId: PropTypes.string,
		showEdit: PropTypes.bool
	}

	handleRefresh = () => {
		const { refresh } = this.props

		refresh()
	}

	render() {
		const { classes, selectedVehicleId, showEdit } = this.props

		if (showEdit) {
			return (
				<div className={classes.root}>
					<VehicleEditContainer selectedVehicleId={selectedVehicleId} />
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.list}>
					<VehicleSelectionContainer
						selectedVehicleId={selectedVehicleId}
						refresh={this.handleRefresh}
					/>
				</div>
				<div className={classes.details}>
					<VehicleContainer selectedVehicleId={selectedVehicleId} />
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(VehiclePlanning)
