import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import planningTypesSelector from '../../../../redux/planningtypes/selectors'
import Planning from './Planning'

class PlanningContainer extends PureComponent {
	static propTypes = {
		planningType: PropTypes.object,
		properties: PropTypes.array,
		planningTypeKey: PropTypes.string
	}

	render() {
		const { planningType, properties } = this.props

		return <Planning planningType={planningType} properties={properties} />
	}
}

const connector = connect((state, props) => ({
	planningType: planningTypesSelector.findPlanningTypeByKeySelector(
		state.planningTypesState,
		props.planningTypeKey
	)
}))

export default connector(PlanningContainer)
