import {
	authorization,
	refreshRequired,
	readRefreshToken,
	storeTokens
} from '../auth'
import apiAuth from '../../services/apiAuth'
import config from '../../config'

let isRefreshing = false

// This function must be used for all API calls to the Elements server
// It ensures the request is authenticated with the stored tokens,
// and it will automatically refresh the tokens when they are about to expire.
// This also means there is no need for a separate process which would
// make sure the tokens are refreshed before they expire, because now
// this check is done on every single API call.
// It is not a problem when other requests are made while the tokens are being refreshed,
// because old access tokens will remain valid regardless of whether a new access token,
// was created by refreshing the tokens.
// This means we only have to make sure only 1 refresh request is made, and there is no
// need to block any requests coming after that.

const elementsFetch = async (url, options = {}) => {
	// Refresh tokens before calling the api
	const isRefreshRequired = refreshRequired()
	if (!isRefreshing && isRefreshRequired) {
		const refreshToken = readRefreshToken()
		if (refreshToken) {
			try {
				isRefreshing = true
				const tokens = await apiAuth.refreshTokens(refreshToken)
				storeTokens(tokens)
			} finally {
				isRefreshing = false
			}
		}
	}
	// Extend the options
	// - Authorization header
	const auth = authorization()
	const { headers } = options
	options.headers = {
		...headers,
		Authorization: auth
	}
	// - Timeout (unless already specified)
	if (!options.timeout) {
		options.timeout = 30000
	}
	// Perform the fetch call
	return fetch(`${config.oeServer}${url}`, options)
}

export default elementsFetch
