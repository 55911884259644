import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TourComparisonItem from './TourComparisonItem'
import SelectionToolbarContainer from './SelectionToolbar'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	header: {
		padding: 16
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		overflowX: 'scroll',
		overflowY: 'scroll',
		position: 'absolute',
		top: 16,
		right: 0,
		bottom: 0,
		left: 0,
		// These margin and padding settings make sure the shadow of the Paper components inside the container are not hidden by the borders of this content container
		margin: -5,
		padding: 5
	},
	footer: {
		padding: 16
	},
	tourItem: {
		flex: '0 0 280px',
		marginRight: 16,
		maxWidth: 280
	},
	tourItemLast: {
		flex: '0 0 280px',
		marginRight: 5,
		maxWidth: 280
	},
	tourItemSpacer: {
		flex: '0 0 1px'
	}
}

class TourComparison extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		deselect: PropTypes.func.isRequired,
		finalize: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		selectedTours: PropTypes.array.isRequired,
		startManualPlanning: PropTypes.func.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		undoConfirm: PropTypes.func.isRequired
	}

	render() {
		const {
			classes,
			confirm,
			deselect,
			finalize,
			metaTours,
			ownUserId,
			selectedTours,
			startManualPlanning,
			tourNumbers,
			undoConfirm
		} = this.props

		const tourItems = selectedTours.map((selectedTour, index) => {
			const metaTour = metaTours.find((meta) => meta.id === selectedTour.id)
			const number = tourNumbers.find(
				(tourNumber) => tourNumber.id === selectedTour.id
			).number
			return (
				<div
					key={`tour-${selectedTour.id}`}
					className={
						index === selectedTours.length - 1
							? classes.tourItemLast
							: classes.tourItem
					}
				>
					<TourComparisonItem
						confirm={confirm}
						deselect={deselect}
						finalize={finalize}
						metaTour={metaTour}
						number={number}
						ownUserId={ownUserId}
						tour={selectedTour}
						undoConfirm={undoConfirm}
					/>
				</div>
			)
		})

		return (
			<div className={classes.root}>
				<SelectionToolbarContainer startManualPlanning={startManualPlanning} />
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						{tourItems}
						<div className={classes.tourItemSpacer} />
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(TourComparison)
