import moment from 'moment-timezone'
import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialOrdersState = []

const ordersReducer = (state = initialOrdersState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_FAILURE: {
			return initialOrdersState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.orders
		}

		case actionTypes.SOCKET_ADD_ORDER: {
			return state
				.filter((order) => order.id !== action.payload.order.id)
				.concat(action.payload.order)
		}

		case actionTypes.SOCKET_REMOVE_ORDER: {
			return state.filter((order) => order.id !== action.payload.order.id)
		}

		case actionTypes.SOCKET_UPDATE_ORDER: {
			return state.map((order) =>
				order.id !== action.payload.order.id ? order : action.payload.order
			)
		}

		case actionTypes.SOCKET_UPDATE_ORDER_TOURDATES: {
			return state.map((order) => {
				// Do not update locked orders because that will disrupt the appointment process
				// Locked orders will be updated automatically when they are saved/unlocked
				if (order.lock) {
					return order
				}
				// Only process relevant planning type
				if (order.planningType.key !== action.payload.planningTypeKey) {
					return order
				}
				// The order tour dates Map contains all orders which were used in the latest planning-run.
				// If the order is not existing, then the tour date is unaffected (this requires a Map.has check,
				// because Map.get returns undefined, which is also the return value if a order could not be scheduled).
				// If the order exists, but has no value (value is undefined), then the order could not be scheduled
				// and the tour date must be cleared.
				// If the order exists with a value, then the order is scheduled and the tour date must be updated,
				if (!action.payload.orderTourDatesMap.has(order.orderKey)) {
					return order
				}
				// Update the tourDate
				const tourDate = action.payload.orderTourDatesMap.get(order.orderKey)
				const { tourDate: oldTourDate, ...orderWithoutTourDate } = order
				if (tourDate === oldTourDate) {
					// Tour date is not modified
					return order
				}
				if (tourDate) {
					// Tour date is added or modified
					return { ...order, tourDate }
				}
				// Tour date is removed
				return orderWithoutTourDate
			})
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isConnected: false,
	isLoading: true
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		default: {
			return state
		}
	}
}

// The filter reducer is used for filtering and search within a selected week
const initialFilterState = {
	contactDateBefore: null,
	contactStatusKeys: [],
	customerLanguageKeys: [],
	orderTypeKeys: [],
	planningTypeKeys: [],
	priorityKeys: [],
	tourDateFrom: null,
	tourDateTill: null,
	query: '',
	routeKeys: [],
	showConfirmed: false,
	showNoTourDateOnly: false,
	userIds: [],
	requirementKeys: [],
	shippingConditionKeys: []
}

const filterReducer = (state = initialFilterState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			// Clear filters when fetching new dataset
			return initialFilterState
		}

		case actionTypes.FILTER: {
			return {
				...state,
				contactDateBefore: action.payload.contactDateBefore,
				contactStatusKeys: action.payload.contactStatusKeys,
				customerLanguageKeys: action.payload.customerLanguageKeys,
				orderTypeKeys: action.payload.orderTypeKeys,
				planningTypeKeys: action.payload.planningTypeKeys,
				priorityKeys: action.payload.priorityKeys,
				tourDateFrom: action.payload.tourDateFrom,
				tourDateTill: action.payload.tourDateTill,
				routeKeys: action.payload.routeKeys,
				showConfirmed: action.payload.showConfirmed,
				showNoTourDateOnly: action.payload.showNoTourDateOnly,
				userIds: action.payload.userIds,
				requirementKeys: action.payload.requirementKeys,
				shippingConditionKeys: action.payload.shippingConditionKeys
			}
		}

		case actionTypes.FILTER_CLEAR: {
			return {
				...state,
				contactDateBefore: null,
				contactStatusKeys: [],
				customerLanguageKeys: [],
				orderTypeKeys: [],
				planningTypeKeys: [],
				priorityKeys: [],
				tourDateFrom: null,
				tourDateTill: null,
				routeKeys: [],
				showConfirmed: false,
				showNoTourDateOnly: false,
				userIds: [],
				requirementKeys: [],
				shippingConditionKeys: []
			}
		}

		case actionTypes.SEARCH_IN_WEEK: {
			return {
				...state,
				query: action.payload.query
			}
		}

		default: {
			return state
		}
	}
}

// The search reducer is used to search in all orders (not just the ones in the selected week)
const initialSearchState = {
	isLoading: false,
	orders: [],
	query: ''
}

const searchReducer = (state = initialSearchState, action) => {
	switch (action.type) {
		case actionTypes.SEARCH_REQUEST: {
			return {
				isLoading: true,
				orders: [],
				query: action.payload.query
			}
		}

		case actionTypes.SEARCH_FAILURE: {
			return initialSearchState
		}

		case actionTypes.SEARCH_SUCCESS: {
			return {
				...state,
				isLoading: false,
				orders: action.payload.orders
			}
		}

		case actionTypes.SEARCH_CLEAR: {
			return initialSearchState
		}

		default: {
			return state
		}
	}
}

const initialSelectedDateState = () =>
	moment.tz('Europe/Amsterdam').startOf('day').format()

const selectedDateReducer = (state = initialSelectedDateState(), action) => {
	switch (action.type) {
		case actionTypes.SELECT_DATE_CURRENT: {
			return initialSelectedDateState()
		}

		case actionTypes.SELECT_DATE_NEXT: {
			return moment.tz(state, 'Europe/Amsterdam').add(1, 'weeks').format()
		}

		case actionTypes.SELECT_DATE_PREVIOUS: {
			const now = moment.tz('Europe/Amsterdam')
			const previousMoment = moment
				.tz(state, 'Europe/Amsterdam')
				.subtract(1, 'weeks')
			// The selected date must not be in the past
			if (previousMoment.isBefore(now)) {
				return now.format()
			}
			return previousMoment.format()
		}

		case actionTypes.SELECT_WEEK: {
			const now = moment.tz('Europe/Amsterdam')
			const selectedMoment = moment
				.tz('Europe/Amsterdam')
				.year(action.payload.year)
				.isoWeek(action.payload.week)
			// The selected date must not be in the past
			if (selectedMoment.isBefore(now)) {
				return now.format()
			}
			return selectedMoment.format()
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	orders: ordersReducer,
	filter: filterReducer,
	meta: metaReducer,
	search: searchReducer,
	selectedDate: selectedDateReducer
})
