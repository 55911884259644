import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursSelectors from '../../../../redux/tours/selectors'
import Summary from './Summary'

class SummaryContainer extends PureComponent {
	static propTypes = {
		tours: PropTypes.array.isRequired
	}

	render() {
		const { tours } = this.props

		return <Summary tours={tours} />
	}
}

const connector = connect((state) => ({
	tours: toursSelectors.tourListSelector(state.toursState)
}))

export default connector(SummaryContainer)
