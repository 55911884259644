import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Exports from './Exports'

class ExportsContainer extends PureComponent {
	static propTypes = {
		scopes: PropTypes.array.isRequired
	}

	hasAppointmentschedulerExportScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'iam:export.orders')
	}

	hasPlanningExportScope() {
		const { scopes } = this.props
		return scopes.some((scope) => scope === 'iam:export.tours')
	}

	render() {
		return (
			<Exports
				hasAppointmentschedulerExportScope={this.hasAppointmentschedulerExportScope()}
				hasPlanningExportScope={this.hasPlanningExportScope()}
			/>
		)
	}
}

const connector = connect((state) => ({
	scopes:
		state.userState.user && state.userState.user.scopes
			? state.userState.user.scopes
			: []
}))

export default connector(ExportsContainer)
