import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import DriverQualifications from './DriverQualifications'

class DriverQualificationsContainer extends PureComponent {
	static propTypes = {
		driverIds: PropTypes.array.isRequired,
		qualifications: PropTypes.array.isRequired
	}

	render() {
		const { qualifications } = this.props

		return <DriverQualifications qualifications={qualifications} />
	}
}

const connector = connect((state, props) => ({
	qualifications: driversSelectors.qualificationsByDriverIdsSelector(
		state.driversState,
		props.driverIds
	)
}))

export default connector(DriverQualificationsContainer)
