import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import { formatMoney } from '../../../utils/currency'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Costs extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props

		const { costPerHour, costPerKm } = vehicle

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Costs.title')}
				</Typography>
				<Grid container>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Costs.perKm')}
						</Typography>
						<Typography variant="body2">{formatMoney(costPerKm)}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Costs.perHour')}
						</Typography>
						<Typography variant="body2">{formatMoney(costPerHour)}</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(Costs)
