const actionTypes = {
	FETCH: 'vehicleUnavailability/FETCH',
	FETCH_REQUEST: 'vehicleUnavailability/FETCH_REQUEST',
	FETCH_SUCCESS: 'vehicleUnavailability/FETCH_SUCCESS',
	FETCH_FAILURE: 'vehicleUnavailability/FETCH_FAILURE',
	CREATE: 'vehicleUnavailability/CREATE',
	CREATE_REQUEST: 'vehicleUnavailability/CREATE_REQUEST',
	CREATE_SUCCESS: 'vehicleUnavailability/CREATE_SUCCESS',
	CREATE_FAILURE: 'vehicleUnavailability/CREATE_FAILURE',
	REMOVE: 'vehicleUnavailability/REMOVE',
	REMOVE_REQUEST: 'vehicleUnavailability/REMOVE_REQUEST',
	REMOVE_SUCCESS: 'vehicleUnavailability/REMOVE_SUCCESS',
	REMOVE_FAILURE: 'vehicleUnavailability/REMOVE_FAILURE'
}

export default actionTypes
