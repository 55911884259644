const actionTypes = {
	FETCH_OPTIMIZATION: 'manualplanning/FETCH_OPTIMIZATION',
	FETCH_OPTIMIZATION_FAILURE: 'manualplanning/FETCH_OPTIMIZATION_FAILURE',
	FETCH_OPTIMIZATION_REQUEST: 'manualplanning/FETCH_OPTIMIZATION_REQUEST',
	FETCH_OPTIMIZATION_SUCCESS: 'manualplanning/FETCH_OPTIMIZATION_SUCCESS',
	FETCH_REDUCTION: 'manualplanning/FETCH_REDUCTION',
	FETCH_REDUCTION_FAILURE: 'manualplanning/FETCH_REDUCTION_FAILURE',
	FETCH_REDUCTION_REQUEST: 'manualplanning/FETCH_REDUCTION_REQUEST',
	FETCH_REDUCTION_SUCCESS: 'manualplanning/FETCH_REDUCTION_SUCCESS',
	FETCH_VALIDATION: 'manualplanning/FETCH_VALIDATION',
	FETCH_VALIDATION_FAILURE: 'manualplanning/FETCH_VALIDATION_FAILURE',
	FETCH_VALIDATION_REQUEST: 'manualplanning/FETCH_VALIDATION_REQUEST',
	FETCH_VALIDATION_SUCCESS: 'manualplanning/FETCH_VALIDATION_SUCCESS',
	SAVE: 'manualplanning/SAVE',
	SAVE_FAILURE: 'manualplanning/SAVE_FAILURE',
	SAVE_REQUEST: 'manualplanning/SAVE_REQUEST',
	SAVE_SUCCESS: 'manualplanning/SAVE_SUCCESS',
	SOCKET_CLOSE: 'manualplanning/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'manualplanning/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'manualplanning/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'manualplanning/SOCKET_OPEN'
}

export default actionTypes
