import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import formatDuration from '../../../../utils/duration'
import { formatMoney } from '../../../../utils/currency'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16
	},
	summaryItem: {
		minWidth: 100,
		textAlign: 'center'
	}
}

class Summary extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		tours: PropTypes.array.isRequired
	}

	render() {
		const { classes, tours } = this.props

		const distance = tours.reduce((acc, tour) => acc + tour.distance, 0)
		const formattedDistance = `${Math.floor(distance / 1000)} km`

		const cost = tours.reduce((acc, tour) => acc + tour.cost, 0)
		const formattedCost = formatMoney(cost)

		const totalDuration = tours.reduce((acc, tour) => acc + tour.duration, 0)
		const formattedTotalDuration = formatDuration(totalDuration)
		const serviceDuration = tours.reduce(
			(acc, tour) =>
				acc +
				tour.route.reduce(
					(routeAcc, routeItem) => routeAcc + routeItem.servicePeriod,
					0
				),
			0
		)
		const formattedServiceDuration = formatDuration(serviceDuration)
		const travelDuration = totalDuration - serviceDuration
		const formattedTravelDuration = formatDuration(travelDuration)

		return (
			<div className={classes.root}>
				<Grid container justifyContent="space-around">
					<Grid item className={classes.summaryItem}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.count')}
						</Typography>
						<Typography variant="body2" paragraph>
							{tours.length}
						</Typography>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.travelDuration')}
						</Typography>
						<Typography variant="body2" paragraph>
							{formattedTravelDuration}
						</Typography>
					</Grid>
					<Grid item className={classes.summaryItem}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.cost')}
						</Typography>
						<Typography variant="body2" paragraph>
							{formattedCost}
						</Typography>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.serviceDuration')}
						</Typography>
						<Typography variant="body2" paragraph>
							{formattedServiceDuration}
						</Typography>
					</Grid>
					<Grid item className={classes.summaryItem}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.distance')}
						</Typography>
						<Typography variant="body2" paragraph>
							{formattedDistance}
						</Typography>
						<Typography variant="caption" color="primary">
							{i18n.t('app:planning.Tours.Summary.totalDuration')}
						</Typography>
						<Typography variant="body2" paragraph>
							{formattedTotalDuration}
						</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Summary)
