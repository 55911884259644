import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import selectors from '../../../redux/vehiclescustom/selectors'
import actionCreators from '../../../redux/vehiclescustom/actionCreators'
import VehicleEdit from './VehicleEdit'
import { createCustomVehicle } from '../../../utils/vehicles'

class VehicleEditContainer extends PureComponent {
	static propTypes = {
		history: PropTypes.object.isRequired,
		save: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedVehicleId: PropTypes.string.isRequired,
		vehicle: PropTypes.object
	}

	constructor(props) {
		super(props)

		// based on the vehicle we create a custom vehicle
		// this custom vehicle object will contain all changed fields and will be send to the backend
		this.state = {
			customVehicle: props.vehicle
				? createCustomVehicle(props.vehicle, props.selectedMoment)
				: null,
			meta: {
				firstDriverEmpty: false,
				startDepotEmpty: false,
				endDepotEmpty: false,
				planningTypeEmpty: false,
				percentageEmpty: false
			}
		}
	}

	handleChangeDepots = (depots) => {
		const { customVehicle } = this.state

		this.setState({
			customVehicle: {
				...customVehicle,
				startDepot: depots.startDepot,
				endDepot: depots.endDepot,
				startAtDriverHome: depots.startAtDriverHome
			}
		})
	}

	handleChangeDrivers = (drivers) => {
		const { customVehicle } = this.state

		// if one of the drivers is changed, reset startAtDriverHome
		this.setState({
			customVehicle: {
				...customVehicle,
				firstDriver: drivers.firstDriver || 0,
				secondDriver: drivers.secondDriver || 0,
				startAtDriverHome: false
			}
		})
	}

	handleChangePlanning = (planning) => {
		const { customVehicle } = this.state

		// if the planning type is changed, reset startAtDriverHome
		this.setState({
			customVehicle: {
				...customVehicle,
				planningType: planning.planningTypeKey,
				startAtDriverHome: false
			}
		})
	}

	handleChangeProperties = (properties) => {
		const { customVehicle } = this.state

		this.setState({
			customVehicle: {
				...customVehicle,
				maxLoadPercentage: properties.maxLoadPercentage,
				scaffold: properties.scaffold
			}
		})
	}

	handleChangeMaxPeriod = (period) => {
		const { customVehicle } = this.state
		this.setState({
			customVehicle: {
				...customVehicle,
				maxPeriod: period.maxPeriod,
				maxPeriodEnabled: period.maxPeriodEnabled
			}
		})
	}

	handleSave = () => {
		const { history, save } = this.props
		const { customVehicle } = this.state

		if (this.customVehicleIsValid()) {
			save(history, customVehicle)
		}
	}

	customVehicleIsValid = () => {
		const { customVehicle } = this.state
		const {
			endDepot,
			firstDriver,
			maxLoadPercentage,
			planningType,
			startAtDriverHome,
			startDepot,
			maxPeriod,
			maxPeriodEnabled
		} = customVehicle

		// at least the first driver must be selected
		const firstDriverEmpty = !firstDriver

		// start depot is required when not starting at the driver's home
		const startDepotEmpty = !startAtDriverHome && !startDepot

		// end depot is always required
		const endDepotEmpty = !endDepot

		// planning type is always required
		const planningTypeEmpty = !planningType

		// percentage cannot be empty
		const percentageEmpty =
			!maxLoadPercentage || Number.isNaN(maxLoadPercentage)

		// max period is required when max periode enabled is active
		const maxPeriodEmpty = maxPeriodEnabled && !maxPeriod

		this.setState({
			meta: {
				firstDriverEmpty,
				startDepotEmpty,
				endDepotEmpty,
				planningTypeEmpty,
				percentageEmpty,
				maxPeriodEmpty
			}
		})

		return (
			!firstDriverEmpty &&
			!startDepotEmpty &&
			!endDepotEmpty &&
			!planningTypeEmpty &&
			!percentageEmpty &&
			!maxPeriodEmpty
		)
	}

	render() {
		const { selectedMoment, vehicle } = this.props
		const { customVehicle, meta } = this.state

		if (!customVehicle) {
			return <div />
		}

		return (
			<VehicleEdit
				customVehicle={customVehicle}
				meta={meta}
				save={this.handleSave}
				selectedMoment={selectedMoment}
				changeDepots={this.handleChangeDepots}
				changeDrivers={this.handleChangeDrivers}
				changePlanning={this.handleChangePlanning}
				changeProperties={this.handleChangeProperties}
				changeMaxPeriod={this.handleChangeMaxPeriod}
				vehicle={vehicle}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		selectedMoment: selectors.selectedMomentSelector(state.vehiclesState),
		vehicle: selectors.findVehicleByIdSelector(
			state.vehiclesState,
			state.driversState,
			props.selectedVehicleId
		)
	}),
	{
		save: actionCreators.save
	}
)

export default withRouter(connector(VehicleEditContainer))
