import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialUnscheduledOrdersState = []

const unscheduledOrdersReducer = (
	state = initialUnscheduledOrdersState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_FAILURE: {
			return initialUnscheduledOrdersState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.unscheduledOrders
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: true
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	meta: metaReducer,
	unscheduledOrders: unscheduledOrdersReducer
})
