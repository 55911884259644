import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import DriverContainer from './Driver'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Drivers extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props

		const { firstDriver, secondDriver } = vehicle

		if (!firstDriver && !secondDriver) {
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:vehicleplanning.Drivers.title')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:vehicleplanning.Drivers.unknown')}
					</Typography>
				</div>
			)
		}

		let firstDriverItem
		if (firstDriver) {
			firstDriverItem = <DriverContainer driverId={firstDriver} />
		}

		let secondDriverItem
		if (secondDriver) {
			secondDriverItem = <DriverContainer driverId={secondDriver} />
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Drivers.title')}
				</Typography>
				{firstDriverItem}
				{secondDriverItem}
			</div>
		)
	}
}

export default withStyles(styles)(Drivers)
