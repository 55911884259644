import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem, SvgIcon } from '@material-ui/core'
import { PlusFal } from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'
import { days } from '../../../../utils/drivers'

class NewItemButton extends PureComponent {
	static propTypes = {
		selectedMoment: PropTypes.object.isRequired,
		newItem: PropTypes.func.isRequired
	}

	state = {
		menuAnchor: null
	}

	handleNewClick = (event) => {
		this.setState({
			menuAnchor: event.currentTarget
		})
	}

	handleMenuClose = () => {
		this.closeMenu()
	}

	handleItemSelect = (dayMoment) => {
		const { newItem } = this.props
		newItem(dayMoment.unix())
		this.closeMenu()
	}

	closeMenu = () => {
		this.setState({
			menuAnchor: null
		})
	}

	render() {
		const { selectedMoment } = this.props
		const { menuAnchor } = this.state

		const today = moment.tz('Europe/Amsterdam')

		const menuItems = days.reduce((acc, day) => {
			// create a moment for the specific day of the week
			const dayMoment = moment
				.tz(selectedMoment, 'Europe/Amsterdam')
				.isoWeekday(day)

			// if the day is in the past, it is not selectable, skip it
			if (dayMoment.isBefore(today, 'day')) {
				return acc
			}

			acc.push(
				<MenuItem key={day} onClick={() => this.handleItemSelect(dayMoment)}>
					{dayMoment.format('dddd')}
				</MenuItem>
			)
			return acc
		}, [])

		return (
			<div>
				<IconButton color="primary" onClick={this.handleNewClick}>
					<SvgIcon>
						<PlusFal />
					</SvgIcon>
				</IconButton>
				<Menu
					anchorEl={menuAnchor}
					open={Boolean(menuAnchor)}
					onClose={this.handleMenuClose}
				>
					{menuItems}
				</Menu>
			</div>
		)
	}
}

export default NewItemButton
