import { Collapse, Grid, List, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import Placeholder from '../../Shared/Placeholder'
import DayNavigatorContainer from './DayNavigator'
import HeaderButtonsContainer from './HeaderButtons'
import ListContainer from './List'
import PlanningTypeMenuContainer from './PlanningTypeMenu'
import SummaryContainer from './Summary'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	header: {
		display: 'flex',
		paddingTop: 16,
		paddingLeft: 4,
		paddingRight: 4
	},
	contentContainer: {
		flex: 1,
		overflow: 'hidden',
		position: 'relative'
	},
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class TourSelection extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isLoading: PropTypes.bool
	}

	state = {
		showSummary: false
	}

	handleToggleSummary = () => {
		const { showSummary } = this.state
		this.setState({
			showSummary: !showSummary
		})
	}

	render() {
		const { classes, isLoading } = this.props
		const { showSummary } = this.state

		if (isLoading) {
			const placeholders = [...Array(11)].map((row, index) => (
				<Placeholder key={`tourlist-placeholder-${index}`} />
			))
			return (
				<Paper className={classes.root}>
					<div className={classes.contentContainer}>
						<List disablePadding className={classes.content}>
							{placeholders}
						</List>
					</div>
				</Paper>
			)
		}

		const summary = (
			<Collapse in={showSummary}>
				<SummaryContainer />
			</Collapse>
		)

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Grid container>
						<Grid item xs={8}>
							<DayNavigatorContainer />
						</Grid>
						<Grid item xs={4}>
							<HeaderButtonsContainer
								handleToggleSummary={this.handleToggleSummary}
								showSummary={showSummary}
							/>
						</Grid>
					</Grid>
				</div>
				<PlanningTypeMenuContainer />
				{summary}
				<div className={classes.contentContainer}>
					<ListContainer />
				</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(TourSelection)
