import { default as i18n } from 'i18next'
import config from '../config'

const loginUrl = `${config.oeServer}/api/authenticate`
const refreshTokensUrl = `${config.oeServer}/api/refreshtokens/refresh`

const login = async (credentials) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(loginUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify(credentials),
			timeout: 10000
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Login.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		const tokens = await response.json()
		return tokens
	}
}

const refreshTokens = async (refreshToken) => {
	let response
	try {
		const headers = {
			Authorization: `Bearer ${refreshToken}`
		}
		response = await fetch(refreshTokensUrl, {
			method: 'POST',
			headers,
			timeout: 10000
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401 || response.status === 404) {
			throw new Error(i18n.t('app:elements.Api.Generic.invalidRefreshToken'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		const tokens = await response.json()
		return tokens
	}
}

const api = {
	login,
	refreshTokens
}

export default api
