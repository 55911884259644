import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/drivers/actionCreators'
import DriverSearch from './DriverSearch'

class DriverSearchContainer extends PureComponent {
	static propTypes = {
		clearSearch: PropTypes.func.isRequired,
		excludedDrivers: PropTypes.array,
		select: PropTypes.func.isRequired
	}

	handleSelectDriver = (driver) => {
		const { clearSearch, select } = this.props
		select(driver)
		clearSearch()
	}

	render() {
		const { excludedDrivers } = this.props

		return (
			<DriverSearch
				excludedDrivers={excludedDrivers}
				select={this.handleSelectDriver}
			/>
		)
	}
}

const connector = connect(null, {
	clearSearch: actionCreators.clearSearch
})

export default connector(DriverSearchContainer)
