import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import planningTypesActionCreators from '../../../redux/planningtypes/actionCreators'

import PlanningTypesSelection from './PlanningTypesSelection'

class PlanningTypeSelectionContainer extends PureComponent {
	static propTypes = {
		planningTypes: PropTypes.array.isRequired,
		planningTypesLoading: PropTypes.bool,
		selectedPlanningTypeKey: PropTypes.string,
		fetch: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { planningTypes } = this.props
		if (planningTypes.length === 0) {
			this.loadPlanningTypes()
		}
	}

	handleRefresh = () => {
		this.loadPlanningTypes()
	}

	loadPlanningTypes = () => {
		const { planningTypesLoading, fetch } = this.props

		// Fetch planning types if loading not already in progress
		if (!planningTypesLoading) {
			fetch()
		}
	}

	render() {
		const { planningTypesLoading, selectedPlanningTypeKey } = this.props

		return (
			<PlanningTypesSelection
				isLoading={planningTypesLoading}
				selectedPlanningTypeKey={selectedPlanningTypeKey}
				refresh={this.handleRefresh}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		planningTypes: state.planningTypesState.planningTypes,
		planningTypesLoading: state.planningTypesState.meta.isLoading
	}),
	{
		fetch: planningTypesActionCreators.fetch
	}
)

export default connector(PlanningTypeSelectionContainer)
