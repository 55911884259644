import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16
	}
}

class InternalRemark extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const internalRemark = order.internalRemark
			? order.internalRemark
			: i18n.t('app:appointmentscheduler.Order.noInternalRemark')
		const internalRemarkLines = internalRemark
			.split(/\r?\n/)
			.map((internalRemarkLine, key) => (
				<span key={`internalRemark-${key}`}>
					{internalRemarkLine}
					<br />
				</span>
			))

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.internalRemark')}
				</Typography>
				<Typography variant="body2">{internalRemarkLines}</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(InternalRemark)
