import { all, fork, take, put, call } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import apiPostcodes from '../../services/apiPostcodes'
import notificationsActionCreators from '../notifications/actionCreators'

function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(actionCreators.fetchRequest())
		try {
			const postcodes = yield call(apiPostcodes.getAll)
			yield put(actionCreators.fetchSuccess(postcodes))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:postcodeadmin.Postcodes.Error.fetch')
			yield put(
				actionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* create() {
	while (true) {
		const action = yield take(actionTypes.CREATE_POSTCODE)
		yield put(actionCreators.createPostcodeRequest())
		try {
			const { history, postcode } = action.payload
			const newPostcode = yield call(apiPostcodes.create, postcode)
			yield put(actionCreators.createPostcodeSuccess(newPostcode))
			// Trigger navigation in the main window
			history.push('/postcodeadmin')
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:postcodeadmin.Postcode.Error.create')
			yield put(
				actionCreators.createPostcodeFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* remove() {
	while (true) {
		const action = yield take(actionTypes.REMOVE_POSTCODE)
		yield put(actionCreators.removePostcodeRequest())
		try {
			const { id } = action.meta
			yield call(apiPostcodes.remove, id)
			yield put(actionCreators.removePostcodeSuccess(id))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:postcodeadmin.Postcode.Error.remove')
			yield put(
				actionCreators.removePostcodeFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch, create, remove].map((saga) => fork(saga)))
}
