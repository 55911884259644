import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { hours } from '../../../../utils/drivers'

const styleSheet = {
	root: {
		width: 50,
		paddingRight: 8
	},
	label: {
		height: 60
	}
}

class HoursBar extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	render() {
		const { classes } = this.props

		const hourLabels = hours.map((hour) => (
			<Typography
				display="block"
				variant="caption"
				className={classes.label}
				key={`hourlabel-${hour}`}
			>
				{hour}
			</Typography>
		))

		return <div className={classes.root}>{hourLabels}</div>
	}
}

export default withStyles(styleSheet)(HoursBar)
