import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { default as i18n } from 'i18next'

import WeekNavigatorContainer from '../WeekNavigator'
import WeekContentContainer from '../WeekContent'
import NewItemButton from './NewItemButton'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'scroll'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: 16,
		paddingBottom: 8
	},
	subheader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingTop: 8,
		paddingBottom: 16,
		paddingRight: 16
	},
	title: {
		flex: 1
	}
}

class WeekViewer extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driver: PropTypes.object.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		newItem: PropTypes.func.isRequired
	}

	render() {
		const { classes, driver, newItem, selectedMoment } = this.props

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Button
						variant="outlined"
						component={Link}
						to={`/driveradmin/${driver.driverId}`}
					>
						{i18n.t('app:driveradmin.DriverEdit.WeekViewer.close')}
					</Button>
					<Typography
						variant="h6"
						className={classes.title}
						align="center"
					>{`${driver.firstName} ${driver.lastName}`}</Typography>
				</div>
				<div className={classes.subheader}>
					<WeekNavigatorContainer />
					<NewItemButton selectedMoment={selectedMoment} newItem={newItem} />
				</div>
				<WeekContentContainer />
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(WeekViewer)
