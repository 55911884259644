import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		paddingTop: 32
	}
}

class Note extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired
	}

	render() {
		const { classes, appointment } = this.props

		if (appointment.note !== null) {
			const note = appointment.note
				? appointment.note
				: i18n.t('app:appointmentscheduler.Order.noNote')
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.note')}
					</Typography>
					<Typography variant="body2">{note}</Typography>
				</div>
			)
		}

		return null
	}
}

export default withStyles(styleSheet)(Note)
