import moment from 'moment-timezone'
import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import { PLANNING_TYPE_DC_UDEN } from '../../utils/constants'

const initialToursState = []

const toursReducer = (state = initialToursState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ALL_FAILURE: {
			return initialToursState
		}

		case actionTypes.FETCH_ALL_SUCCESS: {
			return action.payload.tours
		}

		case actionTypes.FETCH_REPLACEMENTS_SUCCESS: {
			const { planningTypeKey, fromDate } = action.meta
			return state
				.filter((tour) => {
					if (tour.planningType.key !== planningTypeKey) {
						return true
					}
					if (
						moment
							.tz(tour.dates[0], 'Europe/Amsterdam')
							.isBefore(moment.tz(fromDate, 'Europe/Amsterdam'))
					) {
						return true
					}
					return false
				})
				.concat(action.payload.tours)
		}

		case actionTypes.SOCKET_ADD_TOUR: {
			return state
				.filter((tour) => tour.id !== action.payload.tour.id)
				.concat(action.payload.tour)
		}

		case actionTypes.SOCKET_REMOVE_TOUR: {
			return state.filter((tour) => tour.id !== action.payload.tour.id)
		}

		case actionTypes.SOCKET_REPLACE_TOURS: {
			return state
				.filter((tour) => tour.planningType.key !== action.meta.planningTypeKey)
				.concat(action.payload.tours)
		}

		case actionTypes.SOCKET_UPDATE_TOUR: {
			return state.map((tour) =>
				tour.id !== action.payload.tour.id ? tour : action.payload.tour
			)
		}

		case actionTypes.SOCKET_UPDATE_TOURS: {
			return state.map(
				(tour) =>
					action.payload.tours.find(
						(updatedTour) => updatedTour.id === tour.id
					) || tour
			)
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isConnected: false,
	isLoading: true,
	loadingReplacements: []
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ALL_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_ALL_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_ALL_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_REPLACEMENTS_REQUEST: {
			const { planningTypeKey, fromDate } = action.meta
			return {
				...state,
				loadingReplacements: state.loadingReplacements.concat({
					planningTypeKey,
					fromDate
				})
			}
		}

		case actionTypes.FETCH_REPLACEMENTS_SUCCESS: {
			const { planningTypeKey, fromDate } = action.meta
			return {
				...state,
				loadingReplacements: state.loadingReplacements.filter(
					(loadingReplacementsItem) =>
						loadingReplacementsItem.planningTypeKey !== planningTypeKey &&
						loadingReplacementsItem.fromDate !== fromDate
				)
			}
		}

		case actionTypes.FETCH_REPLACEMENTS_FAILURE: {
			const { planningTypeKey, fromDate } = action.meta
			return {
				...state,
				loadingReplacements: state.loadingReplacements.filter(
					(loadingReplacementsItem) =>
						loadingReplacementsItem.planningTypeKey !== planningTypeKey &&
						loadingReplacementsItem.fromDate !== fromDate
				)
			}
		}

		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		default: {
			return state
		}
	}
}

const initialMetaToursState = []

const metaToursReducer = (state = initialMetaToursState, action) => {
	switch (action.type) {
		case actionTypes.CLEAN_META: {
			return state.filter((tour) => action.meta.tourIds.includes(tour.id))
		}

		case actionTypes.FETCH_ALL_REQUEST: {
			// Reset updating meta when the tours list is fetched
			// This should only happen when the websocket connection was interrupted and we might have missed some updates
			return state.map((tour) => ({
				...tour,
				isUpdating: false,
				errorMessages: null
			}))
		}

		case actionTypes.CONFIRM_REQUEST:
		case actionTypes.FINALIZE_REQUEST:
		case actionTypes.LOCK_REQUEST:
		case actionTypes.TAKEOVERLOCK_REQUEST:
		case actionTypes.UNDO_CONFIRM_REQUEST:
		case actionTypes.UNLOCK_REQUEST: {
			if (state.some((tour) => tour.id === action.meta.id)) {
				return state.map((tour) =>
					tour.id === action.meta.id
						? { ...tour, isUpdating: true, errorMessages: null }
						: tour
				)
			}
			return state.concat({
				id: action.meta.id,
				isUpdating: true,
				errorMessages: null
			})
		}

		case actionTypes.CONFIRM_SUCCESS:
		case actionTypes.FINALIZE_SUCCESS:
		case actionTypes.LOCK_SUCCESS:
		case actionTypes.TAKEOVERLOCK_SUCCESS:
		case actionTypes.UNDO_CONFIRM_SUCCESS:
		case actionTypes.UNLOCK_SUCCESS: {
			if (state.some((tour) => tour.id === action.meta.id)) {
				return state.map((tour) =>
					tour.id === action.meta.id ? { ...tour, isUpdating: false } : tour
				)
			}
			return state.concat({ id: action.meta.id, isUpdating: false })
		}

		case actionTypes.CONFIRM_FAILURE:
		case actionTypes.FINALIZE_FAILURE:
		case actionTypes.LOCK_FAILURE:
		case actionTypes.TAKEOVERLOCK_FAILURE:
		case actionTypes.UNDO_CONFIRM_FAILURE:
		case actionTypes.UNLOCK_FAILURE: {
			if (state.some((tour) => tour.id === action.meta.id)) {
				return state.map((tour) =>
					tour.id === action.meta.id
						? {
								...tour,
								isUpdating: false,
								errorMessages: action.payload.errorMessages
						  }
						: tour
				)
			}
			return state.concat({
				id: action.meta.id,
				isUpdating: false,
				errorMessages: action.payload.errorMessages
			})
		}

		case actionTypes.SOCKET_UPDATE_TOUR: {
			if (state.some((tour) => tour.id === action.payload.tour.id)) {
				return state.map((tour) =>
					tour.id === action.payload.tour.id
						? { ...tour, isUpdating: false }
						: tour
				)
			}
			return state.concat({ id: action.payload.tour.id, isUpdating: false })
		}

		case actionTypes.TOUR_DESELECT: {
			if (state.some((tour) => tour.id === action.meta.id)) {
				return state.map((tour) =>
					tour.id === action.meta.id ? { ...tour, selected: false } : tour
				)
			}
			return state.concat({ id: action.meta.id, selected: false })
		}

		case actionTypes.TOUR_DESELECT_ALL: {
			return state.map((tour) =>
				tour.selected ? { ...tour, selected: false } : tour
			)
		}

		case actionTypes.TOUR_SELECT: {
			if (state.some((tour) => tour.id === action.meta.id)) {
				return state.map((tour) =>
					tour.id === action.meta.id ? { ...tour, selected: true } : tour
				)
			}
			return state.concat({ id: action.meta.id, selected: true })
		}

		case actionTypes.TOUR_SELECT_MULTIPLE: {
			let newState = [...state]
			action.meta.ids.forEach((selectedId) => {
				if (newState.some((tour) => tour.id === selectedId)) {
					newState = newState.map((tour) =>
						tour.id === selectedId ? { ...tour, selected: true } : tour
					)
				} else {
					newState.push({ id: selectedId, selected: true })
				}
			})
			return newState
		}

		case actionTypes.SELECT_PLANNING_TYPE: {
			// When the selected planning type changes, we must clear the current selection of tours
			return state.map((tour) =>
				tour.selected ? { ...tour, selected: false } : tour
			)
		}

		case actionTypes.SELECT_DATE_CURRENT:
		case actionTypes.SELECT_DATE_NEXT:
		case actionTypes.SELECT_DATE_PREVIOUS: {
			// When the selected date changes, we must clear the current selection of tours
			return state.map((tour) =>
				tour.selected ? { ...tour, selected: false } : tour
			)
		}

		default: {
			return state
		}
	}
}

const initialSelectedPlanningTypeState = PLANNING_TYPE_DC_UDEN

const selectedPlanningTypeKeyReducer = (
	state = initialSelectedPlanningTypeState,
	action
) => {
	switch (action.type) {
		case actionTypes.SELECT_PLANNING_TYPE: {
			return action.payload.planningTypeKey
		}

		default: {
			return state
		}
	}
}

const initialSelectedDateState = () =>
	moment.tz('Europe/Amsterdam').startOf('day').format()

const selectedDateReducer = (state = initialSelectedDateState(), action) => {
	switch (action.type) {
		case actionTypes.SELECT_DATE_CURRENT: {
			return initialSelectedDateState()
		}

		case actionTypes.SELECT_DATE_NEXT: {
			return moment.tz(state, 'Europe/Amsterdam').add(1, 'days').format()
		}

		case actionTypes.SELECT_DATE_PREVIOUS: {
			return moment.tz(state, 'Europe/Amsterdam').subtract(1, 'days').format()
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	tours: toursReducer,
	meta: metaReducer,
	metaTours: metaToursReducer,
	selectedDate: selectedDateReducer,
	selectedPlanningTypeKey: selectedPlanningTypeKeyReducer
})
