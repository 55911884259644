import { io } from 'socket.io-client'
import { default as i18n } from 'i18next'
import { readAccessToken } from '../utils/auth'
import actionCreators from '../redux/manualplannings/actionCreators'
import notificationsActionCreators from '../redux/notifications/actionCreators'
import config from '../config'

const { webSocketUrl } = config

class ManualPlanningsSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/manualplanning`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('optimization_result', this.handleOptimizationResult)
			this.socket.on('optimization_error', this.handleOptimizationError)
			this.socket.on('reduction_result', this.handleReductionResult)
			this.socket.on('reduction_error', this.handleReductionError)
			this.socket.on('validation_result', this.handleValidationResult)
			this.socket.on('validation_error', this.handleValidationError)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleOptimizationResult = ({ id, tours, unscheduledOrderKeys }) => {
		this.store.dispatch(
			actionCreators.fetchOptimizationSuccess(id, tours, unscheduledOrderKeys)
		)
	}

	handleOptimizationError = ({ id, message }) => {
		const techMessage = message || i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Manual.Error.fetchOptimization')
		this.store.dispatch(
			actionCreators.fetchOptimizationFailure(id, {
				userMessage,
				techMessage
			})
		)
		this.store.dispatch(
			notificationsActionCreators.addNotification(userMessage)
		)
	}

	handleReductionResult = ({ id, tours, unscheduledOrderKeys }) => {
		this.store.dispatch(
			actionCreators.fetchReductionSuccess(id, tours, unscheduledOrderKeys)
		)
	}

	handleReductionError = ({ id, message }) => {
		const techMessage = message || i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Manual.Error.fetchReduction')
		this.store.dispatch(
			actionCreators.fetchReductionFailure(id, {
				userMessage,
				techMessage
			})
		)
		this.store.dispatch(
			notificationsActionCreators.addNotification(userMessage)
		)
	}

	handleValidationResult = ({ id, tours }) => {
		this.store.dispatch(actionCreators.fetchValidationSuccess(id, tours))
	}

	handleValidationError = ({ id, message }) => {
		const techMessage = message || i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Manual.Error.fetchValidation')
		this.store.dispatch(
			actionCreators.fetchValidationFailure(id, {
				userMessage,
				techMessage
			})
		)
		this.store.dispatch(
			notificationsActionCreators.addNotification(userMessage)
		)
	}

	getOptimization = (id, tours, startAtDepot) => {
		this.store.dispatch(actionCreators.fetchOptimizationRequest(id, tours))
		this.socket.emit('optimization_request', { id, tours, startAtDepot })
	}

	getReduction = (id, tours, startAtDepot) => {
		this.store.dispatch(actionCreators.fetchReductionRequest(id, tours))
		this.socket.emit('reduction_request', { id, tours, startAtDepot })
	}

	getValidation = (id, tours, startAtDepot) => {
		this.store.dispatch(actionCreators.fetchValidationRequest(id, tours))
		this.socket.emit('validation_request', { id, tours, startAtDepot })
	}
}

const manualPlanningsSocket = new ManualPlanningsSocket()

export default manualPlanningsSocket
