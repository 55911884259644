import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import { SvgIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SearchFal } from '@oliverit/react-fontawesome'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		})
	},
	empty: {
		textAlign: 'center'
	},
	search: {
		paddingTop: 80,
		paddingBottom: 30
	},
	searchIcon: {
		fontSize: '80px'
	}
})

class ListEmpty extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	render() {
		const { classes } = this.props

		return (
			<div className={classes.root}>
				<div className={classes.empty}>
					<div className={classes.search}>
						<SvgIcon color="disabled" className={classes.searchIcon}>
							<SearchFal />
						</SvgIcon>
					</div>
					<Typography variant="subtitle1">
						{i18n.t('app:vehicleplanning.DriverSearch.empty')}
					</Typography>
					<Typography variant="caption">
						{i18n.t('app:vehicleplanning.DriverSearch.emptyDescription')}
					</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(ListEmpty)
