import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import EmployeeNumber from './EmployeeNumber'
import Type from './Type'
import Address from './Address'
import QualificationList from './QualificationList'

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		display: 'flex',
		...theme.mixins.gutters({
			paddingBottom: 16,
			paddingTop: 16
		})
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		overflowY: 'auto',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	columnLeft: {
		width: '55%',
		height: '100%'
	},
	columnRight: {
		width: '45%',
		height: '100%'
	},
	title: {
		flex: 1
	}
})

class Driver extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driver: PropTypes.object
	}

	render() {
		const { classes, driver } = this.props

		if (!driver) {
			return <div />
		}

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h5" className={classes.title}>
						{driver.firstName} {driver.lastName}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						component={Link}
						to={`/driveradmin/${driver.driverId}/edit`}
					>
						{i18n.t('app:driveradmin.Driver.edit')}
					</Button>
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<div className={classes.columnLeft}>
							<EmployeeNumber number={driver.driverId} />
							<Type type={driver.driverType} />
							<Address driver={driver} />
						</div>
						<div className={classes.columnRight}>
							<QualificationList qualifications={driver.qualifications} />
						</div>
					</div>
				</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Driver)
