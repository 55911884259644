const SECONDS_IN_ONE_MINUTE = 60
const SECONDS_IN_ONE_HOUR = SECONDS_IN_ONE_MINUTE * 60
const SECONDS_IN_ONE_DAY = SECONDS_IN_ONE_HOUR * 24
const METERS_IN_ONE_KM = 1000

const formatPlanningParams = (planningParams) => {
	// no need to check if this structure exists, it is defaulted serverside
	const {
		standardParams: {
			restrictions: { tourRestrictions }
		}
	} = planningParams

	// max period is returend by the server in seconds, we convert it to days/hours/minutes
	const maxPeriodDays = tourRestrictions.maxPeriod
		? Math.floor(tourRestrictions.maxPeriod / SECONDS_IN_ONE_DAY)
		: 0
	const maxPeriodHours = tourRestrictions.maxPeriod
		? Math.floor(
				(tourRestrictions.maxPeriod - maxPeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_HOUR
		  )
		: 0
	const maxPeriodMinutes = tourRestrictions.maxPeriod
		? Math.floor(
				(tourRestrictions.maxPeriod -
					maxPeriodHours * SECONDS_IN_ONE_HOUR -
					maxPeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_MINUTE
		  )
		: 0

	// max driving period is returend by the server in seconds, we convert it to hours/minutes
	const maxDrivingPeriodDays = tourRestrictions.maxDrivingPeriod
		? Math.floor(tourRestrictions.maxDrivingPeriod / SECONDS_IN_ONE_DAY)
		: 0
	const maxDrivingPeriodHours = tourRestrictions.maxDrivingPeriod
		? Math.floor(
				(tourRestrictions.maxDrivingPeriod -
					maxDrivingPeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_HOUR
		  )
		: 0
	const maxDrivingPeriodMinutes = tourRestrictions.maxDrivingPeriod
		? Math.floor(
				(tourRestrictions.maxDrivingPeriod -
					maxDrivingPeriodHours * SECONDS_IN_ONE_HOUR -
					maxDrivingPeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_MINUTE
		  )
		: 0

	// max service period is returend by the server in seconds, we convert it to hours/minutes
	const maxServicePeriodDays = tourRestrictions.maxServicePeriod
		? Math.floor(tourRestrictions.maxServicePeriod / SECONDS_IN_ONE_DAY)
		: 0
	const maxServicePeriodHours = tourRestrictions.maxServicePeriod
		? Math.floor(
				(tourRestrictions.maxServicePeriod -
					maxServicePeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_HOUR
		  )
		: 0
	const maxServicePeriodMinutes = tourRestrictions.maxServicePeriod
		? Math.floor(
				(tourRestrictions.maxServicePeriod -
					maxServicePeriodHours * SECONDS_IN_ONE_HOUR -
					maxServicePeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_MINUTE
		  )
		: 0

	// max distance is returned by the server in meters, we convert it to kilometers
	const maxDistance = tourRestrictions.maxDistanceBetweenTransportPoints
		? tourRestrictions.maxDistanceBetweenTransportPoints / METERS_IN_ONE_KM
		: 0

	const maxPeriodEnabled = tourRestrictions.maxPeriodEnabled
	const maxDrivingPeriodEnabled = tourRestrictions.maxDrivingPeriodEnabled
	const maxDistanceBetweenTransportPointsEnabled =
		tourRestrictions.maxDistanceBetweenTransportPointsEnabled
	const maxServicePeriodEnabled = tourRestrictions.maxServicePeriodEnabled

	const plannableDaysWithoutPriority =
		planningParams.plannableDaysWithoutPriority
			? planningParams.plannableDaysWithoutPriority
			: 1
	const plannableDaysMaximum = planningParams.plannableDaysMaximum
		? planningParams.plannableDaysMaximum
		: 1
	const plannableDaysMinimum = planningParams.plannableDaysMinimum
		? planningParams.plannableDaysMinimum
		: 1

	const returnVolume = planningParams.returnVolumeFactor
		? planningParams.returnVolumeFactor
		: 0
	const startAtDepot = planningParams.startAtDepot
		? planningParams.startAtDepot
		: false
	const appointmentRequired = planningParams.appointmentRequired
		? planningParams.appointmentRequired
		: false

	const dateProposalDirectVelocity = planningParams.dateProposalDirectVelocity
	const prioritizedOrderRequirements =
		planningParams.prioritizedOrderRequirements

	const drivingPeriodFactors = planningParams.drivingPeriodFactors || []

	const serviceOrdersPriority = planningParams.serviceOrdersPriority || 2

	return {
		dateProposalDirectVelocity,
		maxPeriodDays,
		maxPeriodHours,
		maxPeriodMinutes,
		maxPeriodEnabled,
		maxDistance,
		maxDistanceBetweenTransportPointsEnabled,
		maxDrivingPeriodHours,
		maxDrivingPeriodMinutes,
		maxDrivingPeriodEnabled,
		maxServicePeriodHours,
		maxServicePeriodMinutes,
		maxServicePeriodEnabled,
		plannableDaysWithoutPriority,
		plannableDaysMaximum,
		plannableDaysMinimum,
		returnVolume,
		startAtDepot,
		appointmentRequired,
		prioritizedOrderRequirements,
		drivingPeriodFactors,
		serviceOrdersPriority
	}
}

const formatPlanningParamsForApi = (planningParams) => {
	const maxPeriod =
		planningParams.maxPeriodDays * SECONDS_IN_ONE_DAY +
		planningParams.maxPeriodHours * SECONDS_IN_ONE_HOUR +
		planningParams.maxPeriodMinutes * SECONDS_IN_ONE_MINUTE
	const maxDrivingPeriod =
		planningParams.maxDrivingPeriodHours * SECONDS_IN_ONE_HOUR +
		planningParams.maxDrivingPeriodMinutes * SECONDS_IN_ONE_MINUTE
	const maxServicePeriod =
		planningParams.maxServicePeriodHours * SECONDS_IN_ONE_HOUR +
		planningParams.maxServicePeriodMinutes * SECONDS_IN_ONE_MINUTE
	const maxDistanceBetweenTransportPoints =
		planningParams.maxDistance * METERS_IN_ONE_KM

	return {
		standardParams: {
			restrictions: {
				tourRestrictions: {
					maxPeriod,
					maxPeriodEnabled: planningParams.maxPeriodEnabled,
					maxDistanceBetweenTransportPoints,
					maxDistanceBetweenTransportPointsEnabled:
						planningParams.maxDistanceBetweenTransportPointsEnabled,
					maxDrivingPeriod,
					maxDrivingPeriodEnabled: planningParams.maxDrivingPeriodEnabled,
					maxServicePeriod,
					maxServicePeriodEnabled: planningParams.maxServicePeriodEnabled
				}
			}
		},
		plannableDaysWithoutPriority: planningParams.plannableDaysWithoutPriority,
		plannableDaysMaximum: planningParams.plannableDaysMaximum,
		plannableDaysMinimum: planningParams.plannableDaysMinimum,
		returnVolumeFactor: planningParams.returnVolume,
		startAtDepot: planningParams.startAtDepot,
		appointmentRequired: planningParams.appointmentRequired,
		dateProposalDirectVelocity: planningParams.dateProposalDirectVelocity,
		prioritizedOrderRequirements: planningParams.prioritizedOrderRequirements,
		drivingPeriodFactors: planningParams.drivingPeriodFactors,
		serviceOrdersPriority: planningParams.serviceOrdersPriority
	}
}

export { formatPlanningParams, formatPlanningParamsForApi }
