import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DriverAdmin from './DriverAdmin'

class DriverAdminContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired
	}

	render() {
		const { match } = this.props

		const showEdit = Boolean(match.params.edit)

		return (
			<DriverAdmin
				selectedDriverId={match.params.driverId}
				showEdit={showEdit}
			/>
		)
	}
}

export default DriverAdminContainer
