import { createSelector } from 'reselect'

import localizedDescription from '../../utils/localizedDescription'

const planningTypesSelector = (state) => state.planningTypes

const compareByDescription = (a, b) => {
	const descriptionA = localizedDescription(a.descriptions)
	const descriptionB = localizedDescription(b.descriptions)

	return descriptionA.localeCompare(descriptionB)
}

// Return the order with the specified id
const findPlanningTypeByKeySelector = createSelector(
	[planningTypesSelector, (state, planningTypeKey) => planningTypeKey],
	(planningTypes, planningTypeKey) =>
		planningTypes.find(
			(planningType) => planningType.planningTypeKey === planningTypeKey
		)
)

const availablePlanningTypesSelector = createSelector(
	[planningTypesSelector],
	(planningTypes) => planningTypes.sort(compareByDescription)
)

const selectors = {
	availablePlanningTypesSelector,
	findPlanningTypeByKeySelector
}

export default selectors
