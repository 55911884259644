import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { t } from 'i18next'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			display: 'flex',
			alignItems: 'center',
			paddingBottom: 16,
			paddingTop: 16,
			flex: 1
		})
	},
	searchInput: {
		flex: 1,
		marginRight: 16
	}
})

class Search extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		search: PropTypes.func.isRequired,
		searchQuery: PropTypes.string.isRequired
	}

	state = {
		searchQuery: this.props.searchQuery
	}

	handleSearchChange = (event) => {
		const { search } = this.props
		const searchQuery = event.target.value
		this.setState({
			searchQuery
		})
		search(searchQuery)
	}

	render() {
		const { classes } = this.props
		const { searchQuery } = this.state

		return (
			<div className={classes.root}>
				<Input
					className={classes.searchInput}
					onChange={this.handleSearchChange}
					placeholder={t('app:vehicleplanning.Vehicles.search')}
					value={searchQuery}
				/>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Search)
