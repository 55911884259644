import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	loading: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16,
		textAlign: 'center'
	},
	loadingText: {
		marginTop: 16
	}
}

class Loading extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		message: PropTypes.string.isRequired,
		paper: PropTypes.bool
	}

	render() {
		const { classes, message, paper } = this.props

		if (paper) {
			return (
				<Paper className={classes.loading}>
					<CircularProgress size={50} />
					<Typography variant="body2" className={classes.loadingText}>
						{message}
					</Typography>
				</Paper>
			)
		}
		return (
			<div className={classes.loading}>
				<CircularProgress size={50} />
				<Typography variant="body2" className={classes.loadingText}>
					{message}
				</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Loading)
