import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import actionCreators from '../redux/unavailableproposaldates/actionCreators'
import config from '../config'

const { webSocketUrl } = config

class UnavailableProposalDatesSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/unavailableproposaldates`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('unavailableproposaldate_created', this.handleCreated)
			this.socket.on('unavailableproposaldate_deleted', this.handleDeleted)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
		// Fetch unavailable proposal dates when the socket has connected
		this.store.dispatch(actionCreators.fetch())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleCreated = (unavailableProposalDate) => {
		this.store.dispatch(actionCreators.addBySocket(unavailableProposalDate))
	}

	handleDeleted = (unavailableProposalDate) => {
		this.store.dispatch(actionCreators.removeBySocket(unavailableProposalDate))
	}
}

const unavailableProposalDatesSocket = new UnavailableProposalDatesSocket()

export default unavailableProposalDatesSocket
