// Selectors in this file are specifically made to be used in sagas with the `select` effect.
// This means they must not use the `createSelector` function from the reselect module!

const getOrder = (state) => state.orderState.order

const selectors = {
	getOrder
}

export default selectors
