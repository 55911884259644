import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../redux/drivers/selectors'
import driversActionCreators from '../../../redux/drivers/actionCreators'

import DriverSelection from './DriverSelection'

class DriverSelectionContainer extends PureComponent {
	static propTypes = {
		drivers: PropTypes.array.isRequired,
		driversLoading: PropTypes.bool,
		fetch: PropTypes.func.isRequired,
		selectedDriverId: PropTypes.string
	}

	componentDidMount() {
		const { drivers } = this.props
		if (drivers.length === 0) {
			this.loadDrivers()
		}
	}

	handleRefresh = () => {
		this.loadDrivers()
	}

	loadDrivers = () => {
		const { driversLoading, fetch } = this.props

		// fetch drivers if loading not already in progress
		if (!driversLoading) {
			fetch()
		}
	}

	render() {
		const { driversLoading, selectedDriverId } = this.props

		return (
			<DriverSelection
				isLoading={driversLoading}
				selectedDriverId={selectedDriverId}
				refresh={this.handleRefresh}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		drivers: driversSelectors.driversListSelector(state.driversState),
		driversLoading: state.driversState.meta.isLoading
	}),
	{
		fetch: driversActionCreators.fetch
	}
)

export default connector(DriverSelectionContainer)
