import { all, fork } from 'redux-saga/effects'
import authSaga from './auth/saga'
import dateProposalSaga from './dateproposal/saga'
import depotsSaga from './depots/saga'
import driversSaga from './drivers/saga'
import excludedServiceDatesSaga from './excludedservicedates/saga'
import manualPlanningsSaga from './manualplannings/saga'
import orderSaga from './order/saga'
import ordersSaga from './orders/saga'
import planningsSaga from './plannings/saga'
import planningTypesSaga from './planningtypes/saga'
import postcodesSaga from './postcodes/saga'
import toursSaga from './tours/saga'
import unavailableProposalDatesSaga from './unavailableproposaldates/saga'
import unscheduledOrdersSaga from './unscheduledorders/saga'
import userSaga from './user/saga'
import vehicleUnavailabilitySaga from './vehicleUnavailability/saga'
import vehiclesSaga from './vehiclescustom/saga'

function* rootSaga() {
	yield all(
		[
			authSaga,
			dateProposalSaga,
			depotsSaga,
			driversSaga,
			excludedServiceDatesSaga,
			manualPlanningsSaga,
			orderSaga,
			ordersSaga,
			planningTypesSaga,
			planningsSaga,
			postcodesSaga,
			toursSaga,
			unavailableProposalDatesSaga,
			unscheduledOrdersSaga,
			userSaga,
			vehicleUnavailabilitySaga,
			vehiclesSaga
		].map((saga) => fork(saga))
	)
}

export default rootSaga
