import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversActionCreators from '../../../../redux/drivers/actionCreators'
import DayEntry from './DayEntry'

class DayEntryContainer extends PureComponent {
	static propTypes = {
		from: PropTypes.number.isRequired,
		till: PropTypes.number.isRequired,
		selectedAvailabilityItem: PropTypes.object,
		select: PropTypes.func.isRequired
	}

	isSelected = () => {
		const { from, till, selectedAvailabilityItem } = this.props

		if (!selectedAvailabilityItem) {
			return false
		}

		// check if this availability item is selected, the from and till timestamps must be the same
		return (
			selectedAvailabilityItem.from === from &&
			selectedAvailabilityItem.till === till
		)
	}

	render() {
		const { from, till, select } = this.props

		return (
			<DayEntry
				from={from}
				till={till}
				select={select}
				selected={this.isSelected()}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		selectedAvailabilityItem: state.driversState.selectedAvailabilityItem
	}),
	{
		select: driversActionCreators.selectAvailabilityItem
	}
)

export default connector(DayEntryContainer)
