import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../../redux/drivers/selectors'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		drivers: PropTypes.array.isRequired,
		excluded: PropTypes.array,
		select: PropTypes.func.isRequired
	}

	render() {
		const { drivers, select } = this.props

		return <List drivers={drivers} select={select} />
	}
}

const connector = connect((state, props) => ({
	drivers: selectors.driversSearchSelector(state.driversState, props.excluded)
}))

export default connector(ListContainer)
