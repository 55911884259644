import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initalPlanningTypesState = []

const planningTypesReducer = (state = initalPlanningTypesState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SUCCESS: {
			return action.payload.planningTypes
		}

		case actionTypes.FETCH_FAILURE: {
			return initalPlanningTypesState
		}

		case actionTypes.UPDATE_SUCCESS: {
			const { planningType: updatedPlanningType } = action.payload
			return state.map((planningType) =>
				planningType.planningTypeKey !== updatedPlanningType.planningTypeKey
					? planningType
					: updatedPlanningType
			)
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default:
			return state
	}
}

export default combineReducers({
	planningTypes: planningTypesReducer,
	meta: metaReducer
})
