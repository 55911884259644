import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Switch
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { scaffoldAnswers } from '../../../../utils/callscript'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	confirmButton: {
		marginTop: 16
	}
}

class Scaffold extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		setCanScaffoldBeUsed: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		const current = scaffoldAnswers(props.appointment)
		const previous = scaffoldAnswers(props.order.appointment)
		const canScaffoldBeUsed = current || previous
		if (canScaffoldBeUsed) {
			this.state = {
				...canScaffoldBeUsed
			}
		} else {
			this.state = {
				canBePlaced: true,
				fitsThroughWindow: true
			}
		}
	}

	handleToggleFitsThroughWindow = () => {
		const { fitsThroughWindow } = this.state
		this.setState({
			fitsThroughWindow: !fitsThroughWindow
		})
	}

	handleToggleCanBePlaced = () => {
		const { canBePlaced } = this.state
		this.setState({
			canBePlaced: !canBePlaced
		})
	}

	handleConfirm = () => {
		const { setCanScaffoldBeUsed } = this.props
		const { fitsThroughWindow, canBePlaced } = this.state
		setCanScaffoldBeUsed({
			canBePlaced,
			fitsThroughWindow
		})
	}

	render() {
		const { classes } = this.props
		const { fitsThroughWindow, canBePlaced } = this.state

		let canBePlacedInput
		if (fitsThroughWindow) {
			canBePlacedInput = (
				<ListItem button disableGutters onClick={this.handleToggleCanBePlaced}>
					<ListItemText
						primary={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryScaffold.canBePlaced'
						)}
						primaryTypographyProps={{ variant: 'body2' }}
					/>
					<ListItemSecondaryAction>
						<Switch
							checked={canBePlaced}
							color="primary"
							onChange={this.handleToggleCanBePlaced}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			)
		}

		return (
			<div className={classes.root}>
				<List disablePadding>
					<ListItem
						button
						disableGutters
						onClick={this.handleToggleFitsThroughWindow}
					>
						<ListItemText
							primary={i18n.t(
								'app:appointmentscheduler.Order.Edit.DeliveryScaffold.fitsThroughWindow'
							)}
							primaryTypographyProps={{ variant: 'body2' }}
						/>
						<ListItemSecondaryAction>
							<Switch
								checked={fitsThroughWindow}
								color="primary"
								onChange={this.handleToggleFitsThroughWindow}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					{canBePlacedInput}
				</List>
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Scaffold)
