import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import localizedDescription from '../../../utils/localizedDescription'

const styleSheet = {
	secondary: {
		display: 'flex',
		alignItems: 'baseline'
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	}
}

class MaterialListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isServiceType: PropTypes.bool,
		material: PropTypes.object.isRequired
	}

	render() {
		const { classes, isServiceType, material } = this.props

		let itemType
		if (isServiceType) {
			itemType = (
				<>
					<Typography variant="body2" className={classes.separator} />
					<Typography variant="body2" color="textSecondary">
						{localizedDescription(material.itemType.descriptions)}
					</Typography>
				</>
			)
		}
		const secondary = (
			<div className={classes.secondary}>
				<Typography variant="body2" color="primary">{`${
					material.quantity.value
				} ${localizedDescription(material.quantity.descriptions)}`}</Typography>
				{itemType}
			</div>
		)
		return (
			<ListItem>
				<ListItemText
					disableTypography
					primary={
						<Typography variant="body2">
							{localizedDescription(material.material.descriptions)}
						</Typography>
					}
					secondary={secondary}
				/>
			</ListItem>
		)
	}
}

export default withStyles(styleSheet)(MaterialListItem)
