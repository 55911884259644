import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styleSheet = {
	header: {
		paddingTop: 16,
		paddingLeft: 16,
		paddingRight: 16
	}
}

class ContactHistory extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const historyItems = [...order.attachments]
			.sort(
				(a, b) =>
					moment.tz(b.createdAt, 'Europe/Amsterdam').unix() -
					moment.tz(a.createdAt, 'Europe/Amsterdam').unix()
			)
			.map((attachment, index) => {
				const createdAt = moment
					.tz(attachment.createdAt, 'Europe/Amsterdam')
					.format('D MMMM Y')
				const createdBy = attachment.fullName
					? attachment.fullName
					: i18n.t('app:appointmentscheduler.Order.unknownUser')
				const secondary = `${createdAt} door ${createdBy}`
				return (
					<ListItem key={`attachment-${index}`}>
						<ListItemText
							primary={attachment.subject}
							secondary={secondary}
							primaryTypographyProps={{ variant: 'body2' }}
						/>
					</ListItem>
				)
			})

		return (
			<>
				<div className={classes.header}>
					<Typography variant="h6">
						{i18n.t('app:appointmentscheduler.Order.history')}
					</Typography>
				</div>
				<List>{historyItems}</List>
			</>
		)
	}
}

export default withStyles(styleSheet)(ContactHistory)
