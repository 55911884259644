import { all, fork, take, put, call } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import actionTypes from './actionTypes'
import driversActionCreators from './actionCreators'
import apiDrivers from '../../services/apiDrivers'
import notificationsActionCreators from '../notifications/actionCreators'

function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(driversActionCreators.fetchRequest())
		try {
			const drivers = yield call(apiDrivers.getAll)
			yield put(driversActionCreators.fetchSuccess(drivers))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:driveradmin.Drivers.Error.fetch')
			yield put(
				driversActionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* fetchAvailability() {
	while (true) {
		const action = yield take(actionTypes.FETCH_AVAILABILITY)
		yield put(driversActionCreators.fetchAvailabilityRequest())
		try {
			const { id, year, week } = action.meta
			const availability = yield call(
				apiDrivers.getAvailability,
				id,
				year,
				week
			)
			yield put(driversActionCreators.fetchAvailabilitySuccess(availability))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t(
				'app:driveradmin.DriverEdit.Error.fetchAvailability'
			)
			yield put(
				driversActionCreators.fetchAvailabilityFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* saveAvailability() {
	while (true) {
		const action = yield take(actionTypes.SAVE_AVAILABILITY)
		yield put(driversActionCreators.saveAvailabilityRequest())
		try {
			const { id, day } = action.meta
			const { availability } = action.payload
			const updatedAvailability = yield call(
				apiDrivers.saveAvailability,
				id,
				day,
				availability
			)
			yield put(
				driversActionCreators.saveAvailabilitySuccess(day, updatedAvailability)
			)
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t(
				'app:driveradmin.DriverEdit.Error.saveAvailability'
			)
			yield put(
				driversActionCreators.saveAvailabilityFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all(
		[fetch, fetchAvailability, saveAvailability].map((saga) => fork(saga))
	)
}
