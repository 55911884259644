const actionTypes = {
	FETCH: 'orders/FETCH',
	FETCH_FAILURE: 'orders/FETCH_FAILURE',
	FETCH_REQUEST: 'orders/FETCH_REQUEST',
	FETCH_SUCCESS: 'orders/FETCH_SUCCESS',
	FILTER: 'orders/FILTER',
	FILTER_CLEAR: 'orders/FILTER_CLEAR',
	LOCK: 'orders/LOCK',
	SEARCH: 'orders/SEARCH',
	SEARCH_FAILURE: 'orders/SEARCH_FAILURE',
	SEARCH_REQUEST: 'orders/SEARCH_REQUEST',
	SEARCH_SUCCESS: 'orders/SEARCH_SUCCESS',
	SEARCH_CLEAR: 'orders/SEARCH_CLEAR',
	SEARCH_IN_WEEK: 'orders/SEARCH_IN_WEEK',
	SELECT_DATE_CURRENT: 'orders/SELECT_DATE_CURRENT',
	SELECT_DATE_NEXT: 'orders/SELECT_DATE_NEXT',
	SELECT_DATE_PREVIOUS: 'orders/SELECT_DATE_PREVIOUS',
	SELECT_WEEK: 'orders/SELECT_WEEK',
	SOCKET_ADD_ORDER: 'orders/SOCKET_ADD_ORDER',
	SOCKET_CLOSE: 'orders/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'orders/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'orders/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'orders/SOCKET_OPEN',
	SOCKET_REMOVE_ORDER: 'orders/SOCKET_REMOVE_ORDER',
	SOCKET_UPDATE_ORDER: 'orders/SOCKET_UPDATE_ORDER',
	SOCKET_UPDATE_ORDER_TOURDATES: 'orders/SOCKET_UPDATE_ORDER_TOURDATES'
}

export default actionTypes
