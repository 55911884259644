import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 32
		})
	}
})

class EmployeeNumber extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		number: PropTypes.number.isRequired
	}

	render() {
		const { classes, number } = this.props

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:driveradmin.Driver.EmployeeNumber.title')}
				</Typography>
				<Typography variant="body2">{number}</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(EmployeeNumber)
