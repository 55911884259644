import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import actionTypes from './actionTypes'
import apiManualPlannings from '../../services/apiManualPlannings'
import manualPlanningsSocket from '../../sockets/manualPlanningsSocket'
import notificationsActionCreators from '../notifications/actionCreators'
import manualPlanningsActionCreators from './actionCreators'
import toursActionCreators from '../tours/actionCreators'

// Will toggle the date proposal socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(manualPlanningsSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(manualPlanningsSocket.close)
	}
}

// Will fetch an optimization
function* fetchOptimization(action) {
	yield call(
		manualPlanningsSocket.getOptimization,
		action.meta.id,
		action.payload.tours,
		action.payload.startAtDepot
	)
}

// Will handle every fetchOptimization action
function* watchFetchOptimization() {
	yield takeEvery(actionTypes.FETCH_OPTIMIZATION, fetchOptimization)
}

// Will fetch an reduction
function* fetchReduction(action) {
	yield call(
		manualPlanningsSocket.getReduction,
		action.meta.id,
		action.payload.tours,
		action.payload.startAtDepot
	)
}

// Will handle every fetchReduction action
function* watchFetchReduction() {
	yield takeEvery(actionTypes.FETCH_REDUCTION, fetchReduction)
}

// Will fetch an validation
function* fetchValidation(action) {
	yield call(
		manualPlanningsSocket.getValidation,
		action.meta.id,
		action.payload.tours,
		action.payload.startAtDepot
	)
}

// Will handle every fetchValidation action
function* watchFetchValidation() {
	yield takeEvery(actionTypes.FETCH_VALIDATION, fetchValidation)
}

// Will save a manual planning
function* save(action) {
	try {
		// Deselect all tours before saving. This is done because after saving a manual planning,
		// the current selection of tours may no longer be consistent with the selected tour date.
		yield put(toursActionCreators.deselectAll())
		yield call(apiManualPlannings.save, action.payload.manualPlanning)
		yield put(
			manualPlanningsActionCreators.saveSuccess(action.payload.manualPlanning)
		)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Manual.Error.save')
		yield put(
			manualPlanningsActionCreators.saveFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every save action
function* watchSave() {
	yield takeEvery(actionTypes.SAVE, save)
}

export default function* rootSaga() {
	yield all(
		[
			socket,
			watchFetchOptimization,
			watchFetchReduction,
			watchFetchValidation,
			watchSave
		].map((saga) => fork(saga))
	)
}
