import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import ConfirmDialog from '../../../Shared/ConfirmDialog'

const styleSheet = {
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
		paddingTop: 16
	},
	button: {
		marginLeft: 8
	},
	buttons: {
		flex: 1,
		paddingLeft: 8
	},
	selectedText: {
		marginLeft: 8
	}
}

class SelectionToolbar extends PureComponent {
	static propTypes = {
		disableConfirmButton: PropTypes.bool,
		disableFinalizeButton: PropTypes.bool,
		disableSelectAllButton: PropTypes.bool,
		disableStartManualPlanningButton: PropTypes.bool,
		classes: PropTypes.object.isRequired,
		confirm: PropTypes.func.isRequired,
		deselect: PropTypes.func.isRequired,
		finalize: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		selectAll: PropTypes.func.isRequired,
		startManualPlanning: PropTypes.func.isRequired,
		tourCount: PropTypes.number.isRequired
	}

	state = {
		showConfirmDialog: false
	}

	handleOpenConfirmDialog = () => {
		this.setState({
			showConfirmDialog: true
		})
	}

	handleCloseConfirmDialog = () => {
		this.setState({
			showConfirmDialog: false
		})
	}

	handleFinalizeConfirmDialog = () => {
		const { finalize } = this.props
		this.setState({
			showConfirmDialog: false
		})
		finalize()
	}

	render() {
		const {
			disableConfirmButton,
			disableFinalizeButton,
			disableSelectAllButton,
			disableStartManualPlanningButton,
			classes,
			confirm,
			deselect,
			selectAll,
			startManualPlanning,
			tourCount
		} = this.props
		const { showConfirmDialog } = this.state
		let selectedText
		if (tourCount === 0) {
			selectedText = i18n.t('app:planning.Tours.selectedNone')
		} else if (tourCount === 1) {
			selectedText = `${tourCount} ${i18n.t('app:planning.Tours.selectedOne')}`
		} else {
			selectedText = `${tourCount} ${i18n.t('app:planning.Tours.selectedMany')}`
		}

		let deselectButton
		if (tourCount > 0) {
			deselectButton = (
				<Button
					onClick={deselect}
					variant="outlined"
					className={classes.button}
				>
					{i18n.t('app:planning.Tours.deselect')}
				</Button>
			)
		}

		return (
			<Paper className={classes.root}>
				<Typography variant="h6" className={classes.selectedText}>
					{selectedText}
				</Typography>
				<div className={classes.buttons}>
					{deselectButton}
					<Button
						disabled={disableSelectAllButton}
						onClick={selectAll}
						variant="outlined"
						className={classes.button}
					>
						{i18n.t('app:planning.Tours.selectAll')}
					</Button>
				</div>
				<Button
					disabled={disableStartManualPlanningButton}
					variant="outlined"
					color="primary"
					onClick={startManualPlanning}
					className={classes.button}
				>
					{i18n.t('app:planning.Tour.startManualPlanning')}
				</Button>
				<Button
					disabled={disableConfirmButton}
					variant="outlined"
					color="primary"
					onClick={confirm}
					className={classes.button}
				>
					{i18n.t('app:planning.Tour.confirm')}
				</Button>
				<Button
					disabled={disableFinalizeButton}
					variant="outlined"
					color="secondary"
					onClick={this.handleOpenConfirmDialog}
					className={classes.button}
				>
					{i18n.t('app:planning.Tour.finalize')}
				</Button>
				<ConfirmDialog
					isOpen={showConfirmDialog}
					cancel={this.handleCloseConfirmDialog}
					cancelButtonText={i18n.t('app:planning.Tour.SaveDialog.cancel')}
					confirm={this.handleFinalizeConfirmDialog}
					confirmButtonText={i18n.t('app:planning.Tour.SaveDialog.confirm')}
					titleText={i18n.t('app:planning.Tour.SaveDialog.title')}
					descriptionText={i18n.t('app:planning.Tour.SaveDialog.description')}
				/>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(SelectionToolbar)
