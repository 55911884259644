import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Avatar,
	CircularProgress,
	IconButton,
	ListItem as MaterialListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarCheckFal,
	CalendarFal,
	CalendarTimesFal,
	LockAltFal,
	TruckMovingFal
} from '@oliverit/react-fontawesome'
import { orange, green, blue } from '@material-ui/core/colors'
import { default as i18n } from 'i18next'
import formatDuration from '../../../../utils/duration'

const styleSheet = (theme) => ({
	active: {
		backgroundColor: theme.palette.action.selected
	},
	iconOrange: {
		color: orange[500]
	},
	iconGreen: {
		color: green[500]
	},
	iconBlue: {
		color: blue[500]
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		deselect: PropTypes.func.isRequired,
		isOwnUser: PropTypes.bool,
		isSelected: PropTypes.bool,
		metaTour: PropTypes.object,
		number: PropTypes.number.isRequired,
		select: PropTypes.func.isRequired,
		tour: PropTypes.object.isRequired
	}

	handleToggleSelected = () => {
		const { deselect, isSelected, select, tour } = this.props
		if (isSelected) {
			deselect(tour.id)
		} else {
			select(tour.id)
		}
	}

	render() {
		const { classes, tour, isOwnUser, isSelected, metaTour, number } =
			this.props

		const isUpdating = metaTour ? metaTour.isUpdating : false

		let secondaryActionIcon
		if (isUpdating) {
			// Tour is updating
			secondaryActionIcon = <CircularProgress size={20} />
		} else if (tour.isProcessing) {
			// Tour is being finalized
			secondaryActionIcon = <CircularProgress size={20} />
		} else if (tour.lock) {
			// Tour locked
			if (isOwnUser) {
				secondaryActionIcon = (
					<SvgIcon color="primary">
						<LockAltFal />
					</SvgIcon>
				)
			} else {
				secondaryActionIcon = (
					<Tooltip
						title={`${i18n.t('app:planning.Tour.lockedBy')} ${
							tour.lock.firstName
						} ${tour.lock.lastName}`}
						placement="top"
					>
						<SvgIcon color="secondary">
							<LockAltFal />
						</SvgIcon>
					</Tooltip>
				)
			}
		} else if (tour.finalizedAt) {
			// Tour finalized
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconGreen}>
					<TruckMovingFal />
				</SvgIcon>
			)
		} else if (
			tour.confirmedAt &&
			tour.route.length === 0 &&
			tour.confirmedBy &&
			tour.confirmedBy.isSystem
		) {
			// Tour reserved by system
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconBlue}>
					<CalendarTimesFal />
				</SvgIcon>
			)
		} else if (tour.confirmedAt && tour.route.length === 0) {
			// Tour reserved
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconBlue}>
					<CalendarFal />
				</SvgIcon>
			)
		} else if (tour.confirmedAt) {
			// Tour confirmed
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconOrange}>
					<CalendarCheckFal />
				</SvgIcon>
			)
		}

		let secondaryAction
		if (secondaryActionIcon) {
			secondaryAction = (
				<ListItemSecondaryAction>
					<IconButton onClick={this.handleToggleSelected}>
						{secondaryActionIcon}
					</IconButton>
				</ListItemSecondaryAction>
			)
		}

		if (tour.route.length <= 0) {
			// This tour has no route items (this can happen when orders are deleted after the tour was created)
			// It will only show up in the list as empty and cannot be selected
			const primaryEmpty = (
				<Typography color="textSecondary" variant="h6">
					{i18n.t('app:planning.Tours.noOrdersTitle')}
				</Typography>
			)
			const secondaryEmpty = (
				<Typography variant="subtitle1">
					{tour.vehicle.licensePlate || '??????'} &middot;{' '}
					{i18n.t('app:planning.Tours.noOrders')}
				</Typography>
			)
			return (
				<MaterialListItem
					button
					onClick={this.handleToggleSelected}
					className={isSelected ? classes.active : ''}
				>
					<ListItemAvatar>
						<Avatar>{number}</Avatar>
					</ListItemAvatar>
					<ListItemText primary={primaryEmpty} secondary={secondaryEmpty} />
					{secondaryAction}
				</MaterialListItem>
			)
		}

		const firstTransportPoint = tour.route[0]
		const lastTransportPoint = tour.route[tour.route.length - 1]
		const cityFrom =
			firstTransportPoint.customer && firstTransportPoint.customer.city
				? firstTransportPoint.customer.city
				: '??????'
		const cityTo =
			lastTransportPoint.customer && lastTransportPoint.customer.city
				? lastTransportPoint.customer.city
				: '??????'
		const orderCount = tour.route.length
		const orderCountText =
			orderCount === 1
				? i18n.t('app:planning.Tours.order')
				: i18n.t('app:planning.Tours.orders')
		const formattedDuration = formatDuration(tour.duration)

		// Calculate the load volume percentage
		let loadVolumePercentage = 0
		if (
			tour.vehicle.loadVolume &&
			tour.vehicle.loadVolume > 0 &&
			tour.maxLoadVolume &&
			tour.maxLoadVolume > 0
		) {
			loadVolumePercentage = parseInt(
				(tour.maxLoadVolume / tour.vehicle.loadVolume) * 100,
				10
			)
		}

		const primary = (
			<Typography variant="h6">
				{cityFrom} - {cityTo}
			</Typography>
		)

		const secondary = (
			<Typography variant="subtitle1">
				{tour.vehicle.licensePlate || '??????'} &middot; {orderCount}{' '}
				{orderCountText} &middot; {formattedDuration} &middot;{' '}
				{loadVolumePercentage}%
			</Typography>
		)

		return (
			<MaterialListItem
				button
				onClick={this.handleToggleSelected}
				className={isSelected ? classes.active : ''}
			>
				<ListItemAvatar>
					<Avatar>{number}</Avatar>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
				{secondaryAction}
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
