import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Switch
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { stairsAnswers } from '../../../../utils/callscript'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	confirmButton: {
		marginTop: 16
	}
}

class Stairs extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		setCanStairsBeUsed: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		const current = stairsAnswers(props.appointment)
		const previous = stairsAnswers(props.order.appointment)
		const canStairsBeUsed = current || previous
		if (canStairsBeUsed) {
			this.state = {
				...canStairsBeUsed
			}
		} else {
			this.state = {
				isLargeEnough: true,
				isPresent: true
			}
		}
	}

	handleToggleIsPresent = () => {
		const { isPresent } = this.state
		this.setState({
			isPresent: !isPresent
		})
	}

	handleToggleIsLargeEnough = () => {
		const { isLargeEnough } = this.state
		this.setState({
			isLargeEnough: !isLargeEnough
		})
	}

	handleConfirm = () => {
		const { setCanStairsBeUsed } = this.props
		const { isPresent, isLargeEnough } = this.state
		setCanStairsBeUsed({
			isLargeEnough,
			isPresent
		})
	}

	render() {
		const { classes } = this.props
		const { isPresent, isLargeEnough } = this.state

		let isLargeEnoughInput
		if (isPresent) {
			isLargeEnoughInput = (
				<ListItem
					button
					disableGutters
					onClick={this.handleToggleIsLargeEnough}
				>
					<ListItemText
						primary={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryStairs.isLargeEnough'
						)}
						primaryTypographyProps={{ variant: 'body2' }}
					/>
					<ListItemSecondaryAction>
						<Switch
							checked={isLargeEnough}
							color="primary"
							onChange={this.handleToggleIsLargeEnough}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			)
		}

		return (
			<div className={classes.root}>
				<List disablePadding>
					<ListItem button disableGutters onClick={this.handleToggleIsPresent}>
						<ListItemText
							primary={i18n.t(
								'app:appointmentscheduler.Order.Edit.DeliveryStairs.isPresent'
							)}
							primaryTypographyProps={{ variant: 'body2' }}
						/>
						<ListItemSecondaryAction>
							<Switch
								checked={isPresent}
								color="primary"
								onChange={this.handleToggleIsPresent}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					{isLargeEnoughInput}
				</List>
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Stairs)
