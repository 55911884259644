import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import apiExports from '../../services/apiExports'

const styleSheet = {
	root: {
		flexGrow: 1
	},
	section: {
		padding: 16,
		marginBottom: 16
	}
}

class Exports extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		hasAppointmentschedulerExportScope: PropTypes.bool,
		hasPlanningExportScope: PropTypes.bool
	}

	state = {
		exportAppointmentsCSVData: '',
		exportAppointmentsLoading: false,
		exportPlanningCSVData: '',
		exportPlanningLoading: false
	}

	handleExportAppointments = async () => {
		this.setState({
			exportAppointmentsLoading: true
		})
		const exportData = await apiExports.get('afspraken')
		this.setState({
			exportAppointmentsCSVData: exportData,
			exportAppointmentsLoading: false
		})
	}

	handleExportPlanning = async () => {
		this.setState({
			exportPlanningLoading: true
		})
		const exportData = await apiExports.get('ritten')
		this.setState({
			exportPlanningCSVData: exportData,
			exportPlanningLoading: false
		})
	}

	render() {
		const {
			classes,
			hasAppointmentschedulerExportScope,
			hasPlanningExportScope
		} = this.props
		const {
			exportAppointmentsCSVData,
			exportAppointmentsLoading,
			exportPlanningCSVData,
			exportPlanningLoading
		} = this.state

		let exportAppointmentsButton
		if (hasAppointmentschedulerExportScope) {
			if (exportAppointmentsLoading) {
				exportAppointmentsButton = <CircularProgress />
			} else if (exportAppointmentsCSVData) {
				exportAppointmentsButton = (
					<Button
						color="primary"
						download="afspraken.csv"
						href={encodeURI(
							`data:text/csv;charset=utf-8,${exportAppointmentsCSVData}`
						)}
					>
						{i18n.t('app:exports.download')}
					</Button>
				)
			} else {
				exportAppointmentsButton = (
					<Button color="primary" onClick={this.handleExportAppointments}>
						{i18n.t('app:exports.request')}
					</Button>
				)
			}
		}

		let exportPlanningButton
		if (hasPlanningExportScope) {
			if (exportPlanningLoading) {
				exportPlanningButton = <CircularProgress />
			} else if (exportPlanningCSVData) {
				exportPlanningButton = (
					<Button
						color="primary"
						download="ritten.csv"
						href={encodeURI(
							`data:text/csv;charset=utf-8,${exportPlanningCSVData}`
						)}
					>
						{i18n.t('app:exports.download')}
					</Button>
				)
			} else {
				exportPlanningButton = (
					<Button color="primary" onClick={this.handleExportPlanning}>
						{i18n.t('app:exports.request')}
					</Button>
				)
			}
		}

		return (
			<div className={classes.root}>
				<Typography paragraph variant="h4">
					{i18n.t('app:exports.Main.title')}
				</Typography>
				<Paper className={classes.section}>
					<Typography variant="h5" paragraph>
						{i18n.t('app:exports.appointments')}
					</Typography>
					{exportAppointmentsButton}
				</Paper>
				<Paper className={classes.section}>
					<Typography variant="h5" paragraph>
						{i18n.t('app:exports.tours')}
					</Typography>
					{exportPlanningButton}
				</Paper>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Exports)
