import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import HourBlock from './HourBlock'
import DayEntryContainer from '../DayEntry'
import { hours } from '../../../../utils/drivers'

const MONDAY = 1

const styleSheet = {
	root: {
		flex: 1,
		position: 'relative'
	},
	leftBorder: {
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		borderLeftColor: grey[200]
	}
}

class DayViewer extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		dayMoment: PropTypes.object.isRequired,
		availability: PropTypes.array,
		deselect: PropTypes.func.isRequired
	}

	handleHourClick = () => {
		const { deselect } = this.props
		deselect()
	}

	render() {
		const { classes, dayMoment, availability } = this.props

		const hourBlocks = hours.map((hour) => (
			<HourBlock key={`hourblock-${hour}`} click={this.handleHourClick} />
		))

		const dayEntries = availability.map((item) => (
			<DayEntryContainer key={item.from} from={item.from} till={item.till} />
		))

		// if the day is a monday, no left borders must be drawn because monday is the first column in the week grid
		const borderClass =
			dayMoment.isoWeekday() === MONDAY ? null : classes.leftBorder

		return (
			<div className={classes.root}>
				<div className={borderClass}>
					{hourBlocks}
					{dayEntries}
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DayViewer)
