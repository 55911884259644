import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import apiVehiclesCustom from '../../services/apiVehiclesCustom'
import notificationsActionCreators from '../notifications/actionCreators'

function* fetch() {
	while (true) {
		const action = yield take(actionTypes.FETCH)
		yield put(actionCreators.fetchRequest())
		try {
			const { day, month, year } = action.meta
			const vehicles = yield call(apiVehiclesCustom.getByDay, day, month, year)
			yield put(actionCreators.fetchSuccess(vehicles))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleplanning.Vehicles.Error.fetch')
			yield put(
				actionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* save() {
	while (true) {
		const action = yield take(actionTypes.SAVE)
		yield put(actionCreators.saveRequest())
		try {
			const { history, vehicle } = action.payload
			const updatedVehicle = yield call(apiVehiclesCustom.save, vehicle)
			yield put(actionCreators.saveSuccess(updatedVehicle))
			// Trigger navigation in the main window
			history.push(`/vehicleplanning/${updatedVehicle.vehicleId}`)
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleplanning.Vehicle.Error.save')
			yield put(
				actionCreators.saveFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch, save].map((saga) => fork(saga)))
}
