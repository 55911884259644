import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import ConfirmDialog from '../../Shared/ConfirmDialog'
import SaveDialog from '../../Shared/SaveDialog'

const styleSheet = {}

class CancelButton extends PureComponent {
	static propTypes = {
		canOnlyStopWithoutSaving: PropTypes.bool,
		classes: PropTypes.object.isRequired,
		noAnswer: PropTypes.func.isRequired,
		postpone: PropTypes.func.isRequired,
		stop: PropTypes.func.isRequired
	}

	state = {
		endCallMenuAnchorEl: null,
		showConfirmStopDialog: false
	}

	closeEndCallMenu() {
		this.setState({
			endCallMenuAnchorEl: null
		})
	}

	handleOpenEndCallMenu = (event) => {
		this.setState({
			endCallMenuAnchorEl: event.currentTarget
		})
	}

	handleCloseEndCallMenu = () => {
		this.closeEndCallMenu()
	}

	handleNoAnswer = () => {
		const { noAnswer } = this.props
		this.closeEndCallMenu()
		noAnswer()
	}

	handlePostpone = () => {
		const { postpone } = this.props
		this.closeEndCallMenu()
		postpone()
	}

	handleStop = () => {
		this.closeEndCallMenu()
		this.setState({
			showConfirmStopDialog: true
		})
	}

	handleStopCancel = () => {
		this.setState({
			showConfirmStopDialog: false
		})
	}

	handleStopConfirm = () => {
		const { stop } = this.props
		this.setState({
			showConfirmStopDialog: false
		})
		stop({ save: true })
	}

	handleStopReject = () => {
		const { stop } = this.props
		this.setState({
			showConfirmStopDialog: false
		})
		stop({ save: false })
	}

	render() {
		const { canOnlyStopWithoutSaving, classes } = this.props
		const { endCallMenuAnchorEl, showConfirmStopDialog } = this.state

		if (canOnlyStopWithoutSaving) {
			return (
				<div className={classes.root}>
					<Button
						color="secondary"
						variant="outlined"
						onClick={this.handleStop}
					>
						{i18n.t('app:appointmentscheduler.Order.endCall')}
					</Button>
					<ConfirmDialog
						cancel={this.handleStopCancel}
						cancelButtonText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmStopWithoutSaveDialog.cancel'
						)}
						confirm={this.handleStopReject}
						confirmButtonText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmStopWithoutSaveDialog.confirm'
						)}
						descriptionText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmStopWithoutSaveDialog.description'
						)}
						isDestructive
						isOpen={showConfirmStopDialog}
						titleText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmStopWithoutSaveDialog.title'
						)}
					/>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<Button
					color="secondary"
					variant="outlined"
					onClick={this.handleOpenEndCallMenu}
				>
					{i18n.t('app:appointmentscheduler.Order.endCall')}
				</Button>
				<Menu
					anchorEl={endCallMenuAnchorEl}
					open={Boolean(endCallMenuAnchorEl)}
					onClose={this.handleCloseEndCallMenu}
				>
					<MenuItem key="endcall-stopprocessing" onClick={this.handleStop}>
						{i18n.t('app:appointmentscheduler.Order.stopProcessing')}
					</MenuItem>
					<MenuItem key="endcall-noanswer" onClick={this.handleNoAnswer}>
						{i18n.t('app:appointmentscheduler.Order.noAnswer')}
					</MenuItem>
					<MenuItem key="endcall-postpone" onClick={this.handlePostpone}>
						{i18n.t('app:appointmentscheduler.Order.postpone')}
					</MenuItem>
				</Menu>
				<SaveDialog
					cancel={this.handleStopCancel}
					cancelButtonText={i18n.t(
						'app:appointmentscheduler.Order.ConfirmStopDialog.cancel'
					)}
					descriptionText={i18n.t(
						'app:appointmentscheduler.Order.ConfirmStopDialog.description'
					)}
					isOpen={showConfirmStopDialog}
					ignore={this.handleStopReject}
					ignoreButtonText={i18n.t(
						'app:appointmentscheduler.Order.ConfirmStopDialog.ignore'
					)}
					save={this.handleStopConfirm}
					saveButtonText={i18n.t(
						'app:appointmentscheduler.Order.ConfirmStopDialog.save'
					)}
					titleText={i18n.t(
						'app:appointmentscheduler.Order.ConfirmStopDialog.title'
					)}
				/>
			</div>
		)
	}
}

export default withStyles(styleSheet)(CancelButton)
