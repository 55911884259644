const actionTypes = {
	FETCH: 'dateproposal/FETCH',
	FETCH_FAILURE: 'dateproposal/FETCH_FAILURE',
	FETCH_REQUEST: 'dateproposal/FETCH_REQUEST',
	FETCH_SUCCESS: 'dateproposal/FETCH_SUCCESS',
	SOCKET_CLOSE: 'dateproposal/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'dateproposal/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'dateproposal/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'dateproposal/SOCKET_OPEN'
}

export default actionTypes
