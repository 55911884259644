import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import authActionTypes from '../auth/actionTypes'

const initialUserState = null

const userReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_USER_SUCCESS: {
			return action.payload.user
		}

		case authActionTypes.LOGIN_SUCCESS: {
			return action.payload.user
		}

		case authActionTypes.AUTOLOGIN_FAILURE:
		case authActionTypes.LOGIN_FAILURE: {
			return initialUserState
		}

		case authActionTypes.LOGIN_CANCELLED: {
			return initialUserState
		}

		case authActionTypes.LOGOUT: {
			return initialUserState
		}

		default: {
			return state
		}
	}
}

const initialUserMetaState = {
	isLoggingIn: false,
	isAutoLoggingIn: true,
	errorMessages: null,
	autoLoginFailed: false
}

const metaReducer = (state = initialUserMetaState, action) => {
	switch (action.type) {
		case authActionTypes.AUTOLOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				isAutoLoggingIn: true,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.AUTOLOGIN_NOTOKEN: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.AUTOLOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: true,
				errorMessages: null
			}
		}

		case actionTypes.CLEAR_ERROR: {
			return {
				...state,
				errorMessages: null
			}
		}

		case authActionTypes.LOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.LOGIN_SUCCESS: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.LOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case authActionTypes.LOGIN_CANCELLED: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case actionTypes.RESETPASSWORD_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.RESETPASSWORD_SUCCESS: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.LOGOUT: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	user: userReducer,
	meta: metaReducer
})
