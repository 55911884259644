import { Dialog, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import Loading from '../../Shared/Loading'
import Action from './Action'
import Amounts from './Amounts'
import Appointment from './Appointment'
import ContactHistory from './ContactHistory'
import Customer from './Customer'
import Dates from './Dates'
import InternalRemark from './InternalRemark'
import Language from './Language'
import MaterialList from './MaterialList'
import Properties from './Properties'
import Remarks from './Remarks'
import Requirements from './Requirements'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		padding: 16
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	columnLeft: {
		width: '55%',
		height: '100%'
	},
	columnRight: {
		width: '45%',
		height: '100%'
	}
}

class Order extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object,
		isLoading: PropTypes.bool,
		isUpdating: PropTypes.bool,
		start: PropTypes.func.isRequired,
		takeOver: PropTypes.func.isRequired
	}

	state = {
		showAppointment: false
	}

	contentRef = null

	componentDidUpdate(prevProps) {
		const { order } = this.props
		if (this.contentRef && order !== prevProps.order) {
			this.contentRef.scrollTop = 0
		}
		if (order !== prevProps.order) {
			this.setState({
				showAppointment: false
			})
		}
	}

	handleHideAppointment = () => {
		this.setState({
			showAppointment: false
		})
	}

	handleShowAppointment = () => {
		this.setState({
			showAppointment: true
		})
	}

	setContentRef = (ref) => {
		this.contentRef = ref
	}

	render() {
		const { classes, order, isLoading, isUpdating, start, takeOver } =
			this.props
		const { showAppointment } = this.state

		if (isLoading) {
			return (
				<Loading message={i18n.t('app:appointmentscheduler.Order.loading')} />
			)
		}

		if (isUpdating) {
			return (
				<Loading message={i18n.t('app:appointmentscheduler.Order.updating')} />
			)
		}

		if (!order) {
			return null
		}

		const appointmentDialog = (
			<Dialog
				fullWidth
				open={showAppointment}
				onClose={this.handleHideAppointment}
			>
				<Appointment order={order} />
			</Dialog>
		)

		let contactHistory
		if (order.attachments && order.attachments.length > 0) {
			contactHistory = <ContactHistory order={order} />
		}

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Action
						order={order}
						showAppointment={this.handleShowAppointment}
						start={start}
						takeOver={takeOver}
					/>
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.content} ref={this.setContentRef}>
						<div className={classes.columnLeft}>
							<Customer customer={order.customer} />
							<Language customer={order.customer} />
							<Properties order={order} />
							<Dates order={order} />
							<Amounts order={order} />
							<InternalRemark order={order} />
						</div>
						<div className={classes.columnRight}>
							<Remarks order={order} />
							{contactHistory}
							<Requirements requirements={order.requirements} />
							<MaterialList order={order} />
						</div>
					</div>
				</div>
				{appointmentDialog}
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Order)
