import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import Header from './Header'
import Edit from './Edit'
import Display from './Display'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%'
	},
	header: {
		marginBottom: 16
	},
	content: {
		flex: 1,
		display: 'flex'
	},
	columnLeft: {
		position: 'relative',
		width: '35%',
		height: '100%',
		marginRight: 8
	},
	columnRight: {
		width: '65%',
		height: '100%'
	}
}

class VehicleEdit extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		customVehicle: PropTypes.object.isRequired,
		meta: PropTypes.object.isRequired,
		save: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		changeDepots: PropTypes.func.isRequired,
		changeDrivers: PropTypes.func.isRequired,
		changePlanning: PropTypes.func.isRequired,
		changeProperties: PropTypes.func.isRequired,
		changeMaxPeriod: PropTypes.func.isRequired,
		vehicle: PropTypes.object
	}

	handleChangeDepots = (depots) => {
		const { changeDepots } = this.props
		changeDepots(depots)
	}

	handleChangeDrivers = (drivers) => {
		const { changeDrivers } = this.props
		changeDrivers(drivers)
	}

	handleChangePlanning = (planning) => {
		const { changePlanning } = this.props
		changePlanning(planning)
	}

	handleChangeProperties = (properties) => {
		const { changeProperties } = this.props
		changeProperties(properties)
	}

	handleChangeMaxPeriod = (period) => {
		const { changeMaxPeriod } = this.props
		changeMaxPeriod(period)
	}

	handleSave = () => {
		const { save } = this.props
		save()
	}

	render() {
		const { classes, customVehicle, meta, selectedMoment, vehicle } = this.props

		if (!vehicle) {
			return (
				<div className={classes.root}>
					<Typography variant="body2">
						{i18n.t('app:vehicleplanning.Vehicle.notFound')}
					</Typography>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<Header
						save={this.handleSave}
						selectedMoment={selectedMoment}
						vehicle={vehicle}
					/>
				</div>
				<div className={classes.content}>
					<div className={classes.columnLeft}>
						<Edit
							changeDepots={this.handleChangeDepots}
							changeDrivers={this.handleChangeDrivers}
							changePlanning={this.handleChangePlanning}
							changeProperties={this.handleChangeProperties}
							changeMaxPeriod={this.handleChangeMaxPeriod}
							customVehicle={customVehicle}
							meta={meta}
						/>
					</div>
					<div className={classes.columnRight}>
						<Display customVehicle={customVehicle} vehicle={vehicle} />
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(VehicleEdit)
