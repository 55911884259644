import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import OrderContainer from './Order'
import OrderEditContainer from './OrderEdit'
import OrderSelectionContainer from './OrderSelection'

const styleSheet = {
	split: {
		display: 'flex',
		height: '100%'
	},
	list: {
		marginRight: 16,
		width: 600
	},
	details: {
		flex: 1
	}
}

class AppointmentScheduler extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		ownUserId: PropTypes.string.isRequired,
		selectOrder: PropTypes.func.isRequired,
		selectedOrder: PropTypes.object,
		selectedOrderId: PropTypes.string
	}

	render() {
		const { classes, ownUserId, selectOrder, selectedOrder, selectedOrderId } =
			this.props

		const showEdit =
			selectedOrder &&
			selectedOrder.lock &&
			selectedOrder.lock.userId === ownUserId

		if (showEdit && selectedOrderId) {
			return (
				<OrderEditContainer
					key={`order-${selectedOrderId}`}
					selectedOrderId={selectedOrderId}
				/>
			)
		}

		let orderDetails
		if (selectedOrderId) {
			orderDetails = (
				<OrderContainer
					key={`order-${selectedOrderId}`}
					selectedOrderId={selectedOrderId}
				/>
			)
		}

		return (
			<div className={classes.split}>
				<div className={classes.list}>
					<OrderSelectionContainer
						selectedOrderId={selectedOrderId}
						selectOrder={selectOrder}
					/>
				</div>
				<div className={classes.details}>{orderDetails}</div>
			</div>
		)
	}
}

const connector = connect((state) => ({
	ownUserId: state.userState.user.id,
	selectedOrder: state.orderState.order
}))

export default withStyles(styleSheet)(connector(AppointmentScheduler))
