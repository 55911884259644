import moment from 'moment-timezone'
import { createSelector } from 'reselect'

// Shortcuts
const unscheduledOrdersSelector = (state) => state.unscheduledOrders
const selectedPlanningTypeKeySelector = (state, toursState) =>
	toursState.selectedPlanningTypeKey
const selectedDateSelector = (state, toursState) => toursState.selectedDate

// Return the selected date as a moment
const selectedMomentSelector = createSelector(
	selectedDateSelector,
	(selectedDate) => moment.tz(selectedDate, 'Europe/Amsterdam')
)

// The unscheduled orders list shows the filtered unscheduledOrders, sorted by unscheduledOrder number
const unscheduledOrderListSelector = createSelector(
	[
		unscheduledOrdersSelector,
		selectedPlanningTypeKeySelector,
		selectedMomentSelector
	],
	(unscheduledOrders, selectedPlanningTypeKey, selectedMoment) => {
		const filteredUnscheduledOrders = unscheduledOrders.filter(
			(unscheduledOrder) => {
				if (unscheduledOrder.planningType.key !== selectedPlanningTypeKey) {
					return false
				}
				if (
					!unscheduledOrder.requireAppointment &&
					!moment
						.tz(unscheduledOrder.minimumDeliveryDate, 'Europe/Amsterdam')
						.isSameOrBefore(selectedMoment, 'day')
				) {
					return false
				}
				if (
					unscheduledOrder.requireAppointment &&
					(!unscheduledOrder.isAppointmentConfirmed ||
						!moment
							.tz(unscheduledOrder.appointmentDate, 'Europe/Amsterdam')
							.isSame(selectedMoment, 'day'))
				) {
					return false
				}
				return true
			}
		)

		return filteredUnscheduledOrders.sort((a, b) => {
			const byConfirmed = b.isAppointmentConfirmed - a.isAppointmentConfirmed
			if (byConfirmed === 0) {
				return a.salesOrderNumber - b.salesOrderNumber
			}
			return byConfirmed
		})
	}
)

// Return the order with the specified id
const findUnscheduledOrderByIdSelector = createSelector(
	[unscheduledOrdersSelector, (state, id) => id],
	(unscheduledOrders, id) => unscheduledOrders.find((order) => order.id === id)
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	findUnscheduledOrderByIdSelector,
	unscheduledOrderListSelector
}

export default selectors
