import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import localizedDescription from '../../../../utils/localizedDescription'

const styleSheet = {
	button: {
		textAlign: 'center'
	}
}

class PlanningTypeMenu extends PureComponent {
	static propTypes = {
		planningTypes: PropTypes.array.isRequired,
		classes: PropTypes.object.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		selectPlanningType: PropTypes.func.isRequired
	}

	state = {
		showSelectPlanningTypeDialog: false
	}

	handleOpenPlanningTypeMenu = () => {
		this.setState({
			showSelectPlanningTypeDialog: true
		})
	}

	handleSelectPlanningType = (planningTypeKey) => {
		const { selectPlanningType } = this.props
		this.closePlanningTypeMenu()
		selectPlanningType(planningTypeKey)
	}

	handleClosePlanningTypeMenu = () => {
		this.closePlanningTypeMenu()
	}

	closePlanningTypeMenu() {
		this.setState({
			showSelectPlanningTypeDialog: false
		})
	}

	render() {
		const { classes, planningTypes, selectedPlanningTypeKey } = this.props
		const { showSelectPlanningTypeDialog } = this.state

		const planningTypeMenuItems = planningTypes.map((planningType) => (
			<ListItem
				key={`planningType-${planningType.planningTypeKey}`}
				button
				onClick={() =>
					this.handleSelectPlanningType(planningType.planningTypeKey)
				}
			>
				<ListItemText
					primary={localizedDescription(planningType.descriptions)}
					primaryTypographyProps={{ variant: 'body2' }}
				/>
			</ListItem>
		))
		const selectedPlanningType = planningTypes.find(
			(planningType) => planningType.planningTypeKey === selectedPlanningTypeKey
		)
		const selectedPlanningTypeDescriptions = selectedPlanningType
			? selectedPlanningType.descriptions
			: null

		return (
			<>
				<List disablePadding>
					<ListItem
						button
						onClick={this.handleOpenPlanningTypeMenu}
						className={classes.button}
					>
						<ListItemText
							primary={localizedDescription(selectedPlanningTypeDescriptions)}
							primaryTypographyProps={{ color: 'primary', variant: 'h6' }}
						/>
					</ListItem>
				</List>
				<Dialog
					onClose={this.handleClosePlanningTypeMenu}
					open={showSelectPlanningTypeDialog}
				>
					<DialogTitle>
						{i18n.t('app:planning.Tours.selectPlanningType')}
					</DialogTitle>
					<div>
						<List>{planningTypeMenuItems}</List>
					</div>
				</Dialog>
			</>
		)
	}
}

export default withStyles(styleSheet)(PlanningTypeMenu)
