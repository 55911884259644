import { default as i18n } from 'i18next'
import elementsFetch from '../utils/fetch'

const planningsUrl = '/api/plannings'

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(planningsUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getAll
}

export default api
