import config from '../../config'

const localizedDescription = (descriptions) => {
	// This function will try to return the description based on the user language.
	// If it doesn't find a description it will try to return the value for the fallback language.
	// It expects an object which conforms to the descriptions scheme defined on the Oliver Elements server.
	// Example:
	//	"descriptions": {
	//		"de": "Niederländisch",
	//		"en": "Dutch",
	//		"fr": "Néerlandais",
	//		"nl": "Nederlands"
	//	}
	if (!descriptions) {
		return '??'
	}
	const description = descriptions[config.userLanguage]
	if (!description) {
		const descriptionNL = descriptions[config.fallbackLanguage]
		if (!descriptionNL) {
			return '??'
		}
		return descriptionNL
	}
	return description
}

export default localizedDescription
