import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import planningTypesSelector from '../../../../../redux/planningtypes/selectors'
import driversSelector from '../../../../../redux/drivers/selectors'
import Depots from './Depots'

class DepotsContainer extends PureComponent {
	static propTypes = {
		change: PropTypes.func.isRequired,
		meta: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		startAddress: PropTypes.object,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { change, meta, planningType, startAddress, vehicle } = this.props

		return (
			<Depots
				change={change}
				meta={meta}
				planningType={planningType}
				startAddress={startAddress}
				vehicle={vehicle}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	planningType: planningTypesSelector.findPlanningTypeByKeySelector(
		state.planningTypesState,
		props.vehicle.planningType
	),
	startAddress: driversSelector.findAddressByDriverIdSelector(
		state.driversState,
		props.vehicle.firstDriver
	)
}))

export default connector(DepotsContainer)
