import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { BroadcastChannel } from 'broadcast-channel'
import { connect } from 'react-redux'
import toursSelectors from '../../../redux/tours/selectors'
import unscheduledOrdersSelectors from '../../../redux/unscheduledorders/selectors'
import TourSelection from './TourSelection'

class TourSelectionContainer extends PureComponent {
	static propTypes = {
		planningTypesLoading: PropTypes.bool,
		selectedTourIds: PropTypes.array.isRequired,
		tours: PropTypes.array.isRequired,
		toursLoading: PropTypes.bool,
		unscheduledOrders: PropTypes.array.isRequired
	}

	state = {
		mapChannel: new BroadcastChannel('map')
	}

	componentWillUnmount() {
		const { mapChannel } = this.state
		mapChannel.close()
	}

	componentDidUpdate(prevProps) {
		const { unscheduledOrders } = this.props
		// Update map with unscheduled orders if reference is modified
		if (prevProps.unscheduledOrders !== unscheduledOrders) {
			this.sendUnscheduledOrders(unscheduledOrders)
		}
	}

	sendUnscheduledOrders(unscheduledOrders) {
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-unscheduled-orders',
			payload: { unscheduledOrders }
		})
	}

	render() {
		const { planningTypesLoading, toursLoading } = this.props

		const isLoading = planningTypesLoading || toursLoading

		return <TourSelection isLoading={isLoading} />
	}
}

const connector = connect((state) => ({
	planningTypesLoading: state.planningTypesState.meta.isLoading,
	selectedTourIds: toursSelectors.selectedTourIdsSelector(state.toursState),
	tours: toursSelectors.tourListSelector(state.toursState),
	toursLoading: toursSelectors.toursLoadingSelector(state.toursState),
	unscheduledOrders: unscheduledOrdersSelectors.unscheduledOrderListSelector(
		state.unscheduledOrdersState,
		state.toursState
	)
}))

export default connector(TourSelectionContainer)
