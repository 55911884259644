import { io } from 'socket.io-client'
import config from '../config'
import actionCreators from '../redux/excludedservicedates/actionCreators'
import { readAccessToken } from '../utils/auth'

const { webSocketUrl } = config

class ExcludedServiceDatesSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/excludedservicedates`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('excludedservicedate_created', this.handleCreated)
			this.socket.on('excludedservicedate_deleted', this.handleDeleted)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
		// Fetch unavailable proposal dates when the socket has connected
		this.store.dispatch(actionCreators.fetch())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleCreated = (excludedServiceDate) => {
		this.store.dispatch(actionCreators.addBySocket(excludedServiceDate))
	}

	handleDeleted = (excludedServiceDate) => {
		this.store.dispatch(actionCreators.removeBySocket(excludedServiceDate))
	}
}

const excludedServiceDatesSocket = new ExcludedServiceDatesSocket()

export default excludedServiceDatesSocket
