import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialOptimizationState = []

const optimizationsReducer = (state = initialOptimizationState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_OPTIMIZATION_SUCCESS: {
			if (state.some((optimization) => optimization.id === action.meta.id)) {
				return state.map((optimization) =>
					optimization.id === action.meta.id
						? {
								id: action.meta.id,
								tours: action.payload.tours,
								unscheduledOrderKeys: action.payload.unscheduledOrderKeys
						  }
						: optimization
				)
			}
			return state.concat({
				id: action.meta.id,
				tours: action.payload.tours,
				unscheduledOrderKeys: action.payload.unscheduledOrderKeys
			})
		}

		default: {
			return state
		}
	}
}

const initialReductionState = []

const reductionsReducer = (state = initialReductionState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REDUCTION_SUCCESS: {
			if (state.some((reduction) => reduction.id === action.meta.id)) {
				return state.map((reduction) =>
					reduction.id === action.meta.id
						? {
								id: action.meta.id,
								tours: action.payload.tours,
								unscheduledOrderKeys: action.payload.unscheduledOrderKeys
						  }
						: reduction
				)
			}
			return state.concat({
				id: action.meta.id,
				tours: action.payload.tours,
				unscheduledOrderKeys: action.payload.unscheduledOrderKeys
			})
		}

		default: {
			return state
		}
	}
}

const initialValidationState = []

const validationsReducer = (state = initialValidationState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_VALIDATION_SUCCESS: {
			if (state.some((validation) => validation.id === action.meta.id)) {
				return state.map((validation) =>
					validation.id === action.meta.id
						? {
								id: action.meta.id,
								tours: action.payload.tours
						  }
						: validation
				)
			}
			return state.concat({
				id: action.meta.id,
				tours: action.payload.tours
			})
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isConnected: false
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		default: {
			return state
		}
	}
}

const initialMetaOptimizationsState = []

const metaOptimizationsReducer = (
	state = initialMetaOptimizationsState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_OPTIMIZATION_REQUEST: {
			if (state.some((optimization) => optimization.id === action.meta.id)) {
				return state.map((optimization) =>
					optimization.id === action.meta.id
						? { ...optimization, isLoading: true, errorMessages: null }
						: optimization
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: true,
				errorMessages: null
			})
		}

		case actionTypes.FETCH_OPTIMIZATION_SUCCESS: {
			if (state.some((optimization) => optimization.id === action.meta.id)) {
				return state.map((optimization) =>
					optimization.id === action.meta.id
						? { ...optimization, isLoading: false }
						: optimization
				)
			}
			return state.concat({ id: action.meta.id, isLoading: false })
		}

		case actionTypes.FETCH_OPTIMIZATION_FAILURE: {
			if (state.some((optimization) => optimization.id === action.meta.id)) {
				return state.map((optimization) =>
					optimization.id === action.meta.id
						? {
								...optimization,
								isLoading: false,
								errorMessages: action.payload.errorMessages
						  }
						: optimization
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: false,
				errorMessages: action.payload.errorMessages
			})
		}

		default: {
			return state
		}
	}
}

const initialMetaReductionsState = []

const metaReductionsReducer = (state = initialMetaReductionsState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REDUCTION_REQUEST: {
			if (state.some((reduction) => reduction.id === action.meta.id)) {
				return state.map((reduction) =>
					reduction.id === action.meta.id
						? { ...reduction, isLoading: true, errorMessages: null }
						: reduction
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: true,
				errorMessages: null
			})
		}

		case actionTypes.FETCH_REDUCTION_SUCCESS: {
			if (state.some((reduction) => reduction.id === action.meta.id)) {
				return state.map((reduction) =>
					reduction.id === action.meta.id
						? { ...reduction, isLoading: false }
						: reduction
				)
			}
			return state.concat({ id: action.meta.id, isLoading: false })
		}

		case actionTypes.FETCH_REDUCTION_FAILURE: {
			if (state.some((reduction) => reduction.id === action.meta.id)) {
				return state.map((reduction) =>
					reduction.id === action.meta.id
						? {
								...reduction,
								isLoading: false,
								errorMessages: action.payload.errorMessages
						  }
						: reduction
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: false,
				errorMessages: action.payload.errorMessages
			})
		}

		default: {
			return state
		}
	}
}

const initialMetaValidationsState = []

const metaValidationsReducer = (
	state = initialMetaValidationsState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_VALIDATION_REQUEST: {
			if (state.some((validation) => validation.id === action.meta.id)) {
				return state.map((validation) =>
					validation.id === action.meta.id
						? { ...validation, isLoading: true, errorMessages: null }
						: validation
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: true,
				errorMessages: null
			})
		}

		case actionTypes.FETCH_VALIDATION_SUCCESS: {
			if (state.some((validation) => validation.id === action.meta.id)) {
				return state.map((validation) =>
					validation.id === action.meta.id
						? { ...validation, isLoading: false }
						: validation
				)
			}
			return state.concat({ id: action.meta.id, isLoading: false })
		}

		case actionTypes.FETCH_VALIDATION_FAILURE: {
			if (state.some((validation) => validation.id === action.meta.id)) {
				return state.map((validation) =>
					validation.id === action.meta.id
						? {
								...validation,
								isLoading: false,
								errorMessages: action.payload.errorMessages
						  }
						: validation
				)
			}
			return state.concat({
				id: action.meta.id,
				isLoading: false,
				errorMessages: action.payload.errorMessages
			})
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	meta: metaReducer,
	metaOptimizations: metaOptimizationsReducer,
	metaReductions: metaReductionsReducer,
	metaValidations: metaValidationsReducer,
	optimizations: optimizationsReducer,
	reductions: reductionsReducer,
	validations: validationsReducer
})
