import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import localizedDescription from '../../../../utils/localizedDescription'
import { formatPlanningParams } from '../../../../utils/planningtypes'
import { formatPeriodText } from '../../../../utils/vehicles'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Planning extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		properties: PropTypes.array
	}

	render() {
		const { classes, planningType, properties } = this.props

		if (!planningType) {
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:vehicleplanning.Planning.title')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:vehicleplanning.Planning.unknown')}
					</Typography>
				</div>
			)
		}

		let maxPeriod
		const vehicleMaxPeriodEnabled = properties.find(
			(property) => property.key === 'maxPeriodEnabled'
		)
		const vehicleMaxPeriod = properties.find(
			(property) => property.key === 'maxPeriod'
		)
		if (
			vehicleMaxPeriodEnabled &&
			vehicleMaxPeriodEnabled.value === true &&
			vehicleMaxPeriod &&
			vehicleMaxPeriod.value
		) {
			maxPeriod = vehicleMaxPeriod.value
		} else {
			maxPeriod =
				planningType.planningParams.standardParams.restrictions.tourRestrictions
					.maxPeriod
		}

		const { days, hours, minutes } = formatPeriodText(maxPeriod)
		const maxPeriodText = (
			<Typography variant="body2">
				{days} {hours} {minutes}
			</Typography>
		)

		const { maxDistance, returnVolume } = formatPlanningParams(
			planningType.planningParams
		)

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Planning.title')}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Planning.type')}
						</Typography>
						<Typography variant="body2">
							{localizedDescription(planningType.descriptions)}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Planning.maxPeriod')}
						</Typography>
						{maxPeriodText}
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Planning.maxDistance')}
						</Typography>
						<Typography variant="body2">{`${maxDistance} ${i18n.t(
							'app:vehicleplanning.Planning.maxDistanceUnit'
						)}`}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Planning.returnVolume')}
						</Typography>
						<Typography variant="body2">{returnVolume}</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(Planning)
