import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/postcodes/actionCreators'
import Search from './Search'

class SearchContainer extends PureComponent {
	static propTypes = {
		code: PropTypes.string,
		date: PropTypes.number,
		search: PropTypes.func.isRequired
	}

	render() {
		const { code, date, search } = this.props

		return <Search code={code} date={date} search={search} />
	}
}

const connector = connect(
	(state) => ({
		code: state.postcodesState.search.code,
		date: state.postcodesState.search.date
	}),
	{
		search: actionCreators.search
	}
)

export default connector(SearchContainer)
