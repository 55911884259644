import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 16,
		paddingRight: 16
	},
	formRow: {
		display: 'flex',
		alignItems: 'center'
	},
	confirmButton: {
		marginBottom: 16,
		marginTop: 16
	},
	textFieldNote: {
		flex: 1
	}
}

class Note extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		close: PropTypes.func.isRequired,
		order: PropTypes.object.isRequired,
		setNote: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		const note = props.appointment.note || props.order.appointment.note || ''
		this.state = {
			note
		}
	}

	handleChangeNote = (event) => {
		const noteInput = event.target.value
		if (noteInput.length <= 50) {
			this.setState({
				note: noteInput
			})
		}
	}

	handleConfirm = () => {
		const { close, setNote } = this.props
		const { note } = this.state
		setNote(note)
		close()
	}

	render() {
		const { classes } = this.props
		const { note } = this.state

		return (
			<div className={classes.root}>
				<div className={classes.formRow}>
					<TextField
						placeholder={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryNote.note'
						)}
						className={classes.textFieldNote}
						value={note}
						onChange={this.handleChangeNote}
						margin="normal"
					/>
				</div>
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Note)
