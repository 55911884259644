import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../../../redux/drivers/selectors'
import Driver from './Driver'

class DriverContainer extends PureComponent {
	static propTypes = {
		change: PropTypes.func.isRequired,
		driver: PropTypes.object,
		driverId: PropTypes.number,
		error: PropTypes.bool,
		errorText: PropTypes.string,
		excludedDrivers: PropTypes.array,
		remove: PropTypes.func.isRequired
	}

	render() {
		const { driver, change, error, errorText, excludedDrivers, remove } =
			this.props

		return (
			<Driver
				driver={driver}
				error={error}
				errorText={errorText}
				excludedDrivers={excludedDrivers}
				change={change}
				remove={remove}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	driver: selectors.findDriverByIdSelector(state.driversState, props.driverId)
}))

export default connector(DriverContainer)
