import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import apiDepots from '../../services/apiDepots'
import actionTypes from './actionTypes'
import depotsActionCreators from './actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'

// Will fetch all depots
function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(depotsActionCreators.fetchRequest())
		try {
			const depots = yield call(apiDepots.getAll)
			yield put(depotsActionCreators.fetchSuccess(depots))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleplanning.Depots.Error.fetch')
			yield put(
				depotsActionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch].map((saga) => fork(saga)))
}
