const fallbackLanguage = 'nl'
const defaultLanguage =
	window.navigator.language.slice(0, 2) || fallbackLanguage
const userLanguage = localStorage.getItem('language') || defaultLanguage

const oeServer = process.env.REACT_APP_OE_SERVER || ''
const webSocketUrl = process.env.REACT_APP_OE_SERVER || ''

const config = {
	fallbackLanguage,
	defaultLanguage,
	oeServer,
	userLanguage,
	webSocketUrl
}

module.exports = config
