import { combineReducers } from 'redux'

import actionTypes from './actionTypes'

const initialUnavailableProposalDatesState = []
const unavailableProposalDatesReducer = (
	state = initialUnavailableProposalDatesState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialUnavailableProposalDatesState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.unavailableProposalDates
		}

		case actionTypes.FETCH_FAILURE: {
			return initialUnavailableProposalDatesState
		}

		case actionTypes.SOCKET_ADD: {
			return state.concat(action.payload.unavailableProposalDate)
		}

		case actionTypes.SOCKET_REMOVE: {
			const { unavailableProposalDate: removedUnavailableProposalDate } =
				action.payload
			return state.filter(
				(unavailableProposalDate) =>
					unavailableProposalDate.id !== removedUnavailableProposalDate.id
			)
		}

		default: {
			return state
		}
	}
}

const initialUnavailableProposalDatesMetaState = {
	isConnected: false,
	isLoading: false,
	isSaving: false
}
const unavailableProposalDatesMetaReducer = (
	state = initialUnavailableProposalDatesMetaState,
	action
) => {
	switch (action.type) {
		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.ADD_REQUEST: {
			return {
				...state,
				isSaving: true
			}
		}

		case actionTypes.ADD_SUCCESS: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.ADD_FAILURE: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.REMOVE_REQUEST: {
			return {
				...state,
				isSaving: true
			}
		}

		case actionTypes.REMOVE_SUCCESS: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.REMOVE_FAILURE: {
			return {
				...state,
				isSaving: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	unavailableProposalDates: unavailableProposalDatesReducer,
	meta: unavailableProposalDatesMetaReducer
})
