import { default as i18n } from 'i18next'

const languages = () => {
	const lng = [
		{ id: 'de', description: i18n.t('app:settings.Language.lngGerman') },
		{ id: 'en', description: i18n.t('app:settings.Language.lngEnglish') },
		{ id: 'nl', description: i18n.t('app:settings.Language.lngDutch') }
	].sort((a, b) => a.description.localeCompare(b.description))
	return lng
}

export default languages
