import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiUnscheduledOrders from '../../services/apiUnscheduledOrders'
import notificationsActionCreators from '../notifications/actionCreators'
import unscheduledOrdersActionCreators from './actionCreators'
import manualPlanningsActionTypes from '../manualplannings/actionTypes'
import unscheduledOrdersActionTypes from './actionTypes'

// Will fetch all unscheduled orders at certain event
function* fetch() {
	while (true) {
		yield take([
			manualPlanningsActionTypes.SAVE_SUCCESS, // When a manual planning is saved successfully
			unscheduledOrdersActionTypes.FETCH // When a manual refresh is triggered
		])
		yield put(unscheduledOrdersActionCreators.fetchRequest())
		try {
			const unscheduledOrders = yield call(apiUnscheduledOrders.getAll)
			yield put(unscheduledOrdersActionCreators.fetchSuccess(unscheduledOrders))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:planning.UnscheduledOrder.Error.fetch')
			yield put(
				unscheduledOrdersActionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch].map((saga) => fork(saga)))
}
