import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ordersActionCreators from '../../../redux/orders/actionCreators'
import OrderSearch from './OrderSearch'

class OrderSearchContainer extends PureComponent {
	static propTypes = {
		clearSearch: PropTypes.func.isRequired,
		select: PropTypes.func.isRequired
	}

	handleSelect = (order) => {
		const { clearSearch, select } = this.props
		select(order)
		clearSearch()
	}

	render() {
		return <OrderSearch select={this.handleSelect} />
	}
}

const connector = connect(null, {
	clearSearch: ordersActionCreators.clearSearch
})

export default connector(OrderSearchContainer)
