import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	ListItem as MaterialListItem,
	Typography,
	ListItemText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import localizedDescription from '../../../../utils/localizedDescription'

const styleSheet = (theme) => ({
	active: {
		backgroundColor: theme.palette.action.selected
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		driver: PropTypes.object.isRequired,
		isSelected: PropTypes.bool
	}

	render() {
		const { driver, classes, isSelected } = this.props

		const name = `${driver.firstName} ${driver.lastName}`
		const description = localizedDescription(driver.driverType.translations)

		const primary = (
			<div className={classes.primary}>
				<Typography variant="h6">{name}</Typography>
			</div>
		)

		const secondary = (
			<div className={classes.primary}>
				<Typography variant="subtitle1">{description}</Typography>
			</div>
		)

		return (
			<MaterialListItem
				button
				component={Link}
				to={`/driveradmin/${driver.driverId}`}
				className={isSelected ? classes.active : ''}
			>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
