import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { ORDER_TYPE_SERVICE } from '../../../utils/constants'
import MaterialListItem from './MaterialListItem'

const styleSheet = {
	header: {
		paddingTop: 16,
		paddingLeft: 16,
		paddingRight: 16
	}
}

class MaterialList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const items = [...order.items]
			.sort((a, b) => a.position - b.position)
			.map((item) => (
				<MaterialListItem
					key={`material-item-${item.position}`}
					isServiceType={order.orderType.key === ORDER_TYPE_SERVICE}
					material={item}
				/>
			))

		return (
			<>
				<div className={classes.header}>
					<Typography variant="h6">
						{i18n.t('app:appointmentscheduler.Order.materials')}
					</Typography>
				</div>
				<List disablePadding>{items}</List>
			</>
		)
	}
}

export default withStyles(styleSheet)(MaterialList)
