// Selectors in this file are specifically made to be used in sagas with the `select` effect.
// This means they must not use the `createSelector` function from the reselect module!

const getIds = (state) => state.toursState.tours.map((tour) => tour.id)

const selectors = {
	getIds
}

export default selectors
