const moment = require('moment-timezone')

const formatDuration = (durationInSeconds, { withSeconds = true } = {}) => {
	const duration = moment.duration(durationInSeconds * 1000)
	const hours = `${Math.floor(duration.asHours())}`
	const minutes = `${duration.minutes()}`.padStart(2, '0')
	const seconds = `${duration.seconds()}`.padStart(2, '0')
	return withSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`
}

export default formatDuration
