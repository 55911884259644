import {
	all,
	call,
	fork,
	put,
	take,
	cancel,
	cancelled
} from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import { clearTokens, readRefreshToken, storeTokens } from '../../utils/auth'
import apiAuth from '../../services/apiAuth'
import apiUser from '../../services/apiUser'
import actionTypes from './actionTypes'
import actionCreators from './actionCreators'

function* autoLoginSaga() {
	try {
		// Can only auto login when a refresh token is available
		const refreshToken = yield call(readRefreshToken)
		if (refreshToken) {
			// Refresh the tokens and store the new tokens
			yield put(actionCreators.autoLoginRequest())
			const tokens = yield call(apiAuth.refreshTokens, refreshToken)
			yield call(storeTokens, tokens)
			// Fetch user details
			const userData = yield call(apiUser.get)
			yield put(actionCreators.loginSuccess(userData))
		} else {
			// No refresh token available, cannot auto login
			yield put(actionCreators.autoLoginNoToken())
		}
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:elements.Login.loginFailure')
		yield put(
			actionCreators.autoLoginFailure({
				userMessage,
				techMessage
			})
		)
	} finally {
		if (yield cancelled()) {
			// The login task was cancelled. We cannot cancel the running HTTP request,
			// but the eventual result of the running request will be ignored.
			yield put(actionCreators.loginCancelled())
		}
	}
}

function* loginSaga(credentials) {
	try {
		// Login with specified credentials and store the tokens
		yield put(actionCreators.loginRequest())
		const tokens = yield call(apiAuth.login, credentials)
		yield call(storeTokens, tokens)
		// Fetch user details
		const userData = yield call(apiUser.get)
		yield put(actionCreators.loginSuccess(userData))
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:elements.Login.loginFailure')
		yield put(
			actionCreators.loginFailure({
				userMessage,
				techMessage
			})
		)
	} finally {
		if (yield cancelled()) {
			// The login task was cancelled. We cannot cancel the running HTTP request (not possible at time of writing https://github.com/whatwg/fetch/issues/447),
			// but the eventual result of the running request will be ignored due to this being a saga.
			yield put(actionCreators.loginCancelled())
		}
	}
}

function* authSaga() {
	let loginTask
	while (true) {
		const authAction = yield take([
			actionTypes.AUTOLOGIN,
			actionTypes.LOGIN,
			actionTypes.LOGIN_FAILURE,
			actionTypes.LOGOUT
		])
		if (loginTask && authAction.type === actionTypes.LOGOUT) {
			// Cancel login task when the user logs out before it completes
			yield cancel(loginTask)
			// Navigate to root
			if (authAction.payload.history) {
				authAction.payload.history.push('/')
			}
		}
		if (
			authAction.type === actionTypes.LOGOUT ||
			authAction.type === actionTypes.LOGIN_FAILURE
		) {
			// Clear tokens when user logs out, or if the login fails
			yield call(clearTokens)
		}
		if (authAction.type === actionTypes.LOGIN) {
			// Login with credentials
			loginTask = yield fork(loginSaga, authAction.payload.credentials)
		}
		if (authAction.type === actionTypes.AUTOLOGIN) {
			// Login with stored refresh token
			loginTask = yield fork(autoLoginSaga)
		}
	}
}

export default function* rootSaga() {
	yield all([authSaga].map((saga) => fork(saga)))
}
