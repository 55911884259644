import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const configureStore = () => {
	const sagaMiddleware = createSagaMiddleware()
	const enhancer = compose(applyMiddleware(sagaMiddleware))
	const store = createStore(rootReducer, enhancer)
	sagaMiddleware.run(rootSaga)
	return store
}

export default configureStore
