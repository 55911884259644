import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Costs from '../../Vehicle/Costs'
import Data from '../../Vehicle/Data'
import PlanningContainer from './Planning'
import Properties from '../../Vehicle/Properties'
import DriverQualificationsContainer from '../../Vehicle/DriverQualifications'
import {
	extractProperties,
	extractReadOnlyProperties
} from '../../../../utils/vehicles'

const styleSheet = {
	root: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	columnLeft: {
		width: '50%'
	},
	columnRight: {
		width: '50%'
	}
}

// the display component needs the vehicle and the custom vehicle
// following fields are displayed based on the changed values in the custom vehicle
// - the planning type parameters must be shown for the selected planning type
// - the qualifications must be shown for the selected drivers
class Display extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		customVehicle: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, customVehicle, vehicle } = this.props

		const properties = extractProperties(vehicle)
		const readOnlyProperties = extractReadOnlyProperties(vehicle)

		return (
			<Paper className={classes.root}>
				<div className={classes.columnLeft}>
					<Data vehicle={vehicle} />
					<PlanningContainer
						planningTypeKey={customVehicle.planningType}
						properties={properties}
					/>
					<Properties properties={readOnlyProperties} />
				</div>
				<div className={classes.columnRight}>
					<Costs vehicle={vehicle} />
					<DriverQualificationsContainer
						driverIds={[customVehicle.firstDriver, customVehicle.secondDriver]}
					/>
				</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(Display)
