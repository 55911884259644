import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import escapeStringRegexp from '../../utils/escapeStringRegexp'
import localizedDescription from '../../utils/localizedDescription'

const vehiclesSelector = (state) => state.vehicles
const searchSelector = (state) => state.search
const selectedDateSelector = (state) => state.selectedDate
const filterSelector = (state) => state.filter
const driversSelector = (state, driversState) => driversState.drivers
const planningTypesSelector = (state, planningTypesState) =>
	planningTypesState.planningTypes

const queryVehicle = (vehicle, query, driversMap) => {
	const queryRegex = new RegExp(escapeStringRegexp(query), 'i')
	if (
		vehicle.licensePlate &&
		queryRegex.test(vehicle.licensePlate.toString())
	) {
		return true
	}
	if (
		vehicle.descriptions &&
		queryRegex.test(localizedDescription(vehicle.descriptions).toString())
	) {
		return true
	}
	if (vehicle.vehicleId && queryRegex.test(vehicle.vehicleId.toString())) {
		return true
	}
	if (vehicle.firstDriver && queryRegex.test(vehicle.firstDriver.toString())) {
		return true
	}
	if (
		vehicle.secondDriver &&
		queryRegex.test(vehicle.secondDriver.toString())
	) {
		return true
	}
	if (vehicle.firstDriver && driversMap.has(vehicle.firstDriver)) {
		const firstDriver = driversMap.get(vehicle.firstDriver)
		if (
			queryRegex.test(
				`${firstDriver.firstName.toString()} ${firstDriver.lastName.toString()}`
			)
		) {
			return true
		}
	}
	if (vehicle.secondDriver && driversMap.has(vehicle.secondDriver)) {
		const secondDriver = driversMap.get(vehicle.secondDriver)
		if (
			queryRegex.test(
				`${secondDriver.firstName.toString()} ${secondDriver.lastName.toString()}`
			)
		) {
			return true
		}
	}

	return false
}

const filteredVehiclesSelector = createSelector(
	[filterSelector, vehiclesSelector],
	(filter, vehicles) =>
		vehicles.filter((vehicle) => {
			if (filter.onlyUnavailable) {
				if (vehicle.available) {
					return false
				}
			}
			if (filter.onlyAvailable) {
				if (!vehicle.available) {
					return false
				}
			}
			if (filter.planningTypeKeys.length > 0) {
				if (!vehicle.planningType) {
					return false
				}
				if (
					!filter.planningTypeKeys.some((key) => key === vehicle.planningType)
				) {
					return false
				}
			}
			if (filter.vehicleTypes.length > 0) {
				if (!vehicle.type) {
					return false
				}
				if (!filter.vehicleTypes.some((type) => type === vehicle.type)) {
					return false
				}
			}
			return true
		})
)

const filteredVehiclesSearchSelector = createSelector(
	[searchSelector, filteredVehiclesSelector, driversSelector],
	(search, filteredVehicles, drivers) => {
		const driversMap = drivers.reduce((acc, driver) => {
			acc.set(driver.driverId, driver)
			return acc
		}, new Map())
		return filteredVehicles
			.filter((vehicle) => queryVehicle(vehicle, search.query, driversMap))
			.sort((a, b) => a.licensePlate.localeCompare(b.licensePlate))
	}
)

const findVehicleByIdSelector = createSelector(
	[
		filteredVehiclesSearchSelector,
		(state, driverState, vehicleId) =>
			vehicleId ? Number.parseInt(vehicleId, 10) : null
	],
	(vehicles, vehicleId) =>
		vehicles.find((vehicle) => vehicle.vehicleId === vehicleId)
)

const selectedMomentSelector = createSelector(
	selectedDateSelector,
	(selectedDate) => moment.unix(selectedDate).tz('Europe/Amsterdam')
)

const availablePlanningTypes = createSelector(
	[vehiclesSelector, planningTypesSelector],
	(vehicles, planningTypes) => {
		const planningTypesMap = new Map()
		vehicles.forEach((vehicle) => {
			if (vehicle.planningType) {
				const vehiclePlanningType = planningTypes.find(
					(planningType) =>
						planningType.planningTypeKey === vehicle.planningType
				)
				planningTypesMap.set(vehicle.planningType, vehiclePlanningType)
			}
		})
		return [...planningTypesMap.values()].sort((a, b) =>
			localizedDescription(a.descriptions).localeCompare(
				localizedDescription(b.descriptions)
			)
		)
	}
)

const availableVehicleTypes = createSelector([vehiclesSelector], (vehicles) => {
	const vehicleTypesSet = new Set()
	vehicles.forEach((vehicle) => {
		if (vehicle.type) {
			vehicleTypesSet.add(vehicle.type)
		}
	})
	return [...vehicleTypesSet]
})

const hasActiveFilters = createSelector(
	[filterSelector],
	(filter) =>
		filter.onlyAvailable ||
		filter.onlyUnavailable ||
		filter.planningTypeKeys.length > 0 ||
		filter.vehicleTypes.length > 0
)

const selectors = {
	findVehicleByIdSelector,
	selectedMomentSelector,
	filteredVehiclesSearchSelector,
	availablePlanningTypes,
	availableVehicleTypes,
	hasActiveFilters
}

export default selectors
