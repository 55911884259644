import moment from 'moment-timezone'
import { createSelector } from 'reselect'

const toursSelector = (state) => state.tours
const selectedPlanningTypeKeySelector = (state) => state.selectedPlanningTypeKey
const metaToursSelector = (state) => state.metaTours
const selectedDateSelector = (state) => state.selectedDate
const isLoadingSelector = (state) => state.meta.isLoading
const loadingReplacementsSelector = (state) => state.meta.loadingReplacements

// Sort tours on:
//   1. Confirmed (voorlopig gepland)
//   2. Open (openstaande ritten)
//   3. Empty (lege voertuigen)
//   4. Reserved (gereserveerde voertuigen)
//   5. Finalized (vastgezette ritten)
const getTourSortOrder = (tour) => {
	if (tour.finalizedAt) {
		return 5 // Finalized
	}
	if (tour.confirmedAt && tour.route.length === 0) {
		return 4 // Reserved
	}
	if (tour.confirmedAt) {
		return 1 // Confirmed
	}
	if (tour.route.length === 0) {
		return 3 // Empty
	}
	return 2 // Open
}

const sortedToursSelector = createSelector([toursSelector], (tours) =>
	tours.sort((a, b) => getTourSortOrder(a) - getTourSortOrder(b))
)

// Generate tour numbers for each day in each planning type
const tourNumberGroupsSelector = createSelector(
	[sortedToursSelector],
	(tours) => {
		// Group tours by day and number them starting at 1.
		// There is no other logic attached to these numbers.
		// The only purpose is to make the tours easily identifiable when working in the planning UI,
		// which displays the tours grouped by day.
		const tourNumberGroups = new Map()
		const tourPlanningTypeGroups = tours.reduce((acc, tour) => {
			const toursForPlanningType = acc.get(tour.planningType.key)
			if (toursForPlanningType) {
				acc.set(tour.planningType.key, [...toursForPlanningType, tour])
			} else {
				acc.set(tour.planningType.key, [tour])
			}
			return acc
		}, new Map())
		const planningTypeKeys = [].concat(...tourPlanningTypeGroups.keys())
		planningTypeKeys.forEach((planningTypeKey) => {
			const toursForPlanningType = tourPlanningTypeGroups.get(planningTypeKey)
			const tourDateGroups = toursForPlanningType.reduce((acc, tour) => {
				tour.dates.forEach((tourDate) => {
					const tourNumbersForDate = acc.get(tourDate)
					if (tourNumbersForDate) {
						const nextTourNumber =
							tourNumbersForDate[tourNumbersForDate.length - 1].number + 1
						acc.set(tourDate, [
							...tourNumbersForDate,
							{ id: tour.id, number: nextTourNumber }
						])
					} else {
						acc.set(tourDate, [{ id: tour.id, number: 1 }])
					}
				})
				return acc
			}, new Map())
			tourNumberGroups.set(planningTypeKey, tourDateGroups)
		})
		return tourNumberGroups
	}
)

// Return the selected date as a moment
const selectedMomentSelector = createSelector(
	selectedDateSelector,
	(selectedDate) => moment.tz(selectedDate, 'Europe/Amsterdam')
)

// The tour list shows the filtered tours
const tourListSelector = createSelector(
	[
		sortedToursSelector,
		selectedPlanningTypeKeySelector,
		selectedMomentSelector
	],
	(tours, selectedPlanningTypeKey, selectedMoment) =>
		tours.filter(
			(tour) =>
				tour.planningType.key === selectedPlanningTypeKey &&
				tour.dates.some((date) =>
					moment.tz(date, 'Europe/Amsterdam').isSame(selectedMoment, 'day')
				)
		)
)
const tourListNumbersSelector = createSelector(
	[
		tourNumberGroupsSelector,
		selectedPlanningTypeKeySelector,
		selectedMomentSelector
	],
	(tourNumberGroups, selectedPlanningTypeKey, selectedMoment) => {
		const tourNumberGroup = tourNumberGroups.get(selectedPlanningTypeKey)
		if (tourNumberGroup) {
			return tourNumberGroup.get(selectedMoment.format()) || []
		}
		return []
	}
)
const tourListIdsSelector = createSelector([tourListSelector], (tours) =>
	tours.map((tour) => tour.id)
)

// Return selected tours
const selectedToursSelector = createSelector(
	[sortedToursSelector, metaToursSelector],
	(tours, metaTours) =>
		tours.filter((tour) =>
			metaTours.some((metaTour) => metaTour.id === tour.id && metaTour.selected)
		)
)

// Return selected tour id's
const selectedTourIdsSelector = createSelector(
	[sortedToursSelector, metaToursSelector],
	(tours, metaTours) =>
		tours
			.filter((tour) =>
				metaTours.some(
					(metaTour) => metaTour.id === tour.id && metaTour.selected
				)
			)
			.map((tour) => tour.id)
)

// Return the tour with the specified id
const findTourByIdSelector = createSelector(
	[sortedToursSelector, (state, id) => id],
	(tours, id) => tours.find((tour) => tour.id === id)
)

// Return the tour meta with the specified id
const findTourMetaByIdSelector = createSelector(
	[metaToursSelector, (state, id) => id],
	(metaTours, id) => metaTours.find((metaTour) => metaTour.id === id)
)

// Return the loading tours indicators
const toursLoadingSelector = createSelector(
	[
		selectedPlanningTypeKeySelector,
		selectedMomentSelector,
		isLoadingSelector,
		loadingReplacementsSelector
	],
	(selectedPlanningTypeKey, selectedMoment, isLoading, loadingReplacements) =>
		isLoading ||
		loadingReplacements.some(
			(loadingReplacementsItem) =>
				loadingReplacementsItem.planningTypeKey === selectedPlanningTypeKey &&
				moment
					.tz(loadingReplacementsItem.fromDate, 'Europe/Amsterdam')
					.isSameOrBefore(selectedMoment)
		)
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	findTourByIdSelector,
	findTourMetaByIdSelector,
	selectedMomentSelector,
	selectedToursSelector,
	selectedTourIdsSelector,
	tourListSelector,
	tourListIdsSelector,
	tourListNumbersSelector,
	toursLoadingSelector
}

export default selectors
