const actionTypes = {
	FETCH: 'postcodes/FETCH',
	FETCH_REQUEST: 'postcodes/FETCH_REQUEST',
	FETCH_SUCCESS: 'postcodes/FETCH_SUCCESS',
	FETCH_FAILURE: 'postcodes/FETCH_FAILURE',
	SELECT_COUNTRY: 'postcodes/SELECT_COUNTRY',
	SEARCH: 'postcodes/SEARCH',
	CREATE_POSTCODE: 'postcodes/CREATE_POSTCODE',
	CREATE_POSTCODE_REQUEST: 'postcodes/CREATE_POSTCODE_REQUEST',
	CREATE_POSTCODE_SUCCESS: 'postcodes/CREATE_POSTCODE_SUCCESS',
	CREATE_POSTCODE_FAILURE: 'postcodes/CREATE_POSTCODE_FAILURE',
	REMOVE_POSTCODE: 'postcodes/REMOVE_POSTCODE',
	REMOVE_POSTCODE_REQUEST: 'postcodes/REMOVE_POSTCODE_REQUEST',
	REMOVE_POSTCODE_SUCCESS: 'postcodes/REMOVE_POSTCODE_SUCCESS',
	REMOVE_POSTCODE_FAILURE: 'postcodes/REMOVE_POSTCODE_FAILURE'
}

export default actionTypes
