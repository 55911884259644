import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Paper,
	List as MaterialList,
	Typography,
	IconButton,
	SvgIcon
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SyncFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import Placeholder from '../../Shared/Placeholder'

import ListContainer from './List'

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	header: {
		display: 'flex',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	},
	headerTitle: {
		flex: 1
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	}
})

class PlanningTypeSelection extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		selectedPlanningTypeKey: PropTypes.string,
		refresh: PropTypes.func.isRequired
	}

	handleClickRefresh = () => {
		const { refresh } = this.props
		refresh()
	}

	render() {
		const { classes, isLoading, selectedPlanningTypeKey } = this.props

		let list
		if (isLoading) {
			const placeholders = [...Array(10)].map((row, index) => (
				<Placeholder key={`planningtypeslist-placeholder-${index}`} />
			))
			list = <MaterialList disablePadding>{placeholders}</MaterialList>
		} else {
			list = <ListContainer selectedPlanningTypeKey={selectedPlanningTypeKey} />
		}

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h5" className={classes.headerTitle}>
						{i18n.t('app:planningtypeadmin.PlanningTypes.title')}
					</Typography>
					<IconButton color="primary" onClick={this.handleClickRefresh}>
						<SvgIcon>
							<SyncFal />
						</SvgIcon>
					</IconButton>
				</div>
				<div className={classes.contentContainer}>{list}</div>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(PlanningTypeSelection)
