import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Navigation from '../Navigation/Navigation'
import UpdateChecker from './UpdateChecker/UpdateChecker'

const styleSheet = (theme) => ({
	content: {
		flexGrow: 1,
		paddingTop: theme.spacing(7),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1)
	}
})

class AppContent extends PureComponent {
	static propTypes = {
		children: PropTypes.node,
		classes: PropTypes.object.isRequired,
		logout: PropTypes.func
	}

	render() {
		const { children, classes, logout } = this.props
		return (
			<>
				<Navigation logout={logout} />
				<div className={classes.content}>{children}</div>
				<UpdateChecker />
			</>
		)
	}
}

export default withStyles(styleSheet)(AppContent)
