import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {}

class Progress extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		selectedStep: PropTypes.string.isRequired
	}

	progressSteps = [
		{
			id: 0,
			label: i18n.t('app:appointmentscheduler.Order.ProcessSteps.step1')
		},
		{
			id: 1,
			label: i18n.t('app:appointmentscheduler.Order.ProcessSteps.step2')
		},
		{
			id: 2,
			label: i18n.t('app:appointmentscheduler.Order.ProcessSteps.step3')
		},
		{
			id: 3,
			label: i18n.t('app:appointmentscheduler.Order.ProcessSteps.step4')
		}
	]

	render() {
		const { selectedStep } = this.props

		let activeProgressStep = 0
		switch (selectedStep) {
			case 'date': {
				activeProgressStep = 0
				break
			}
			case 'address': {
				activeProgressStep = 1
				break
			}
			case 'confirm': {
				activeProgressStep = 3
				break
			}
			default: {
				activeProgressStep = 2
				break
			}
		}

		const steps = this.progressSteps.map((progressStep) => {
			const error = selectedStep === 'abort' && progressStep.id === 2
			return (
				<Step key={`progressstep-${progressStep.id}`}>
					<StepLabel error={error}>{progressStep.label}</StepLabel>
				</Step>
			)
		})

		return (
			<Stepper activeStep={activeProgressStep} alternativeLabel>
				{steps}
			</Stepper>
		)
	}
}

export default withStyles(styleSheet)(Progress)
