import { default as i18n } from 'i18next'
import elementsFetch from '../utils/fetch'

const exportUrl = (exportType) => {
	if (exportType === 'afspraken') {
		return '/api/orders/export/afspraken.csv'
	}
	if (exportType === 'ritten') {
		return '/api/tours/export/ritten.csv'
	}
	return null
}

const get = async (exportType) => {
	let response
	try {
		const url = exportUrl(exportType)
		if (!url) {
			throw new Error(i18n.t('app:exports.Error.unknownExportType'))
		}
		response = await elementsFetch(url)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.text()
	}
}

const api = {
	get
}

export default api
