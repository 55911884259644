import { combineReducers } from 'redux'
import dateProposalReducer from './dateproposal/reducer'
import depotsReducer from './depots/reducer'
import driversReducer from './drivers/reducer'
import excludedServiceDatesReducer from './excludedservicedates/reducer'
import manualPlanningsReducer from './manualplannings/reducer'
import notificationsReducer from './notifications/reducer'
import orderReducer from './order/reducer'
import ordersReducer from './orders/reducer'
import planningsReducer from './plannings/reducer'
import planningTypesReducer from './planningtypes/reducer'
import postcodesReducer from './postcodes/reducer'
import toursReducer from './tours/reducer'
import unavailableProposalDatesReducer from './unavailableproposaldates/reducer'
import unscheduledOrdersReducer from './unscheduledorders/reducer'
import userReducer from './user/reducer'
import vehicleUnavailabilityReducer from './vehicleUnavailability/reducer'
import vehiclesReducer from './vehiclescustom/reducer'

const rootReducer = combineReducers({
	dateProposalState: dateProposalReducer,
	depotsState: depotsReducer,
	driversState: driversReducer,
	excludedServiceDatesState: excludedServiceDatesReducer,
	manualPlanningsState: manualPlanningsReducer,
	notificationsState: notificationsReducer,
	orderState: orderReducer,
	ordersState: ordersReducer,
	planningTypesState: planningTypesReducer,
	planningsState: planningsReducer,
	postcodesState: postcodesReducer,
	toursState: toursReducer,
	unavailableProposalDatesState: unavailableProposalDatesReducer,
	unscheduledOrdersState: unscheduledOrdersReducer,
	userState: userReducer,
	vehicleUnavailabilityState: vehicleUnavailabilityReducer,
	vehiclesState: vehiclesReducer
})

export default rootReducer
