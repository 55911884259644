import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../redux/drivers/selectors'
import Driver from './Driver'

class DriverContainer extends PureComponent {
	static propTypes = {
		selectedDriverId: PropTypes.string,
		driver: PropTypes.object
	}

	render() {
		const { selectedDriverId, driver } = this.props

		if (!selectedDriverId) {
			return <div />
		}

		return <Driver driver={driver} />
	}
}

const connector = connect((state, props) => ({
	driver: driversSelectors.findDriverByIdSelector(
		state.driversState,
		props.selectedDriverId
	)
}))

export default connector(DriverContainer)
