import actionTypes from './actionTypes'

const actionCreators = {
	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	fetchOptimization: (id, tours, startAtDepot) => ({
		type: actionTypes.FETCH_OPTIMIZATION,
		meta: {
			id
		},
		payload: {
			tours,
			startAtDepot
		}
	}),

	fetchOptimizationFailure: (id, errorMessages) => ({
		type: actionTypes.FETCH_OPTIMIZATION_FAILURE,
		meta: {
			id
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchOptimizationRequest: (id, tours) => ({
		type: actionTypes.FETCH_OPTIMIZATION_REQUEST,
		meta: {
			id
		},
		payload: {
			tours
		}
	}),

	fetchOptimizationSuccess: (id, tours, unscheduledOrderKeys) => ({
		type: actionTypes.FETCH_OPTIMIZATION_SUCCESS,
		meta: {
			id
		},
		payload: {
			tours,
			unscheduledOrderKeys
		}
	}),

	fetchReduction: (id, tours, startAtDepot) => ({
		type: actionTypes.FETCH_REDUCTION,
		meta: {
			id
		},
		payload: {
			tours,
			startAtDepot
		}
	}),

	fetchReductionFailure: (id, errorMessages) => ({
		type: actionTypes.FETCH_REDUCTION_FAILURE,
		meta: {
			id
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchReductionRequest: (id, tours) => ({
		type: actionTypes.FETCH_REDUCTION_REQUEST,
		meta: {
			id
		},
		payload: {
			tours
		}
	}),

	fetchReductionSuccess: (id, tours, unscheduledOrderKeys) => ({
		type: actionTypes.FETCH_REDUCTION_SUCCESS,
		meta: {
			id
		},
		payload: {
			tours,
			unscheduledOrderKeys
		}
	}),

	fetchValidation: (id, tours, startAtDepot) => ({
		type: actionTypes.FETCH_VALIDATION,
		meta: {
			id
		},
		payload: {
			tours,
			startAtDepot
		}
	}),

	fetchValidationFailure: (id, errorMessages) => ({
		type: actionTypes.FETCH_VALIDATION_FAILURE,
		meta: {
			id
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchValidationRequest: (id, tours) => ({
		type: actionTypes.FETCH_VALIDATION_REQUEST,
		meta: {
			id
		},
		payload: {
			tours
		}
	}),

	fetchValidationSuccess: (id, tours) => ({
		type: actionTypes.FETCH_VALIDATION_SUCCESS,
		meta: {
			id
		},
		payload: {
			tours
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	save: (manualPlanning) => ({
		type: actionTypes.SAVE,
		payload: {
			manualPlanning
		}
	}),

	saveFailure: (id, errorMessages) => ({
		type: actionTypes.SAVE_FAILURE,
		meta: {
			id
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	saveRequest: (manualPlanning) => ({
		type: actionTypes.SAVE_REQUEST,
		payload: {
			manualPlanning
		}
	}),

	saveSuccess: (manualPlanning) => ({
		type: actionTypes.SAVE_SUCCESS,
		payload: {
			manualPlanning
		}
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	})
}

export default actionCreators
