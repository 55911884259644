import { PureComponent } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TourEditContainer from './TourEdit'
import TourSelectionContainer from './TourSelection'
import TourComparisonContainer from './TourComparison'

const styleSheet = {
	split: {
		display: 'flex',
		height: '100%'
	},
	list: {
		marginRight: 16,
		width: 600
	},
	details: {
		flex: 1
	}
}

class Planning extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		lock: PropTypes.func.isRequired,
		selectedTours: PropTypes.array.isRequired,
		unlock: PropTypes.func.isRequired
	}

	state = {
		mapChannel: new BroadcastChannel('map'),
		showEdit: false
	}

	static getDerivedStateFromProps(props, state) {
		// Move away from the edit view if there aren't any tours selected
		if (state.showEdit && props.selectedTours.length === 0) {
			return {
				showEdit: false
			}
		}
		return null
	}

	componentDidMount() {
		this.sendEditMode()
	}

	componentWillUnmount() {
		const { mapChannel } = this.state
		mapChannel.close()
	}

	componentDidUpdate(prevProps, prevState) {
		const { showEdit } = this.state
		// Send updates to the map window
		if (prevState.showEdit !== showEdit) {
			this.sendEditMode()
		}
	}

	sendEditMode() {
		const { mapChannel, showEdit } = this.state
		mapChannel.postMessage({
			type: 'map-set-manual-planning-mode',
			payload: { manualPlanningMode: showEdit }
		})
	}

	handleCancelManualPlanning = () => {
		// Unlock the selected tours
		const { unlock, selectedTours } = this.props
		selectedTours.forEach((tour) => {
			if (tour.lock) {
				unlock(tour.id)
			}
		})
		// Switch to view mode
		this.setState({
			showEdit: false
		})
	}

	handleStartManualPlanning = () => {
		// Lock the selected tours
		const { lock, selectedTours } = this.props
		selectedTours.forEach((tour) => {
			if (!tour.lock) {
				lock(tour.id)
			}
		})
		// Switch to edit mode
		this.setState({
			showEdit: true
		})
	}

	handleFinishManualPlanning = () => {
		this.setState({
			showEdit: false
		})
	}

	render() {
		const { classes } = this.props
		const { showEdit } = this.state

		if (showEdit) {
			return (
				<TourEditContainer
					cancel={this.handleCancelManualPlanning}
					finish={this.handleFinishManualPlanning}
				/>
			)
		}

		return (
			<div className={classes.split}>
				<div className={classes.list}>
					<TourSelectionContainer />
				</div>
				<div className={classes.details}>
					<TourComparisonContainer
						startManualPlanning={this.handleStartManualPlanning}
					/>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Planning)
