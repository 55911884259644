import moment from 'moment-timezone'

const hours = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00'
]

const MONDAY = 1
const TUESDAY = 2
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5
const SATURDAY = 6
const SUNDAY = 7
const days = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]

const computeDayEntryTop = (from) => {
	const fromMoment = moment.unix(from).tz('Europe/Amsterdam')

	// we compute the difference between the from and 00:00 to determine the 'top' property
	// this duration in minutes is the offset from the top of the day viewer column (every row in the week viewer grid represents 1 hour)
	const midnight = moment.unix(from).startOf('day')
	const offset = fromMoment.diff(midnight)
	const offsetMinutes = moment.duration(offset).asMinutes()

	return offsetMinutes
}

const computeDayEntryHeight = (from, till) => {
	const fromMoment = moment.unix(from).tz('Europe/Amsterdam')
	const tillMoment = moment.unix(till).tz('Europe/Amsterdam')

	// compute entry height based on duration (diff between from and till) in minutes
	// every row in the week viewer grid represents 1 hour
	const duration = tillMoment.diff(fromMoment)
	const durationMinutes = moment.duration(duration).asMinutes()
	return durationMinutes
}

// this merge function will merge the item into the given array of availabilty items
// if the new item overlaps with one or more existing items, those overlapping items will be merged into new ones
const mergeAvailability = (availability, item) => {
	let temp = { ...item }
	const newAvailability = availability.reduce((acc, currentValue) => {
		if (currentValue.till < temp.from) {
			// the existing item comes completely before the new item, keep the existing
			acc.push(currentValue)
		} else if (temp.till < currentValue.from) {
			// the existing item comes completely after the new item, keep the existing
			acc.push(currentValue)
		} else {
			// the existing item overlaps completely or partially with the new item, merge those items
			// we take the from and till timestamps of both items and sort them ascending
			const points = [
				currentValue.from,
				currentValue.till,
				temp.from,
				temp.till
			]
			points.sort((a, b) => a - b)
			// the merged item starts at the lowest timestamp and ends at the highest
			temp = {
				from: points[0],
				till: points[3]
			}
		}

		return acc
	}, [])

	newAvailability.push(temp)

	return newAvailability
}

export {
	hours,
	days,
	computeDayEntryTop,
	computeDayEntryHeight,
	mergeAvailability
}
