import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = {
	postcode: {
		flex: 1
	}
}

class Postcode extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		country: PropTypes.string.isRequired,
		error: PropTypes.bool,
		change: PropTypes.func.isRequired
	}

	state = {
		code: ''
	}

	handleChangeCode = (event) => {
		const postcode = event.target.value

		const code = postcode.toUpperCase()

		this.setState({
			code
		})

		this.handleChange(code)
	}

	handleChange = (postcode) => {
		const { change } = this.props

		change(postcode)
	}

	maxPostcodeLength = () => {
		const { country } = this.props

		if (country === 'NL') return '7'
		if (country === 'BE') return '4'
		if (country === 'DE') return '5'

		return null
	}

	render() {
		const { classes, error } = this.props
		const { code } = this.state

		const maxLength = this.maxPostcodeLength()

		let errorText
		if (error) {
			errorText = i18n.t('app:postcodeadmin.Postcode.codeErrorText')
		}

		return (
			<TextField
				label={i18n.t('app:postcodeadmin.Postcode.code')}
				className={classes.postcode}
				value={code}
				onChange={this.handleChangeCode}
				margin="normal"
				error={error}
				helperText={errorText}
				inputProps={{
					maxLength
				}}
			/>
		)
	}
}

export default withStyles(styles)(Postcode)
