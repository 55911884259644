import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	List as MaterialList,
	ListSubheader,
	Typography,
	Divider
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import ListItem from './ListItem'

const styles = {
	root: {
		paddingTop: 16,
		overflowY: 'scroll'
	},
	header: {
		display: 'flex',
		paddingRight: 32
	},
	column: {
		width: '40%'
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		remove: PropTypes.func.isRequired,
		unavailability: PropTypes.array.isRequired,
		vehicleId: PropTypes.string.isRequired
	}

	handleRemove = (item) => {
		const { remove, vehicleId } = this.props

		remove({ vehicleId, from: item.from, till: item.till })
	}

	render() {
		const { classes, vehicleId, unavailability } = this.props

		if (unavailability.length === 0) {
			return (
				<div className={classes.root}>
					<Typography variant="body2">
						{i18n.t('app:vehicleUnavailability.Items.empty')}
					</Typography>
				</div>
			)
		}

		const items = unavailability.map((item) => (
			<ListItem
				key={`${vehicleId}-${item.from}-${item.till}`}
				item={item}
				remove={this.handleRemove}
			/>
		))

		return (
			<div className={classes.root}>
				<MaterialList disablePadding>
					<ListSubheader
						disableGutters
						disableSticky
						className={classes.header}
					>
						<span className={classes.column}>
							{i18n.t('app:vehicleUnavailability.Items.from')}
						</span>
						<span className={classes.column}>
							{i18n.t('app:vehicleUnavailability.Items.till')}
						</span>
					</ListSubheader>
					<Divider />
					{items}
				</MaterialList>
			</div>
		)
	}
}

export default withStyles(styles)(List)
