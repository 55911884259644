import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { ORDER_TYPE_SERVICE } from '../../../utils/constants'
import localizedDescription from '../../../utils/localizedDescription'
import { fields, localizedText } from '../../../utils/localizedText'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16
	}
}

class Properties extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		let appointmentStatus
		if (order.appointmentStatus) {
			appointmentStatus = (
				<Grid item xs={4}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:appointmentscheduler.Order.appointmentStatus')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedText(fields.APPOINTMENT_STATUS, order.appointmentStatus)}
					</Typography>
				</Grid>
			)
		}

		let contactStatus
		if (order.contactStatus) {
			contactStatus = (
				<Grid item xs={4}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:appointmentscheduler.Order.contactStatus')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedText(fields.CONTACT_STATUS, order.contactStatus)}
					</Typography>
				</Grid>
			)
		}

		let priority
		if (order.priority && order.priority.key && order.priority.descriptions) {
			priority = (
				<Grid item xs={4}>
					<Typography variant="caption" color="secondary">
						{i18n.t('app:appointmentscheduler.Order.priority')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedDescription(order.priority.descriptions)}
					</Typography>
				</Grid>
			)
		}

		let route
		if (order.route && order.route.descriptions) {
			route = (
				<Grid item xs={4}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:appointmentscheduler.Order.route')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedDescription(order.route.descriptions)}
					</Typography>
				</Grid>
			)
		}

		let orderType
		if (
			order.orderType &&
			order.orderType.descriptions &&
			order.orderType.key === ORDER_TYPE_SERVICE
		) {
			orderType = (
				<Grid item xs={4}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:appointmentscheduler.Order.orderType')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedDescription(order.orderType.descriptions)}
					</Typography>
				</Grid>
			)
		}

		let planningType
		if (order.planningType && order.planningType.descriptions) {
			planningType = (
				<Grid item xs={4}>
					<Typography variant="caption" color="primary">
						{i18n.t('app:appointmentscheduler.Order.planningType')}
					</Typography>
					<Typography variant="body2" paragraph>
						{localizedDescription(order.planningType.descriptions)}
					</Typography>
				</Grid>
			)
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.properties')}
				</Typography>
				<Grid container>
					{appointmentStatus}
					{contactStatus}
					{priority}
					{route}
					{orderType}
					{planningType}
				</Grid>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Properties)
