import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = {
	root: {
		display: 'flex'
	},
	title: {
		flex: 1
	},
	newButton: {
		marginRight: 16
	}
}

class Header extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicleId: PropTypes.string.isRequired
	}

	render() {
		const { classes, vehicleId } = this.props

		return (
			<div className={classes.root}>
				<Typography className={classes.title} variant="h5" gutterBottom>
					{i18n.t('app:vehicleUnavailability.Main.title')}
				</Typography>
				<Button
					className={classes.newButton}
					variant="contained"
					color="primary"
					component={Link}
					to={`/vehicleunavailability/${vehicleId}/new`}
				>
					{i18n.t('app:vehicleUnavailability.Main.new')}
				</Button>
				<Button
					variant="outlined"
					component={Link}
					to={`/vehicleplanning/${vehicleId}`}
				>
					{i18n.t('app:vehicleUnavailability.Main.close')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styles)(Header)
