import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	ListItem as MaterialListItem,
	Typography,
	ListItemText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import localizedDescription from '../../../../utils/localizedDescription'

const styleSheet = (theme) => ({
	active: {
		backgroundColor: theme.palette.action.selected
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		planningType: PropTypes.object.isRequired,
		isSelected: PropTypes.bool
	}

	render() {
		const { planningType, classes, isSelected } = this.props

		const description = localizedDescription(planningType.descriptions)

		const primary = (
			<div className={classes.primary}>
				<Typography variant="subtitle1">{description}</Typography>
			</div>
		)

		return (
			<MaterialListItem
				button
				component={Link}
				to={`/planningtypeadmin/${planningType.planningTypeKey}`}
				className={isSelected ? classes.active : ''}
			>
				<ListItemText disableTypography primary={primary} />
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
