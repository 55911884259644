import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiUser from '../../services/apiUser'
import actionTypes from './actionTypes'
import authActionCreators from '../auth/actionCreators'
import actionCreators from './actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'

function* fetchUser() {
	while (true) {
		yield take(actionTypes.FETCH_USER)
		yield put(actionCreators.fetchUserRequest())
		try {
			const userData = yield call(apiUser.get)
			yield put(actionCreators.fetchUserSuccess(userData))
		} catch (error) {
			// Fetching our own user failed, so something must be broken...
			// Immediately logout if this ever happens
			yield put(authActionCreators.logout())
		}
	}
}

function* newPasswordReset() {
	while (true) {
		const newPasswordResetAction = yield take(actionTypes.NEWPASSWORDRESET)
		yield put(actionCreators.newPasswordResetRequest())
		try {
			yield call(
				apiUser.newPasswordReset,
				newPasswordResetAction.payload.userName
			)
			yield put(actionCreators.newPasswordResetSuccess())
			yield put(
				notificationsActionCreators.addNotification(
					i18n.t('app:elements.Login.newResetSuccess')
				)
			)
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:elements.Login.resetFailure')
			yield put(
				actionCreators.newPasswordResetFailure({
					userMessage,
					techMessage
				})
			)
		}
	}
}

function* resetPassword() {
	while (true) {
		const resetPasswordAction = yield take(actionTypes.RESETPASSWORD)
		yield put(actionCreators.resetPasswordRequest())
		try {
			yield call(
				apiUser.resetPassword,
				resetPasswordAction.payload.userName,
				resetPasswordAction.payload.password,
				resetPasswordAction.payload.pin
			)
			yield put(actionCreators.resetPasswordSuccess())
			yield put(
				notificationsActionCreators.addNotification(
					i18n.t('app:elements.Login.resetSuccess')
				)
			)
			// Automatically login after successful password reset
			yield put(
				authActionCreators.login(
					resetPasswordAction.payload.userName,
					resetPasswordAction.payload.password
				)
			)
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:elements.Login.resetFailure')
			yield put(
				actionCreators.resetPasswordFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
			// Trigger logout so the user returns to the login screen
			yield put(authActionCreators.logout())
		}
	}
}

export default function* rootSaga() {
	yield all(
		[fetchUser, newPasswordReset, resetPassword].map((saga) => fork(saga))
	)
}
