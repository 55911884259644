import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import config from '../config'
import actionCreators from '../redux/plannings/actionCreators'

const { webSocketUrl } = config

class PlanningsSocket {
	init(store) {
		this.store = store
	}

	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
		}
	}

	open = () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/plannings`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.socket.on('connect', this.handleConnect)
			this.socket.on('disconnect', this.handleDisconnect)
			this.socket.on('planning_status_updated', this.handleUpdated)
		}
	}

	handleConnect = () => {
		this.store.dispatch(actionCreators.socketConnected())
		// Fetch plannings when the socket has connected
		this.store.dispatch(actionCreators.fetch())
	}

	handleDisconnect = () => {
		this.store.dispatch(actionCreators.socketDisconnected())
	}

	handleUpdated = ({ planningTypeKey, status, dates }) => {
		// Update plannings
		this.store.dispatch(actionCreators.update(planningTypeKey, status, dates))
	}
}

const planningsSocket = new PlanningsSocket()

export default planningsSocket
