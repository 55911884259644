import { fields, localizedText } from '../localizedText'

// When the address on an appointment is confirmed, the customer object of the order is copied to the appointment
// So when displaying the customer on the appointment, we use the order.customer before the address is confirmed, and order.appointment.customer after
export const appointmentCustomer = (order) => {
	if (order.appointment.customer) {
		return {
			...order.customer,
			...order.appointment.customer
		}
	}
	return order.customer
}

// Adjust the appointment format for saving (the customer has a different structure than the one we receive from the orders api)
export const formatAppointmentCustomer = (customer) => {
	if (!customer) {
		return null
	}
	return {
		street: customer.street,
		houseNumber: customer.houseNumber,
		postCode: customer.postCode,
		city: customer.city,
		email: customer.email,
		telephoneNumber: customer.telephoneNumber,
		mobileNumber: customer.mobileNumber
	}
}

// Parse a customer object and format it for a consistent appearance in the UI
// Important: this function has a significant processing time, so using it when processing a large list will likely result in janky UI behavior
export const formatCustomer = (customer) => {
	const name = customer.name
		? customer.name
				.trim()
				.split(' ')
				.map(
					(namePart) =>
						`${namePart.charAt(0).toUpperCase()}${namePart.slice(1)}`
				)
				.join(' ')
		: ''
	const street = customer.street
		? customer.street
				.trim()
				.split(' ')
				.map(
					(streetPart) =>
						`${streetPart.charAt(0).toUpperCase()}${streetPart.slice(1)}`
				)
				.join(' ')
		: ''
	const houseNumber = customer.houseNumber ? customer.houseNumber : ''
	const postCode = customer.postCode ? customer.postCode.toUpperCase() : ''
	const city = customer.city
		? `${customer.city.charAt(0).toUpperCase()}${customer.city.slice(1)}`
		: ''
	const country = customer.country
		? localizedText(fields.COUNTRY, customer.country.key)
		: ''
	const countryKey = customer.country ? customer.country.key.toUpperCase() : ''
	const language = customer.language
		? localizedText(fields.LANGUAGE, customer.language.key)
		: ''
	const languageKey = customer.language
		? customer.language.key.toUpperCase()
		: ''
	const email = customer.email ? customer.email.toLowerCase() : ''

	// Single line address
	const addressLineLeft = `${street} ${houseNumber}`.trim()
	const addressLineSeparator = postCode || city ? ', ' : ''
	const addressLineRight = `${postCode} ${city}`.trim()
	const addressLine = `${addressLineLeft}${addressLineSeparator}${addressLineRight}`

	// Parse and format phone numbers
	const telephoneNumber = customer.telephoneNumber
		? customer.telephoneNumber.replace(/[^+0-9]/g, '')
		: ''
	const mobileNumber = customer.mobileNumber
		? customer.mobileNumber.replace(/[^+0-9]/g, '')
		: ''

	return {
		name,
		street,
		houseNumber,
		postCode,
		city,
		country,
		countryKey,
		language,
		languageKey,
		email,
		telephoneNumber,
		mobileNumber,
		addressLine
	}
}
