import { combineReducers } from 'redux'

import actionTypes from './actionTypes'

const initialUnavailabilityState = []
const unavailabilityReducer = (state = initialUnavailabilityState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialUnavailabilityState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.unavailability
		}

		case actionTypes.FETCH_FAILURE: {
			return initialUnavailabilityState
		}

		case actionTypes.CREATE_SUCCESS: {
			return [action.payload.unavailability, ...state]
		}

		case actionTypes.REMOVE_SUCCESS: {
			const { unavailability } = action.meta
			return state.filter(
				(item) =>
					item.from !== unavailability.from || item.till !== unavailability.till
			)
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	unavailability: unavailabilityReducer,
	meta: metaReducer
})
