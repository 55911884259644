import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	CircularProgress,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarCheckFal,
	PhoneVolumeFal,
	TruckMovingFal
} from '@oliverit/react-fontawesome'
import { green } from '@material-ui/core/colors'
import { default as i18n } from 'i18next'
import ConfirmDialog from '../../Shared/ConfirmDialog'
import { ORDER_KEY_TYPE_SALESORDER_DELIVERYGROUP } from '../../../utils/constants'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	headerRow: {
		display: 'flex',
		alignItems: 'center'
	},
	headerText: {
		paddingBottom: 16
	},
	headerTextFlex: {
		flex: 1
	},
	green: {
		color: green[500]
	},
	confirming: {
		marginRight: 8
	},
	subheaderRow: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: 16,
		paddingBottom: 12
	},
	leftIcon: {
		marginRight: 8
	},
	leftIconGreen: {
		color: green[500],
		marginRight: 8
	},
	reloadButton: {
		marginLeft: 4
	}
}

class Action extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		showAppointment: PropTypes.func.isRequired,
		start: PropTypes.func.isRequired,
		takeOver: PropTypes.func.isRequired
	}

	state = {
		showConfirmTakeOverDialog: false
	}

	componentDidUpdate(prevProps) {
		const { order } = this.props
		if (prevProps.order !== order) {
			this.setState({
				showConfirmTakeOverDialog: false
			})
		}
	}

	handleTakeOver = () => {
		this.setState({
			showConfirmTakeOverDialog: true
		})
	}

	handleTakeOverCancel = () => {
		this.setState({
			showConfirmTakeOverDialog: false
		})
	}

	handleTakeOverConfirm = () => {
		const { takeOver } = this.props
		this.setState({
			showConfirmTakeOverDialog: false
		})
		takeOver()
	}

	render() {
		const { classes, order, showAppointment, start } = this.props
		const { showConfirmTakeOverDialog } = this.state

		const salesOrderText =
			order.salesOrderDeliveryGroup && order.salesOrderDeliveryGroup !== '0'
				? `${order.salesOrderNumber} (${order.salesOrderDeliveryGroup})`
				: `${order.salesOrderNumber}`

		const deliveryText = order.deliveryNumber
			? `${order.deliveryNumber}`
			: `${i18n.t('app:appointmentscheduler.Order.deliveryNumberUnknown')}`

		// Order is locked
		if (order.lock) {
			const lockedByText = `${i18n.t(
				'app:appointmentscheduler.Order.lockedBy'
			)} ${order.lock.firstName} ${order.lock.lastName}`
			return (
				<div className={classes.root}>
					<div className={classes.headerRow}>
						<Typography variant="h4" className={classes.headerTextFlex}>
							{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
						</Typography>
						<Button color="secondary" onClick={this.handleTakeOver}>
							{i18n.t('app:appointmentscheduler.Order.takeOverProcessing')}
						</Button>
					</div>
					<Typography variant="h6" color="textSecondary">
						{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
					</Typography>
					<div className={classes.subheaderRow}>
						<SvgIcon color="secondary" className={classes.leftIcon}>
							<PhoneVolumeFal />
						</SvgIcon>
						<Typography variant="body2" color="secondary">
							{lockedByText}
						</Typography>
					</div>
					<ConfirmDialog
						cancel={this.handleTakeOverCancel}
						cancelButtonText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmTakeOverDialog.cancel'
						)}
						confirm={this.handleTakeOverConfirm}
						confirmButtonText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmTakeOverDialog.confirm'
						)}
						descriptionText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmTakeOverDialog.description'
						)}
						isDestructive
						isOpen={showConfirmTakeOverDialog}
						titleText={i18n.t(
							'app:appointmentscheduler.Order.ConfirmTakeOverDialog.title'
						)}
					/>
				</div>
			)
		}

		// Order is planned (no more changes allowed)
		if (order.isAppointmentPlanned) {
			return (
				<div>
					<div className={classes.headerRow}>
						<Typography variant="h4" className={classes.headerTextFlex}>
							{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
						</Typography>
						<Button color="primary" onClick={showAppointment}>
							{i18n.t('app:appointmentscheduler.Order.showAppointment')}
						</Button>
					</div>
					<Typography variant="h6" color="textSecondary">
						{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
					</Typography>
					<div className={classes.subheaderRow}>
						<SvgIcon className={classes.leftIconGreen}>
							<TruckMovingFal />
						</SvgIcon>
						<Typography variant="body2" className={classes.green}>
							{i18n.t('app:appointmentscheduler.Order.planned')}
						</Typography>
					</div>
				</div>
			)
		}

		// Order is confirmed (can restart)
		if (order.isAppointmentConfirmed) {
			let restartProcessingButton
			if (order.orderKeyType === ORDER_KEY_TYPE_SALESORDER_DELIVERYGROUP) {
				// Only orders with the salesorder/deliverygroup source can be restarted
				// Orders with the delivery source are appointed in SAP and cannot be modified with the MuleSoft plan-assignments interface
				restartProcessingButton = (
					<Button color="secondary" onClick={start}>
						{i18n.t('app:appointmentscheduler.Order.restartProcessing')}
					</Button>
				)
			}

			return (
				<div>
					<div className={classes.headerRow}>
						<Typography variant="h4" className={classes.headerTextFlex}>
							{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
						</Typography>
						<Button color="primary" onClick={showAppointment}>
							{i18n.t('app:appointmentscheduler.Order.showAppointment')}
						</Button>
						{restartProcessingButton}
					</div>
					<Typography variant="h6" color="textSecondary">
						{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
					</Typography>
					<div className={classes.subheaderRow}>
						<SvgIcon className={classes.leftIconGreen}>
							<CalendarCheckFal />
						</SvgIcon>
						<Typography variant="body2" className={classes.green}>
							{i18n.t('app:appointmentscheduler.Order.confirmed')}
						</Typography>
					</div>
				</div>
			)
		}

		// Order is being confirmed
		if (order.appointment && order.appointment.isProcessing) {
			return (
				<div>
					<div className={classes.headerRow}>
						<Typography variant="h4" className={classes.headerTextFlex}>
							{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
						</Typography>
						<Button color="primary" onClick={showAppointment}>
							{i18n.t('app:appointmentscheduler.Order.showAppointment')}
						</Button>
					</div>
					<Typography variant="h6" color="textSecondary">
						{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
					</Typography>
					<div className={classes.subheaderRow}>
						<CircularProgress size={20} className={classes.confirming} />
						<Typography variant="body2" color="primary">
							{i18n.t('app:appointmentscheduler.Order.confirming')}
						</Typography>
					</div>
				</div>
			)
		}

		// Order ready to start processing
		return (
			<div>
				<Typography variant="h4">
					{i18n.t('app:appointmentscheduler.Order.order')} {salesOrderText}
				</Typography>
				<Typography
					variant="h6"
					color="textSecondary"
					className={classes.headerText}
				>
					{i18n.t('app:appointmentscheduler.Order.delivery')} {deliveryText}
				</Typography>
				<Button variant="contained" color="primary" onClick={start}>
					{i18n.t('app:appointmentscheduler.Order.startProcessing')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Action)
