import moment from 'moment-timezone'
import { default as i18n } from 'i18next'

const desiredDeliveryWeek = (order) => {
	const now = moment.tz('Europe/Amsterdam')
	if (parseInt(order.year, 10) !== now.year()) {
		return `${i18n.t('app:appointmentscheduler.Order.week')} ${parseInt(
			order.week,
			10
		)} (${order.year})`
	}
	return `${i18n.t('app:appointmentscheduler.Order.week')} ${parseInt(
		order.week,
		10
	)}`
}

export default desiredDeliveryWeek
