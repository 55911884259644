import { InputLabel, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

const styleSheet = () => ({
	label: {
		marginTop: '16px',
		marginBottom: '8px'
	}
})

class Help extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	render() {
		const { classes } = this.props

		return (
			<>
				<Typography variant="h5">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.title')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.maxPeriod')}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.maxPeriod')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.maxDistance')}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.maxDistance')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.maxDrivingPeriod'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.maxDrivingPeriod')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.maxServicePeriod'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.maxServicePeriod')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.returnVolume')}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.returnVolume')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.dateProposalDirectVelocity'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Help.dateProposalDirectVelocity'
					)}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.startAtDepot')}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.startAtDepot')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.appointmentRequired'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Help.appointmentRequired'
					)}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t('app:planningtypeadmin.PlanningType.Details.WBKB')}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.1')}
				</Typography>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.2')}
				</Typography>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.3')}
				</Typography>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.4')}
				</Typography>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.5')}
				</Typography>
				<Typography variant="body2">
					{i18n.t('app:planningtypeadmin.PlanningType.Help.WKBK.6')}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.drivingPeriodFactorTitle'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Help.drivingPeriodFactors'
					)}
				</Typography>
				<InputLabel className={classes.label}>
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Details.serviceOrdersPriorityTitle'
					)}
				</InputLabel>
				<Typography variant="body2">
					{i18n.t(
						'app:planningtypeadmin.PlanningType.Help.serviceOrdersPriority'
					)}
				</Typography>
			</>
		)
	}
}

export default withStyles(styleSheet)(Help)
