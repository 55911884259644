import { createSelector } from 'reselect'
import escapeStringRegexp from '../../utils/escapeStringRegexp'

const postcodesSelector = (state) => state.postcodes
const selectedCountrySelector = (state) => state.selectedCountry
const searchSelector = (state) => state.search

const findPostcodesByCountrySelector = createSelector(
	[postcodesSelector, selectedCountrySelector],
	(postcodes, country) => postcodes.filter((code) => code.country === country)
)

const queryPostcode = (postcode, code) => {
	const queryRegex = new RegExp(escapeStringRegexp(code), 'i')
	if (postcode.start && queryRegex.test(postcode.start.toString())) {
		return true
	}
	if (postcode.end && queryRegex.test(postcode.end.toString())) {
		return true
	}
	return false
}

const queryDate = (postcode, date) => {
	if (!date) return true

	return postcode.from <= date && date <= postcode.till
}

const searchPostcodesSelector = createSelector(
	[findPostcodesByCountrySelector, searchSelector],
	(postcodes, search) =>
		postcodes.filter(
			(code) => queryPostcode(code, search.code) && queryDate(code, search.date)
		)
)

const selectors = {
	findPostcodesByCountrySelector,
	searchPostcodesSelector
}

export default selectors
