const actionTypes = {
	FETCH: 'drivers/FETCH',
	FETCH_REQUEST: 'drivers/FETCH_REQUEST',
	FETCH_SUCCESS: 'drivers/FETCH_SUCCESS',
	FETCH_FAILURE: 'drivers/FETCH_FAILURE',
	SEARCH: 'drivers/SEARCH',
	CLEAR_SEARCH: 'drivers/CLEAR_SEARCH',
	SELECT_CURRENT_WEEK: 'drivers/SELECT_CURRENT_WEEK',
	SELECT_NEXT_WEEK: 'drivers/SELECT_NEXT_WEEK',
	SELECT_PREVIOUS_WEEK: 'drivers/SELECT_PREVIOUS_WEEK',
	FETCH_AVAILABILITY: 'drivers/FETCH_AVAILABILITY',
	FETCH_AVAILABILITY_REQUEST: 'drivers/FETCH_AVAILABILITY_REQUEST',
	FETCH_AVAILABILITY_SUCCESS: 'drivers/FETCH_AVAILABILITY_SUCCESS',
	FETCH_AVAILABILITY_FAILURE: 'drivers/FETCH_AVAILABILITY_FAILURE',
	SELECT_AVAILABILITY_ITEM: 'drivers/SELECT_AVAILABILITY_ITEM',
	DESELECT_AVAILABILITY_ITEM: 'drivers/DESELECT_AVAILABILITY_ITEM',
	NEW_AVAILABILITY_ITEM: 'drivers/NEW_AVAILABILITY_ITEM',
	SAVE_AVAILABILITY: 'drivers/SAVE_AVAILABILITY',
	SAVE_AVAILABILITY_REQUEST: 'drivers/SAVE_AVAILABILITY_REQUEST',
	SAVE_AVAILABILITY_SUCCESS: 'drivers/SAVE_AVAILABILITY_SUCCESS',
	SAVE_AVAILABILITY_FAILURE: 'drivers/SAVE_AVAILABILITY_FAILURE'
}

export default actionTypes
