import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		...theme.mixins.gutters({
			paddingBottom: 16,
			paddingTop: 16
		})
	},
	centerTitle: {
		flex: 1,
		textAlign: 'center'
	},
	cancelButton: {
		marginLeft: 8
	}
})

class Header extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		save: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	handleSaveButtonClick = () => {
		const { save } = this.props
		save()
	}

	render() {
		const { classes, selectedMoment, vehicle } = this.props

		const formattedDate = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.format('DD MMM Y')

		return (
			<Paper className={classes.root}>
				<Typography variant="h5" className={classes.leftTitle}>
					{vehicle.licensePlate}
				</Typography>
				<Typography variant="h5" className={classes.centerTitle}>
					{formattedDate}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={this.handleSaveButtonClick}
				>
					{i18n.t('app:vehicleplanning.Vehicle.Edit.save')}
				</Button>
				<Button
					className={classes.cancelButton}
					variant="outlined"
					component={Link}
					to={`/vehicleplanning/${vehicle.vehicleId}`}
				>
					{i18n.t('app:vehicleplanning.Vehicle.Edit.cancel')}
				</Button>
			</Paper>
		)
	}
}

export default withStyles(styles)(Header)
