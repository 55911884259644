import { default as i18n } from 'i18next'
import numeral from 'numeral'

const SECONDS_IN_ONE_MINUTE = 60
const SECONDS_IN_ONE_HOUR = SECONDS_IN_ONE_MINUTE * 60
const SECONDS_IN_ONE_DAY = SECONDS_IN_ONE_HOUR * 24

const formatDimension = (centimeters = 0) =>
	`${numeral(centimeters / 100).format('0.00')} m`
const formatWeight = (grams = 0) =>
	`${numeral(Math.round(grams / 1000)).format('0')} kg`
const formatVolume = (liters = 0) =>
	`${numeral(Math.round(liters)).format('0')} l`
const formatSpeed = (speed = 0) => `${numeral(speed).format('0.00')} km/u`
const formatPercentage = (percentage = 0) =>
	numeral(percentage / 100).format('0 %')
const formatBool = (bool = false) =>
	bool ? i18n.t('app:generic.yes') : i18n.t('app:generic.no')
const formatDays = (days) =>
	`${days} ${i18n.t('app:vehicleplanning.Vehicle.reservationUnit')}`

const createUnknownProperty = (key, label) => ({
	key,
	value: i18n.t('app:vehicleplanning.Vehicle.unknown'),
	label
})

const createProperty = (key, value, label) => {
	if (value) {
		return {
			key,
			value,
			label
		}
	}

	return createUnknownProperty(key, label)
}

const createDimensionProperty = (key, dimension, label) =>
	createProperty(key, formatDimension(dimension), label)
const createWeightProperty = (key, weight, label) =>
	createProperty(key, formatWeight(weight), label)
const createVolumeProperty = (key, volume, label) =>
	createProperty(key, formatVolume(volume), label)
const createSpeedProperty = (key, speed, label) =>
	createProperty(key, formatSpeed(speed), label)
const createBoolProperty = (key, bool, label) =>
	createProperty(key, formatBool(bool), label)
const createPercentageProperty = (key, percentage, label) =>
	createProperty(key, formatPercentage(percentage), label)
const createReservationProperty = (
	key,
	defaultReservationEnabled,
	days,
	label
) => {
	if (defaultReservationEnabled) {
		return createProperty(key, formatDays(days), label)
	}
	return createProperty(
		key,
		i18n.t('app:vehicleplanning.Vehicle.noReservation'),
		label
	)
}

const extractReadOnlyProperties = (vehicle) => {
	const properties = []

	properties.push(
		createDimensionProperty(
			'length',
			vehicle.length,
			i18n.t('app:vehicleplanning.Vehicle.length')
		)
	)
	properties.push(
		createDimensionProperty(
			'width',
			vehicle.width,
			i18n.t('app:vehicleplanning.Vehicle.width')
		)
	)
	properties.push(
		createDimensionProperty(
			'height',
			vehicle.height,
			i18n.t('app:vehicleplanning.Vehicle.height')
		)
	)
	properties.push(
		createWeightProperty(
			'weight',
			vehicle.weight,
			i18n.t('app:vehicleplanning.Vehicle.weight')
		)
	)
	properties.push(
		createWeightProperty(
			'maxLoadWeight',
			vehicle.maxLoadWeight,
			i18n.t('app:vehicleplanning.Vehicle.maxLoadWeight')
		)
	)
	properties.push(
		createVolumeProperty(
			'maxLoadVolume',
			vehicle.maxLoadVolume,
			i18n.t('app:vehicleplanning.Vehicle.maxLoadVolume')
		)
	)
	properties.push(
		createSpeedProperty(
			'speed',
			vehicle.speed,
			i18n.t('app:vehicleplanning.Vehicle.speed')
		)
	)
	properties.push(
		createReservationProperty(
			'reservation',
			vehicle.defaultReservationEnabled,
			vehicle.defaultReservationAfterDays,
			i18n.t('app:vehicleplanning.Vehicle.reservationDays')
		)
	)

	return properties
}

const extractProperties = (vehicle) => {
	const properties = extractReadOnlyProperties(vehicle)

	properties.push(
		createPercentageProperty(
			'maxLoadPercentage',
			vehicle.maxLoadPercentage,
			i18n.t('app:vehicleplanning.Vehicle.maxLoadPercentage')
		)
	)
	properties.push(
		createBoolProperty(
			'scaffold',
			vehicle.scaffold,
			i18n.t('app:vehicleplanning.Vehicle.scaffold')
		)
	)
	properties.push(createProperty('maxPeriod', vehicle.maxPeriod))
	properties.push(createProperty('maxPeriodEnabled', vehicle.maxPeriodEnabled))

	return properties
}

const createCustomVehicle = (vehicle, moment) => ({
	vehicleId: vehicle.vehicleId,
	planningType: vehicle.planningType,
	returnVolume: vehicle.returnVolume,
	from: moment.startOf('day').unix(),
	till: moment.endOf('day').unix(),
	firstDriver: vehicle.firstDriver,
	secondDriver: vehicle.secondDriver,
	startDepot: vehicle.startDepot,
	endDepot: vehicle.endDepot,
	startAtDriverHome: vehicle.startAtDriverHome,
	scaffold: vehicle.scaffold,
	maxLoadPercentage: vehicle.maxLoadPercentage,
	maxPeriod: vehicle.maxPeriod || 0,
	maxPeriodEnabled: vehicle.maxPeriodEnabled || false
})

const formatPeriodInput = (period) => {
	const maxPeriodDays = period ? Math.floor(period / SECONDS_IN_ONE_DAY) : 0
	const maxPeriodHours = period
		? Math.floor(
				(period - maxPeriodDays * SECONDS_IN_ONE_DAY) / SECONDS_IN_ONE_HOUR
		  )
		: 0
	const maxPeriodMinutes = period
		? Math.floor(
				(period -
					maxPeriodHours * SECONDS_IN_ONE_HOUR -
					maxPeriodDays * SECONDS_IN_ONE_DAY) /
					SECONDS_IN_ONE_MINUTE
		  )
		: 0
	return { maxPeriodDays, maxPeriodHours, maxPeriodMinutes }
}

const formatPeriodText = (period) => {
	const { maxPeriodDays, maxPeriodHours, maxPeriodMinutes } =
		formatPeriodInput(period)
	const days = maxPeriodDays
		? `${maxPeriodDays}${i18n.t(
				'app:vehicleplanning.Planning.maxPeriodDaysUnit'
		  )}`
		: undefined
	const hours = maxPeriodHours
		? `${maxPeriodHours}${i18n.t(
				'app:vehicleplanning.Planning.maxPeriodHoursUnit'
		  )}`
		: undefined
	const minutes = maxPeriodMinutes
		? `${maxPeriodMinutes}${i18n.t(
				'app:vehicleplanning.Planning.maxPeriodMinutesUnit'
		  )}`
		: undefined
	return { days, hours, minutes }
}

const formatPeriodToSeconds = (
	maxPeriodDays,
	maxPeriodHours,
	maxPeriodMinutes
) => {
	const maxPeriod =
		maxPeriodDays * SECONDS_IN_ONE_DAY +
		maxPeriodHours * SECONDS_IN_ONE_HOUR +
		maxPeriodMinutes * SECONDS_IN_ONE_MINUTE
	return maxPeriod
}

export {
	createCustomVehicle,
	extractProperties,
	extractReadOnlyProperties,
	formatPeriodInput,
	formatPeriodText,
	formatPeriodToSeconds
}
