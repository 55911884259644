import config from '../../config'

// The plan-assignments do not contain all translated texts. This is by design by Swiss Sense to reduce data transfer through MuleSoft.
// The texts are loaded when the selected plan-assignment is loaded by a user. Then the texts of the language of the user are loaded through MuleSoft.
// The texts of the filters however need to be available at all times. Therefore these texts are statically provided in this file.
// There are plans to sync this texts from SAP through MuleSoft. But those plans are in early stages, this solution is the simplest solution for now.
// A back-end solution was also considered, but then an update of the texts would require a back-end deployment, which can only be performed outside
// business hours due to the Heroku restart. A front-end deployment does not have any downtime.

const texts = {
	'appointmentStatus:01': {
		de: 'Termin noch nicht vergeben',
		en: 'Appointment not made yet',
		fr: 'Rendez-vous pas encore pris',
		nl: 'Afspraak nog niet gemaakt'
	},
	'appointmentStatus:02': {
		de: 'Termin gemacht',
		en: 'Appointment made',
		fr: 'Rendez-vous pris',
		nl: 'Afspraak gemaakt'
	},
	'appointmentStatus:03': {
		de: 'Termin N/A',
		en: 'Appointment N/A',
		fr: 'Rendez-vous S.O.',
		nl: 'Afspraak n.v.t.'
	},
	'appointmentStatus:04': {
		de: 'Termin basierend auf Lager',
		en: 'Make appointment due to stock',
		fr: 'Prenez rendez-vous en fonction de Stock',
		nl: 'Afspraak maken o.b.v. voorraad'
	},
	'contactStatus:01': {
		de: 'Kein Kontakt (neu)',
		en: 'No contact (new)',
		fr: 'Pas de contact (nouveau)',
		nl: 'Geen contact (nieuw)'
	},
	'contactStatus:02': {
		de: 'Erfolgreich',
		en: 'Successful',
		fr: 'À succès',
		nl: 'Succesvol'
	},
	'contactStatus:03': {
		de: 'Rückrufanfrage',
		en: 'Callback request',
		fr: 'Demande de rappel',
		nl: 'Terugbel verzoek'
	},
	'contactStatus:04': {
		de: 'Termin wegen Verspätung verschieben',
		en: 'Reschedule appointment due to delay',
		fr: "Reprogrammer un rendez-vous en raison d'un retard",
		nl: 'Afspraak verzetten ivm vertraging'
	},
	'contactStatus:05': {
		de: 'Keinen Ton',
		en: '',
		fr: '',
		nl: 'Geen gehoor'
	},
	'contactStatus:06': {
		de: 'Termin absagen durch Verspätung',
		en: 'Cancel appointment because of delay',
		fr: '',
		nl: 'Afspraak afzeggen ivm vertraging'
	},
	'country:AT': {
		de: '',
		en: '',
		fr: '',
		nl: ''
	},
	'country:BE': {
		de: 'Belgien',
		en: 'Belgium',
		fr: 'Belgique',
		nl: 'België'
	},
	'country:CH': {
		de: 'Schweiz',
		en: 'Switzerland',
		fr: 'Suisse',
		nl: 'Zwitserland'
	},
	'country:DE': {
		de: 'Deutschland',
		en: 'Germany',
		fr: 'Allemagne',
		nl: 'Duitsland'
	},
	'country:NL': {
		de: 'Niederlande',
		en: 'Netherlands',
		fr: 'Pays-Bas',
		nl: 'Nederland'
	},
	'language:DE': {
		de: 'Deutsch',
		en: 'German',
		fr: 'Allemand',
		nl: 'Duits'
	},
	'language:FR': {
		de: 'Französisch',
		en: 'French',
		fr: 'Français',
		nl: 'Frans'
	},
	'language:NL': {
		de: 'Niederländisch',
		en: 'Dutch',
		fr: 'Néerlandais',
		nl: 'Nederlands'
	},
	'orderType:DELIVERY': {
		de: 'Auslieferung',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering'
	},
	'orderType:LF': {
		de: 'Auslieferung',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering'
	},
	'orderType:SERVICE': {
		de: 'Auslieferung Service',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering Service'
	},
	'orderType:ZSLF': {
		de: 'Auslieferung Service',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering Service'
	},
	'orderType:ZSOU': {
		de: 'Rück Lager 2 Outlet',
		en: 'Replenishment Dlv.',
		fr: 'Livraison réappro.',
		nl: 'Ret vrd 2 outlet'
	},
	'planningType:Z01': {
		de: 'DC Uden',
		en: 'DC Uden',
		fr: 'DC Uden',
		nl: 'DC Uden'
	},
	'planningType:Z02': {
		de: '',
		en: '',
		fr: '',
		nl: 'Depots Duitsland'
	},
	'planningType:Z03': {
		de: '',
		en: '',
		fr: '',
		nl: 'Next day'
	},
	'planningType:Z04': {
		de: '',
		en: '',
		fr: '',
		nl: 'Service'
	},
	'planningType:Z05': {
		de: '',
		en: '',
		fr: '',
		nl: 'Filiaal'
	},
	'planningType:Z06': {
		de: '',
		en: '',
		fr: '',
		nl: 'Meerdaagse rit'
	},
	'planningType:Z07': {
		de: '',
		en: '',
		fr: '',
		nl: 'Service Duitsland'
	},
	'priority:1': {
		de: 'Hoch',
		en: 'High',
		fr: '',
		nl: 'Hoog'
	},
	'priority:2': {
		de: 'Normal',
		en: 'Normal item',
		fr: '',
		nl: 'Normaal'
	},
	'priority:20': {
		de: 'Prio 20',
		en: '',
		fr: '',
		nl: 'Prio 20'
	},
	'priority:3': {
		de: 'Dringend Eskalation',
		en: 'High',
		fr: '',
		nl: 'Urgent escalatie'
	},
	'priority:41': {
		de: 'Abruf: bis 6 Monat',
		en: '',
		fr: '',
		nl: 'Afroep: tot 6 maand'
	},
	'priority:42': {
		de: 'Abruf: nach 6 Mon.',
		en: '',
		fr: '',
		nl: 'Afroep: na 6 maand'
	},
	'priority:50': {
		de: 'Kontrolle voraus',
		en: '',
		fr: '',
		nl: 'Controle vooraf'
	},
	'priority:61': {
		de: 'Abnahme Verplichtung',
		en: '',
		fr: '',
		nl: 'Afname verplichting'
	},
	'priority:62': {
		de: 'Abnahme Verpl. CS',
		en: '',
		fr: '',
		nl: 'Afn. verplicht CS NL'
	},
	'priority:90': {
		de: 'Teillieferung',
		en: '',
		fr: '',
		nl: 'COVID19'
	},
	'requirement:1': {
		de: 'Sprachkenntnisse - Deutsch',
		en: 'Language skills - German',
		fr: '',
		nl: 'Talenkennis - Duits'
	},
	'requirement:10': {
		de: 'Bezorgung A',
		en: 'Delivery A',
		fr: '',
		nl: 'Bezorging A'
	},
	'requirement:11': {
		de: 'Bezorgung B',
		en: 'Delivery B',
		fr: '',
		nl: 'Bezorging B'
	},
	'requirement:12': {
		de: 'Bezorgung C',
		en: 'Delivery C',
		fr: '',
		nl: 'Bezorging C'
	},
	'requirement:20': {
		de: 'Mechaniker M',
		en: 'Mechanic M',
		fr: '',
		nl: 'Monteur M'
	},
	'requirement:21': {
		de: 'Mechaniker N',
		en: 'Mechanic N',
		fr: '',
		nl: 'Monteur N'
	},
	'requirement:22': {
		de: 'Mechaniker O',
		en: 'Mechanic O',
		fr: '',
		nl: 'Monteur O'
	},
	'requirement:23': {
		de: 'Mechaniker P',
		en: 'Mechanic P',
		fr: '',
		nl: 'Monteur P'
	},
	'requirement:30': {
		de: '2 Personen Lieferung',
		en: '2 person delivery',
		fr: '',
		nl: '2 persoons levering'
	},
	'requirement:31': {
		de: 'Sprinter Lieferung',
		en: 'Delivery by delivery van',
		fr: '',
		nl: 'Bus levering'
	},
	'requirement:32': {
		de: 'Euro 6 motor',
		en: 'Euro 6 motor',
		fr: '',
		nl: 'Euro 6 motor'
	},
	'requirement:33': {
		de: 'Winterreifen',
		en: 'All season Tires',
		fr: '',
		nl: 'All season banden/winterbanden'
	},
	'requirement:34': {
		de: 'Terminvereinbarung erforderlich',
		en: 'Time appointment required',
		fr: '',
		nl: 'Tijdsafspraak vereist'
	},
	'requirement:35': {
		de: 'Vergunning benodigd',
		en: 'Vergunning benodigd',
		fr: '',
		nl: 'Vergunning benodigd'
	},
	'requirement:40': {
		de: 'MautSystem DE',
		en: 'Toll system DE',
		fr: '',
		nl: 'Tolsysteem DE'
	},
	'requirement:41': {
		de: 'MautSystem BE',
		en: 'Toll system BE',
		fr: '',
		nl: 'Tolsysteem BE'
	},
	'requirement:42': {
		de: 'Do not use',
		en: 'Do not use',
		fr: '',
		nl: 'Inplan blokkade'
	},
	'requirement:44': {
		de: 'Reutlingen',
		en: 'Reutlingen',
		fr: '',
		nl: 'Reutlingen'
	},
	'requirement:45': {
		de: 'Ingolstadt',
		en: 'Ingolstadt',
		fr: '',
		nl: 'Ingolstadt'
	},
	'requirement:90': {
		de: 'Externer Spezialist Z',
		en: 'External specialist Z',
		fr: '',
		nl: 'Externe specialist Z'
	},
	'route:40AFRO': {
		de: '40 Abruf',
		en: '',
		fr: '',
		nl: '40 Afroep'
	},
	'route:451': {
		de: 'Hamburg zu planen',
		en: 'Heerlen',
		fr: '',
		nl: 'Hamburg te plannen'
	},
	'route:DEPOTP': {
		de: 'Depot Fahrt zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Depot rit te plannen'
	},
	'route:DCUDEN': {
		de: 'DC Uden',
		en: 'DC Uden',
		fr: 'DC Uden',
		nl: 'DC Uden'
	},
	'route:FILLEV': {
		de: 'Filiallieferung - normal',
		en: '',
		fr: '',
		nl: 'Filiaallevering - Normaal'
	},
	'route:GERNGP': {
		de: 'Depot Gernsheim Termin vereinbart',
		en: 'Zwolle',
		fr: '',
		nl: 'Depot Gernsheim afspraak gemaakt'
	},
	'route:GERNSH': {
		de: 'Depot Gernsheim',
		en: 'Depot Gernsheim',
		fr: 'Depot Gernsheim',
		nl: 'Depot Gernsheim'
	},
	'route:GERNTP': {
		de: 'Gernsheim zu planen',
		en: 'Zwolle',
		fr: '',
		nl: 'Gernsheim te plannen'
	},
	'route:GGEHO1': {
		de: 'Erste Mal kein Verbindung',
		en: '',
		fr: '',
		nl: 'Eerste keer geen gehoor'
	},
	'route:GGEHO2': {
		de: 'Zweite Mal kein Verbindung',
		en: '',
		fr: '',
		nl: 'Tweede keer geen gehoor'
	},
	'route:GGEHO3': {
		de: 'Dritte Mal kein Verbindung',
		en: '',
		fr: '',
		nl: 'Derde keer geen gehoor'
	},
	'route:GGEHOO': {
		de: 'Schnell Lieferung kein Verbindung',
		en: 'Heerlen',
		fr: '',
		nl: 'Versneld leveren geen gehoor'
	},
	'route:HAMBGP': {
		de: 'Depot Hamburg Termin vereinbart',
		en: 'Heerlen',
		fr: '',
		nl: 'Depot Hamburg Afspraak gemaakt'
	},
	'route:HAMBRG': {
		de: 'Depot Hamburg',
		en: 'Depot Hamburg',
		fr: 'Depot Hamburg',
		nl: 'Depot Hamburg'
	},
	'route:HUISMA': {
		de: '',
		en: '',
		fr: '',
		nl: 'Huisman'
	},
	'route:INGOLS': {
		de: 'Depot Ingolstadt',
		en: 'Depot Ingolstadt',
		fr: 'Depot Ingolstadt',
		nl: 'Depot Ingolstadt'
	},
	'route:INTPAK': {
		de: '',
		en: '',
		fr: '',
		nl: 'Internetorders - Pakket'
	},
	'route:MANNGP': {
		de: 'Depot Mannheim Termin vereinbart',
		en: 'Zwolle',
		fr: '',
		nl: 'Depot Mannheim Afspraak gemaakt'
	},
	'route:MDGSGP': {
		de: 'Mehrtägig Termin vereinbart',
		en: 'Utrecht',
		fr: '',
		nl: 'Meerdaags Afspraak gemaakt'
	},
	'route:MEERDG': {
		de: 'Mehrtägige Fahrt',
		en: 'Multi-day Tour',
		fr: '',
		nl: 'Meerdaagse Rit'
	},
	'route:MDGSTP': {
		de: 'Mehrtägig zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Meerdaags te plannen'
	},
	'route:NEXTGP': {
		de: 'NextDay Termin vereinbart',
		en: 'Eindhoven',
		fr: '',
		nl: 'Next day gepland'
	},
	'route:NEXTDY': {
		de: 'Next Day',
		en: 'Next Day',
		fr: 'Next Day',
		nl: 'Next Day'
	},
	'route:NEXTTP': {
		de: 'NextDay zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Nextday te plannen'
	},
	'route:REUTLI': {
		de: 'Depot Reutlingen',
		en: 'Depot Reutlingen',
		fr: 'Depot Reutlingen',
		nl: 'Depot Reutlingen'
	},
	'route:SERGER': {
		de: 'Service Depot Gernsheim',
		en: 'Service Depot Gernsheim',
		fr: 'Service Depot Gernsheim',
		nl: 'Service Depot Gernsheim'
	},
	'route:SERGTP': {
		de: 'Service Gernsheim zu Planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Service Gernsheim te plannen'
	},
	'route:SERHAM': {
		de: 'Service Depot Hamburg',
		en: 'Service Depot Hamburg',
		fr: 'Service Depot Hamburg',
		nl: 'Service Depot Hamburg'
	},
	'route:SERUDN': {
		de: 'Service DC Uden',
		en: 'Service DC Uden',
		fr: 'Service DC Uden',
		nl: 'Service DC Uden'
	},
	'route:SERVGP': {
		de: 'Service Termin vereinbart',
		en: 'Almelo',
		fr: '',
		nl: 'Service Afspraak gemaakt'
	},
	'route:SERVTP': {
		de: 'Service zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Service te plannen'
	},
	'route:SNLPLN': {
		de: 'Schnell Lieferung zu planen',
		en: 'Heerlen',
		fr: '',
		nl: 'Versneld leveren nog te plannen'
	},
	'route:SPECLP': {
		de: 'Speziell Lieferung zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'Speciale levering te plannen'
	},
	'route:STRAT1': {
		de: '',
		en: '',
		fr: '',
		nl: 'TNT'
	},
	'route:UDENGP': {
		de: 'DC Uden Termin vereinbart',
		en: 'Utrecht',
		fr: '',
		nl: 'DC uden Afspraak gemaakt'
	},
	'route:UDENTP': {
		de: 'DC Uden zu planen',
		en: 'Tilburg',
		fr: '',
		nl: 'DC uden te plannen'
	},
	'salesOrganization:1000': {
		de: 'Swiss Sense NL',
		en: 'Retail NL',
		fr: '',
		nl: 'Swiss Sense NL'
	},
	'salesOrganization:3000': {
		de: 'Swiss Sense BE',
		en: 'Retail BE',
		fr: '',
		nl: 'Swiss Sense BE'
	},
	'salesOrganization:4000': {
		de: 'Swiss Sense DE',
		en: '',
		fr: '',
		nl: 'Swiss Sense DE'
	},
	'salesOrganization:4500': {
		de: '',
		en: '',
		fr: '',
		nl: ''
	},
	'shippingCondition:D2': {
		de: '',
		en: '',
		fr: '',
		nl: 'Depot Gernsheim'
	},
	'shippingCondition:D6': {
		de: '',
		en: '',
		fr: '',
		nl: 'Service Gernsheim'
	},
	'shippingCondition:E2': {
		de: '',
		en: '',
		fr: '',
		nl: 'NextDay'
	},
	'shippingCondition:E3': {
		de: '',
		en: '',
		fr: '',
		nl: 'NextDay (P)'
	},
	'shippingCondition:E5': {
		de: '',
		en: '',
		fr: '',
		nl: 'STR'
	},
	'shippingCondition:LF': {
		de: 'Auslieferung',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering'
	},
	'shippingCondition:Z1': {
		de: '',
		en: '',
		fr: '',
		nl: 'Eigen transport'
	},
	'shippingCondition:Z3': {
		de: '',
		en: '',
		fr: '',
		nl: 'Verzending Service'
	},
	'shippingCondition:ZS': {
		de: '',
		en: '',
		fr: '',
		nl: 'Verkorte levertijd'
	},
	'shippingCondition:ZSLF': {
		de: 'Auslieferung Service',
		en: 'Outbound Delivery',
		fr: 'Livraison sortante',
		nl: 'Uitlevering Service'
	},
	'shippingCondition:ZSOU': {
		de: 'Rück Lager 2 Outlet',
		en: 'Replenishment Dlv.',
		fr: 'Livraison réappro.',
		nl: 'Ret vrd 2 outlet'
	}
}

export const fields = {
	APPOINTMENT_STATUS: 'appointmentStatus',
	CONTACT_STATUS: 'contactStatus',
	COUNTRY: 'country',
	LANGUAGE: 'language',
	ORDER_TYPE: 'orderType',
	PLANNING_TYPE: 'planningType',
	PRIORITY: 'priority',
	REQUIREMENT: 'requirement',
	ROUTE: 'route',
	SALESORGANIZATION: 'salesOrganization',
	SHIPPING_CONDITION: 'shippingCondition'
}

export const localizedText = (field, key) => {
	// This function will try to return the description based on the user language.
	// If it doesn't find a description it will try to return the value for the fallback language.
	const textKey = `${field}:${key}`
	if (!texts[textKey]) {
		return `?? (${key})`
	}
	const description = texts[textKey][config.userLanguage]
	if (!description) {
		const descriptionNL = texts[textKey][config.fallbackLanguage]
		if (!descriptionNL) {
			return `?? (${key})`
		}
		return descriptionNL
	}
	return description
}
