import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import authActionCreators from '../../../redux/auth/actionCreators'
import AutoLogin from './AutoLogin'

class AutoLoginContainer extends PureComponent {
	static propTypes = {
		autoLoginRetry: PropTypes.func.isRequired,
		errorMessages: PropTypes.object,
		logout: PropTypes.func.isRequired
	}

	render() {
		const { autoLoginRetry, errorMessages, logout } = this.props

		return (
			<AutoLogin
				autoLoginRetry={autoLoginRetry}
				errorMessages={errorMessages}
				logout={logout}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		errorMessages: state.userState.meta.errorMessages
	}),
	{
		autoLoginRetry: authActionCreators.autoLoginRetry,
		logout: authActionCreators.logout
	}
)

export default connector(AutoLoginContainer)
