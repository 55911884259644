import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import WeekContent from './WeekContent'

class WeekContentContainer extends PureComponent {
	static propTypes = {
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const { selectedMoment } = this.props

		return <WeekContent selectedMoment={selectedMoment} />
	}
}

const connector = connect((state) => ({
	selectedMoment: driversSelectors.selectedMomentSelector(state.driversState)
}))

export default connector(WeekContentContainer)
