import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	root: {
		flex: 1,
		paddingTop: 100
	},
	paper: {
		margin: '0 auto',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
		width: 400
	},
	button: {
		marginTop: 20,
		width: '100%'
	},
	logo: {
		width: '100%'
	},
	autoLoginText: {
		marginBottom: 16,
		marginTop: 16
	}
}

class AutoLogin extends PureComponent {
	static propTypes = {
		autoLoginRetry: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		errorMessages: PropTypes.object,
		logout: PropTypes.func.isRequired
	}

	handleLogout = () => {
		const { logout } = this.props
		logout()
	}

	render() {
		const { autoLoginRetry, classes, errorMessages } = this.props

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" className={classes.errorMessage}>
					{errorMessages.userMessage}
				</Typography>
			)
		}

		return (
			<Grid
				container
				align="center"
				justifyContent="center"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<Typography variant="body2" className={classes.autoLoginText}>
							{i18n.t('app:elements.AutoLogin.explanation')}
						</Typography>
						{message}
						<Button
							variant="contained"
							className={classes.button}
							onClick={autoLoginRetry}
						>
							{i18n.t('app:elements.AutoLogin.retry')}
						</Button>
						<Button
							variant="contained"
							className={classes.button}
							onClick={this.handleLogout}
						>
							{i18n.t('app:elements.AutoLogin.cancel')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(AutoLogin)
