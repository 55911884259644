import clsx from 'clsx'
import {
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Typography,
	SvgIcon,
	Divider
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useLocation } from 'react-router-dom'
import {
	AngleLeftFal,
	CogsFad,
	FileExportFad,
	RouteFad,
	UserHeadsetFad,
	MailboxFad,
	UserClockFad,
	SlidersVFad,
	TruckFad
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// // necessary for content to be below app bar
		// ...theme.mixins.toolbar
		minHeight: 48
	},
	toolbarText: {
		flex: 1,
		marginLeft: theme.spacing(1)
	}
}))

const SideMenu = ({ isSideMenuOpen, hideSideMenu }) => {
	const classes = useStyles()
	const { pathname } = useLocation()

	const handleHideSideMenuClick = () => {
		if (isSideMenuOpen) {
			hideSideMenu()
		}
	}

	const drawerContent = (
		<>
			<div className={classes.toolbar}>
				<div className={classes.toolbarText}>
					<Typography variant="h6">{i18n.t('app:generic.menu')}</Typography>
				</div>
				<IconButton onClick={handleHideSideMenuClick}>
					<SvgIcon>
						<AngleLeftFal />
					</SvgIcon>
				</IconButton>
			</div>
			<Divider />
			<List component="nav" onClick={handleHideSideMenuClick}>
				<ListItem
					button
					component={Link}
					to="/afspraken"
					selected={pathname === '/' || pathname.startsWith('/afspraken')}
				>
					<ListItemIcon>
						<SvgIcon>
							<UserHeadsetFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={i18n.t('app:appointmentscheduler.Main.menuTitle')}
					/>
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/planning"
					selected={pathname === '/planning'}
				>
					<ListItemIcon>
						<SvgIcon>
							<RouteFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary={i18n.t('app:planning.Main.menuTitle')} />
				</ListItem>
			</List>
			<Divider />
			<List component="nav" onClick={handleHideSideMenuClick}>
				<ListItem
					button
					component={Link}
					to="/postcodeadmin"
					selected={pathname.startsWith('/postcodeadmin')}
				>
					<ListItemIcon>
						<SvgIcon>
							<MailboxFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary={i18n.t('app:postcodeadmin.Main.menuTitle')} />
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/driveradmin"
					selected={pathname.startsWith('/driveradmin')}
				>
					<ListItemIcon>
						<SvgIcon>
							<UserClockFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary={i18n.t('app:driveradmin.Main.menuTitle')} />
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/planningtypeadmin"
					selected={pathname.startsWith('/planningtypeadmin')}
				>
					<ListItemIcon>
						<SvgIcon>
							<SlidersVFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={i18n.t('app:planningtypeadmin.Main.menuTitle')}
					/>
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/vehicleplanning"
					selected={pathname.startsWith('/vehicleplanning')}
				>
					<ListItemIcon>
						<SvgIcon>
							<TruckFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={i18n.t('app:vehicleplanning.Main.menuTitle')}
					/>
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/exports"
					selected={pathname.startsWith('/exports')}
				>
					<ListItemIcon>
						<SvgIcon>
							<FileExportFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary={i18n.t('app:exports.Main.menuTitle')} />
				</ListItem>
				<ListItem
					button
					component={Link}
					to="/settings"
					selected={pathname.startsWith('/settings')}
				>
					<ListItemIcon>
						<SvgIcon>
							<CogsFad />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary={i18n.t('app:settings.Main.menuTitle')} />
				</ListItem>
			</List>
		</>
	)

	const drawerProps = {
		className: clsx(classes.drawer, {
			[classes.drawerOpen]: isSideMenuOpen,
			[classes.drawerClose]: !isSideMenuOpen
		}),
		classes: {
			paper: clsx({
				[classes.drawerOpen]: isSideMenuOpen,
				[classes.drawerClose]: !isSideMenuOpen
			})
		},
		open: isSideMenuOpen,
		onClose: hideSideMenu,
		ModalProps: {
			keepMounted: true
		}
	}

	return (
		<>
			<Hidden smDown>
				<Drawer variant="permanent" {...drawerProps}>
					{drawerContent}
				</Drawer>
			</Hidden>
			<Hidden mdUp>
				<Drawer variant="temporary" {...drawerProps}>
					{drawerContent}
				</Drawer>
			</Hidden>
		</>
	)
}

export default SideMenu
