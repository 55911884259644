import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialOrderState = null

const orderReducer = (state = initialOrderState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_FAILURE:
		case actionTypes.REMOVE: {
			return initialOrderState
		}

		case actionTypes.FETCH_SUCCESS:
		case actionTypes.UPDATE: {
			return action.payload.order
		}

		case actionTypes.CONFIRM_APPOINTMENT:
		case actionTypes.NOANSWER_APPOINTMENT:
		case actionTypes.POSTPONE_APPOINTMENT:
		case actionTypes.SAVE_APPOINTMENT: {
			return {
				...state,
				appointment: action.payload.appointment
			}
		}

		case actionTypes.UPDATE_TOURDATE: {
			// See related saga `socketUpdateTourDates` for more info
			// Do not update a locked order because that will disrupt the appointment process
			// A locked order will be updated automatically when it is saved/unlocked
			if (state.lock) {
				return state
			}
			// Update the tourDate
			const newTourDate = action.payload.tourDate
			const { tourDate: oldTourDate, ...orderWithoutTourDate } = state
			if (newTourDate === oldTourDate) {
				// Tour date is not modified
				return state
			}
			if (newTourDate) {
				// Tour date is added or modified
				return { ...state, tourDate: newTourDate }
			}
			// Tour date is removed
			return orderWithoutTourDate
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isFetching: false,
	isUpdating: false,
	errorMessages: null,
	isFetchingTimeSlots: false
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			// Reset updating meta when the orders list is fetched
			// This should only happen when the websocket connection was interrupted and we might have missed some updates
			return {
				...initialMetaState,
				isFetching: true
			}
		}

		case actionTypes.CONFIRM_APPOINTMENT_REQUEST:
		case actionTypes.LOCK_REQUEST:
		case actionTypes.NOANSWER_APPOINTMENT_REQUEST:
		case actionTypes.POSTPONE_APPOINTMENT_REQUEST:
		case actionTypes.SAVE_APPOINTMENT_REQUEST:
		case actionTypes.TAKEOVERLOCK_REQUEST:
		case actionTypes.UNLOCK_REQUEST: {
			return {
				...initialMetaState,
				isUpdating: true
			}
		}

		case actionTypes.FETCH_SUCCESS:
		case actionTypes.UPDATE:
		case actionTypes.REMOVE:
		case actionTypes.CONFIRM_APPOINTMENT_SUCCESS:
		case actionTypes.LOCK_SUCCESS:
		case actionTypes.NOANSWER_APPOINTMENT_SUCCESS:
		case actionTypes.POSTPONE_APPOINTMENT_SUCCESS:
		case actionTypes.SAVE_APPOINTMENT_SUCCESS:
		case actionTypes.TAKEOVERLOCK_SUCCESS:
		case actionTypes.UNLOCK_SUCCESS: {
			return initialMetaState
		}

		case actionTypes.FETCH_FAILURE:
		case actionTypes.CONFIRM_APPOINTMENT_FAILURE:
		case actionTypes.LOCK_FAILURE:
		case actionTypes.NOANSWER_APPOINTMENT_FAILURE:
		case actionTypes.POSTPONE_APPOINTMENT_FAILURE:
		case actionTypes.SAVE_APPOINTMENT_FAILURE:
		case actionTypes.TAKEOVERLOCK_FAILURE:
		case actionTypes.UNLOCK_FAILURE: {
			return {
				...initialMetaState,
				errorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.FETCH_TIME_SLOTS_REQUEST: {
			return {
				...state,
				isFetchingTimeSlots: true
			}
		}

		case actionTypes.FETCH_TIME_SLOTS_FAILURE:
		case actionTypes.FETCH_TIME_SLOTS_SUCCESS: {
			return {
				...state,
				isFetchingTimeSlots: false
			}
		}

		default: {
			return state
		}
	}
}

const initialTimeSlotsState = []

const timeSlotsReducer = (state = initialTimeSlotsState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_TIME_SLOTS_REQUEST:
		case actionTypes.FETCH_TIME_SLOTS_FAILURE: {
			return initialTimeSlotsState
		}

		case actionTypes.FETCH_TIME_SLOTS_SUCCESS: {
			return action.payload.timeSlots
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	order: orderReducer,
	meta: metaReducer,
	timeSlots: timeSlotsReducer
})
