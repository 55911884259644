import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/postcodes/actionCreators'
import Header from './Header'

class HeaderContainer extends PureComponent {
	static propTypes = {
		selectedCountry: PropTypes.string.isRequired,
		selectCountry: PropTypes.func.isRequired
	}

	render() {
		const { selectedCountry, selectCountry } = this.props

		return <Header country={selectedCountry} selectCountry={selectCountry} />
	}
}

const connector = connect(
	(state) => ({
		selectedCountry: state.postcodesState.selectedCountry
	}),
	{
		selectCountry: actionCreators.selectCountry
	}
)

export default connector(HeaderContainer)
