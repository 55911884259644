import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Switch,
	TextField,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: 8
	}
})

class Properties extends PureComponent {
	static propTypes = {
		change: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		meta: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	state = {
		maxLoadPercentage: this.props.vehicle.maxLoadPercentage,
		scaffold: this.props.vehicle.scaffold
	}

	handleChangeMaxLoadPerc = (event) => {
		const { change } = this.props
		const { scaffold } = this.state
		const input = event.target.value

		const floatInput = Number.parseFloat(input)
		const maxLoadPercentage = Number.isNaN(floatInput) ? '' : floatInput

		// a percentage value cannot be smaller than 0 or larger than 100
		if (maxLoadPercentage < 0 || maxLoadPercentage > 100) {
			return
		}

		this.setState({
			maxLoadPercentage
		})

		change({
			maxLoadPercentage,
			scaffold
		})
	}

	handleToggleScaffold = () => {
		const { change } = this.props
		const { maxLoadPercentage, scaffold } = this.state

		this.setState({
			scaffold: !scaffold
		})

		change({
			maxLoadPercentage,
			scaffold: !scaffold
		})
	}

	render() {
		const { classes, meta } = this.props
		const { maxLoadPercentage, scaffold } = this.state
		const { percentageEmpty } = meta

		return (
			<div className={classes.root}>
				<div className={classes.content}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:vehicleplanning.Vehicle.properties')}
					</Typography>
					<TextField
						label={i18n.t('app:vehicleplanning.Vehicle.maxLoadPercentage')}
						value={maxLoadPercentage}
						type="number"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">%</InputAdornment>
							)
						}}
						inputProps={{
							min: '0',
							max: '100'
						}}
						error={percentageEmpty}
						helperText={
							percentageEmpty
								? i18n.t('app:vehicleplanning.Vehicle.maxLoadPercRequired')
								: null
						}
						onChange={this.handleChangeMaxLoadPerc}
						margin="normal"
					/>
					<List disablePadding>
						<ListItem button disableGutters onClick={this.handleToggleScaffold}>
							<ListItemText
								disableTypography
								primary={
									<Typography variant="body2">
										{i18n.t('app:vehicleplanning.Vehicle.scaffold')}
									</Typography>
								}
							/>
							<ListItemSecondaryAction>
								<Switch
									color="primary"
									checked={scaffold}
									onChange={this.handleToggleScaffold}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(Properties)
