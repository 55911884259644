import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	IconButton,
	ListItem as MaterialListItem,
	ListItemSecondaryAction,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TrashAltFal } from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'
import { default as i18n } from 'i18next'

const styles = {
	column: {
		width: '30%'
	}
}

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		postcode: PropTypes.object.isRequired,
		removeItem: PropTypes.func.isRequired
	}

	handleRemoveIconClick = () => {
		const { postcode, removeItem } = this.props

		removeItem(postcode.id)
	}

	render() {
		const { classes, postcode } = this.props

		let primary
		if (postcode.start && postcode.end) {
			const separator = i18n.t('app:postcodeadmin.Availability.rangeSeparator')
			primary = `${postcode.start} ${separator} ${postcode.end}`
		} else {
			primary = postcode.start
		}

		const fromText = moment
			.unix(postcode.from)
			.tz('Europe/Amsterdam')
			.format('D MMM Y, H:mm')
		const tillText = moment
			.unix(postcode.till)
			.tz('Europe/Amsterdam')
			.format('D MMM Y, H:mm')

		return (
			<MaterialListItem divider disableGutters>
				<Typography variant="body2" className={classes.column}>
					{primary}
				</Typography>
				<Typography variant="body2" className={classes.column}>
					{fromText}
				</Typography>
				<Typography variant="body2" className={classes.column}>
					{tillText}
				</Typography>
				<ListItemSecondaryAction>
					<IconButton color="secondary" onClick={this.handleRemoveIconClick}>
						<SvgIcon>
							<TrashAltFal />
						</SvgIcon>
					</IconButton>
				</ListItemSecondaryAction>
			</MaterialListItem>
		)
	}
}

export default withStyles(styles)(ListItem)
