const actionTypes = {
	FETCH: 'excludeServiceDates/FETCH',
	FETCH_REQUEST: 'excludeServiceDates/FETCH_REQUEST',
	FETCH_SUCCESS: 'excludeServiceDates/FETCH_SUCCESS',
	FETCH_FAILURE: 'excludeServiceDates/FETCH_FAILURE',
	ADD: 'excludeServiceDates/ADD',
	ADD_REQUEST: 'excludeServiceDates/ADD_REQUEST',
	ADD_SUCCESS: 'excludeServiceDates/ADD_SUCCESS',
	ADD_FAILURE: 'excludeServiceDates/ADD_FAILURE',
	REMOVE: 'excludeServiceDates/REMOVE',
	REMOVE_REQUEST: 'excludeServiceDates/REMOVE_REQUEST',
	REMOVE_SUCCESS: 'excludeServiceDates/REMOVE_SUCCESS',
	REMOVE_FAILURE: 'excludeServiceDates/REMOVE_FAILURE',
	SOCKET_ADD: 'excludeServiceDates/SOCKET_ADD',
	SOCKET_CLOSE: 'excludeServiceDates/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'excludeServiceDates/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'excludeServiceDates/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'excludeServiceDates/SOCKET_OPEN',
	SOCKET_REMOVE: 'excludeServiceDates/SOCKET_REMOVE'
}

export default actionTypes
