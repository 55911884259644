import config from '../../config'

const currencies = {
	EUR: (cents, hideDecimals) =>
		new Intl.NumberFormat(config.userLanguage, {
			style: 'currency',
			currency: 'EUR'
		}).format(hideDecimals ? Math.round(cents / 100) : cents / 100),
	USD: (cents, hideDecimals) =>
		new Intl.NumberFormat(config.userLanguage, {
			style: 'currency',
			currency: 'USD'
		}).format(hideDecimals ? Math.round(cents / 100) : cents / 100)
}

export const formatMoney = (cents = 0, code = 'EUR', hideDecimals = false) =>
	currencies[code](cents, hideDecimals)
export const divideMoney = (dividendCents = 0, divisorCents = 1) =>
	Math.round(dividendCents / divisorCents)
