import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import { Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	root: {
		flex: 1,
		paddingTop: 100
	},
	paper: {
		margin: '0 auto',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
		width: 400
	},
	description: {
		marginTop: 16
	},
	logo: {
		width: '100%'
	}
}

class NoScopes extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	render() {
		const { classes } = this.props

		return (
			<Grid
				container
				align="center"
				justifyContent="center"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<Typography variant="body2" className={classes.description}>
							{i18n.t('app:elements.NoScopes.description')}
						</Typography>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(NoScopes)
