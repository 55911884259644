import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

// This component will catch all JavaScript errors
// When this happens, we show an error view which lets the user restart the application

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		height: '100%'
	},
	details: {
		flex: 1,
		marginTop: theme.spacing(6),
		padding: theme.spacing(2)
	},
	content: {
		marginTop: 200,
		textAlign: 'center'
	},
	logo: {
		width: 70,
		height: 'auto',
		marginBottom: 25
	}
})

class ErrorBoundary extends PureComponent {
	static propTypes = {
		children: PropTypes.node,
		classes: PropTypes.object.isRequired
	}

	componentDidMount() {
		window.onunhandledrejection = (event) => {
			this.setState({ error: event.reason })
		}
	}

	componentDidCatch(error, info) {
		this.setState({
			error,
			info
		})
	}

	handleRestartClick = () => {
		window.location.reload()
	}

	render() {
		const { children, classes } = this.props
		const { error } = this.state || {}

		if (!error) {
			return children || null
		}

		return (
			<div className={classes.root}>
				<div className={classes.details}>
					<Grid container justifyContent="center">
						<Grid item>
							<div className={classes.content}>
								<img src="/logo.png" className={classes.logo} alt="logo" />
								<Typography variant="h5">
									{i18n.t('app:generic.fatalError')}
								</Typography>
								<Typography variant="caption" color="textSecondary" paragraph>
									{i18n.t('app:generic.fatalErrorDescription')}
								</Typography>
								<Button
									color="primary"
									variant="outlined"
									onClick={this.handleRestartClick}
								>
									{i18n.t('app:generic.fatalErrorRestart')}
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(ErrorBoundary)
