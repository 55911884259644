import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import localizedDescription from '../../../utils/localizedDescription'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Data extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props

		const { type, vehicleId } = vehicle
		const description = localizedDescription(vehicle.descriptions)

		let qualificationItems
		if (vehicle.qualifications && vehicle.qualifications.length === 0) {
			qualificationItems = (
				<Typography variant="body2">
					{i18n.t('app:vehicleplanning.Data.noQualifications')}
				</Typography>
			)
		} else {
			qualificationItems = vehicle.qualifications.map((qualification) => (
				<Typography key={`qualif-item-${qualification.key}`} variant="body2">
					{localizedDescription(qualification.translations)}
				</Typography>
			))
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Data.title')}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Data.type')}
						</Typography>
						<Typography variant="body2">{type}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Data.equipmentNr')}
						</Typography>
						<Typography variant="body2">{vehicleId}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Data.description')}
						</Typography>
						<Typography variant="body2">{description}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Data.qualifications')}
						</Typography>
						{qualificationItems}
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(Data)
