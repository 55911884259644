import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import userActionCreators from '../../../redux/user/actionCreators'
import Password from './Password'

class PasswordContainer extends PureComponent {
	static propTypes = {
		clearUserError: PropTypes.func.isRequired,
		errorMessages: PropTypes.object,
		newPasswordReset: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { clearUserError } = this.props
		clearUserError()
	}

	render() {
		const { errorMessages, newPasswordReset, resetPassword } = this.props

		return (
			<Password
				errorMessages={errorMessages}
				newPasswordReset={newPasswordReset}
				resetPassword={resetPassword}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		errorMessages: state.userState.meta.errorMessages
	}),
	{
		clearUserError: userActionCreators.clearUserError,
		newPasswordReset: userActionCreators.newPasswordReset,
		resetPassword: userActionCreators.resetPassword
	}
)

export default connector(PasswordContainer)
