import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import selectors from '../../../../redux/depots/selectors'
import Depot from './Depot'

class DepotContainer extends PureComponent {
	static propTypes = {
		depot: PropTypes.object,
		label: PropTypes.string.isRequired,
		plant: PropTypes.string
	}

	render() {
		const { depot, label, plant } = this.props

		return (
			<Depot
				key={`startdepot-${plant}`}
				depot={depot}
				label={label}
				plant={plant}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	depot: selectors.findDepotByPlantSelector(state.depotsState, props.plant)
}))

export default connector(DepotContainer)
