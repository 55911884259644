import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../redux/postcodes/actionCreators'
import PostcodeAdmin from './PostcodeAdmin'

class PostcodeAdminContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		fetchPostcodes: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { isLoading, fetchPostcodes } = this.props

		if (!isLoading) {
			fetchPostcodes()
		}
	}

	render() {
		const { match } = this.props

		const showNew = Boolean(match.params.new)

		return <PostcodeAdmin showNew={showNew} />
	}
}

const connector = connect(
	(state) => ({
		isLoading: state.postcodesState.meta.isLoading
	}),
	{
		fetchPostcodes: actionCreators.fetchPostcodes
	}
)

export default connector(PostcodeAdminContainer)
