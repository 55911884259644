import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import vehicleSelectors from '../../../redux/vehiclescustom/selectors'
import VehicleSelection from './VehicleSelection'

class VehicleSelectionContainer extends PureComponent {
	static propTypes = {
		depotsLoading: PropTypes.bool,
		driversLoading: PropTypes.bool,
		planningTypesLoading: PropTypes.bool,
		refresh: PropTypes.func.isRequired,
		selectedVehicleId: PropTypes.string,
		vehiclesLoading: PropTypes.bool,
		hasActiveFilters: PropTypes.bool
	}

	handleRefresh = () => {
		const { refresh } = this.props
		refresh()
	}

	render() {
		const {
			depotsLoading,
			driversLoading,
			planningTypesLoading,
			selectedVehicleId,
			vehiclesLoading,
			hasActiveFilters
		} = this.props

		const isLoading =
			depotsLoading || driversLoading || planningTypesLoading || vehiclesLoading

		return (
			<VehicleSelection
				isLoading={isLoading}
				selectedVehicleId={selectedVehicleId}
				refresh={this.handleRefresh}
				hasActiveFilters={hasActiveFilters}
			/>
		)
	}
}

const connector = connect((state) => ({
	depotsLoading: state.depotsState.meta.isLoading,
	driversLoading: state.driversState.meta.isLoading,
	planningTypesLoading: state.planningTypesState.meta.isLoading,
	vehiclesLoading: state.vehiclesState.meta.isLoading,
	hasActiveFilters: vehicleSelectors.hasActiveFilters(state.vehiclesState)
}))

export default connector(VehicleSelectionContainer)
