import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { default as i18n } from 'i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const styleSheet = {
	root: {
		flex: 1,
		paddingTop: 100
	},
	loginPaper: {
		margin: '0 auto',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
		width: 400
	},
	cancelLoginButton: {
		marginTop: 20,
		width: '100%'
	},
	loginButton: {
		marginBottom: 10,
		width: '100%'
	},
	resetPasswordButton: {
		width: '100%'
	},
	progress: {
		marginTop: 20
	},
	userNameTextField: {
		marginTop: 20,
		width: '100%'
	},
	passwordTextField: {
		marginTop: 0,
		marginBottom: 30,
		width: '100%'
	},
	logo: {
		width: '100%'
	},
	errorMessage: {
		color: red[400],
		marginBottom: 30
	},
	loggingInText: {
		marginTop: 16
	}
}

class Login extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		errorMessages: PropTypes.object,
		isLoggingIn: PropTypes.bool,
		login: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired
	}

	state = {
		userName: '',
		password: ''
	}

	login() {
		const { userName, password } = this.state
		const { login } = this.props
		if (userName.length > 0 && password.length > 0) {
			this.setState({
				userName: '',
				password: ''
			})
			login(userName, password)
		}
	}

	cancelLogin() {
		// Calling the logout action will automatically cancel any running login requests
		const { logout } = this.props
		logout()
	}

	handleChangeUserName = (event) => {
		this.setState({
			userName: event.target.value
		})
	}

	handleKeyPressUserName = (event) => {
		if (event.key === 'Enter') {
			this.login()
		}
	}

	handleChangePassword = (event) => {
		this.setState({
			password: event.target.value
		})
	}

	handleKeyPressPassword = (event) => {
		if (event.key === 'Enter') {
			this.login()
		}
	}

	handleLogin = () => {
		this.login()
	}

	handleCancelLogin = () => {
		this.cancelLogin()
	}

	render() {
		const { isLoggingIn } = this.props

		if (isLoggingIn) {
			return this.renderLoggingIn()
		}
		return this.renderLoginForm()
	}

	renderLoggingIn() {
		const { classes } = this.props

		return (
			<Grid
				container
				align="center"
				justifyContent="center"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.loginPaper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<CircularProgress className={classes.progress} size={50} />
						<Typography variant="body2" className={classes.loggingInText}>
							{i18n.t('app:elements.Login.loggingIn')}
						</Typography>
						<Button
							variant="contained"
							className={classes.cancelLoginButton}
							onClick={this.handleCancelLogin}
						>
							{i18n.t('app:elements.Login.cancelLogin')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}

	renderLoginForm() {
		const { classes, errorMessages } = this.props

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" className={classes.errorMessage}>
					{errorMessages.techMessage}
				</Typography>
			)
		}

		return (
			<Grid
				container
				align="center"
				direction="column"
				className={classes.root}
			>
				<Grid item md={12}>
					<Paper className={classes.loginPaper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<TextField
							label={i18n.t('app:elements.Login.userName')}
							className={classes.userNameTextField}
							value={this.state.userName}
							onChange={this.handleChangeUserName}
							onKeyPress={this.handleKeyPressUserName}
							margin="normal"
						/>
						<TextField
							label={i18n.t('app:elements.Login.password')}
							className={classes.passwordTextField}
							type="password"
							value={this.state.password}
							onChange={this.handleChangePassword}
							onKeyPress={this.handleKeyPressPassword}
							margin="normal"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							className={classes.loginButton}
							onClick={this.handleLogin}
						>
							{i18n.t('app:elements.Login.login')}
						</Button>
						<Button
							component={Link}
							to="/password"
							className={classes.resetPasswordButton}
						>
							{i18n.t('app:elements.Login.resetPassword')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(Login)
