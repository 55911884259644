import actionTypes from './actionTypes'

const actionCreators = {
	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (planningTypes) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			planningTypes
		}
	}),

	update: (planningType) => ({
		type: actionTypes.UPDATE,
		payload: {
			planningType
		}
	}),

	updateRequest: () => ({
		type: actionTypes.UPDATE_REQUEST
	}),

	updateSuccess: (planningType) => ({
		type: actionTypes.UPDATE_SUCCESS,
		payload: {
			planningType
		}
	}),

	updateFailure: (errorMessages) => ({
		type: actionTypes.UPDATE_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	})
}

export default actionCreators
