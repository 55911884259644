import actionTypes from './actionTypes'

const actionCreators = {
	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (drivers) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			drivers
		}
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	search: (query) => ({
		type: actionTypes.SEARCH,
		payload: {
			query
		}
	}),

	clearSearch: () => ({
		type: actionTypes.CLEAR_SEARCH
	}),

	selectCurrentWeek: () => ({
		type: actionTypes.SELECT_CURRENT_WEEK
	}),

	selectNextWeek: () => ({
		type: actionTypes.SELECT_NEXT_WEEK
	}),

	selectPreviousWeek: () => ({
		type: actionTypes.SELECT_PREVIOUS_WEEK
	}),

	fetchAvailability: (id, year, week) => ({
		type: actionTypes.FETCH_AVAILABILITY,
		meta: {
			id,
			year,
			week
		}
	}),

	fetchAvailabilityRequest: () => ({
		type: actionTypes.FETCH_AVAILABILITY_REQUEST
	}),

	fetchAvailabilitySuccess: (availability) => ({
		type: actionTypes.FETCH_AVAILABILITY_SUCCESS,
		payload: {
			availability
		}
	}),

	fetchAvailabilityFailure: (errorMessages) => ({
		type: actionTypes.FETCH_AVAILABILITY_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	selectAvailabilityItem: (availabilityItem) => ({
		type: actionTypes.SELECT_AVAILABILITY_ITEM,
		payload: {
			availabilityItem
		}
	}),

	deselectAvailabilityItem: () => ({
		type: actionTypes.DESELECT_AVAILABILITY_ITEM
	}),

	newAvailabilityItem: (date) => ({
		type: actionTypes.NEW_AVAILABILITY_ITEM,
		payload: {
			date
		}
	}),

	saveAvailability: (id, day, availability) => ({
		type: actionTypes.SAVE_AVAILABILITY,
		payload: {
			availability
		},
		meta: {
			id,
			day
		}
	}),

	saveAvailabilityRequest: () => ({
		type: actionTypes.SAVE_AVAILABILITY_REQUEST
	}),

	saveAvailabilitySuccess: (day, availability) => ({
		type: actionTypes.SAVE_AVAILABILITY_SUCCESS,
		payload: {
			availability
		},
		meta: {
			day
		}
	}),

	saveAvailabilityFailure: (errorMessages) => ({
		type: actionTypes.SAVE_AVAILABILITY_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	})
}

export default actionCreators
