import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../redux/vehicleUnavailability/actionCreators'
import VehicleUnavailability from './VehicleUnavailability'

class VehicleUnavailabilityContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		fetch: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { fetch, isLoading, match } = this.props

		if (!isLoading) {
			fetch(match.params.id)
		}
	}

	render() {
		const { match } = this.props

		const selectedVehicleId = match.params.id
		const showNew = Boolean(match.params.new)

		return (
			<VehicleUnavailability vehicleId={selectedVehicleId} showNew={showNew} />
		)
	}
}

const connector = connect(
	(state) => ({
		isLoading: state.vehicleUnavailabilityState.meta.isLoading
	}),
	{
		fetch: actionCreators.fetch
	}
)

export default connector(VehicleUnavailabilityContainer)
