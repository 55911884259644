import { blue } from '@material-ui/core/colors'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { default as i18n } from 'i18next'
import 'leaflet'
import 'leaflet.nontiledlayer'
import 'leaflet/dist/leaflet.css'
import moment from 'moment-timezone'
import 'moment/locale/de'
import 'moment/locale/nl'
import numeral from 'numeral'
import 'numeral/locales'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppContainer from './components/App'
import config from './config'
import locales from './locales'
import authActionCreators from './redux/auth/actionCreators'
import configureStore from './redux/configureStore'
import dateProposalSocket from './sockets/dateProposalSocket'
import excludedServiceDatesSocket from './sockets/excludedServiceDatesSocket'
import manualPlanningsSocket from './sockets/manualPlanningsSocket'
import ordersSocket from './sockets/ordersSocket'
import planningsSocket from './sockets/planningsSocket'
import toursSocket from './sockets/toursSocket'
import unavailableProposalDatesSocket from './sockets/unavailableProposalDatesSocket'
import { momentLocale, numeralLocale } from './utils/localeStrings'

const initialize = async () => {
	// Initialize i18n
	await i18n.init({
		lng: config.userLanguage,
		fallbackLng: config.fallbackLanguage,
		resources: locales
	})

	// Initialize moment
	moment.locale(momentLocale())

	// Initialize numeral
	numeral.locale(numeralLocale())

	// MUI Theme
	const theme = createTheme({
		palette: {
			primary: blue
		},
		typography: {
			fontFamily:
				'-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'
		}
	})

	// Initialize Redux store
	const store = configureStore()

	// Initialize websockets
	dateProposalSocket.init(store)
	ordersSocket.init(store)
	manualPlanningsSocket.init(store)
	planningsSocket.init(store)
	toursSocket.init(store)
	unavailableProposalDatesSocket.init(store)
	excludedServiceDatesSocket.init(store)

	// Autologin
	store.dispatch(authActionCreators.autoLogin())

	// React Render
	const rootElement = document.getElementById('root')
	if (!rootElement)
		throw new Error('Root element not found. Unable to create root.')
	const root = createRoot(rootElement)
	root.render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<LocalizationProvider
					dateAdapter={AdapterMoment}
					locale={momentLocale()}
				>
					<BrowserRouter>
						<AppContainer />
					</BrowserRouter>
				</LocalizationProvider>
			</MuiThemeProvider>
		</Provider>
	)
}

initialize()
