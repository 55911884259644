import { all, fork, take, put, call, select } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import unavailableProposalDatesSocket from '../../sockets/unavailableProposalDatesSocket'
import apiUnavailableProposalDates from '../../services/apiUnavailableProposalDates'
import notificationsActionCreators from '../notifications/actionCreators'

// Will toggle the unavailable proposal dates socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(unavailableProposalDatesSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(unavailableProposalDatesSocket.close)
	}
}

function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(actionCreators.fetchRequest())
		try {
			const unavailableProposalDates = yield call(
				apiUnavailableProposalDates.getAll
			)
			yield put(actionCreators.fetchSuccess(unavailableProposalDates))
		} catch (error) {
			const errorMessage = i18n.t(
				'unavailableProposalDateadmin:UnavailableProposalDates.Error.fetch'
			)
			yield put(actionCreators.fetchFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

function* add() {
	while (true) {
		yield take(actionTypes.ADD)
		yield put(actionCreators.addRequest())
		try {
			const selectedPlanningTypeKey = yield select(
				(state) => state.toursState.selectedPlanningTypeKey
			)
			const selectedDate = yield select(
				(state) => state.toursState.selectedDate
			)
			const unavailableProposalDate = {
				planningTypeKey: selectedPlanningTypeKey,
				unavailableDate: selectedDate
			}
			yield call(apiUnavailableProposalDates.add, unavailableProposalDate)
			yield put(actionCreators.addSuccess())
		} catch (error) {
			const errorMessage = i18n.t(
				'app:planning.Tours.Error.addUnavailableProposalDateError'
			)
			yield put(actionCreators.addFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

function* remove() {
	while (true) {
		yield take(actionTypes.REMOVE)
		yield put(actionCreators.removeRequest())
		try {
			const selectedPlanningTypeKey = yield select(
				(state) => state.toursState.selectedPlanningTypeKey
			)
			const selectedDate = yield select(
				(state) => state.toursState.selectedDate
			)
			const unavailableProposalDates = yield select(
				(state) => state.unavailableProposalDatesState.unavailableProposalDates
			)
			const selectedUnavailableProposalDate = unavailableProposalDates.find(
				(unavailableProposalDate) =>
					unavailableProposalDate.planningTypeKey === selectedPlanningTypeKey &&
					unavailableProposalDate.unavailableDate === selectedDate
			)
			yield call(
				apiUnavailableProposalDates.remove,
				selectedUnavailableProposalDate.id
			)
			yield put(actionCreators.removeSuccess())
		} catch (error) {
			const errorMessage = i18n.t(
				'app:planning.Tours.Error.removeUnavailableProposalDateError'
			)
			yield put(actionCreators.removeFailure())
			yield put(notificationsActionCreators.addNotification(errorMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([socket, fetch, add, remove].map((saga) => fork(saga)))
}
