import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import Details from './Details'
import Help from './Help'

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		height: '100%'
	},
	details: {
		width: '50%',
		marginRight: 8,
		display: 'flex',
		overflowY: 'auto',
		flexDirection: 'column',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	},
	help: {
		overflow: 'scroll',
		width: '50%',
		marginLeft: 8,
		marginRight: 8,
		display: 'flex',
		flexDirection: 'column',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	}
})

class PlanningType extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		confirm: PropTypes.func.isRequired,
		availableVehicleTypes: PropTypes.array.isRequired
	}

	render() {
		const { classes, planningType, confirm, availableVehicleTypes } = this.props

		return (
			<div className={classes.root}>
				<Paper className={classes.details}>
					<Details
						planningType={planningType}
						confirm={confirm}
						availableVehicleTypes={availableVehicleTypes}
					/>
				</Paper>
				<Paper className={classes.help}>
					<Help />
				</Paper>
			</div>
		)
	}
}

export default withStyles(styleSheet)(PlanningType)
