import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	ListItem as MaterialListItem,
	Typography,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	SvgIcon
} from '@material-ui/core'
import { TruckFal } from '@oliverit/react-fontawesome'
import { withStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'

const styleSheet = (theme) => ({
	active: {
		backgroundColor: theme.palette.action.selected
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	greenIcon: {
		color: green[500]
	},
	redIcon: {
		color: red[500]
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired,
		isSelected: PropTypes.bool
	}

	render() {
		const { vehicle, classes, isSelected } = this.props

		const primary = (
			<div className={classes.primary}>
				<Typography variant="h6">{vehicle.licensePlate}</Typography>
			</div>
		)

		const secondaryAction = (
			<ListItemSecondaryAction>
				<IconButton
					component={Link}
					to={`/vehicleplanning/${vehicle.vehicleId}`}
				>
					<SvgIcon
						color="primary"
						className={vehicle.available ? classes.greenIcon : classes.redIcon}
					>
						<TruckFal />
					</SvgIcon>
				</IconButton>
			</ListItemSecondaryAction>
		)

		return (
			<MaterialListItem
				button
				component={Link}
				to={`/vehicleplanning/${vehicle.vehicleId}`}
				className={isSelected ? classes.active : ''}
			>
				<ListItemText disableTypography primary={primary} />
				{secondaryAction}
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
