import actionTypes from './actionTypes'

const actionCreators = {
	addBySocket: (unavailableProposalDate) => ({
		type: actionTypes.SOCKET_ADD,
		payload: {
			unavailableProposalDate
		}
	}),

	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	add: () => ({
		type: actionTypes.ADD
	}),

	addRequest: () => ({
		type: actionTypes.ADD_REQUEST
	}),

	addSuccess: () => ({
		type: actionTypes.ADD_SUCCESS
	}),

	addFailure: () => ({
		type: actionTypes.ADD_FAILURE
	}),

	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (unavailableProposalDates) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			unavailableProposalDates
		}
	}),

	fetchFailure: () => ({
		type: actionTypes.FETCH_FAILURE
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	remove: () => ({
		type: actionTypes.REMOVE
	}),

	removeRequest: () => ({
		type: actionTypes.REMOVE_REQUEST
	}),

	removeSuccess: () => ({
		type: actionTypes.REMOVE_SUCCESS
	}),

	removeFailure: () => ({
		type: actionTypes.REMOVE_FAILURE
	}),

	removeBySocket: (unavailableProposalDate) => ({
		type: actionTypes.SOCKET_REMOVE,
		payload: {
			unavailableProposalDate
		}
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	})
}

export default actionCreators
