import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { formatCustomer } from '../../../utils/customer'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 24
	}
}

class Customer extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		customer: PropTypes.object.isRequired
	}

	render() {
		const { classes, customer } = this.props

		const formattedCustomer = formatCustomer(customer)
		const telephoneNumber = formattedCustomer.telephoneNumber ? (
			<Typography variant="body2">
				{formattedCustomer.telephoneNumber}
			</Typography>
		) : null
		const mobileNumber = formattedCustomer.mobileNumber ? (
			<Typography variant="body2">{formattedCustomer.mobileNumber}</Typography>
		) : null
		let noPhone
		if (!formattedCustomer.telephoneNumber && !formattedCustomer.mobileNumber) {
			noPhone = (
				<Typography variant="body2" color="error">
					{i18n.t('app:appointmentscheduler.Order.noPhone')}
				</Typography>
			)
		}
		const emailButton = formattedCustomer.email ? (
			<ButtonBase href={`mailto:${formattedCustomer.email}`}>
				<Typography variant="body2" color="primary">
					{formattedCustomer.email}
				</Typography>
			</ButtonBase>
		) : null

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.customer')}
				</Typography>
				<Typography variant="body2">{formattedCustomer.name}</Typography>
				<Typography variant="body2">
					{formattedCustomer.street} {formattedCustomer.houseNumber}
				</Typography>
				<Typography variant="body2">
					{formattedCustomer.postCode} {formattedCustomer.city},{' '}
					{formattedCustomer.country}
				</Typography>
				{telephoneNumber}
				{mobileNumber}
				{noPhone}
				{emailButton}
			</div>
		)
	}
}

export default withStyles(styleSheet)(Customer)
