const actionTypes = {
	FETCH: 'planningtypes/FETCH',
	FETCH_FAILURE: 'planningtypes/FETCH_FAILURE',
	FETCH_REQUEST: 'planningtypes/FETCH_REQUEST',
	FETCH_SUCCESS: 'planningtypes/FETCH_SUCCESS',
	UPDATE: 'planningtypes/UPDATE',
	UPDATE_FAILURE: 'planningtypes/UPDATE_FAILURE',
	UPDATE_REQUEST: 'planningtypesUPDATEM_REQUEST',
	UPDATE_SUCCESS: 'planningtypeUPDATERM_SUCCESS'
}

export default actionTypes
