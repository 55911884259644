import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText, Typography } from '@material-ui/core'

import localizedDescription from '../../../utils/localizedDescription'

class QualificationListItem extends PureComponent {
	static propTypes = {
		qualification: PropTypes.object.isRequired
	}

	render() {
		const { qualification } = this.props

		return (
			<ListItem>
				<ListItemText
					disableTypography
					primary={
						<Typography variant="body2">
							{localizedDescription(qualification.translations)}
						</Typography>
					}
				/>
			</ListItem>
		)
	}
}

export default QualificationListItem
