import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursActionCreators from '../../../../redux/tours/actionCreators'
import PlanningTypeMenu from './PlanningTypeMenu'

class PlanningTypeMenuContainer extends PureComponent {
	static propTypes = {
		planningTypes: PropTypes.array.isRequired,
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		selectPlanningType: PropTypes.func.isRequired
	}

	render() {
		const { planningTypes, selectedPlanningTypeKey, selectPlanningType } =
			this.props

		return (
			<PlanningTypeMenu
				planningTypes={planningTypes}
				selectedPlanningTypeKey={selectedPlanningTypeKey}
				selectPlanningType={selectPlanningType}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		planningTypes: state.planningTypesState.planningTypes,
		selectedPlanningTypeKey: state.toursState.selectedPlanningTypeKey
	}),
	{
		selectPlanningType: toursActionCreators.selectPlanningType
	}
)

export default connector(PlanningTypeMenuContainer)
