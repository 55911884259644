import { default as i18n } from 'i18next'

import elementsFetch from '../utils/fetch'

const vehicleUnavailability = (id) => `/api/vehicles/${id}/unavailable`
const removeVehicleUnavailability = (id, from, till) =>
	`/api/vehicles/${id}/unavailable/${from}/${till}`

const getUnavailabilityById = async (vehicleId) => {
	let response
	try {
		response = await elementsFetch(vehicleUnavailability(vehicleId))
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const createUnavailability = async ({ vehicleId, from, till }) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(vehicleUnavailability(vehicleId), {
			method: 'POST',
			headers,
			body: JSON.stringify({ from, till })
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const removeUnavailability = async ({ vehicleId, from, till }) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(
			removeVehicleUnavailability(vehicleId, from, till),
			{
				method: 'DELETE',
				headers
			}
		)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	}
}

const api = {
	getUnavailabilityById,
	createUnavailability,
	removeUnavailability
}

export default api
