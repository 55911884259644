import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		drivers: PropTypes.array.isRequired,
		selectedDriverId: PropTypes.string
	}

	render() {
		const { drivers, selectedDriverId } = this.props

		return <List drivers={drivers} selectedDriverId={selectedDriverId} />
	}
}

const connector = connect((state) => ({
	drivers: driversSelectors.driversListSelector(state.driversState)
}))

export default connector(ListContainer)
