import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'

import planningTypesActionCreators from '../../../redux/planningtypes/actionCreators'
import planningTypesSelectors from '../../../redux/planningtypes/selectors'
import vehicleSelectors from '../../../redux/vehiclescustom/selectors'
import PlanningType from './PlanningType'

class PlanningTypeContainer extends PureComponent {
	static propTypes = {
		selectedPlanningTypeKey: PropTypes.string,
		planningType: PropTypes.object,
		update: PropTypes.func.isRequired
	}

	handleConfirm = (planningType) => {
		const { update } = this.props

		update(planningType)
	}

	render() {
		const { selectedPlanningTypeKey, planningType, availableVehicleTypes } =
			this.props

		if (!selectedPlanningTypeKey || !planningType) {
			return <div />
		}

		return (
			<PlanningType
				planningType={planningType}
				confirm={this.handleConfirm}
				availableVehicleTypes={availableVehicleTypes}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		planningType: planningTypesSelectors.findPlanningTypeByKeySelector(
			state.planningTypesState,
			props.selectedPlanningTypeKey
		),
		availableVehicleTypes:
			vehicleSelectors.availableVehicleTypes(state.vehiclesState).length > 0
				? vehicleSelectors.availableVehicleTypes(state.vehiclesState)
				: ['BUS', 'VRACHTW.', 'SERVICEBUS'] // default vehicle types
	}),
	{
		update: planningTypesActionCreators.update
	}
)

export default connector(PlanningTypeContainer)
