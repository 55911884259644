import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	IconButton,
	ListItem as MaterialListItem,
	ListItemSecondaryAction,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TrashAltFal } from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'

const styles = {
	column: {
		width: '40%'
	}
}

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		item: PropTypes.object.isRequired,
		remove: PropTypes.func.isRequired
	}

	handleRemoveIconClick = () => {
		const { item, remove } = this.props

		remove(item)
	}

	render() {
		const { classes, item } = this.props

		const fromText = moment
			.unix(item.from)
			.tz('Europe/Amsterdam')
			.format('D MMM Y, H:mm')
		const tillText = moment
			.unix(item.till)
			.tz('Europe/Amsterdam')
			.format('D MMM Y, H:mm')

		return (
			<MaterialListItem divider disableGutters>
				<Typography variant="body2" className={classes.column}>
					{fromText}
				</Typography>
				<Typography variant="body2" className={classes.column}>
					{tillText}
				</Typography>
				<ListItemSecondaryAction>
					<IconButton color="secondary" onClick={this.handleRemoveIconClick}>
						<SvgIcon>
							<TrashAltFal />
						</SvgIcon>
					</IconButton>
				</ListItemSecondaryAction>
			</MaterialListItem>
		)
	}
}

export default withStyles(styles)(ListItem)
