import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import {
	ListItem as MaterialListItem,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	ExclamationCircleFal,
	PhoneFal,
	WrenchFal
} from '@oliverit/react-fontawesome'
import { ORDER_TYPE_SERVICE } from '../../../../utils/constants'
import { formatCustomer } from '../../../../utils/customer'
import { fields, localizedText } from '../../../../utils/localizedText'

const styleSheet = {
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 4,
		marginRight: 16
	},
	secondary: {
		display: 'flex',
		alignItems: 'center'
	}
}

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired
	}

	render() {
		const { classes, order, select } = this.props

		const salesOrderText =
			order.salesOrderDeliveryGroup && order.salesOrderDeliveryGroup !== '0'
				? `${order.salesOrderNumber} (${order.salesOrderDeliveryGroup})`
				: `${order.salesOrderNumber}`

		const formattedCustomer = formatCustomer(order.customer)
		const primary = (
			<>
				<div className={classes.primary}>
					<Typography variant="h6">{formattedCustomer.name}</Typography>
					<Typography variant="h6" className={classes.separator} />
					<Typography variant="h6" color="textSecondary">
						{salesOrderText}
					</Typography>
				</div>
				<Typography variant="body2">
					{formattedCustomer.postCode} {formattedCustomer.city},{' '}
					{formattedCustomer.country}
				</Typography>
			</>
		)

		let ptvStatus
		if (!order.tourDate) {
			ptvStatus = (
				<div className={classes.label}>
					<SvgIcon color="secondary" className={classes.icon}>
						<ExclamationCircleFal />
					</SvgIcon>
					<Typography variant="body2" color="secondary">
						{i18n.t('app:appointmentscheduler.Order.noTourDate')}
					</Typography>
				</div>
			)
		}

		let priority
		if (order.priority && order.priority.key && order.priority.descriptions) {
			priority = (
				<div className={classes.label}>
					<SvgIcon color="secondary" className={classes.icon}>
						<ExclamationCircleFal />
					</SvgIcon>
					<Typography variant="body2" color="secondary">
						{localizedText(fields.PRIORITY, order.priority.key)}
					</Typography>
				</div>
			)
		}

		let contactStatus
		if (order.contactStatus) {
			contactStatus = (
				<div className={classes.label}>
					<SvgIcon color="action" className={classes.icon}>
						<PhoneFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{localizedText(fields.CONTACT_STATUS, order.contactStatus)}
					</Typography>
				</div>
			)
		}

		let orderType
		if (order.orderType.key === ORDER_TYPE_SERVICE) {
			orderType = (
				<div className={classes.label}>
					<SvgIcon color="action" className={classes.icon}>
						<WrenchFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{localizedText(fields.ORDER_TYPE, order.orderType.key)}
					</Typography>
				</div>
			)
		}

		const secondary = (
			<div className={classes.secondary}>
				{ptvStatus}
				{priority}
				{contactStatus}
				{orderType}
			</div>
		)

		return (
			<MaterialListItem button onClick={() => select(order)}>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
