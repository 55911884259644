import { useLocation, useHistory } from 'react-router-dom'
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	SvgIcon
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BarsFal, SignOutAltFal } from '@oliverit/react-fontawesome'
import clsx from 'clsx'
import { default as i18n } from 'i18next'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	toolbar: {
		paddingLeft: theme.spacing(2)
	},
	menuButton: {
		marginRight: theme.spacing(1)
	},
	hide: {
		display: 'none'
	},
	titleText: {
		flexGrow: 1
	}
}))

const TopBar = ({ isSideMenuOpen, showSideMenu, logout }) => {
	const classes = useStyles()
	const { pathname } = useLocation()
	const history = useHistory()

	const handleLogout = () => {
		logout(history)
	}

	// Render header title based on app location
	let title = i18n.t('app:elements.Main.title')
	if (pathname.startsWith('/afspraken')) {
		title = i18n.t('app:appointmentscheduler.Main.title')
	}
	if (pathname.startsWith('/planning')) {
		title = i18n.t('app:planning.Main.title')
	}
	if (pathname.startsWith('/exports')) {
		title = i18n.t('app:exports.Main.title')
	}
	if (pathname.startsWith('/settings')) {
		title = i18n.t('app:settings.Main.title')
	}
	if (pathname.startsWith('/postcodeadmin')) {
		title = i18n.t('app:postcodeadmin.Main.title')
	}
	if (pathname.startsWith('/driveradmin')) {
		title = i18n.t('app:driveradmin.Main.title')
	}
	if (pathname.startsWith('/planningtypeadmin')) {
		title = i18n.t('app:planningtypeadmin.Main.title')
	}
	if (pathname.startsWith('/vehicleplanning')) {
		title = i18n.t('app:vehicleplanning.Main.title')
	}
	if (pathname.startsWith('/vehicleunavailability')) {
		title = i18n.t('app:vehicleUnavailability.Main.title')
	}

	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: isSideMenuOpen
			})}
		>
			<Toolbar variant="dense" className={classes.toolbar}>
				<IconButton
					color="inherit"
					onClick={showSideMenu}
					edge="start"
					className={clsx(classes.menuButton, {
						[classes.hide]: isSideMenuOpen
					})}
				>
					<SvgIcon>
						<BarsFal />
					</SvgIcon>
				</IconButton>
				<Typography variant="h6" noWrap className={classes.titleText}>
					{title}
				</Typography>
				<IconButton color="inherit" edge="end" onClick={handleLogout}>
					<SvgIcon>
						<SignOutAltFal />
					</SvgIcon>
				</IconButton>
			</Toolbar>
		</AppBar>
	)
}

export default TopBar
