import { default as i18n } from 'i18next'
import elementsFetch from '../utils/fetch'

const manualPlanningSaveUrl = '/api/manualplanning'

const save = async (manualPlanning) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(manualPlanningSaveUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify(manualPlanning)
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}
const api = {
	save
}

export default api
