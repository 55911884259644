import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ExclamationFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import { appointmentCustomer, formatCustomer } from '../../../../utils/customer'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	formRow: {
		display: 'flex',
		alignItems: 'center'
	},
	confirmButton: {
		marginTop: 16
	},
	textFieldStreet: {
		flex: 1,
		marginRight: 8
	},
	textFieldHouseNumber: {
		flex: '0 0 120px',
		marginLeft: 8
	},
	textFieldPostCode: {
		flex: '0 0 200px',
		marginRight: 8
	},
	textFieldCity: {
		flex: 1,
		marginLeft: 8
	},
	textFieldEmail: {
		flex: 1
	},
	textFieldTelephoneNumber: {
		flex: 1,
		marginRight: 8
	},
	textFieldMobileNumber: {
		flex: 1,
		marginLeft: 8
	},
	errorMessage: {
		marginTop: 16
	},
	helperTextWithIcon: {
		display: 'flex',
		alignItems: 'center'
	},
	helperTextIcon: {
		fontSize: 16,
		marginRight: 4
	}
}

class Address extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		appointment: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		setAddress: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		const customer = props.appointment.customer
			? props.appointment.customer
			: appointmentCustomer(props.order)
		const formattedCustomer = formatCustomer(customer)
		this.state = {
			errorMessage: '',
			street: formattedCustomer.street,
			streetError: false,
			houseNumber: formattedCustomer.houseNumber,
			houseNumberError: false,
			postCode: formattedCustomer.postCode,
			postCodeError: false,
			city: formattedCustomer.city,
			cityError: false,
			email: formattedCustomer.email,
			emailError: false,
			telephoneNumber: formattedCustomer.telephoneNumber,
			telephoneNumberError: false,
			mobileNumber: formattedCustomer.mobileNumber,
			mobileNumberError: false
		}
	}

	handleChangeStreet = (event) => {
		const streetInput = event.target.value
		if (streetInput.length < 100) {
			this.setState({
				street: streetInput,
				streetError: false
			})
		}
	}

	handleChangeHouseNumber = (event) => {
		const houseNumberInput = event.target.value
		if (houseNumberInput.length < 10) {
			this.setState({
				houseNumber: houseNumberInput,
				houseNumberError: false
			})
		}
	}

	handleChangePostCode = (event) => {
		const postCodeInput = event.target.value
		if (postCodeInput.length < 10) {
			this.setState({
				postCode: postCodeInput,
				postCodeError: false
			})
		}
	}

	handleChangeCity = (event) => {
		const cityInput = event.target.value
		if (cityInput.length < 100) {
			this.setState({
				city: cityInput,
				cityError: false
			})
		}
	}

	handleChangeEmail = (event) => {
		const emailInput = event.target.value
		if (emailInput.length < 255) {
			this.setState({
				email: emailInput,
				emailError: false
			})
		}
	}

	handleChangeTelephoneNumber = (event) => {
		const telephoneNumberInput = event.target.value
		if (telephoneNumberInput.length < 20) {
			this.setState({
				telephoneNumber: telephoneNumberInput,
				telephoneNumberError: false
			})
		}
	}

	handleChangeMobileNumber = (event) => {
		const mobileNumberInput = event.target.value
		if (mobileNumberInput.length < 20) {
			this.setState({
				mobileNumber: mobileNumberInput,
				mobileNumberError: false
			})
		}
	}

	handleConfirm = () => {
		const { setAddress } = this.props
		const {
			street,
			houseNumber,
			postCode,
			city,
			email,
			telephoneNumber,
			mobileNumber
		} = this.state
		if (this.validateInput()) {
			setAddress({
				street,
				houseNumber,
				postCode,
				city,
				email,
				telephoneNumber,
				mobileNumber
			})
		}
	}

	validateInput() {
		const {
			street,
			houseNumber,
			postCode,
			city,
			email,
			telephoneNumber,
			mobileNumber
		} = this.state

		let streetError = false
		let houseNumberError = false
		let postCodeError = false
		let cityError = false
		let emailError = false
		let telephoneNumberError = false
		let mobileNumberError = false

		let errorMessage = ''
		let hasValidPhoneNumber = true

		if (street.length < 1) {
			streetError = true
		}
		if (houseNumber.length < 1) {
			houseNumberError = true
		}
		if (postCode.length < 4) {
			postCodeError = true
		}
		if (city.length < 1) {
			cityError = true
		}
		// Email is optional
		if (email.length > 1) {
			if (!/^.+@.+\..{2,}$/g.test(email) || email.length < 6) {
				emailError = true
			}
		}
		// Telephone number is optional
		if (telephoneNumber.length > 1) {
			if (!/[0-9]+/g.test(telephoneNumber)) {
				telephoneNumberError = true
			}
		}
		// Mobile number is optional
		if (mobileNumber.length > 1) {
			if (!/[0-9]+/g.test(mobileNumber)) {
				mobileNumberError = true
			}
		}
		// If both telephone number and mobile number are missing, then this is an error
		if (telephoneNumber.length === 0 && mobileNumber.length === 0) {
			hasValidPhoneNumber = false
			errorMessage = i18n.t(
				'app:appointmentscheduler.Order.Edit.missingPhoneNumber'
			)
		}

		this.setState({
			errorMessage,
			streetError,
			houseNumberError,
			postCodeError,
			cityError,
			emailError,
			telephoneNumberError,
			mobileNumberError
		})

		const hasInputErrors =
			streetError ||
			houseNumberError ||
			postCodeError ||
			cityError ||
			emailError ||
			telephoneNumberError ||
			mobileNumberError

		return hasValidPhoneNumber && !hasInputErrors
	}

	render() {
		const { classes } = this.props
		const {
			errorMessage,
			street,
			houseNumber,
			postCode,
			city,
			email,
			telephoneNumber,
			mobileNumber
		} = this.state
		const {
			streetError,
			houseNumberError,
			postCodeError,
			cityError,
			emailError,
			telephoneNumberError,
			mobileNumberError
		} = this.state

		let streetHelperText
		if (streetError) {
			streetHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.streetErrorText'
			)
		}
		let houseNumberHelperText
		if (houseNumberError) {
			houseNumberHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.houseNumberErrorText'
			)
		}
		let postCodeHelperText
		if (postCodeError) {
			postCodeHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.postCodeErrorText'
			)
		}
		let cityHelperText
		if (cityError) {
			cityHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.cityErrorText'
			)
		}
		let emailHelperText
		if (emailError) {
			emailHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.emailErrorText'
			)
		} else if (!email) {
			emailHelperText = (
				<span className={classes.helperTextWithIcon}>
					<SvgIcon className={classes.helperTextIcon}>
						<ExclamationFal />
					</SvgIcon>{' '}
					{i18n.t(
						'app:appointmentscheduler.Order.Edit.DeliveryAddress.emailWarningText'
					)}
				</span>
			)
		}
		let telephoneNumberHelperText
		if (telephoneNumberError) {
			telephoneNumberHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.telephoneNumberErrorText'
			)
		}
		let mobileNumberHelperText
		if (mobileNumberError) {
			mobileNumberHelperText = i18n.t(
				'app:appointmentscheduler.Order.Edit.DeliveryAddress.mobileNumberErrorText'
			)
		} else if (!mobileNumber) {
			mobileNumberHelperText = (
				<span className={classes.helperTextWithIcon}>
					<SvgIcon className={classes.helperTextIcon}>
						<ExclamationFal />
					</SvgIcon>{' '}
					{i18n.t(
						'app:appointmentscheduler.Order.Edit.DeliveryAddress.mobileNumberWarningText'
					)}
				</span>
			)
		}

		let error
		if (errorMessage) {
			error = (
				<div className={classes.errorMessage}>
					<Typography variant="body2" color="error">
						{errorMessage}
					</Typography>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.formRow}>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.street'
						)}
						className={classes.textFieldStreet}
						value={street}
						onChange={this.handleChangeStreet}
						margin="normal"
						error={streetError}
						helperText={streetHelperText}
					/>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.houseNumber'
						)}
						className={classes.textFieldHouseNumber}
						value={houseNumber}
						onChange={this.handleChangeHouseNumber}
						margin="normal"
						error={houseNumberError}
						helperText={houseNumberHelperText}
					/>
				</div>
				<div className={classes.formRow}>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.postCode'
						)}
						className={classes.textFieldPostCode}
						value={postCode}
						onChange={this.handleChangePostCode}
						margin="normal"
						error={postCodeError}
						helperText={postCodeHelperText}
					/>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.city'
						)}
						className={classes.textFieldCity}
						value={city}
						onChange={this.handleChangeCity}
						margin="normal"
						error={cityError}
						helperText={cityHelperText}
					/>
				</div>
				<div className={classes.formRow}>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.email'
						)}
						className={classes.textFieldEmail}
						value={email}
						onChange={this.handleChangeEmail}
						margin="normal"
						error={emailError}
						helperText={emailHelperText}
					/>
				</div>
				<div className={classes.formRow}>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.telephoneNumber'
						)}
						className={classes.textFieldTelephoneNumber}
						value={telephoneNumber}
						onChange={this.handleChangeTelephoneNumber}
						margin="normal"
						error={telephoneNumberError}
						helperText={telephoneNumberHelperText}
					/>
					<TextField
						label={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryAddress.mobileNumber'
						)}
						className={classes.textFieldMobileNumber}
						value={mobileNumber}
						onChange={this.handleChangeMobileNumber}
						margin="normal"
						error={mobileNumberError}
						helperText={mobileNumberHelperText}
					/>
				</div>
				{error}
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Address)
