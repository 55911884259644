import { PLANNING_TYPE_FILIAAL } from '../constants'

export const canConfirmTour = (tour, metaTour, ownUserId) => {
	const isUpdating = metaTour ? metaTour.isUpdating : false
	if (isUpdating || tour.isProcessing || tour.confirmedAt || tour.finalizedAt) {
		return false
	}
	if (tour.lock && tour.lock.userId !== ownUserId) {
		return false
	}
	if (tour.planningType.key === PLANNING_TYPE_FILIAAL) {
		// When the tour is planning type filiaal, only check for unconfirmed routitems
		// when they have a salesordernumber (routeitems without salesordernumber do not need confirmation).
		const hasUnconfirmedRouteItems = tour.route.some(
			(routeItem) =>
				routeItem.salesOrderNumber &&
				routeItem.characteristics &&
				routeItem.characteristics.isUnconfirmed
		)
		if (hasUnconfirmedRouteItems) {
			return false
		}
	} else {
		// Check for unconfirmed route items
		const hasUnconfirmedRouteItems = tour.route.some(
			(routeItem) =>
				routeItem.characteristics && routeItem.characteristics.isUnconfirmed
		)
		if (hasUnconfirmedRouteItems) {
			return false
		}
	}
	const hasDeletedRouteItems = tour.route.some((routeItem) => routeItem.deleted)
	if (hasDeletedRouteItems) {
		return false
	}
	return true
}

export const canFinalizeTour = (tour, metaTour, ownUserId) => {
	const isUpdating = metaTour ? metaTour.isUpdating : false
	if (isUpdating || tour.isProcessing || tour.finalizedAt) {
		return false
	}
	if (tour.lock && tour.lock.userId !== ownUserId) {
		return false
	}
	if (tour.planningType.key === PLANNING_TYPE_FILIAAL) {
		// When the tour is planning type filiaal, only check for unconfirmed routitems
		// when they have a salesordernumber (routeitems without salesordernumber do not need confirmation).
		const hasUnconfirmedRouteItems = tour.route.some(
			(routeItem) =>
				routeItem.salesOrderNumber &&
				routeItem.characteristics &&
				routeItem.characteristics.isUnconfirmed
		)
		if (hasUnconfirmedRouteItems) {
			return false
		}
	} else {
		// Check for unconfirmed route items
		const hasUnconfirmedRouteItems = tour.route.some(
			(routeItem) =>
				routeItem.characteristics && routeItem.characteristics.isUnconfirmed
		)
		if (hasUnconfirmedRouteItems) {
			return false
		}
	}
	const hasDeletedRouteItems = tour.route.some((routeItem) => routeItem.deleted)
	if (hasDeletedRouteItems) {
		return false
	}
	// Check if there is a routeItem that has no deliveryNumber
	const hasMissingDeliveryNumber = tour.route.some(
		(routeItem) => !routeItem.deliveryNumber
	)
	if (hasMissingDeliveryNumber) {
		return false
	}
	if (tour.route.length === 0) {
		return false
	}
	return true
}
