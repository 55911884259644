import actionTypes from './actionTypes'

const actionCreators = {
	clearUserError: () => ({
		type: actionTypes.CLEAR_ERROR
	}),

	fetchUser: () => ({
		type: actionTypes.FETCH_USER
	}),

	fetchUserRequest: () => ({
		type: actionTypes.FETCH_USER_REQUEST
	}),

	fetchUserSuccess: (user) => ({
		type: actionTypes.FETCH_USER_SUCCESS,
		payload: {
			user
		}
	}),

	newPasswordReset: (userName) => ({
		type: actionTypes.NEWPASSWORDRESET,
		payload: {
			userName
		}
	}),

	newPasswordResetRequest: () => ({
		type: actionTypes.NEWPASSWORDRESET_REQUEST
	}),

	newPasswordResetFailure: (errorMessages) => ({
		type: actionTypes.NEWPASSWORDRESET_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	newPasswordResetSuccess: () => ({
		type: actionTypes.NEWPASSWORDRESET_SUCCESS
	}),

	resetPassword: (userName, password, pin) => ({
		type: actionTypes.RESETPASSWORD,
		payload: {
			userName,
			password,
			pin
		}
	}),

	resetPasswordRequest: () => ({
		type: actionTypes.RESETPASSWORD_REQUEST
	}),

	resetPasswordFailure: (errorMessages) => ({
		type: actionTypes.RESETPASSWORD_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	resetPasswordSuccess: () => ({
		type: actionTypes.RESETPASSWORD_SUCCESS
	})
}

export default actionCreators
