const actionTypes = {
	APPLY_FILTER: 'vehiclescustom/APPLY_FILTER',
	CLEAR_FILTER: 'vehiclescustom/CLEAR_FILTER',
	FETCH: 'vehiclescustom/FETCH',
	FETCH_REQUEST: 'vehiclescustom/FETCH_REQUEST',
	FETCH_SUCCESS: 'vehiclescustom/FETCH_SUCCESS',
	FETCH_FAILURE: 'vehiclescustom/FETCH_FAILURE',
	SAVE: 'vehiclescustom/SAVE',
	SAVE_REQUEST: 'vehiclescustom/SAVE_REQUEST',
	SAVE_SUCCESS: 'vehiclescustom/SAVE_SUCCESS',
	SAVE_FAILURE: 'vehiclescustom/SAVE_FAILURE',
	SEARCH: 'vehiclescustom/SEARCH',
	SELECT_CURRENT_DATE: 'vehiclescustom/SELECT_CURRENT_DATE',
	SELECT_NEXT_DATE: 'vehiclescustom/SELECT_NEXT_DATE',
	SELECT_PREVIOUS_DATE: 'vehiclescustom/SELECT_PREVIOUS_DATE'
}

export default actionTypes
