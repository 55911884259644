import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	ButtonGroup,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	SvgIcon,
	Switch,
	Tooltip,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'
import {
	EraserFad,
	ExclamationTriangleFal,
	TruckMovingFad
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import localizedDescription from '../../../utils/localizedDescription'
import ConfirmDialog from '../../Shared/ConfirmDialog'

const styleSheet = {
	root: {
		padding: 16,
		marginBottom: 16
	},
	header: {
		display: 'flex',
		marginBottom: 8
	},
	headerText: {
		flex: 1
	},
	buttonLeft: {
		marginRight: 8
	},
	buttonMiddle: {
		marginLeft: 8,
		marginRight: 8
	},
	buttonRight: {
		marginLeft: 8
	},
	buttons: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	iconButton: {
		marginRight: 8
	},
	formControl: {
		flex: 1,
		paddingBottom: 8
	},
	violationsIcon: {
		marginRight: 8
	},
	violationsWarning: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		color: amber[700]
	}
}

class TourEditControls extends PureComponent {
	static propTypes = {
		cancel: PropTypes.func.isRequired,
		classes: PropTypes.object.isRequired,
		hasOrdersWithoutAppointment: PropTypes.bool,
		hasViolations: PropTypes.bool,
		isEmpty: PropTypes.bool,
		isModified: PropTypes.bool,
		manualPlanningRemoveAllOrdersWithoutAppointment: PropTypes.func.isRequired,
		optimize: PropTypes.func.isRequired,
		reduce: PropTypes.func.isRequired,
		save: PropTypes.func.isRequired,
		confirmSave: PropTypes.func.isRequired,
		showSaveDialog: PropTypes.bool.isRequired,
		closeSaveDialog: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedPlanningType: PropTypes.object.isRequired,
		startAtDepot: PropTypes.bool.isRequired,
		toggleStartAtDepot: PropTypes.func.isRequired,
		validate: PropTypes.func.isRequired
	}

	handleManualPlanningRemoveOrdersWithoutAppointment = () => {
		const { manualPlanningRemoveAllOrdersWithoutAppointment } = this.props
		manualPlanningRemoveAllOrdersWithoutAppointment()
	}

	render() {
		const {
			classes,
			cancel,
			hasOrdersWithoutAppointment,
			hasViolations,
			isEmpty,
			isModified,
			optimize,
			reduce,
			save,
			confirmSave,
			showSaveDialog,
			closeSaveDialog,
			selectedMoment,
			selectedPlanningType,
			startAtDepot,
			toggleStartAtDepot,
			validate
		} = this.props

		const date = moment
			.tz(selectedMoment, 'Europe/Amsterdam')
			.format('dddd D MMMM Y')

		const disableOptimizeButton = isEmpty
		const disableValidateButton = isEmpty
		const disableReduceButton = isEmpty
		let disableSaveButton = isModified
		if (isEmpty) {
			// Always allow saving when all tours are empty, even when they are modified
			disableSaveButton = false
		}

		let violationsWarning
		if (hasViolations) {
			violationsWarning = (
				<div className={classes.violationsWarning}>
					<SvgIcon className={classes.violationsIcon}>
						<ExclamationTriangleFal />
					</SvgIcon>
					<Typography color="inherit" variant="subtitle1" paragraph>
						{i18n.t('app:planning.Manual.hasViolationsSummary')}
					</Typography>
				</div>
			)
		}

		let startAtDepotSwitch
		if (
			selectedPlanningType &&
			selectedPlanningType.planningParams &&
			!selectedPlanningType.planningParams.startAtDepot
		) {
			startAtDepotSwitch = (
				<FormControl className={classes.formControl}>
					<FormControlLabel
						margin="normal"
						control={
							<Switch
								checked={startAtDepot}
								onChange={toggleStartAtDepot}
								value={startAtDepot}
							/>
						}
						label={
							<Typography variant="body2">
								{i18n.t('app:planning.Manual.startAtDepot')}
							</Typography>
						}
					/>
				</FormControl>
			)
		}

		return (
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6" className={classes.headerText}>
						{i18n.t('app:planning.Manual.title')}
					</Typography>
					<Tooltip
						title={i18n.t('app:planning.Tours.removeOrdersWithoutAppointment')}
						placement="top"
					>
						<div>
							<IconButton
								className={classes.iconButton}
								disabled={!hasOrdersWithoutAppointment}
								onClick={
									this.handleManualPlanningRemoveOrdersWithoutAppointment
								}
							>
								<SvgIcon>
									<EraserFad />
								</SvgIcon>
							</IconButton>
						</div>
					</Tooltip>
					<Button color="secondary" onClick={cancel}>
						{i18n.t('app:planning.Manual.cancel')}
					</Button>
				</div>
				<Typography variant="subtitle1" paragraph>
					{localizedDescription(selectedPlanningType.descriptions)}, {date}
				</Typography>
				<Typography variant="body2" paragraph>
					{i18n.t('app:planning.Manual.explanation1')}
				</Typography>
				<Typography variant="body2" paragraph>
					{i18n.t('app:planning.Manual.explanation2')}
				</Typography>
				{startAtDepotSwitch}
				{violationsWarning}
				<div className={classes.buttons}>
					<ButtonGroup
						color="default"
						variant="outlined"
						className={classes.buttonLeft}
					>
						<Button
							disabled={disableOptimizeButton}
							fullWidth
							onClick={optimize}
						>
							{i18n.t('app:planning.Manual.optimize')}
						</Button>
						<Tooltip
							title={i18n.t('app:planning.Manual.optimizeSequenceWithinTours')}
							placement="top"
						>
							<Button
								disabled={disableReduceButton}
								onClick={reduce}
								size="small"
							>
								<SvgIcon fontSize="small">
									<TruckMovingFad />
								</SvgIcon>
							</Button>
						</Tooltip>
					</ButtonGroup>
					<Button
						disabled={disableValidateButton}
						fullWidth
						color="default"
						variant="outlined"
						onClick={validate}
						className={classes.buttonMiddle}
					>
						{i18n.t('app:planning.Manual.validate')}
					</Button>
					<Button
						disabled={disableSaveButton}
						fullWidth
						color="primary"
						variant="outlined"
						onClick={save}
						className={classes.buttonRight}
					>
						{i18n.t('app:planning.Manual.save')}
					</Button>
				</div>
				<ConfirmDialog
					isOpen={showSaveDialog}
					cancel={closeSaveDialog}
					cancelButtonText={i18n.t('app:planning.Manual.SaveDialog.cancel')}
					confirm={confirmSave}
					confirmButtonText={i18n.t('app:planning.Manual.SaveDialog.confirm')}
					titleText={i18n.t('app:planning.Manual.SaveDialog.title')}
					descriptionText={i18n.t('app:planning.Manual.SaveDialog.description')}
				/>
			</Paper>
		)
	}
}

export default withStyles(styleSheet)(TourEditControls)
