import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green, lightGreen } from '@material-ui/core/colors'
import moment from 'moment-timezone'

import {
	computeDayEntryTop,
	computeDayEntryHeight
} from '../../../../utils/drivers'

const styleSheet = (theme) => ({
	root: {
		position: 'absolute',
		backgroundColor: green[100],
		width: '98%',
		borderLeftStyle: 'solid',
		borderLeftColor: lightGreen.A700
	},
	selected: {
		position: 'absolute',
		backgroundColor: lightGreen.A700,
		width: '98%',
		borderLeftStyle: 'solid',
		borderLeftColor: lightGreen.A700
	},
	label: {
		paddingTop: 4,
		paddingLeft: 4,
		color: green[700],
		fontWeight: 'bold'
	},
	selectedLabel: {
		paddingTop: 4,
		paddingLeft: 4,
		color: theme.palette.primary.contrastText,
		fontWeight: 'bold'
	}
})

class DayEntry extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		from: PropTypes.number.isRequired,
		till: PropTypes.number.isRequired,
		selected: PropTypes.bool,
		select: PropTypes.func.isRequired
	}

	handleEntryClick = () => {
		const { select, from, till } = this.props
		const date = moment.unix(from).tz('Europe/Amsterdam').startOf('day').unix()
		select({ date, from, till })
	}

	render() {
		const { classes, from, till, selected } = this.props

		// the entry is positioned absolute to its parent with the 'top' property
		const top = computeDayEntryTop(from)
		// the height is computed based on the duration of the entry
		const height = computeDayEntryHeight(from, till)

		const style = {
			top,
			height
		}

		const fromMoment = moment.unix(from).tz('Europe/Amsterdam')
		const tillMoment = moment.unix(till).tz('Europe/Amsterdam')
		const formattedLabel = `${fromMoment.format('H:mm')} - ${tillMoment.format(
			'H:mm'
		)}`

		return (
			<div
				className={selected ? classes.selected : classes.root}
				style={style}
				onClick={this.handleEntryClick}
			>
				<Typography
					variant="body2"
					className={selected ? classes.selectedLabel : classes.label}
				>
					{formattedLabel}
				</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DayEntry)
