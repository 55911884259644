import { default as i18n } from 'i18next'
import elementsFetch from '../utils/fetch'

const planningTypesUrl = '/api/planningtypes'
const planningTypeUrl = (id) => `/api/planningtypes/${id}`

const getAll = async () => {
	let response
	try {
		response = await elementsFetch(planningTypesUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const update = async (planningType) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(planningTypeUrl(planningType.id), {
			method: 'PUT',
			headers,
			body: JSON.stringify(planningType)
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const api = {
	getAll,
	update
}

export default api
