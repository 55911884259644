import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ordersActionCreators from '../../../redux/orders/actionCreators'
import ordersSelectors from '../../../redux/orders/selectors'
import OrderSelection from './OrderSelection'

class OrderSelectionContainer extends PureComponent {
	static propTypes = {
		ordersLoading: PropTypes.bool,
		hasActiveFilters: PropTypes.bool,
		selectOrder: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string,
		selectWeek: PropTypes.func.isRequired
	}

	render() {
		const {
			ordersLoading,
			hasActiveFilters,
			selectOrder,
			selectedOrderId,
			selectWeek
		} = this.props

		return (
			<OrderSelection
				hasActiveFilters={hasActiveFilters}
				isLoading={ordersLoading}
				selectOrder={selectOrder}
				selectedOrderId={selectedOrderId}
				selectWeek={selectWeek}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		ordersLoading: state.ordersState.meta.isLoading,
		hasActiveFilters: ordersSelectors.hasActiveFilters(state.ordersState)
	}),
	{
		clearSearch: ordersActionCreators.clearSearch,
		selectWeek: ordersActionCreators.selectWeek
	}
)

export default connector(OrderSelectionContainer)
