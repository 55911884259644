import { default as i18n } from 'i18next'
import config from '../config'
import elementsFetch from '../utils/fetch'

const userUrl = '/api/whoami'
const newPasswordResetUrl = `${config.oeServer}/api/passwordresets/new`
const resetPasswordUrl = `${config.oeServer}/api/passwordresets/reset`

const get = async () => {
	let response
	try {
		response = await elementsFetch(userUrl)
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const newPasswordReset = async (userName) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(newPasswordResetUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				userName
			}),
			timeout: 10000
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	}
}

const resetPassword = async (userName, password, pin) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(resetPasswordUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				userName,
				password,
				pin
			}),
			timeout: 10000
		})
	} catch (error) {
		throw new Error(i18n.t('app:elements.Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new Error(i18n.t('app:elements.Api.Generic.unauthorized'))
		}
		throw new Error(i18n.t('app:elements.Api.Generic.serverError'))
	}
}

const api = {
	get,
	newPasswordReset,
	resetPassword
}

export default api
