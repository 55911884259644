export const ORDER_KEY_TYPE_SALESORDER_DELIVERYGROUP =
	'SALESORDER_DELIVERYGROUP'
export const ORDER_KEY_TYPE_DELIVERY = 'DELIVERY'
export const ORDER_TYPE_SERVICE = 'SERVICE'
export const PLANNING_TYPE_DC_UDEN = 'Z01'
export const PLANNING_TYPE_DEPOTS_DUITSLAND = 'Z02'
export const PLANNING_TYPE_NEXT_DAY = 'Z03'
export const PLANNING_TYPE_SERVICE = 'Z04'
export const PLANNING_TYPE_FILIAAL = 'Z05'
export const PLANNING_TYPE_MEERDAAGSE_RIT = 'Z06'
export const PLANNING_TYPE_SERVICE_DUITSLAND = 'Z07'
export const PLANNING_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const PLANNING_STATUS_FINISHED = 'FINISHED'
export const PLANNING_STATUS_SKIPPED = 'SKIPPED'
export const PLANNING_STATUS_FAILED = 'FAILED'
export const PLANNING_STATUS_ABORTED = 'ABORTED'
export const PRIMARY_MATERIALGROUPS = ['10', '001001']
