import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialPlanningsState = []

const planningsReducer = (state = initialPlanningsState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_FAILURE: {
			return initialPlanningsState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.plannings.map((planning) => ({
				...planning,
				dates: []
			}))
		}

		case actionTypes.SOCKET_UPDATE_PLANNING_STATUS: {
			return state.map((planning) =>
				planning.planningTypeKey !== action.meta.planningTypeKey
					? planning
					: {
							...planning,
							status: action.payload.status,
							dates: action.payload.dates
					  }
			)
		}

		default: {
			return state
		}
	}
}

const initialPlanningMetaState = {
	isConnected: false,
	isLoading: true
}

const metaReducer = (state = initialPlanningMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	plannings: planningsReducer,
	meta: metaReducer
})
