const actionTypes = {
	CONFIRM_APPOINTMENT: 'order/CONFIRM_APPOINTMENT',
	CONFIRM_APPOINTMENT_FAILURE: 'order/CONFIRM_APPOINTMENT_FAILURE',
	CONFIRM_APPOINTMENT_REQUEST: 'order/CONFIRM_APPOINTMENT_REQUEST',
	CONFIRM_APPOINTMENT_SUCCESS: 'order/CONFIRM_APPOINTMENT_SUCCESS',
	FETCH: 'order/FETCH',
	FETCH_FAILURE: 'order/FETCH_FAILURE',
	FETCH_REQUEST: 'order/FETCH_REQUEST',
	FETCH_SUCCESS: 'order/FETCH_SUCCESS',
	FETCH_TIME_SLOTS: 'order/FETCH_TIME_SLOTS',
	FETCH_TIME_SLOTS_REQUEST: 'order/FETCH_TIME_SLOTS_REQUEST',
	FETCH_TIME_SLOTS_SUCCESS: 'order/FETCH_TIME_SLOTS_SUCCESS',
	FETCH_TIME_SLOTS_FAILURE: 'order/FETCH_TIME_SLOTS_FAILURE',
	LOCK: 'order/LOCK',
	LOCK_FAILURE: 'order/LOCK_FAILURE',
	LOCK_REQUEST: 'order/LOCK_REQUEST',
	LOCK_SUCCESS: 'order/LOCK_SUCCESS',
	NOANSWER_APPOINTMENT: 'order/NOANSWER_APPOINTMENT',
	NOANSWER_APPOINTMENT_FAILURE: 'order/NOANSWER_APPOINTMENT_FAILURE',
	NOANSWER_APPOINTMENT_REQUEST: 'order/NOANSWER_APPOINTMENT_REQUEST',
	NOANSWER_APPOINTMENT_SUCCESS: 'order/NOANSWER_APPOINTMENT_SUCCESS',
	POSTPONE_APPOINTMENT: 'order/POSTPONE_APPOINTMENT',
	POSTPONE_APPOINTMENT_FAILURE: 'order/POSTPONE_APPOINTMENT_FAILURE',
	POSTPONE_APPOINTMENT_REQUEST: 'order/POSTPONE_APPOINTMENT_REQUEST',
	POSTPONE_APPOINTMENT_SUCCESS: 'order/POSTPONE_APPOINTMENT_SUCCESS',
	REMOVE: 'order/REMOVE',
	SAVE_APPOINTMENT: 'order/SAVE_APPOINTMENT',
	SAVE_APPOINTMENT_FAILURE: 'order/SAVE_APPOINTMENT_FAILURE',
	SAVE_APPOINTMENT_REQUEST: 'order/SAVE_APPOINTMENT_REQUEST',
	SAVE_APPOINTMENT_SUCCESS: 'order/SAVE_APPOINTMENT_SUCCESS',
	TAKEOVERLOCK: 'order/TAKEOVERLOCK',
	TAKEOVERLOCK_FAILURE: 'order/TAKEOVERLOCK_FAILURE',
	TAKEOVERLOCK_REQUEST: 'order/TAKEOVERLOCK_REQUEST',
	TAKEOVERLOCK_SUCCESS: 'order/TAKEOVERLOCK_SUCCESS',
	UNLOCK: 'order/UNLOCK',
	UNLOCK_FAILURE: 'order/UNLOCK_FAILURE',
	UNLOCK_REQUEST: 'order/UNLOCK_REQUEST',
	UNLOCK_SUCCESS: 'order/UNLOCK_SUCCESS',
	UPDATE: 'order/UPDATE',
	UPDATE_TOURDATE: 'order/UPDATE_TOURDATE'
}

export default actionTypes
