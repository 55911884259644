import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import vehicleCustomSelectors from '../../redux/vehiclescustom/selectors'
import vehicleCustomActionCreators from '../../redux/vehiclescustom/actionCreators'
import driversActionCreators from '../../redux/drivers/actionCreators'
import planningTypesActionCreators from '../../redux/planningtypes/actionCreators'
import depotsActionCreators from '../../redux/depots/actionCreators'
import VehiclePlanning from './VehiclePlanning'

class VehiclePlanningContainer extends PureComponent {
	static propTypes = {
		depotsLoading: PropTypes.bool,
		driversLoading: PropTypes.bool,
		loadDepots: PropTypes.func.isRequired,
		loadDrivers: PropTypes.func.isRequired,
		loadVehicles: PropTypes.func.isRequired,
		loadPlanningTypes: PropTypes.func.isRequired,
		match: PropTypes.object.isRequired,
		planningTypesLoading: PropTypes.bool,
		selectedMoment: PropTypes.object.isRequired,
		vehiclesLoading: PropTypes.bool
	}

	componentDidMount() {
		this.loadDepots()
		this.loadDrivers()
		this.loadPlanningTypes()
		this.loadVehicles()
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedMoment !== prevProps.selectedMoment) {
			this.loadVehicles()
		}
	}

	handleRefresh = () => {
		this.loadDepots()
		this.loadDrivers()
		this.loadPlanningTypes()
		this.loadVehicles()
	}

	loadDepots = () => {
		const { depotsLoading, loadDepots } = this.props

		if (!depotsLoading) {
			loadDepots()
		}
	}

	loadDrivers = () => {
		const { driversLoading, loadDrivers } = this.props

		if (!driversLoading) {
			loadDrivers()
		}
	}

	loadPlanningTypes = () => {
		const { planningTypesLoading, loadPlanningTypes } = this.props

		if (!planningTypesLoading) {
			loadPlanningTypes()
		}
	}

	loadVehicles = () => {
		const { vehiclesLoading, selectedMoment, loadVehicles } = this.props

		// fetch vehicles if loading not already in progress
		if (!vehiclesLoading) {
			const day = selectedMoment.date()
			const month = selectedMoment.month() + 1 // moment uses zero-indexed months: 0=january, 1=february, ...
			const year = selectedMoment.year()

			loadVehicles(day, month, year)
		}
	}

	render() {
		const { match } = this.props

		const selectedVehicleId = match.params.id
		const showEdit = Boolean(match.params.edit)

		return (
			<VehiclePlanning
				selectedVehicleId={selectedVehicleId}
				refresh={this.handleRefresh}
				showEdit={showEdit}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		depotsLoading: state.depotsState.meta.isLoading,
		driversLoading: state.driversState.meta.isLoading,
		planningTypesLoading: state.planningTypesState.meta.isLoading,
		selectedMoment: vehicleCustomSelectors.selectedMomentSelector(
			state.vehiclesState
		),
		vehiclesLoading: state.vehiclesState.meta.isLoading
	}),
	{
		loadDepots: depotsActionCreators.fetch,
		loadDrivers: driversActionCreators.fetch,
		loadPlanningTypes: planningTypesActionCreators.fetch,
		loadVehicles: vehicleCustomActionCreators.fetch
	}
)

export default connector(VehiclePlanningContainer)
