import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	CircularProgress,
	IconButton,
	ListItem as MaterialListItem,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CalendarCheckFal,
	ExclamationCircleFal,
	PhoneFal,
	PhoneVolumeFal,
	TruckMovingFal,
	WrenchFal
} from '@oliverit/react-fontawesome'
import { green } from '@material-ui/core/colors'
import { default as i18n } from 'i18next'
import { ORDER_TYPE_SERVICE } from '../../../../utils/constants'
import { formatCustomer } from '../../../../utils/customer'
import { fields, localizedText } from '../../../../utils/localizedText'

const styleSheet = (theme) => ({
	active: {
		backgroundColor: theme.palette.action.selected
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 4
	},
	separator: {
		marginLeft: 4,
		marginRight: 4
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	},
	iconGreen: {
		color: green[500]
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 4,
		marginRight: 16
	},
	secondary: {
		display: 'flex',
		alignItems: 'center'
	}
})

class ListItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		isOwnUser: PropTypes.bool,
		isSelected: PropTypes.bool,
		selectOrder: PropTypes.func.isRequired
	}

	handleSelect = () => {
		const { order, selectOrder } = this.props
		selectOrder(order.id)
	}

	render() {
		const { classes, order, isOwnUser, isSelected } = this.props

		let secondaryActionIcon
		if (order.appointment && order.appointment.isProcessing) {
			// Order is being confirmed
			secondaryActionIcon = <CircularProgress size={20} />
		} else if (order.lock) {
			// Order locked
			if (isOwnUser) {
				secondaryActionIcon = (
					<SvgIcon color="primary">
						<PhoneVolumeFal />
					</SvgIcon>
				)
			} else {
				secondaryActionIcon = (
					<SvgIcon color="secondary">
						<PhoneVolumeFal />
					</SvgIcon>
				)
			}
		} else if (order.isAppointmentConfirmed) {
			// Order confirmed
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconGreen}>
					<CalendarCheckFal />
				</SvgIcon>
			)
		} else if (order.isAppointmentPlanned) {
			// Order planned
			secondaryActionIcon = (
				<SvgIcon color="primary" className={classes.iconGreen}>
					<TruckMovingFal />
				</SvgIcon>
			)
		}

		let secondaryAction
		if (secondaryActionIcon) {
			secondaryAction = (
				<ListItemSecondaryAction>
					<IconButton onClick={this.handleSelect}>
						{secondaryActionIcon}
					</IconButton>
				</ListItemSecondaryAction>
			)
		}

		const salesOrderText =
			order.salesOrderDeliveryGroup && order.salesOrderDeliveryGroup !== '0'
				? `${order.salesOrderNumber} (${order.salesOrderDeliveryGroup})`
				: `${order.salesOrderNumber}`

		const formattedCustomer = formatCustomer(order.customer)
		const primary = (
			<>
				<div className={classes.primary}>
					<Typography variant="h6">{formattedCustomer.name}</Typography>
					<Typography variant="h6" className={classes.separator} />
					<Typography variant="h6" color="textSecondary">
						{salesOrderText}
					</Typography>
				</div>
				<Typography variant="subtitle1">
					{formattedCustomer.postCode} {formattedCustomer.city},{' '}
					{formattedCustomer.country}
				</Typography>
			</>
		)

		let ptvStatus
		if (!order.tourDate) {
			ptvStatus = (
				<div className={classes.label}>
					<SvgIcon color="secondary" className={classes.icon}>
						<ExclamationCircleFal />
					</SvgIcon>
					<Typography variant="body2" color="secondary">
						{i18n.t('app:appointmentscheduler.Order.noTourDate')}
					</Typography>
				</div>
			)
		}

		let priority
		if (order.priority && order.priority.key && order.priority.descriptions) {
			priority = (
				<div className={classes.label}>
					<SvgIcon color="secondary" className={classes.icon}>
						<ExclamationCircleFal />
					</SvgIcon>
					<Typography variant="body2" color="secondary">
						{localizedText(fields.PRIORITY, order.priority.key)}
					</Typography>
				</div>
			)
		}

		let contactStatus
		if (order.contactStatus) {
			contactStatus = (
				<div className={classes.label}>
					<SvgIcon color="action" className={classes.icon}>
						<PhoneFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{localizedText(fields.CONTACT_STATUS, order.contactStatus)}
					</Typography>
				</div>
			)
		}

		let orderType
		if (order.orderType.key === ORDER_TYPE_SERVICE) {
			orderType = (
				<div className={classes.label}>
					<SvgIcon color="action" className={classes.icon}>
						<WrenchFal />
					</SvgIcon>
					<Typography variant="body2" color="textSecondary">
						{localizedText(fields.ORDER_TYPE, order.orderType.key)}
					</Typography>
				</div>
			)
		}

		const secondary = (
			<div className={classes.secondary}>
				{ptvStatus}
				{priority}
				{contactStatus}
				{orderType}
			</div>
		)

		return (
			<MaterialListItem
				button
				onClick={this.handleSelect}
				className={isSelected ? classes.active : ''}
			>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
				{secondaryAction}
			</MaterialListItem>
		)
	}
}

export default withStyles(styleSheet)(ListItem)
