import actionTypes from './actionTypes'

const actionCreators = {
	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	}),

	fetch: (orderKey) => ({
		type: actionTypes.FETCH,
		meta: {
			orderKey
		}
	}),

	fetchFailure: (orderKey, errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		meta: {
			orderKey
		},
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: (orderKey) => ({
		type: actionTypes.FETCH_REQUEST,
		meta: {
			orderKey
		}
	}),

	fetchSuccess: (orderKey, proposals) => ({
		type: actionTypes.FETCH_SUCCESS,
		meta: {
			orderKey
		},
		payload: {
			proposals
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	socketConnected: () => ({
		type: actionTypes.SOCKET_CONNECTED
	}),

	socketDisconnected: () => ({
		type: actionTypes.SOCKET_DISCONNECTED
	})
}

export default actionCreators
