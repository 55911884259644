import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import DriverContainer from './Driver'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Drivers extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		change: PropTypes.func.isRequired,
		meta: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	handleChangeFirstDriver = (driverId) => {
		const { vehicle } = this.props
		this.changeDrivers(driverId, vehicle.secondDriver)
	}

	handleRemoveFirstDriver = () => {
		const { vehicle } = this.props
		this.changeDrivers(null, vehicle.secondDriver)
	}

	handleChangeSecondDriver = (driverId) => {
		const { vehicle } = this.props
		this.changeDrivers(vehicle.firstDriver, driverId)
	}

	handleRemoveSecondDriver = () => {
		const { vehicle } = this.props
		this.changeDrivers(vehicle.firstDriver, null)
	}

	changeDrivers = (firstDriver, secondDriver) => {
		const { change } = this.props
		change({ firstDriver, secondDriver })
	}

	render() {
		const { classes, meta, vehicle } = this.props
		const { firstDriver, secondDriver } = vehicle
		const { firstDriverEmpty } = meta

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Drivers.title')}
				</Typography>
				<DriverContainer
					driverId={firstDriver}
					error={firstDriverEmpty}
					errorText={
						firstDriverEmpty
							? i18n.t('app:vehicleplanning.Driver.required')
							: null
					}
					excludedDrivers={[secondDriver]}
					change={this.handleChangeFirstDriver}
					remove={this.handleRemoveFirstDriver}
				/>
				<DriverContainer
					driverId={secondDriver}
					excludedDrivers={[firstDriver]}
					change={this.handleChangeSecondDriver}
					remove={this.handleRemoveSecondDriver}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(Drivers)
