import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import driversActionCreators from '../../../../redux/drivers/actionCreators'
import AvailabilityItemDetails from './AvailabilityItemDetails'
import { mergeAvailability } from '../../../../utils/drivers'

class AvailabilityItemDetailsContainer extends PureComponent {
	static propTypes = {
		driverId: PropTypes.string,
		selectedAvailabilityItem: PropTypes.object,
		otherAvailability: PropTypes.array.isRequired,
		driver: PropTypes.object.isRequired,
		saveAvailability: PropTypes.func.isRequired
	}

	handleSaveAvailabilityItem = (item) => {
		const {
			driver,
			selectedAvailabilityItem,
			otherAvailability,
			saveAvailability
		} = this.props
		const newAvailability = mergeAvailability(otherAvailability, item)
		saveAvailability(driver.id, selectedAvailabilityItem.date, newAvailability)
	}

	handleRemoveAvailabilityItem = () => {
		const {
			driver,
			selectedAvailabilityItem,
			otherAvailability,
			saveAvailability
		} = this.props
		saveAvailability(
			driver.id,
			selectedAvailabilityItem.date,
			otherAvailability
		)
	}

	render() {
		const { selectedAvailabilityItem } = this.props

		if (!selectedAvailabilityItem) {
			return null
		}

		return (
			<AvailabilityItemDetails
				item={selectedAvailabilityItem}
				save={this.handleSaveAvailabilityItem}
				remove={this.handleRemoveAvailabilityItem}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		selectedAvailabilityItem: state.driversState.selectedAvailabilityItem,
		otherAvailability:
			driversSelectors.findNotSelectedAvailabiltyForSelectedDaySelector(
				state.driversState
			),
		driver: driversSelectors.findDriverByIdSelector(
			state.driversState,
			props.driverId
		)
	}),
	{
		saveAvailability: driversActionCreators.saveAvailability
	}
)

export default connector(AvailabilityItemDetailsContainer)
