import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import Column from '../Column'
import Amounts from './Amounts'
import Customer from './Customer'
import Dates from './Dates'
import DeliveryDate from './DeliveryDate'
import Characteristics from './Characteristics'
import Note from './Note'
import Remarks from './Remarks'
import { appointmentCustomer } from '../../../../utils/customer'
import MaterialList from './MaterialList'

const styleSheet = {}

class Summary extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		selectedStep: PropTypes.string.isRequired
	}

	render() {
		const { appointment, order } = this.props

		const header = (
			<Typography variant="h5">
				{i18n.t('app:appointmentscheduler.Order.Edit.Summary.title')}
			</Typography>
		)

		const customer = appointment.customer
			? appointment.customer
			: appointmentCustomer(order)

		const content = (
			<>
				<Customer customer={customer} />
				<DeliveryDate appointment={appointment} />
				<Characteristics appointment={appointment} />
				<Remarks appointment={appointment} />
				<Note appointment={appointment} />
				<Dates order={order} />
				<Amounts order={order} />
				<MaterialList order={order} />
			</>
		)

		return <Column header={header}>{content}</Column>
	}
}

export default withStyles(styleSheet)(Summary)
