import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import {
	PLANNING_TYPE_FILIAAL,
	PLANNING_TYPE_MEERDAAGSE_RIT
} from '../../../../utils/constants'
import RouteItem from './RouteItem'

const styleSheet = {
	routeDepot: {
		backgroundColor: 'rgba(0, 0, 0, 0.08)',
		textAlign: 'center'
	},
	routeNonServiceTimeSeparator: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		textAlign: 'center'
	},
	route: {
		flexWrap: 'wrap'
	}
}

class RouteItemList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		manualPlanningDown: PropTypes.func.isRequired,
		manualPlanningIsModified: PropTypes.bool,
		manualPlanningMode: PropTypes.bool,
		manualPlanningMove: PropTypes.func.isRequired,
		manualPlanningRemove: PropTypes.func.isRequired,
		manualPlanningUp: PropTypes.func.isRequired,
		tour: PropTypes.object.isRequired
	}

	handleManualPlanningDown = (orderKey) => {
		const { manualPlanningDown, tour } = this.props
		manualPlanningDown(tour.id, orderKey)
	}

	handleManualPlanningMove = (orderKey) => {
		const { manualPlanningMove, tour } = this.props
		manualPlanningMove(tour.id, orderKey)
	}

	handleManualPlanningRemove = (orderKey) => {
		const { manualPlanningRemove, tour } = this.props
		manualPlanningRemove(tour.id, orderKey)
	}

	handleManualPlanningUp = (orderKey) => {
		const { manualPlanningUp, tour } = this.props
		manualPlanningUp(tour.id, orderKey)
	}

	render() {
		const { classes, manualPlanningIsModified, manualPlanningMode, tour } =
			this.props

		// Start depot item
		// Display the departure time here
		const startDepotRouteItemPrimary = (
			<Typography variant="subtitle2">{tour.startDepot.name}</Typography>
		)
		const startDepotRouteListItem = (
			<ListItem
				key="routeitem-startdepot"
				divider
				className={classes.routeDepot}
			>
				<ListItemText disableTypography primary={startDepotRouteItemPrimary} />
			</ListItem>
		)

		// End depot item
		// Display the arrival time here
		const endDepotRouteItemPrimary = (
			<Typography variant="subtitle2">{tour.endDepot.name}</Typography>
		)
		const endDepotRouteListItem = (
			<ListItem key="routeitem-enddepot" className={classes.routeDepot}>
				<ListItemText disableTypography primary={endDepotRouteItemPrimary} />
			</ListItem>
		)

		// Route items
		let routeListItems = []
		if (tour.route.length > 0) {
			// We use a forEach loop here because we need to keep track of previous time values and pass those to the next item
			let previousDepartureTime = moment
				.unix(tour.startDepot.departureTime)
				.tz('Europe/Amsterdam')
			let previousEndServiceTime = moment
				.unix(tour.startDepot.departureTime)
				.tz('Europe/Amsterdam')
			// Skip deleted route items when in manual planning mode
			let route = tour.route
			if (manualPlanningMode) {
				route = tour.route.filter((routeItem) => !routeItem.deleted)
			}
			// A tour contains the qualifications of the vehicle, the first driver and the second driver
			const qualifications = [
				...(tour.vehicle && tour.vehicle.qualifications
					? tour.vehicle.qualifications
					: []),
				...(tour.firstDriver && tour.firstDriver.qualifications
					? tour.firstDriver.qualifications
					: []),
				...(tour.secondDriver && tour.secondDriver.qualifications
					? tour.secondDriver.qualifications
					: [])
			]
			// A tour can have multiple days. In this case we render day separators.
			const isMultiDay = tour.dates.length > 1
			const isPlanningTypeMultiDay =
				tour.planningType.key === PLANNING_TYPE_MEERDAAGSE_RIT
			route.forEach((routeItem, index) => {
				// The orderKey can have the value 'deleted', which means the order was deleted *during* the PTV run
				// It is possible that a tour contains multiple of these deleted orders, so in this case we add the array index to the key to ensure uniqueness
				const key =
					routeItem.orderKey !== 'deleted'
						? routeItem.orderKey
						: `${routeItem.orderKey}-${index}`
				// A route item is a filiaal order when the planning type of the tour is filiaal,
				// and the route item does not have a salesorder number (it is possible for a filiaal tour
				// to contain orders which are not a filiaal but a regular customer)
				const isFiliaal =
					tour.planningType.key === PLANNING_TYPE_FILIAAL &&
					!routeItem.salesOrderNumber
				routeListItems.push(
					<RouteItem
						key={key}
						isFiliaal={isFiliaal}
						isFirst={index === 0}
						isLast={index === route.length - 1}
						isMultiDay={isMultiDay}
						isPlanningTypeMultiDay={isPlanningTypeMultiDay}
						qualifications={qualifications}
						manualPlanningDown={this.handleManualPlanningDown}
						manualPlanningIsModified={manualPlanningIsModified}
						manualPlanningMode={manualPlanningMode}
						manualPlanningMove={this.handleManualPlanningMove}
						manualPlanningRemove={this.handleManualPlanningRemove}
						manualPlanningUp={this.handleManualPlanningUp}
						previousDepartureTime={previousDepartureTime}
						previousEndServiceTime={previousEndServiceTime}
						routeItem={routeItem}
					/>
				)
				previousDepartureTime = moment
					.unix(routeItem.departureTime)
					.tz('Europe/Amsterdam')
				previousEndServiceTime = moment
					.unix(routeItem.endServiceTime)
					.tz('Europe/Amsterdam')
			})

			// When in modified manual planning mode we don't display times and separators
			if (!manualPlanningMode || !manualPlanningIsModified) {
				// Insert a final listitem which indicates time between the servicetimes of the last route item (travel, breaks, etc.)
				// and the arrival at the end depot
				const endDepotArrivalTime = moment
					.unix(tour.endDepot.arrivalTime)
					.tz('Europe/Amsterdam')
				const nonServiceTimeEndDepotSeparatorPrimary = (
					<Typography variant="body2">
						{previousEndServiceTime.format('H:mm')} -{' '}
						{endDepotArrivalTime.format('H:mm')}
					</Typography>
				)
				const nonServiceTimeEndDepotSeparator = (
					<ListItem
						key="routeitem-nonservicetimeenddepotseparator"
						divider
						className={classes.routeNonServiceTimeSeparator}
					>
						<ListItemText
							disableTypography
							primary={nonServiceTimeEndDepotSeparatorPrimary}
						/>
					</ListItem>
				)
				routeListItems.push(nonServiceTimeEndDepotSeparator)
			}
		} else {
			routeListItems = (
				<ListItem divider className={classes.route}>
					<ListItemText
						primary={i18n.t('app:planning.Tour.noTransportPoints')}
						primaryTypographyProps={{
							color: 'textSecondary',
							variant: 'body2'
						}}
					/>
				</ListItem>
			)
		}

		return (
			<List disablePadding>
				<Divider />
				{startDepotRouteListItem}
				{routeListItems}
				{endDepotRouteListItem}
			</List>
		)
	}
}

export default withStyles(styleSheet)(RouteItemList)
