import { all, call, fork, take, takeEvery } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import dateProposalSocket from '../../sockets/dateProposalSocket'

// Will toggle the date proposal socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(dateProposalSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(dateProposalSocket.close)
	}
}

// Will fetch a date proposal
function* fetch(action) {
	yield call(dateProposalSocket.getProposal, action.meta.orderKey)
}

// Will handle every fetch action
function* watchFetch() {
	yield takeEvery(actionTypes.FETCH, fetch)
}

export default function* rootSaga() {
	yield all([socket, watchFetch].map((saga) => fork(saga)))
}
