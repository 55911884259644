import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import driversSelectors from '../../../../redux/drivers/selectors'
import Driver from './Driver'

class DriverContainer extends PureComponent {
	static propTypes = {
		driver: PropTypes.object,
		driverId: PropTypes.number.isRequired
	}

	render() {
		const { driver, driverId } = this.props

		return <Driver key={driverId} driver={driver} driverId={driverId} />
	}
}

const connector = connect((state, props) => ({
	driver: driversSelectors.findDriverByIdSelector(
		state.driversState,
		props.driverId
	)
}))

export default connector(DriverContainer)
