import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/vehicleUnavailability/actionCreators'
import UnavailabilityItem from './UnavailabilityItem'

class UnavailabilityItemContainer extends PureComponent {
	static propTypes = {
		create: PropTypes.func.isRequired,
		vehicleId: PropTypes.string.isRequired
	}

	render() {
		const { create, vehicleId } = this.props

		return <UnavailabilityItem create={create} vehicleId={vehicleId} />
	}
}

const connector = connect(null, {
	create: actionCreators.create
})

export default connector(UnavailabilityItemContainer)
