import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16,
		flex: 1
	},
	searchInput: {
		flex: 1,
		marginRight: 16
	}
}

class Search extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		search: PropTypes.func.isRequired,
		searchQuery: PropTypes.string.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			searchQuery: props.searchQuery
		}
	}

	handleSearchChange = (event) => {
		const { search } = this.props
		const searchQuery = event.target.value
		this.setState({
			searchQuery
		})
		search(searchQuery)
	}

	render() {
		const { classes } = this.props
		const { searchQuery } = this.state

		return (
			<div className={classes.root}>
				<Input
					className={classes.searchInput}
					onChange={this.handleSearchChange}
					placeholder={i18n.t('app:appointmentscheduler.Orders.search')}
					value={searchQuery}
				/>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Search)
