import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const styleSheet = {
	hour: {
		height: 60,
		borderTopStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: grey[200]
	}
}
class HourBlock extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		click: PropTypes.func
	}

	handleClick = () => {
		const { click } = this.props

		if (click) {
			click()
		}
	}

	render() {
		const { classes } = this.props

		return <div className={classes.hour} onClick={this.handleClick} />
	}
}

export default withStyles(styleSheet)(HourBlock)
