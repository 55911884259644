// Updated tours are received through the websocket connection
// This means most tour mutating sagas will not put redux success actions after successful API calls

import {
	all,
	call,
	delay,
	fork,
	put,
	select,
	take,
	takeEvery
} from 'redux-saga/effects'
import { default as i18n } from 'i18next'
import apiTours from '../../services/apiTours'
import actionTypes from './actionTypes'
import sagaSelectors from './sagaSelectors'
import notificationsActionCreators from '../notifications/actionCreators'
import toursActionCreators from './actionCreators'
import toursSocket from '../../sockets/toursSocket'

// Will toggle the tours socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(toursSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(toursSocket.close)
	}
}

// Will fetch all tours
function* fetchAll() {
	yield put(toursActionCreators.fetchAllRequest())
	try {
		const tours = yield call(apiTours.getAll)
		yield put(toursActionCreators.fetchAllSuccess(tours))
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.fetch')
		yield put(
			toursActionCreators.fetchAllFailure({
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every fetch all action
function* watchFetchAll() {
	yield takeEvery(actionTypes.FETCH_ALL, fetchAll)
}

// Will fetch replacement tours
function* fetchReplacements(action) {
	yield put(
		toursActionCreators.fetchReplacementsRequest(
			action.meta.planningTypeKey,
			action.meta.fromDate
		)
	)
	try {
		const tours = yield call(
			apiTours.getReplacements,
			action.meta.planningTypeKey,
			action.meta.fromDate
		)
		yield put(
			toursActionCreators.fetchReplacementsSuccess(
				tours,
				action.meta.planningTypeKey,
				action.meta.fromDate
			)
		)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.fetch')
		yield put(
			toursActionCreators.fetchReplacementsFailure(
				{ userMessage, techMessage },
				action.meta.planningTypeKey,
				action.meta.fromDate
			)
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every fetch replacements action
function* watchFetchReplacements() {
	yield takeEvery(actionTypes.FETCH_REPLACEMENTS, fetchReplacements)
}

// Will confirm a tour
function* confirm(action) {
	const { id } = action.meta
	yield put(toursActionCreators.confirmRequest(id))
	try {
		yield call(apiTours.confirm, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.confirm')
		yield put(
			toursActionCreators.confirmFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every confirm action
function* watchConfirm() {
	yield takeEvery(actionTypes.CONFIRM, confirm)
}

// Will confirm multiple tours
function* confirmMultiple(action) {
	const { ids } = action.meta
	yield all(ids.map((id) => put(toursActionCreators.confirm(id))))
}

// Will handle every confirmMultiple action
function* watchConfirmMultiple() {
	yield takeEvery(actionTypes.CONFIRM_MULTIPLE, confirmMultiple)
}

// Will confirm a tour
function* finalize(action) {
	const { id } = action.meta
	yield put(toursActionCreators.finalizeRequest(id))
	try {
		yield call(apiTours.finalize, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.finalize')
		yield put(
			toursActionCreators.finalizeFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every finalize action
function* watchFinalize() {
	yield takeEvery(actionTypes.FINALIZE, finalize)
}

// Will confirm multiple tours
function* finalizeMultiple(action) {
	const { ids } = action.meta
	yield all(ids.map((id) => put(toursActionCreators.finalize(id))))
}

// Will handle every finalizeMultiple action
function* watchFinalizeMultiple() {
	yield takeEvery(actionTypes.FINALIZE_MULTIPLE, finalizeMultiple)
}

// Will lock a tour
function* lock(action) {
	const { id } = action.meta
	yield put(toursActionCreators.lockRequest(id))
	try {
		yield call(apiTours.lock, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.lock')
		yield put(
			toursActionCreators.lockFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every lock action
function* watchLock() {
	yield takeEvery(actionTypes.LOCK, lock)
}

// Will take over a lock
function* takeOverLock(action) {
	const { id } = action.meta
	yield put(toursActionCreators.takeOverLockRequest(id))
	try {
		yield call(apiTours.takeOverLock, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.takeOverLock')
		yield put(
			toursActionCreators.takeOverLockFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every take over lock action
function* watchTakeOverLock() {
	yield takeEvery(actionTypes.TAKEOVERLOCK, takeOverLock)
}

// Will undoConfirm a tour
function* undoConfirm(action) {
	const { id } = action.meta
	yield put(toursActionCreators.undoConfirmRequest(id))
	try {
		yield call(apiTours.undoConfirm, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.undoConfirm')
		yield put(
			toursActionCreators.undoConfirmFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every undoConfirm action
function* watchUndoConfirm() {
	yield takeEvery(actionTypes.UNDO_CONFIRM, undoConfirm)
}

// Will unlock a tour
function* unlock(action) {
	const { id } = action.meta
	yield put(toursActionCreators.unlockRequest(id))
	try {
		yield call(apiTours.unlock, id)
	} catch (error) {
		const techMessage = error
			? error.message
			: i18n.t('app:elements.Error.unknown')
		const userMessage = i18n.t('app:planning.Tour.Error.unlock')
		yield put(
			toursActionCreators.unlockFailure(id, {
				userMessage,
				techMessage
			})
		)
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

// Will handle every unlock action
function* watchUnlock() {
	yield takeEvery(actionTypes.UNLOCK, unlock)
}

// Will periodically cleanup the meta reducer
function* cleanMeta() {
	while (true) {
		const tourIds = yield select(sagaSelectors.getIds)
		yield put(toursActionCreators.cleanMeta(tourIds))
		yield delay(60000)
	}
}

export default function* rootSaga() {
	yield all(
		[
			cleanMeta,
			socket,
			watchConfirm,
			watchConfirmMultiple,
			watchFetchAll,
			watchFetchReplacements,
			watchFinalize,
			watchFinalizeMultiple,
			watchLock,
			watchTakeOverLock,
			watchUndoConfirm,
			watchUnlock
		].map((saga) => fork(saga))
	)
}
