import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TourComparisonItem from '../TourComparison/TourComparisonItem'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		display: 'flex',
		overflowX: 'scroll',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		// These margin and padding settings make sure the shadow of the Paper components inside the container are not hidden by the borders of this content container
		margin: -5,
		padding: 5
	},
	tourItem: {
		flex: '0 0 280px',
		marginRight: 16,
		maxWidth: 280
	},
	tourItemLast: {
		flex: '0 0 280px',
		marginRight: 5,
		maxWidth: 280
	},
	tourItemSpacer: {
		flex: '0 0 1px'
	}
}

class TourEditList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isModified: PropTypes.bool,
		manualPlanningAction: PropTypes.func.isRequired,
		manualPlanningDown: PropTypes.func.isRequired,
		manualPlanningMove: PropTypes.func.isRequired,
		manualPlanningRemove: PropTypes.func.isRequired,
		manualPlanningRemoveOrdersWithoutAppointment: PropTypes.func.isRequired,
		manualPlanningReverse: PropTypes.func.isRequired,
		manualPlanningUp: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		tours: PropTypes.array.isRequired,
		tourNumbers: PropTypes.array.isRequired
	}

	render() {
		const {
			classes,
			isModified,
			manualPlanningAction,
			manualPlanningDown,
			manualPlanningMove,
			manualPlanningRemove,
			manualPlanningRemoveOrdersWithoutAppointment,
			manualPlanningReverse,
			manualPlanningUp,
			metaTours,
			ownUserId,
			tours,
			tourNumbers
		} = this.props

		const tourItems = tours.map((selectedTour, index) => {
			const metaTour = metaTours.find((meta) => meta.id === selectedTour.id)
			const number = tourNumbers.find(
				(tourNumber) => tourNumber.id === selectedTour.id
			).number
			return (
				<div
					key={`tour-${selectedTour.id}`}
					className={
						index === tours.length - 1 ? classes.tourItemLast : classes.tourItem
					}
				>
					<TourComparisonItem
						manualPlanningAction={manualPlanningAction}
						manualPlanningDown={manualPlanningDown}
						manualPlanningIsModified={isModified}
						manualPlanningMode={true}
						manualPlanningMove={manualPlanningMove}
						manualPlanningRemove={manualPlanningRemove}
						manualPlanningRemoveOrdersWithoutAppointment={
							manualPlanningRemoveOrdersWithoutAppointment
						}
						manualPlanningReverse={manualPlanningReverse}
						manualPlanningUp={manualPlanningUp}
						metaTour={metaTour}
						number={number}
						ownUserId={ownUserId}
						tour={selectedTour}
					/>
				</div>
			)
		})

		return (
			<div className={classes.root}>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						{tourItems}
						<div className={classes.tourItemSpacer} />
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(TourEditList)
