import {
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	SvgIcon,
	Switch
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { AngleLeftFal, AngleRightFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { fields, localizedText } from '../../../../utils/localizedText'

const styleSheet = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		paddingBottom: 16
	},
	formControl: {
		flex: 1,
		paddingBottom: 8
	},
	formControlContactDate: {
		flex: 1,
		flexDirection: 'row',
		paddingBottom: 8,
		marginTop: 8
	},
	contactDatePickerLeft: {
		flex: 1,
		marginRight: 4
	},
	contactDatePickerRight: {
		flex: 1,
		marginLeft: 4
	},
	formControlTourDate: {
		flex: 1,
		flexDirection: 'row',
		paddingBottom: 8,
		marginTop: 8
	},
	tourDatePickerLeft: {
		flex: 1,
		marginRight: 8
	},
	tourDatePickerRight: {
		flex: 1,
		marginLeft: 8
	},
	selected: {
		fontWeight: theme.typography.fontWeightMedium
	},
	filterButtons: {
		display: 'flex'
	},
	filterButtonLeft: {
		margin: '16px 8px 0 0'
	},
	filterButtonRight: {
		margin: '16px 0 0 8px'
	}
})

class Filter extends PureComponent {
	static propTypes = {
		applyFilter: PropTypes.func.isRequired,
		clearFilter: PropTypes.func.isRequired,
		closeFilter: PropTypes.func.isRequired,
		availableContactStatusKeys: PropTypes.array.isRequired,
		availableCustomerLanguageKeys: PropTypes.array.isRequired,
		availableOrderTypeKeys: PropTypes.array.isRequired,
		availablePlanningTypeKeys: PropTypes.array.isRequired,
		availablePriorityKeys: PropTypes.array.isRequired,
		availableRouteKeys: PropTypes.array.isRequired,
		availableUsers: PropTypes.array.isRequired,
		availableRequirementKeys: PropTypes.array.isRequired,
		availableShippingConditionKeys: PropTypes.array.isRequired,
		classes: PropTypes.object.isRequired,
		selectedContactDateBefore: PropTypes.string,
		selectedContactStatusKeys: PropTypes.array.isRequired,
		selectedCustomerLanguageKeys: PropTypes.array.isRequired,
		selectedOrderTypeKeys: PropTypes.array.isRequired,
		selectedPlanningTypeKeys: PropTypes.array.isRequired,
		selectedPriorityKeys: PropTypes.array.isRequired,
		selectedTourDateFrom: PropTypes.string,
		selectedTourDateTill: PropTypes.string,
		selectedRouteKeys: PropTypes.array.isRequired,
		selectedUserIds: PropTypes.array.isRequired,
		selectedRequirementKeys: PropTypes.array.isRequired,
		selectedShippingConditionKeys: PropTypes.array.isRequired,
		showConfirmed: PropTypes.bool,
		showNoTourDateOnly: PropTypes.bool
	}

	handleChangeSelectedCustomerLanguageKeys = (event) => {
		this.applyFilter({
			selectedCustomerLanguageKeys: event.target.value
		})
	}

	handleChangeSelectedOrderTypeKeys = (event) => {
		this.applyFilter({
			selectedOrderTypeKeys: event.target.value
		})
	}

	handleChangeSelectedPlanningTypeKeys = (event) => {
		this.applyFilter({
			selectedPlanningTypeKeys: event.target.value
		})
	}

	handleChangeSelectedPriorityKeys = (event) => {
		this.applyFilter({
			selectedPriorityKeys: event.target.value
		})
	}

	handleChangeSelectedContactDateBefore = (date) => {
		this.applyFilter({
			selectedContactDateBefore: date ? date.format() : null
		})
	}

	handleChangeSelectedTourDateFrom = (date) => {
		const { selectedTourDateTill } = this.props
		let adjustedSelectedTourDateTill
		if (date && selectedTourDateTill) {
			if (
				moment
					.tz(selectedTourDateTill, 'Europe/Amsterdam')
					.isBefore(date, 'day')
			) {
				adjustedSelectedTourDateTill = date.format()
			} else {
				adjustedSelectedTourDateTill = selectedTourDateTill
			}
		} else {
			adjustedSelectedTourDateTill = date ? date.format() : null
		}
		this.applyFilter({
			selectedTourDateFrom: date ? date.format() : null,
			selectedTourDateTill: adjustedSelectedTourDateTill
		})
	}

	handleChangeSelectedTourDateTill = (date) => {
		const { selectedTourDateFrom } = this.props
		let adjustedSelectedTourDateFrom
		if (date && selectedTourDateFrom) {
			if (
				moment.tz(selectedTourDateFrom, 'Europe/Amsterdam').isAfter(date, 'day')
			) {
				adjustedSelectedTourDateFrom = date.format()
			} else {
				adjustedSelectedTourDateFrom = selectedTourDateFrom
			}
		} else {
			adjustedSelectedTourDateFrom = date ? date.format() : null
		}
		this.applyFilter({
			selectedTourDateTill: date ? date.format() : null,
			selectedTourDateFrom: adjustedSelectedTourDateFrom
		})
	}

	handleChangeSelectedContactStatusKeys = (event) => {
		this.applyFilter({
			selectedContactStatusKeys: event.target.value
		})
	}

	handleChangeSelectedRouteKeys = (event) => {
		this.applyFilter({
			selectedRouteKeys: event.target.value
		})
	}

	handleChangeSelectedUserIds = (event) => {
		this.applyFilter({
			selectedUserIds: event.target.value
		})
	}

	handleChangeSelectedRequirementKeys = (event) => {
		this.applyFilter({
			selectedRequirementKeys: event.target.value
		})
	}

	handleChangeSelectedShippingConditionKeys = (event) => {
		this.applyFilter({
			selectedShippingConditionKeys: event.target.value
		})
	}

	handleToggleShowConfirmed = () => {
		const { showConfirmed } = this.props
		this.applyFilter({
			showConfirmed: !showConfirmed
		})
	}

	handleToggleShowNoTourDateOnly = () => {
		const { showNoTourDateOnly } = this.props
		this.applyFilter({
			showNoTourDateOnly: !showNoTourDateOnly
		})
	}

	handleClearFilter = () => {
		const { clearFilter } = this.props
		clearFilter()
	}

	handleCloseFilter = () => {
		const { closeFilter } = this.props
		closeFilter()
	}

	applyFilter(filter) {
		const { applyFilter } = this.props
		const {
			selectedContactDateBefore,
			selectedContactStatusKeys,
			selectedCustomerLanguageKeys,
			selectedOrderTypeKeys,
			selectedPlanningTypeKeys,
			selectedPriorityKeys,
			selectedTourDateFrom,
			selectedTourDateTill,
			selectedRouteKeys,
			selectedUserIds,
			selectedRequirementKeys,
			selectedShippingConditionKeys,
			showConfirmed,
			showNoTourDateOnly
		} = this.props
		const newFilter = {
			selectedContactDateBefore,
			selectedContactStatusKeys,
			selectedCustomerLanguageKeys,
			selectedOrderTypeKeys,
			selectedPlanningTypeKeys,
			selectedPriorityKeys,
			selectedTourDateFrom,
			selectedTourDateTill,
			selectedRouteKeys,
			selectedUserIds,
			selectedRequirementKeys,
			selectedShippingConditionKeys,
			showConfirmed,
			showNoTourDateOnly,
			...filter
		}
		applyFilter(newFilter)
	}

	render() {
		const {
			classes,
			availableContactStatusKeys,
			availableCustomerLanguageKeys,
			availableOrderTypeKeys,
			availablePlanningTypeKeys,
			availablePriorityKeys,
			availableRouteKeys,
			availableUsers,
			availableRequirementKeys,
			availableShippingConditionKeys,
			selectedContactDateBefore,
			selectedContactStatusKeys,
			selectedCustomerLanguageKeys,
			selectedOrderTypeKeys,
			selectedPlanningTypeKeys,
			selectedPriorityKeys,
			selectedTourDateFrom,
			selectedTourDateTill,
			selectedRouteKeys,
			selectedUserIds,
			selectedRequirementKeys,
			selectedShippingConditionKeys,
			showConfirmed,
			showNoTourDateOnly
		} = this.props

		const contactStatusItems = availableContactStatusKeys.map(
			(availableContactStatusKey) => {
				const className = selectedContactStatusKeys.some(
					(selectedContactStatusKey) =>
						selectedContactStatusKey === availableContactStatusKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availableContactStatus-${availableContactStatusKey}`}
						value={availableContactStatusKey}
						className={className}
					>
						{localizedText(fields.CONTACT_STATUS, availableContactStatusKey)}
					</MenuItem>
				)
			}
		)

		const customerLanguageItems = availableCustomerLanguageKeys.map(
			(availableCustomerLanguageKey) => {
				const className = selectedCustomerLanguageKeys.some(
					(selectedCustomerLanguageKey) =>
						selectedCustomerLanguageKey === availableCustomerLanguageKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availableCustomerLanguage-${availableCustomerLanguageKey}`}
						value={availableCustomerLanguageKey}
						className={className}
					>
						{localizedText(fields.LANGUAGE, availableCustomerLanguageKey)}
					</MenuItem>
				)
			}
		)

		const orderTypeItems = availableOrderTypeKeys.map(
			(availableOrderTypeKey) => {
				const className = selectedOrderTypeKeys.some(
					(selectedOrderTypeKey) =>
						selectedOrderTypeKey === availableOrderTypeKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availableOrderType-${availableOrderTypeKey}`}
						value={availableOrderTypeKey}
						className={className}
					>
						{localizedText(fields.ORDER_TYPE, availableOrderTypeKey)}
					</MenuItem>
				)
			}
		)

		const planningTypeItems = availablePlanningTypeKeys.map(
			(availablePlanningTypeKey) => {
				const className = selectedPlanningTypeKeys.some(
					(selectedPlanningTypeKey) =>
						selectedPlanningTypeKey === availablePlanningTypeKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availablePlanningType-${availablePlanningTypeKey}`}
						value={availablePlanningTypeKey}
						className={className}
					>
						{localizedText(fields.PLANNING_TYPE, availablePlanningTypeKey)}
					</MenuItem>
				)
			}
		)

		const priorityItems = [
			<MenuItem
				key={'availablePriority-none'}
				value="none"
				className={
					selectedPriorityKeys.some(
						(selectedPriorityKey) => selectedPriorityKey === 'none'
					)
						? classes.selected
						: ''
				}
			>
				{i18n.t('app:appointmentscheduler.Order.priorityEmpty')}
			</MenuItem>,
			...availablePriorityKeys.map((availablePriorityKey) => {
				const className = selectedPriorityKeys.some(
					(selectedPriorityKey) => selectedPriorityKey === availablePriorityKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availablePriority-${availablePriorityKey}`}
						value={availablePriorityKey}
						className={className}
					>
						{localizedText(fields.PRIORITY, availablePriorityKey)}
					</MenuItem>
				)
			})
		]

		const routeItems = [
			<MenuItem
				key={'availableRoute-none'}
				value="none"
				className={
					selectedRouteKeys.some(
						(selectedRouteKey) => selectedRouteKey === 'none'
					)
						? classes.selected
						: ''
				}
			>
				{i18n.t('app:appointmentscheduler.Order.routeEmpty')}
			</MenuItem>,
			...availableRouteKeys.map((availableRouteKey) => {
				const className = selectedRouteKeys.some(
					(selectedRouteKey) => selectedRouteKey === availableRouteKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availableRoute-${availableRouteKey}`}
						value={availableRouteKey}
						className={className}
					>
						{localizedText(fields.ROUTE, availableRouteKey)}
					</MenuItem>
				)
			})
		]

		const userItems = availableUsers.map((availableUser) => {
			const className = selectedUserIds.some(
				(selectedUserKey) => selectedUserKey === availableUser.userId
			)
				? classes.selected
				: ''
			return (
				<MenuItem
					key={`availableUser-${availableUser.userId}`}
					value={availableUser.userId}
					className={className}
				>{`${availableUser.firstName} ${availableUser.lastName}`}</MenuItem>
			)
		})

		const requirementItems = availableRequirementKeys.map(
			(availableRequirementKey) => {
				const className = selectedRequirementKeys.some(
					(selectedRequirementKey) =>
						selectedRequirementKey === availableRequirementKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={availableRequirementKey}
						value={availableRequirementKey}
						className={className}
					>
						{localizedText(fields.REQUIREMENT, availableRequirementKey)}
					</MenuItem>
				)
			}
		)

		const shippingConditionItems = availableShippingConditionKeys.map(
			(availableShippingConditionKey) => {
				const className = selectedShippingConditionKeys.some(
					(selectedShippingConditionKey) =>
						selectedShippingConditionKey === availableShippingConditionKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={availableShippingConditionKey}
						value={availableShippingConditionKey}
						className={className}
					>
						{localizedText(
							fields.SHIPPING_CONDITION,
							availableShippingConditionKey
						)}
					</MenuItem>
				)
			}
		)

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.orderType')}
					</InputLabel>
					<Select
						multiple
						value={selectedOrderTypeKeys}
						onChange={this.handleChangeSelectedOrderTypeKeys}
					>
						{orderTypeItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.planningType')}
					</InputLabel>
					<Select
						multiple
						value={selectedPlanningTypeKeys}
						onChange={this.handleChangeSelectedPlanningTypeKeys}
					>
						{planningTypeItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.priority')}
					</InputLabel>
					<Select
						multiple
						value={selectedPriorityKeys}
						onChange={this.handleChangeSelectedPriorityKeys}
					>
						{priorityItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.contactStatus')}
					</InputLabel>
					<Select
						multiple
						value={selectedContactStatusKeys}
						onChange={this.handleChangeSelectedContactStatusKeys}
					>
						{contactStatusItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.route')}
					</InputLabel>
					<Select
						multiple
						value={selectedRouteKeys}
						onChange={this.handleChangeSelectedRouteKeys}
					>
						{routeItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.customerLanguage')}
					</InputLabel>
					<Select
						multiple
						value={selectedCustomerLanguageKeys}
						onChange={this.handleChangeSelectedCustomerLanguageKeys}
					>
						{customerLanguageItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.lockedBy')}
					</InputLabel>
					<Select
						multiple
						value={selectedUserIds}
						onChange={this.handleChangeSelectedUserIds}
					>
						{userItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.requirements')}
					</InputLabel>
					<Select
						multiple
						value={selectedRequirementKeys}
						onChange={this.handleChangeSelectedRequirementKeys}
					>
						{requirementItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:appointmentscheduler.Order.shippingCondition')}
					</InputLabel>
					<Select
						multiple
						value={selectedShippingConditionKeys}
						onChange={this.handleChangeSelectedShippingConditionKeys}
					>
						{shippingConditionItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControlContactDate}>
					<DatePicker
						className={classes.contactDatePickerLeft}
						value={
							selectedContactDateBefore
								? moment.tz(selectedContactDateBefore, 'Europe/Amsterdam')
								: null
						}
						onChange={this.handleChangeSelectedContactDateBefore}
						autoOk={true}
						clearable={true}
						disableFuture={true}
						label={i18n.t('app:appointmentscheduler.Order.contactDateBefore')}
						cancelLabel={i18n.t('app:datepicker.cancel')}
						clearLabel={i18n.t('app:datepicker.clear')}
						format="D MMMM Y"
						okLabel={i18n.t('app:datepicker.ok')}
						todayLabel={i18n.t('app:datepicker.today')}
						leftArrowIcon={
							<SvgIcon>
								<AngleLeftFal />
							</SvgIcon>
						}
						rightArrowIcon={
							<SvgIcon>
								<AngleRightFal />
							</SvgIcon>
						}
						margin="none"
					/>
				</FormControl>
				<FormControl className={classes.formControlTourDate}>
					<DatePicker
						className={classes.tourDatePickerLeft}
						value={
							selectedTourDateFrom
								? moment.tz(selectedTourDateFrom, 'Europe/Amsterdam')
								: null
						}
						onChange={this.handleChangeSelectedTourDateFrom}
						autoOk={true}
						clearable={true}
						label={i18n.t('app:appointmentscheduler.Order.tourDateFrom')}
						cancelLabel={i18n.t('app:datepicker.cancel')}
						clearLabel={i18n.t('app:datepicker.clear')}
						format="D MMMM Y"
						okLabel={i18n.t('app:datepicker.ok')}
						todayLabel={i18n.t('app:datepicker.today')}
						leftArrowIcon={
							<SvgIcon>
								<AngleLeftFal />
							</SvgIcon>
						}
						rightArrowIcon={
							<SvgIcon>
								<AngleRightFal />
							</SvgIcon>
						}
						margin="none"
					/>
					<DatePicker
						className={classes.tourDatePickerRight}
						value={
							selectedTourDateTill
								? moment.tz(selectedTourDateTill, 'Europe/Amsterdam')
								: null
						}
						onChange={this.handleChangeSelectedTourDateTill}
						autoOk={true}
						clearable={true}
						label={i18n.t('app:appointmentscheduler.Order.tourDateTill')}
						cancelLabel={i18n.t('app:datepicker.cancel')}
						clearLabel={i18n.t('app:datepicker.clear')}
						format="D MMMM Y"
						okLabel={i18n.t('app:datepicker.ok')}
						todayLabel={i18n.t('app:datepicker.today')}
						leftArrowIcon={
							<SvgIcon>
								<AngleLeftFal />
							</SvgIcon>
						}
						rightArrowIcon={
							<SvgIcon>
								<AngleRightFal />
							</SvgIcon>
						}
						margin="none"
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								checked={showNoTourDateOnly}
								color="primary"
								onChange={this.handleToggleShowNoTourDateOnly}
							/>
						}
						label={i18n.t('app:appointmentscheduler.Order.showNoTourDateOnly')}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								checked={showConfirmed}
								color="primary"
								onChange={this.handleToggleShowConfirmed}
							/>
						}
						label={i18n.t('app:appointmentscheduler.Order.showConfirmed')}
					/>
				</FormControl>
				<div className={classes.filterButtons}>
					<Button
						color="default"
						fullWidth
						variant="outlined"
						onClick={this.handleClearFilter}
						className={classes.filterButtonLeft}
					>
						{i18n.t('app:appointmentscheduler.Orders.clearFilter')}
					</Button>
					<Button
						color="primary"
						fullWidth
						variant="outlined"
						onClick={this.handleCloseFilter}
						className={classes.filterButtonRight}
					>
						{i18n.t('app:appointmentscheduler.Orders.applyFilter')}
					</Button>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Filter)
