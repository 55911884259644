import actionTypes from './actionTypes'

const actionCreators = {
	fetch: (id) => ({
		type: actionTypes.FETCH,
		meta: {
			id
		}
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (unavailability) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			unavailability
		}
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		}
	}),

	create: (history, unavailability) => ({
		type: actionTypes.CREATE,
		payload: {
			history,
			unavailability
		}
	}),

	createRequest: () => ({
		type: actionTypes.CREATE_REQUEST
	}),

	createSuccess: (unavailability) => ({
		type: actionTypes.CREATE_SUCCESS,
		payload: {
			unavailability
		}
	}),

	createFailure: (errorMessages) => ({
		type: actionTypes.CREATE_FAILURE,
		payload: {
			errorMessages
		}
	}),

	remove: (unavailability) => ({
		type: actionTypes.REMOVE,
		meta: {
			unavailability
		}
	}),

	removeRequest: () => ({
		type: actionTypes.REMOVE_REQUEST
	}),

	removeSuccess: (unavailability) => ({
		type: actionTypes.REMOVE_SUCCESS,
		meta: {
			unavailability
		}
	}),

	removeFailure: (errorMessages) => ({
		type: actionTypes.REMOVE_FAILURE,
		payload: {
			errorMessages
		}
	})
}

export default actionCreators
