import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import DepotContainer from '../Depot'
import StartLocation from './StartLocation'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class Depots extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		change: PropTypes.func.isRequired,
		meta: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		startAddress: PropTypes.object,
		vehicle: PropTypes.object.isRequired
	}

	handleChangeStart = (plant) => {
		const { change, vehicle } = this.props

		change({
			startDepot: plant,
			endDepot: vehicle.endDepot,
			startAtDriverHome: vehicle.startAtDriverHome
		})
	}

	handleChangeEnd = (plant) => {
		const { change, vehicle } = this.props

		change({
			startDepot: vehicle.startDepot,
			endDepot: plant,
			startAtDriverHome: vehicle.startAtDriverHome
		})
	}

	handleChangeStartLocation = () => {
		const { change, vehicle } = this.props

		change({
			startDepot: vehicle.startDepot,
			endDepot: vehicle.endDepot,
			startAtDriverHome: !vehicle.startAtDriverHome
		})
	}

	render() {
		const { classes, meta, planningType, startAddress, vehicle } = this.props
		const { startDepot, endDepot } = vehicle
		const { startDepotEmpty, endDepotEmpty } = meta
		const startAtDepot = planningType
			? planningType.planningParams.startAtDepot
			: false

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Depots.title')}
				</Typography>
				<DepotContainer
					label={i18n.t('app:vehicleplanning.Depots.start')}
					plant={startDepot}
					error={startDepotEmpty}
					errorText={
						startDepotEmpty
							? i18n.t('app:vehicleplanning.Depot.required')
							: null
					}
					change={this.handleChangeStart}
				/>
				<DepotContainer
					label={i18n.t('app:vehicleplanning.Depots.end')}
					plant={endDepot}
					error={endDepotEmpty}
					errorText={
						endDepotEmpty ? i18n.t('app:vehicleplanning.Depot.required') : null
					}
					change={this.handleChangeEnd}
				/>
				<StartLocation
					address={startAddress}
					startAtDepot={startAtDepot}
					vehicle={vehicle}
					toggleStartAtHome={this.handleChangeStartLocation}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(Depots)
