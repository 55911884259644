import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { default as i18n } from 'i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = {
	root: {
		flex: 1,
		paddingTop: 100
	},
	paper: {
		margin: '0 auto',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
		width: 400
	},
	progress: {
		marginTop: 20
	},
	progressDescription: {
		marginTop: 16
	},
	logo: {
		width: '100%'
	},
	cancelButton: {
		marginTop: 20,
		width: '100%'
	}
}

class Starting extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		logout: PropTypes.func.isRequired
	}

	handleLogout = () => {
		const { logout } = this.props
		logout()
	}

	render() {
		const { classes } = this.props

		return (
			<Grid
				container
				align="center"
				justifyContent="center"
				className={classes.root}
			>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<div>
							<img src="/logo.png" className={classes.logo} alt="logo" />
						</div>
						<CircularProgress className={classes.progress} size={50} />
						<Typography variant="body2" className={classes.progressDescription}>
							{i18n.t('app:elements.Starting.application')}
						</Typography>
						<Button
							variant="contained"
							className={classes.cancelButton}
							onClick={this.handleLogout}
						>
							{i18n.t('app:elements.Starting.cancel')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styleSheet)(Starting)
