import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List as MaterialList } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List as VirtualizedList
} from 'react-virtualized'
import ListEmpty from './ListEmpty'
import ListItem from './ListItem'

const styleSheet = {
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		drivers: PropTypes.array.isRequired,
		select: PropTypes.func.isRequired
	}

	state = {
		cache: new CellMeasurerCache({
			defaultHeight: 96,
			fixedWidth: true
		}),
		previousDrivers: []
	}

	static getDerivedStateFromProps(props, state) {
		if (props.drivers !== state.previousDrivers) {
			// Clear cache when the list changes, otherwise cells may be rendered with incorrect height
			state.cache.clearAll()
		}
		return {
			previousDrivers: props.drivers
		}
	}

	rowRenderer = ({ index, key, parent, style }) => {
		const { drivers, select } = this.props
		const { cache } = this.state

		const driver = drivers[index]
		return (
			<CellMeasurer
				cache={cache}
				columnIndex={0}
				key={key}
				parent={parent}
				rowIndex={index}
			>
				<div key={key} style={style}>
					<ListItem
						key={`driversearch-${driver.driverId}`}
						driver={driver}
						select={select}
					/>
				</div>
			</CellMeasurer>
		)
	}

	render() {
		const { classes, drivers } = this.props
		const { cache } = this.state

		if (drivers.length === 0) {
			return (
				<div className={classes.content}>
					<ListEmpty />
				</div>
			)
		}

		// Important: the VirtualizedList component does not automatically re-render when the props on
		// this component change. This means we have to pass these same props into VirtualizedList as
		// well, even though they are not used by the VirtualizedList component.

		return (
			<MaterialList disablePadding className={classes.content}>
				<AutoSizer>
					{({ height, width }) => (
						<VirtualizedList
							deferredMeasurementCache={cache}
							height={height}
							rowCount={drivers.length}
							rowHeight={cache.rowHeight}
							rowRenderer={this.rowRenderer}
							tabIndex={null}
							width={width}
							drivers={drivers}
						/>
					)}
				</AutoSizer>
			</MaterialList>
		)
	}
}

export default withStyles(styleSheet)(List)
