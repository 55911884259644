import actionTypes from './actionTypes'

const actionCreators = {
	fetchPostcodes: () => ({
		type: actionTypes.FETCH
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (postcodes) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			postcodes
		}
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		}
	}),

	selectCountry: (country) => ({
		type: actionTypes.SELECT_COUNTRY,
		payload: {
			country
		}
	}),

	search: (search) => ({
		type: actionTypes.SEARCH,
		payload: {
			search
		}
	}),

	createPostcode: (history, postcode) => ({
		type: actionTypes.CREATE_POSTCODE,
		payload: {
			history,
			postcode
		}
	}),

	createPostcodeRequest: () => ({
		type: actionTypes.CREATE_POSTCODE_REQUEST
	}),

	createPostcodeSuccess: (postcode) => ({
		type: actionTypes.CREATE_POSTCODE_SUCCESS,
		payload: {
			postcode
		}
	}),

	createPostcodeFailure: (errorMessages) => ({
		type: actionTypes.CREATE_POSTCODE_FAILURE,
		payload: {
			errorMessages
		}
	}),

	removePostcode: (id) => ({
		type: actionTypes.REMOVE_POSTCODE,
		meta: {
			id
		}
	}),

	removePostcodeRequest: () => ({
		type: actionTypes.REMOVE_POSTCODE_REQUEST
	}),

	removePostcodeSuccess: (id) => ({
		type: actionTypes.REMOVE_POSTCODE_SUCCESS,
		meta: {
			id
		}
	}),

	removePostcodeFailure: (errorMessages) => ({
		type: actionTypes.REMOVE_POSTCODE_FAILURE,
		payload: {
			errorMessages
		}
	})
}

export default actionCreators
