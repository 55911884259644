import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { default as i18n } from 'i18next'

class Driver extends PureComponent {
	static propTypes = {
		driver: PropTypes.object,
		driverId: PropTypes.number.isRequired
	}

	render() {
		const { driver, driverId } = this.props

		if (!driver) {
			return (
				<Typography variant="body2">{`${i18n.t(
					'app:vehicleplanning.Driver.unknown'
				)} ${driverId}`}</Typography>
			)
		}

		return (
			<Typography variant="body2">{`${driver.firstName} ${driver.lastName}`}</Typography>
		)
	}
}

export default Driver
