import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialDateProposalState = []

const dateProposalsReducer = (state = initialDateProposalState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SUCCESS: {
			if (
				state.some(
					(dateProposal) => dateProposal.orderKey === action.meta.orderKey
				)
			) {
				return state.map((dateProposal) =>
					dateProposal.orderKey === action.meta.orderKey
						? {
								orderKey: action.meta.orderKey,
								proposals: action.payload.proposals
						  }
						: dateProposal
				)
			}
			return state.concat({
				orderKey: action.meta.orderKey,
				proposals: action.payload.proposals
			})
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isConnected: false
}

const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		default: {
			return state
		}
	}
}

const initialMetaDateProposalsState = []

const metaDateProposalsReducer = (
	state = initialMetaDateProposalsState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			if (
				state.some(
					(dateProposal) => dateProposal.orderKey === action.meta.orderKey
				)
			) {
				return state.map((dateProposal) =>
					dateProposal.orderKey === action.meta.orderKey
						? { ...dateProposal, isLoading: true, errorMessages: null }
						: dateProposal
				)
			}
			return state.concat({
				orderKey: action.meta.orderKey,
				isLoading: true,
				errorMessages: null
			})
		}

		case actionTypes.FETCH_SUCCESS: {
			if (
				state.some(
					(dateProposal) => dateProposal.orderKey === action.meta.orderKey
				)
			) {
				return state.map((dateProposal) =>
					dateProposal.orderKey === action.meta.orderKey
						? { ...dateProposal, isLoading: false }
						: dateProposal
				)
			}
			return state.concat({ orderKey: action.meta.orderKey, isLoading: false })
		}

		case actionTypes.FETCH_FAILURE: {
			if (
				state.some(
					(dateProposal) => dateProposal.orderKey === action.meta.orderKey
				)
			) {
				return state.map((dateProposal) =>
					dateProposal.orderKey === action.meta.orderKey
						? {
								...dateProposal,
								isLoading: false,
								errorMessages: action.payload.errorMessages
						  }
						: dateProposal
				)
			}
			return state.concat({
				orderKey: action.meta.orderKey,
				isLoading: false,
				errorMessages: action.payload.errorMessages
			})
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	dateProposals: dateProposalsReducer,
	meta: metaReducer,
	metaDateProposals: metaDateProposalsReducer
})
