import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ordersSelectors from '../../../../redux/orders/selectors'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		orders: PropTypes.array.isRequired,
		ownUserId: PropTypes.string.isRequired,
		selectOrder: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string,
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const { orders, ownUserId, selectOrder, selectedOrderId, selectedMoment } =
			this.props

		return (
			<List
				orders={orders}
				ownUserId={ownUserId}
				selectOrder={selectOrder}
				selectedOrderId={selectedOrderId}
				selectedMoment={selectedMoment}
			/>
		)
	}
}

const connector = connect((state) => ({
	orders: ordersSelectors.orderListSelector(
		state.ordersState,
		state.userState.user.id
	),
	ownUserId: state.userState.user.id,
	selectedMoment: ordersSelectors.selectedMomentSelector(state.ordersState)
}))

export default connector(ListContainer)
