import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import excludedServiceDatesSelectors from '../../../../redux/excludedservicedates/selectors'
import toursActionCreators from '../../../../redux/tours/actionCreators'
import toursSelectors from '../../../../redux/tours/selectors'
import unavailableProposalDatesSelectors from '../../../../redux/unavailableproposaldates/selectors'
import DayNavigator from './DayNavigator'

class DayNavigatorContainer extends PureComponent {
	static propTypes = {
		isUnavailableProposalDate: PropTypes.bool.isRequired,
		isExcludedServiceDate: PropTypes.bool.isRequired,
		selectCurrentDate: PropTypes.func.isRequired,
		selectNextDate: PropTypes.func.isRequired,
		selectPreviousDate: PropTypes.func.isRequired,
		selectedMoment: PropTypes.object.isRequired
	}

	render() {
		const {
			isUnavailableProposalDate,
			isExcludedServiceDate,
			selectCurrentDate,
			selectNextDate,
			selectPreviousDate,
			selectedMoment
		} = this.props

		const now = moment.tz('Europe/Amsterdam')
		const isCurrentDay = selectedMoment.isSame(now, 'day')

		return (
			<DayNavigator
				selectCurrentDate={selectCurrentDate}
				selectNextDate={selectNextDate}
				selectPreviousDate={selectPreviousDate}
				selectedMoment={selectedMoment}
				isCurrentDay={isCurrentDay}
				isUnavailableProposalDate={isUnavailableProposalDate}
				isExcludedServiceDate={isExcludedServiceDate}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		isUnavailableProposalDate:
			unavailableProposalDatesSelectors.isUnavailableProposalDateSelector(
				state.toursState,
				state.unavailableProposalDatesState
			),
		isExcludedServiceDate:
			excludedServiceDatesSelectors.isExcludedServiceDateSelector(
				state.toursState,
				state.excludedServiceDatesState
			),
		selectedMoment: toursSelectors.selectedMomentSelector(state.toursState)
	}),
	{
		selectCurrentDate: toursActionCreators.selectCurrentDate,
		selectNextDate: toursActionCreators.selectNextDate,
		selectPreviousDate: toursActionCreators.selectPreviousDate
	}
)

export default connector(DayNavigatorContainer)
