import moment from 'moment-timezone'
import { default as i18n } from 'i18next'

const desiredDeliveryWeek = (order) => {
	const now = moment.tz('Europe/Amsterdam')
	if (parseInt(order.originalYear, 10) !== now.year()) {
		return `${i18n.t('app:appointmentscheduler.Order.week')} ${parseInt(
			order.originalWeek,
			10
		)} (${order.originalYear})`
	}
	return `${i18n.t('app:appointmentscheduler.Order.week')} ${parseInt(
		order.originalWeek,
		10
	)}`
}

export default desiredDeliveryWeek
