import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import vehicleSelectors from '../../../../redux/vehiclescustom/selectors'
import actionCreators from '../../../../redux/vehiclescustom/actionCreators'
import Filter from './Filter'

class FilterContainer extends PureComponent {
	static propTypes = {
		applyFilter: PropTypes.func.isRequired,
		clearFilter: PropTypes.func.isRequired,
		close: PropTypes.func.isRequired,
		onlyUnavailable: PropTypes.bool.isRequired,
		onlyAvailable: PropTypes.bool.isRequired,
		availablePlanningTypes: PropTypes.array.isRequired,
		selectedPlanningTypeKeys: PropTypes.array.isRequired,
		availableVehicleTypes: PropTypes.array.isRequired,
		selectedVehicleTypes: PropTypes.array.isRequired
	}

	handleApplyFilter = ({
		onlyUnavailable,
		onlyAvailable,
		selectedPlanningTypeKeys,
		selectedVehicleTypes
	}) => {
		const { applyFilter } = this.props
		applyFilter({
			onlyUnavailable,
			onlyAvailable,
			selectedPlanningTypeKeys,
			selectedVehicleTypes
		})
	}

	handleClearFilter = () => {
		const { close, clearFilter } = this.props
		clearFilter()
		close()
	}

	render() {
		const {
			close,
			onlyUnavailable,
			onlyAvailable,
			availablePlanningTypes,
			selectedPlanningTypeKeys,
			selectedVehicleTypes,
			availableVehicleTypes
		} = this.props

		return (
			<Filter
				applyFilter={this.handleApplyFilter}
				clearFilter={this.handleClearFilter}
				closeFilter={close}
				onlyUnavailable={onlyUnavailable}
				onlyAvailable={onlyAvailable}
				availablePlanningTypes={availablePlanningTypes}
				selectedPlanningTypeKeys={selectedPlanningTypeKeys}
				availableVehicleTypes={availableVehicleTypes}
				selectedVehicleTypes={selectedVehicleTypes}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		onlyUnavailable: state.vehiclesState.filter.onlyUnavailable,
		onlyAvailable: state.vehiclesState.filter.onlyAvailable,
		selectedPlanningTypeKeys: state.vehiclesState.filter.planningTypeKeys,
		availablePlanningTypes: vehicleSelectors.availablePlanningTypes(
			state.vehiclesState,
			state.planningTypesState
		),
		selectedVehicleTypes: state.vehiclesState.filter.vehicleTypes,
		availableVehicleTypes: vehicleSelectors.availableVehicleTypes(
			state.vehiclesState
		)
	}),
	{
		applyFilter: actionCreators.applyFilter,
		clearFilter: actionCreators.clearFilter
	}
)

export default connector(FilterContainer)
