import { PureComponent } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursActionCreators from '../../../../redux/tours/actionCreators'
import toursSelectors from '../../../../redux/tours/selectors'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		deselect: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		planningTypes: PropTypes.array.isRequired,
		selectedMoment: PropTypes.object.isRequired,
		selectedTourIds: PropTypes.array.isRequired,
		select: PropTypes.func.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		tours: PropTypes.array.isRequired
	}

	state = {
		mapChannel: new BroadcastChannel('map')
	}

	componentWillUnmount() {
		const { mapChannel } = this.state
		mapChannel.close()
	}

	componentDidMount() {
		// Handle incoming BroadcastChannel messages when ready
		const { mapChannel } = this.state
		mapChannel.onmessage = (message) => {
			switch (message.type) {
				case 'tour-deselected':
					this.handleTourDeselected(message.payload.tourId)
					break
				case 'tour-selected':
					this.handleTourSelected(message.payload.tourId)
					break
				case 'map-ready':
					this.handleMapReady()
					break
				default:
					break
			}
		}
		// Send tours to the map window
		this.sendPlanningTypes()
		this.sendSelectedTourIds()
		this.sendTours()
	}

	componentDidUpdate(prevProps) {
		const { planningTypes, selectedTourIds, tours } = this.props
		// Send updates to the map window
		if (prevProps.planningTypes !== planningTypes) {
			this.sendPlanningTypes()
		}
		if (prevProps.selectedTourIds !== selectedTourIds) {
			this.sendSelectedTourIds()
		}
		if (prevProps.tours !== tours) {
			this.sendTours()
		}
	}

	sendSelectedTourIds() {
		const { selectedTourIds } = this.props
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-update-selectedtourids',
			payload: { selectedTourIds }
		})
	}

	sendTours() {
		const { tours } = this.props
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-update-tours',
			payload: { tours }
		})
	}

	sendPlanningTypes() {
		const { planningTypes } = this.props
		const { mapChannel } = this.state
		mapChannel.postMessage({
			type: 'map-update-planningtypes',
			payload: { planningTypes }
		})
	}

	handleTourDeselected = (tourId) => {
		const { deselect } = this.props
		deselect(tourId)
	}

	handleTourSelected = (tourId) => {
		const { select } = this.props
		select(tourId)
	}

	handleMapReady = () => {
		this.sendPlanningTypes()
		this.sendTours()
		this.sendSelectedTourIds()
	}

	render() {
		const {
			deselect,
			metaTours,
			ownUserId,
			select,
			selectedMoment,
			selectedTourIds,
			tourNumbers,
			tours
		} = this.props

		return (
			<List
				deselect={deselect}
				metaTours={metaTours}
				ownUserId={ownUserId}
				select={select}
				selectedMoment={selectedMoment}
				selectedTourIds={selectedTourIds}
				tourNumbers={tourNumbers}
				tours={tours}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		metaTours: state.toursState.metaTours,
		ownUserId: state.userState.user.id,
		planningTypes: state.planningTypesState.planningTypes,
		selectedMoment: toursSelectors.selectedMomentSelector(state.toursState),
		selectedTourIds: toursSelectors.selectedTourIdsSelector(state.toursState),
		tours: toursSelectors.tourListSelector(state.toursState),
		tourNumbers: toursSelectors.tourListNumbersSelector(state.toursState)
	}),
	{
		deselect: toursActionCreators.deselect,
		select: toursActionCreators.select
	}
)

export default connector(ListContainer)
