import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Snackbar, SvgIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TimesFal } from '@oliverit/react-fontawesome'

const styleSheet = {
	close: {
		padding: 4
	}
}

class Notification extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		done: PropTypes.func.isRequired,
		notification: PropTypes.object
	}

	state = {
		open: true
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		// Hide the notification
		this.setState({
			open: false
		})
		// Add a delay before removing the notification, so the hide animation can complete
		const { done, notification } = this.props
		if (notification) {
			setTimeout(() => {
				done(notification.id)
			}, 600)
		}
	}

	render() {
		const { classes, notification } = this.props
		const { open } = this.state

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={open}
				autoHideDuration={3000}
				onClose={this.handleClose}
				message={<span>{notification ? notification.message : ''}</span>}
				action={[
					<IconButton
						key="close"
						color="inherit"
						className={classes.close}
						onClick={this.handleClose}
					>
						<SvgIcon>
							<TimesFal />
						</SvgIcon>
					</IconButton>
				]}
			/>
		)
	}
}

export default withStyles(styleSheet)(Notification)
