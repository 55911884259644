import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const styleSheet = {
	destructiveButton: {
		color: red[500]
	},
	dialogActionsBarLeft: {
		display: 'flex',
		flex: 2,
		justifyContent: 'flex-start'
	},
	dialogActionsBarRight: {
		display: 'flex',
		flex: 3,
		justifyContent: 'flex-end'
	},
	dialogActionsBar: {
		justifyContent: 'flex-start'
	}
}

class SaveDialog extends PureComponent {
	static propTypes = {
		cancel: PropTypes.func.isRequired,
		cancelButtonText: PropTypes.string.isRequired,
		classes: PropTypes.object.isRequired,
		save: PropTypes.func.isRequired,
		saveButtonText: PropTypes.string.isRequired,
		descriptionText: PropTypes.string,
		isOpen: PropTypes.bool,
		ignore: PropTypes.func.isRequired,
		ignoreButtonText: PropTypes.string.isRequired,
		titleText: PropTypes.string.isRequired
	}

	render() {
		const {
			cancel,
			cancelButtonText,
			classes,
			save,
			saveButtonText,
			descriptionText,
			isOpen,
			ignore,
			ignoreButtonText,
			titleText
		} = this.props

		const saveButton = (
			<Button onClick={save} color="primary">
				{saveButtonText}
			</Button>
		)

		const ignoreButton = (
			<Button onClick={ignore} className={classes.destructiveButton}>
				{ignoreButtonText}
			</Button>
		)

		let description
		if (descriptionText) {
			const descriptionTextLines = descriptionText
				.split(/\r?\n/)
				.map((descriptionTextLine, key) => (
					<span key={`descriptionline-${key}`}>
						{descriptionTextLine}
						<br />
					</span>
				))
			description = (
				<DialogContent>
					<DialogContentText>{descriptionTextLines}</DialogContentText>
				</DialogContent>
			)
		}

		return (
			<Dialog open={isOpen} onClose={cancel}>
				<DialogTitle>{titleText}</DialogTitle>
				{description}
				<DialogActions className={classes.dialogActionsBar}>
					<div className={classes.dialogActionsBarLeft}>{ignoreButton}</div>
					<div className={classes.dialogActionsBarRight}>
						<Button onClick={cancel}>{cancelButtonText}</Button>
						{saveButton}
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(styleSheet)(SaveDialog)
