const actionTypes = {
	AUTOLOGIN: 'elements/auth/AUTOLOGIN',
	AUTOLOGIN_FAILURE: 'elements/auth/AUTOLOGIN_FAILURE',
	AUTOLOGIN_REQUEST: 'elements/auth/AUTOLOGIN_REQUEST',
	AUTOLOGIN_NOTOKEN: 'elements/auth/AUTOLOGIN_NOTOKEN',
	LOGIN: 'elements/auth/LOGIN',
	LOGIN_FAILURE: 'elements/auth/LOGIN_FAILURE',
	LOGIN_REQUEST: 'elements/auth/LOGIN_REQUEST',
	LOGIN_SUCCESS: 'elements/auth/LOGIN_SUCCESS',
	LOGIN_CANCELLED: 'elements/auth/LOGIN_CANCELLED',
	LOGOUT: 'elements/auth/LOGOUT'
}

export default actionTypes
