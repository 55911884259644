import { useState } from 'react'
import TopBar from './TopBar'
import SideMenu from './SideMenu'

const Navigation = ({ logout }) => {
	const [isSideMenuOpen, setShowSideMenu] = useState(false)
	const handleShowSideMenu = () => {
		setShowSideMenu(true)
	}

	const handleHideSideMenu = () => {
		setShowSideMenu(false)
	}

	return (
		<>
			<TopBar
				isSideMenuOpen={isSideMenuOpen}
				logout={logout}
				showSideMenu={handleShowSideMenu}
			/>
			<SideMenu
				isSideMenuOpen={isSideMenuOpen}
				hideSideMenu={handleHideSideMenu}
			/>
		</>
	)
}

export default Navigation
