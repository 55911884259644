import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import desiredDeliveryWeek from '../../../utils/desiredDeliveryWeek'
import originalDeliveryWeek from '../../../utils/originalDeliveryWeek'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 24
	},
	firstRow: {
		paddingBottom: 16
	}
}

class Dates extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const minimumDeliveryDate = moment
			.tz(order.minimumDeliveryDate, 'Europe/Amsterdam')
			.format('D MMMM Y')
		const tourDate = order.tourDate
			? moment.tz(order.tourDate, 'Europe/Amsterdam').format('D MMMM Y')
			: i18n.t('app:appointmentscheduler.Order.noTourDate')

		const desiredDeliveryWeekText = desiredDeliveryWeek(order)
		const originalDeliveryWeekText = originalDeliveryWeek(order)

		if (order.isAppointmentConfirmed) {
			const appointmentDate = order.appointmentDate
				? moment
						.tz(order.appointmentDate, 'Europe/Amsterdam')
						.format('D MMMM Y')
				: '?'
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:appointmentscheduler.Order.dates')}
					</Typography>
					<Grid container className={classes.firstRow}>
						<Grid item xs={4}>
							<Typography variant="caption" color="primary">
								{i18n.t('app:appointmentscheduler.Order.originalDeliveryWeek')}
							</Typography>
							<Typography variant="body2">
								{originalDeliveryWeekText}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="caption" color="primary">
								{i18n.t('app:appointmentscheduler.Order.desiredDeliveryWeek')}
							</Typography>
							<Typography variant="body2">{desiredDeliveryWeekText}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="caption" color="primary">
								{i18n.t('app:appointmentscheduler.Order.minimumDeliveryDate')}
							</Typography>
							<Typography variant="body2">{minimumDeliveryDate}</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>
							<Typography variant="caption" color="primary">
								{i18n.t('app:appointmentscheduler.Order.appointmentDate')}
							</Typography>
							<Typography variant="body2">{appointmentDate}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="caption" color="primary">
								{i18n.t('app:appointmentscheduler.Order.tourDate')}
							</Typography>
							<Typography variant="body2">{tourDate}</Typography>
						</Grid>
					</Grid>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.dates')}
				</Typography>
				<Grid container className={classes.firstRow}>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.originalDeliveryWeek')}
						</Typography>
						<Typography variant="body2">{originalDeliveryWeekText}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.desiredDeliveryWeek')}
						</Typography>
						<Typography variant="body2">{desiredDeliveryWeekText}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.minimumDeliveryDate')}
						</Typography>
						<Typography variant="body2">{minimumDeliveryDate}</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.tourDate')}
						</Typography>
						<Typography variant="body2">{tourDate}</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Dates)
