const actionTypes = {
	CLEAN_META: 'tours/CLEAN_META',
	CONFIRM: 'tours/CONFIRM',
	CONFIRM_FAILURE: 'tours/CONFIRM_FAILURE',
	CONFIRM_MULTIPLE: 'tours/CONFIRM_MULTIPLE',
	CONFIRM_REQUEST: 'tours/CONFIRM_REQUEST',
	CONFIRM_SUCCESS: 'tours/CONFIRM_SUCCESS',
	FETCH_ALL: 'tours/FETCH_ALL',
	FETCH_ALL_FAILURE: 'tours/FETCH_ALL_FAILURE',
	FETCH_ALL_REQUEST: 'tours/FETCH_ALL_REQUEST',
	FETCH_ALL_SUCCESS: 'tours/FETCH_ALL_SUCCESS',
	FETCH_REPLACEMENTS: 'tours/FETCH_REPLACEMENTS',
	FETCH_REPLACEMENTS_FAILURE: 'tours/FETCH_REPLACEMENTS_FAILURE',
	FETCH_REPLACEMENTS_REQUEST: 'tours/FETCH_REPLACEMENTS_REQUEST',
	FETCH_REPLACEMENTS_SUCCESS: 'tours/FETCH_REPLACEMENTS_SUCCESS',
	FINALIZE: 'tours/FINALIZE',
	FINALIZE_FAILURE: 'tours/FINALIZE_FAILURE',
	FINALIZE_MULTIPLE: 'tours/FINALIZE_MULTIPLE',
	FINALIZE_REQUEST: 'tours/FINALIZE_REQUEST',
	FINALIZE_SUCCESS: 'tours/FINALIZE_SUCCESS',
	LOCK: 'tours/LOCK',
	LOCK_FAILURE: 'tours/LOCK_FAILURE',
	LOCK_REQUEST: 'tours/LOCK_REQUEST',
	LOCK_SUCCESS: 'tours/LOCK_SUCCESS',
	SELECT_DATE_CURRENT: 'tours/SELECT_DATE_CURRENT',
	SELECT_DATE_NEXT: 'tours/SELECT_DATE_NEXT',
	SELECT_DATE_PREVIOUS: 'tours/SELECT_DATE_PREVIOUS',
	SELECT_PLANNING_TYPE: 'tours/SELECT_PLANNING_TYPE',
	SOCKET_ADD_TOUR: 'tours/SOCKET_ADD_TOUR',
	SOCKET_CLOSE: 'tours/SOCKET_CLOSE',
	SOCKET_CONNECTED: 'tours/SOCKET_CONNECTED',
	SOCKET_DISCONNECTED: 'tours/SOCKET_DISCONNECTED',
	SOCKET_OPEN: 'tours/SOCKET_OPEN',
	SOCKET_REMOVE_TOUR: 'tours/SOCKET_REMOVE_TOUR',
	SOCKET_REPLACE_TOURS: 'tours/SOCKET_REPLACE_TOURS',
	SOCKET_UPDATE_TOUR: 'tours/SOCKET_UPDATE_TOUR',
	SOCKET_UPDATE_TOURS: 'tours/SOCKET_UPDATE_TOURS',
	TAKEOVERLOCK: 'tours/TAKEOVERLOCK',
	TAKEOVERLOCK_FAILURE: 'tours/TAKEOVERLOCK_FAILURE',
	TAKEOVERLOCK_REQUEST: 'tours/TAKEOVERLOCK_REQUEST',
	TAKEOVERLOCK_SUCCESS: 'tours/TAKEOVERLOCK_SUCCESS',
	TOUR_DESELECT: 'tours/TOUR_DESELECT',
	TOUR_DESELECT_ALL: 'tours/TOUR_DESELECT_ALL',
	TOUR_SELECT: 'tours/TOUR_SELECT',
	TOUR_SELECT_MULTIPLE: 'tours/TOUR_SELECT_MULTIPLE',
	UNDO_CONFIRM: 'tours/UNDO_CONFIRM',
	UNDO_CONFIRM_FAILURE: 'tours/UNDO_CONFIRM_FAILURE',
	UNDO_CONFIRM_REQUEST: 'tours/UNDO_CONFIRM_REQUEST',
	UNDO_CONFIRM_SUCCESS: 'tours/UNDO_CONFIRM_SUCCESS',
	UNLOCK: 'tours/UNLOCK',
	UNLOCK_FAILURE: 'tours/UNLOCK_FAILURE',
	UNLOCK_REQUEST: 'tours/UNLOCK_REQUEST',
	UNLOCK_SUCCESS: 'tours/UNLOCK_SUCCESS'
}

export default actionTypes
