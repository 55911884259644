import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Tooltip,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { amber, orange, green } from '@material-ui/core/colors'
import {
	ArrowAltDownFal,
	ArrowAltUpFal,
	CalendarCheckFal,
	CalendarTimesFal,
	CheckFal,
	ExclamationTriangleFal,
	SignOutAltFal,
	InfoCircleFal,
	TimesFal,
	TrashAltFal,
	UserHeadsetFad,
	WarehouseAltFal
} from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import { formatCustomer } from '../../../../utils/customer'
import formatDuration from '../../../../utils/duration'
import localizedDescription from '../../../../utils/localizedDescription'
import { fields, localizedText } from '../../../../utils/localizedText'

const styleSheet = (theme) => ({
	chip: {
		marginTop: 4,
		marginRight: 4
	},
	iconGreen: {
		color: green[500]
	},
	iconRed: {
		color: theme.palette.secondary.main
	},
	hasTimeAppointment: {
		color: orange[500]
	},
	routeNonServiceTimeSeparator: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		textAlign: 'center'
	},
	routeDaySeparator: {
		backgroundColor: 'rgba(0, 0, 0, 0.12)',
		textAlign: 'center'
	},
	routeDeleted: {
		backgroundImage:
			'repeating-linear-gradient(-45deg, transparent 0px, transparent 7px, rgba(0, 0, 0, 0.08) 7px, rgba(0, 0, 0, 0.08) 9px)'
	},
	servicePeriod: {
		color: theme.palette.text.secondary,
		paddingLeft: 8
	},
	requirementsButton: {
		color: theme.palette.text.secondary,
		padding: 0,
		marginLeft: 4,
		marginTop: -1,
		fontSize: 'inherit'
	},
	manualPlanningButtonsListItem: {
		paddingTop: 0
	},
	manualPlanningButtons: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-evenly'
	},
	manualPlanningButtonLeft: {
		marginRight: 4
	},
	manualPlanningButtonRight: {
		marginLeft: 4
	},
	violationsIcon: {
		marginRight: 4,
		width: 12
	},
	violationsWarning: {
		display: 'flex',
		flex: 1,
		color: amber[700]
	}
})

class RouteItem extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isFiliaal: PropTypes.bool,
		isFirst: PropTypes.bool,
		isLast: PropTypes.bool,
		isMultiDay: PropTypes.bool,
		isPlanningTypeMultiDay: PropTypes.bool,
		qualifications: PropTypes.array.isRequired,
		manualPlanningDown: PropTypes.func.isRequired,
		manualPlanningIsModified: PropTypes.bool,
		manualPlanningMode: PropTypes.bool,
		manualPlanningMove: PropTypes.func.isRequired,
		manualPlanningRemove: PropTypes.func.isRequired,
		manualPlanningUp: PropTypes.func.isRequired,
		previousDepartureTime: PropTypes.object.isRequired,
		previousEndServiceTime: PropTypes.object.isRequired,
		routeItem: PropTypes.object.isRequired
	}

	state = {
		isRequirementsOpen: false
	}

	handleDownClick = () => {
		const { manualPlanningDown, routeItem } = this.props
		manualPlanningDown(routeItem.orderKey)
	}

	handleMoveClick = () => {
		const { manualPlanningMove, routeItem } = this.props
		manualPlanningMove(routeItem.orderKey)
	}

	handleRemoveClick = () => {
		const { manualPlanningRemove, routeItem } = this.props
		manualPlanningRemove(routeItem.orderKey)
	}

	handleUpClick = () => {
		const { manualPlanningUp, routeItem } = this.props
		manualPlanningUp(routeItem.orderKey)
	}

	handleOpenRequirementsClick = () => {
		this.setState({ isRequirementsOpen: true })
	}

	handleCloseRequirementsClick = () => {
		this.setState({ isRequirementsOpen: false })
	}

	render() {
		const {
			classes,
			isFiliaal,
			isFirst,
			isLast,
			isMultiDay,
			isPlanningTypeMultiDay,
			qualifications,
			manualPlanningIsModified,
			manualPlanningMode,
			previousDepartureTime,
			previousEndServiceTime,
			routeItem
		} = this.props
		const { isRequirementsOpen } = this.state

		// Customer and salesorder number, if available (these can be missing when the tour contains a route item which still refers to a deleted order)
		let customer
		if (routeItem.customer) {
			const formattedCustomer = formatCustomer(routeItem.customer)
			customer = (
				<>
					<Typography variant="body2" noWrap>
						{formattedCustomer.name}
					</Typography>
					<Typography variant="body2" noWrap>
						{formattedCustomer.street} {formattedCustomer.houseNumber}
					</Typography>
					<Typography variant="body2" noWrap>
						{formattedCustomer.postCode} {formattedCustomer.city},{' '}
						{formattedCustomer.country}
					</Typography>
				</>
			)
		}
		let numbers
		if (
			routeItem.salesOrderNumber &&
			routeItem.salesOrderDeliveryGroup &&
			routeItem.salesOrderDeliveryGroup !== '0' &&
			routeItem.deliveryNumber
		) {
			numbers = (
				<Typography variant="subtitle2">
					{routeItem.salesOrderNumber} (${routeItem.salesOrderDeliveryGroup}) /{' '}
					{routeItem.deliveryNumber}
				</Typography>
			)
		} else if (routeItem.salesOrderNumber && routeItem.deliveryNumber) {
			numbers = (
				<Typography variant="subtitle2">
					{routeItem.salesOrderNumber} / {routeItem.deliveryNumber}
				</Typography>
			)
		} else if (routeItem.salesOrderNumber) {
			numbers = (
				<Typography variant="subtitle2">
					{routeItem.salesOrderNumber}
				</Typography>
			)
		} else if (routeItem.deliveryNumber) {
			numbers = (
				<Typography variant="subtitle2">{routeItem.deliveryNumber}</Typography>
			)
		} else if (routeItem.orderKey) {
			numbers = (
				<Typography variant="subtitle2">{routeItem.orderKey}</Typography>
			)
		}

		// Manual planning buttons
		let manualPlanningButtons
		if (manualPlanningMode) {
			manualPlanningButtons = (
				<ListItem
					dense
					divider
					className={classes.manualPlanningButtonsListItem}
				>
					<div className={classes.manualPlanningButtons}>
						<IconButton
							disabled={isFirst}
							color="primary"
							onClick={this.handleUpClick}
						>
							<SvgIcon>
								<ArrowAltUpFal />
							</SvgIcon>
						</IconButton>
						<IconButton
							disabled={isLast}
							color="primary"
							onClick={this.handleDownClick}
						>
							<SvgIcon>
								<ArrowAltDownFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="primary" onClick={this.handleMoveClick}>
							<SvgIcon>
								<SignOutAltFal />
							</SvgIcon>
						</IconButton>
						<IconButton color="secondary" onClick={this.handleRemoveClick}>
							<SvgIcon>
								<TrashAltFal />
							</SvgIcon>
						</IconButton>
					</div>
				</ListItem>
			)
		}

		// Confirmed, unconfirmed, or filiaal
		let routeItemSecondaryAction
		if (isFiliaal) {
			routeItemSecondaryAction = (
				<ListItemSecondaryAction>
					<IconButton disabled>
						<SvgIcon className={classes.iconGreen}>
							<WarehouseAltFal />
						</SvgIcon>
					</IconButton>
				</ListItemSecondaryAction>
			)
		} else {
			const confirmedIcon =
				routeItem.characteristics && routeItem.characteristics.isUnconfirmed ? (
					<SvgIcon className={classes.iconRed}>
						<CalendarTimesFal />
					</SvgIcon>
				) : (
					<SvgIcon className={classes.iconGreen}>
						<CalendarCheckFal />
					</SvgIcon>
				)
			routeItemSecondaryAction = (
				<ListItemSecondaryAction>
					<IconButton disabled>{confirmedIcon}</IconButton>
				</ListItemSecondaryAction>
			)
		}

		// Characteristics
		let hasExternalElevator
		if (
			routeItem.characteristics &&
			routeItem.characteristics.hasExternalElevator
		) {
			hasExternalElevator = (
				<Chip
					label={i18n.t('app:planning.Tour.hasExternalElevator')}
					className={classes.chip}
					size="small"
				/>
			)
		}
		let hasRetour
		if (routeItem.characteristics && routeItem.characteristics.hasRetour) {
			hasRetour = (
				<Chip
					label={i18n.t('app:planning.Tour.hasRetour')}
					className={classes.chip}
					size="small"
				/>
			)
		}
		let hasScaffold
		if (routeItem.characteristics && routeItem.characteristics.hasScaffold) {
			hasScaffold = (
				<Chip
					label={i18n.t('app:planning.Tour.hasScaffold')}
					className={classes.chip}
					size="small"
				/>
			)
		}
		let dayTimeAppointmentChip
		if (
			routeItem.characteristics &&
			!routeItem.characteristics.isUnconfirmed &&
			routeItem.appointmentDate &&
			(routeItem.characteristics.hasTimeAppointment || isPlanningTypeMultiDay)
		) {
			let dayAppointmentText
			if (isPlanningTypeMultiDay && routeItem.appointmentDate) {
				dayAppointmentText = moment
					.tz(routeItem.appointmentDate, 'Europe/Amsterdam')
					.format('dddd')
				dayAppointmentText = `${dayAppointmentText
					.charAt(0)
					.toUpperCase()}${dayAppointmentText.slice(1)}`
			}

			let timeAppointmentText
			if (
				routeItem.characteristics.hasTimeAppointment &&
				routeItem.appointmentTimeFrom &&
				routeItem.appointmentTimeTill
			) {
				const formattedAppointmentTimeFrom = formatDuration(
					moment.duration(routeItem.appointmentTimeFrom).asSeconds(),
					{ withSeconds: false }
				)
				const formattedAppointmentTimeTill = formatDuration(
					moment.duration(routeItem.appointmentTimeTill).asSeconds(),
					{ withSeconds: false }
				)
				timeAppointmentText = `${formattedAppointmentTimeFrom} - ${formattedAppointmentTimeTill}`
			}

			const dayTimeAppointmentText = [
				dayAppointmentText,
				timeAppointmentText
			].join(' ')

			dayTimeAppointmentChip = (
				<Chip
					className={classes.chip}
					icon={
						<SvgIcon>
							<UserHeadsetFad />
						</SvgIcon>
					}
					label={dayTimeAppointmentText}
					size="small"
				/>
			)
		}

		// Requirements
		let hasRequirements
		if (routeItem.requirements && routeItem.requirements.length > 0) {
			let hasAllRequirements = true
			const requirementRows = routeItem.requirements.map(
				(requirement, index) => {
					const requirementDescription = localizedText(
						fields.REQUIREMENT,
						requirement.key
					)
					const hasRequirement = qualifications.some(
						(qualification) => qualification.key === requirement.key
					)
					let requirementIcon
					if (hasRequirement) {
						requirementIcon = (
							<IconButton disabled edge="end">
								<SvgIcon className={classes.iconGreen} edge="end">
									<CheckFal />
								</SvgIcon>
							</IconButton>
						)
					} else {
						hasAllRequirements = false
						requirementIcon = (
							<IconButton disabled edge="end">
								<SvgIcon className={classes.iconRed} edge="end">
									<TimesFal />
								</SvgIcon>
							</IconButton>
						)
					}
					return (
						<ListItem key={`requirement-${index}`}>
							<ListItemText primary={requirementDescription} />
							<ListItemSecondaryAction>
								{requirementIcon}
							</ListItemSecondaryAction>
						</ListItem>
					)
				}
			)
			hasRequirements = (
				<>
					<Dialog
						onClose={this.handleCloseRequirementsClick}
						open={isRequirementsOpen}
					>
						<List>{requirementRows}</List>
						<DialogActions>
							<Button
								onClick={this.handleCloseRequirementsClick}
								color="primary"
							>
								{i18n.t('app:generic.close')}
							</Button>
						</DialogActions>
					</Dialog>
					<Tooltip
						title={i18n.t('app:planning.Tour.showRequirements')}
						placement="top"
					>
						<IconButton
							className={classes.requirementsButton}
							size="small"
							onClick={this.handleOpenRequirementsClick}
						>
							<SvgIcon
								fontSize="inherit"
								color={hasAllRequirements ? 'inherit' : 'error'}
							>
								<InfoCircleFal />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</>
			)
		}

		// Insert a listitem which indicates time between the servicetimes of route items (travel, breaks, etc.)
		let nonServiceTimeSeparator
		if (!manualPlanningMode || !manualPlanningIsModified) {
			const currentStartServiceTime = moment
				.unix(routeItem.startServiceTime)
				.tz('Europe/Amsterdam')
			const nonServiceTimeSeparatorPrimary = (
				<Typography variant="body2">
					{previousEndServiceTime.format('H:mm')} -{' '}
					{currentStartServiceTime.format('H:mm')}
				</Typography>
			)
			nonServiceTimeSeparator = (
				<ListItem divider className={classes.routeNonServiceTimeSeparator}>
					<ListItemText
						disableTypography
						primary={nonServiceTimeSeparatorPrimary}
					/>
				</ListItem>
			)
		}

		// Insert a listitem which indicates the next day. This can happen on tours which are longer than one day.
		// The day separator is inserted at the first route item, and at route items which are on a different day than the previous one.
		let daySeparator
		if (!manualPlanningMode || !manualPlanningIsModified) {
			if (isMultiDay) {
				const currentDepartureTime = moment
					.unix(routeItem.departureTime)
					.tz('Europe/Amsterdam')
				if (
					isFirst ||
					!currentDepartureTime.isSame(previousDepartureTime, 'day')
				) {
					const daySeparatorPrimary = (
						<Typography variant="subtitle2">
							{currentDepartureTime.format('D MMMM Y')}
						</Typography>
					)
					daySeparator = (
						<ListItem divider className={classes.routeDaySeparator}>
							<ListItemText disableTypography primary={daySeparatorPrimary} />
						</ListItem>
					)
				}
			}
		}

		// Validation violations
		let routeItemViolations
		if (routeItem.violations && routeItem.violations.length > 0) {
			const violationRows = routeItem.violations.map((violation, index) => (
				<span key={`route-violation-${index}`}>
					{localizedDescription(violation.descriptions)}
					<br />
				</span>
			))
			routeItemViolations = (
				<Tooltip title={<>{violationRows}</>}>
					<div className={classes.violationsWarning}>
						<SvgIcon className={classes.violationsIcon}>
							<ExclamationTriangleFal />
						</SvgIcon>
						<Typography variant="body2" color="inherit">
							{i18n.t('app:planning.Manual.hasViolationsTour')}
						</Typography>
					</div>
				</Tooltip>
			)
		}

		let routeTimes
		if (!manualPlanningMode || !manualPlanningIsModified) {
			// Display the service time on route items (this will exclude all other durations such as travel and break time)
			const duration = `${moment
				.unix(routeItem.startServiceTime)
				.tz('Europe/Amsterdam')
				.format('H:mm')} - ${moment
				.unix(routeItem.endServiceTime)
				.tz('Europe/Amsterdam')
				.format('H:mm')}`
			const serviceTime = (
				<span className={classes.servicePeriod}>
					({formatDuration(routeItem.servicePeriod)})
				</span>
			)
			const routeTimesClassName =
				routeItem.characteristics &&
				routeItem.characteristics.hasTimeAppointment
					? classes.hasTimeAppointment
					: null
			routeTimes = (
				<Typography variant="body2" className={routeTimesClassName}>
					{duration} {serviceTime} {hasRequirements}
				</Typography>
			)
		} else {
			// Display the service time on route items (this will exclude all other durations such as travel and break time)
			const serviceTime = `(${formatDuration(routeItem.servicePeriod)})`
			routeTimes = (
				<Typography variant="body2" color="textSecondary">
					{serviceTime} {hasRequirements}
				</Typography>
			)
		}
		const routeDescription = (
			<ListItemText
				disableTypography
				primary={routeTimes}
				secondary={
					<>
						{numbers}
						{customer}
						{dayTimeAppointmentChip}
						{hasExternalElevator}
						{hasScaffold}
						{hasRetour}
						{routeItemViolations}
					</>
				}
			/>
		)

		// Use a different class for the route component when it is marked as deleted
		const routeClassName = routeItem.deleted ? classes.routeDeleted : null

		// Show a divider when there are no manual planning buttons
		const showDivider = !manualPlanningButtons

		return (
			<>
				{daySeparator}
				{nonServiceTimeSeparator}
				<ListItem divider={showDivider} className={routeClassName}>
					{routeDescription}
					{routeItemSecondaryAction}
				</ListItem>
				{manualPlanningButtons}
			</>
		)
	}
}

export default withStyles(styleSheet)(RouteItem)
