import { combineReducers } from 'redux'

import actionTypes from './actionTypes'

const initialExcludedServiceDatesState = []
const excludedServiceDatesReducer = (
	state = initialExcludedServiceDatesState,
	action
) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return initialExcludedServiceDatesState
		}

		case actionTypes.FETCH_SUCCESS: {
			return action.payload.excludeServiceDates
		}

		case actionTypes.FETCH_FAILURE: {
			return initialExcludedServiceDatesState
		}

		case actionTypes.SOCKET_ADD: {
			return state.concat(action.payload.excludeServiceDate)
		}

		case actionTypes.SOCKET_REMOVE: {
			const { excludeServiceDate: removedExcludeServiceDate } = action.payload
			return state.filter(
				(excludeServiceDate) =>
					excludeServiceDate.id !== removedExcludeServiceDate.id
			)
		}

		default: {
			return state
		}
	}
}

const initialExcludedServiceDatesMetaState = {
	isConnected: false,
	isLoading: false,
	isSaving: false
}
const excludedServiceDatesMetaReducer = (
	state = initialExcludedServiceDatesMetaState,
	action
) => {
	switch (action.type) {
		case actionTypes.SOCKET_CONNECTED: {
			return {
				...state,
				isConnected: true
			}
		}

		case actionTypes.SOCKET_DISCONNECTED: {
			return {
				...state,
				isConnected: false
			}
		}

		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.ADD_REQUEST: {
			return {
				...state,
				isSaving: true
			}
		}

		case actionTypes.ADD_SUCCESS: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.ADD_FAILURE: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.REMOVE_REQUEST: {
			return {
				...state,
				isSaving: true
			}
		}

		case actionTypes.REMOVE_SUCCESS: {
			return {
				...state,
				isSaving: false
			}
		}

		case actionTypes.REMOVE_FAILURE: {
			return {
				...state,
				isSaving: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	excludedServiceDates: excludedServiceDatesReducer,
	meta: excludedServiceDatesMetaReducer
})
