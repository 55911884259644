import { all, fork, take, put, call } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import apiVehicles from '../../services/apiVehicles'
import notificationsActionCreators from '../notifications/actionCreators'

function* fetch() {
	while (true) {
		const action = yield take(actionTypes.FETCH)
		yield put(actionCreators.fetchRequest())
		try {
			const { id } = action.meta
			const availability = yield call(apiVehicles.getUnavailabilityById, id)
			yield put(actionCreators.fetchSuccess(availability))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleUnavailability.Items.Error.fetch')
			yield put(
				actionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* create() {
	while (true) {
		const action = yield take(actionTypes.CREATE)
		yield put(actionCreators.createRequest())
		try {
			const { history, unavailability } = action.payload
			const newItem = yield call(
				apiVehicles.createUnavailability,
				unavailability
			)
			yield put(actionCreators.createSuccess(newItem))
			// Trigger navigation in the main window
			history.push(`/vehicleunavailability/${unavailability.vehicleId}`)
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleUnavailability.Item.Error.create')
			yield put(
				actionCreators.createFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* remove() {
	while (true) {
		const action = yield take(actionTypes.REMOVE)
		yield put(actionCreators.removeRequest())
		try {
			const { unavailability } = action.meta
			yield call(apiVehicles.removeUnavailability, unavailability)
			yield put(actionCreators.removeSuccess(unavailability))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t('app:vehicleUnavailability.Item.Error.remove')
			yield put(
				actionCreators.removeFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch, create, remove].map((saga) => fork(saga)))
}
