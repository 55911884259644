import actionTypes from './actionTypes'

const actionCreators = {
	fetch: () => ({
		type: actionTypes.FETCH
	}),

	fetchFailure: (errorMessages) => ({
		type: actionTypes.FETCH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchRequest: () => ({
		type: actionTypes.FETCH_REQUEST
	}),

	fetchSuccess: (depots) => ({
		type: actionTypes.FETCH_SUCCESS,
		payload: {
			depots
		}
	})
}

export default actionCreators
