import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16
	}
}

class Remarks extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		const remarks = order.bijzonderheden
			? order.bijzonderheden
			: i18n.t('app:appointmentscheduler.Order.noRemarks')
		const remarksLines = remarks.split(/\r?\n/).map((remarksLine, key) => (
			<span key={`remark-${key}`}>
				{remarksLine}
				<br />
			</span>
		))

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.remarks')}
				</Typography>
				<Typography variant="body2">{remarksLines}</Typography>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Remarks)
