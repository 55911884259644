import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dateProposalActionCreators from '../../../redux/dateproposal/actionCreators'
import orderActionCreators from '../../../redux/order/actionCreators'
import userSelectors from '../../../redux/user/selectors'
import unavailableProposalDatesSelectors from '../../../redux/unavailableproposaldates/selectors'
import OrderEdit from './OrderEdit'
import { copyAppointment } from '../../../utils/appointment'

class OrderEditContainer extends PureComponent {
	static propTypes = {
		confirmAppointment: PropTypes.func.isRequired,
		dateProposals: PropTypes.array.isRequired,
		fetchDateProposal: PropTypes.func.isRequired,
		meta: PropTypes.object,
		metaDateProposals: PropTypes.array.isRequired,
		noAnswerAppointment: PropTypes.func.isRequired,
		order: PropTypes.object.isRequired,
		ownFullName: PropTypes.string.isRequired,
		postponeAppointment: PropTypes.func.isRequired,
		saveAppointment: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string.isRequired,
		timeSlots: PropTypes.array.isRequired,
		unavailableProposalMomentsForOrder: PropTypes.array.isRequired,
		unlock: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			appointment: copyAppointment(props.order),
			flow: [],
			selectedStep: 'date',
			step: 'date'
		}
	}

	componentDidMount() {
		// Immediately fetch the date proposal so the user will spend less time waiting for the proposal to complete
		const { order, fetchDateProposal } = this.props
		fetchDateProposal(order.orderKey)
	}

	updateCallscript(nextStep, completedStep) {
		const { flow } = this.state
		let newFlow
		if (flow.includes(completedStep)) {
			// The step was already completed, reset the flow so that it now ends with this step
			newFlow = [...flow]
				.slice(0, flow.indexOf(completedStep))
				.concat(completedStep)
		} else {
			newFlow = [...flow, completedStep]
		}
		this.setState({
			flow: newFlow,
			selectedStep: nextStep,
			step: nextStep
		})
	}

	resetCallscript() {
		const { order } = this.props
		this.setState({
			appointment: copyAppointment(order),
			flow: [],
			selectedStep: 'date',
			step: 'date'
		})
	}

	handleConfirm = () => {
		const { confirmAppointment } = this.props
		const { appointment } = this.state
		confirmAppointment(appointment)
	}

	handleNoAnswer = () => {
		const { noAnswerAppointment } = this.props
		const { appointment } = this.state
		noAnswerAppointment(appointment)
	}

	handlePostpone = () => {
		const { postponeAppointment } = this.props
		const { appointment } = this.state
		postponeAppointment(appointment)
	}

	handleStop = ({ save = true } = {}) => {
		const { saveAppointment, unlock } = this.props
		const { appointment } = this.state
		if (save) {
			saveAppointment(appointment)
		} else {
			unlock()
		}
	}

	handleNavigateToNextStep = () => {
		const { flow, selectedStep, step } = this.state
		// Find selectedStep in flow and go to the step after that
		// When selectedStep is not found this means we're already at the last step
		const flowIndex = flow.indexOf(selectedStep)
		if (flowIndex !== -1) {
			const nextStepIndex = flowIndex + 1
			if (nextStepIndex > flow.length - 1) {
				this.setState({
					selectedStep: step
				})
			} else {
				this.setState({
					selectedStep: flow[nextStepIndex]
				})
			}
		}
	}

	handleNavigateToPreviousStep = () => {
		const { flow, selectedStep } = this.state
		// Find selectedStep in flow and go to the step before that
		// When selectedStep is not found this means the previous step is the last entry in flow
		const flowIndex = flow.indexOf(selectedStep)
		if (flowIndex !== -1) {
			const previousStepIndex = flowIndex - 1
			if (previousStepIndex >= 0) {
				this.setState({
					selectedStep: flow[previousStepIndex]
				})
			}
		} else if (flow.length > 0) {
			this.setState({
				selectedStep: flow[flow.length - 1]
			})
		}
	}

	handleResetAppointment = () => {
		this.setState({
			appointment: {
				deliveryDate: null,
				customer: null,
				floorNumber: null,
				characteristics: null,
				bijzonderheden: null,
				earliestDateProposal: null,
				isDatepickerDate: null
			}
		})
		this.resetCallscript()
	}

	handleGetProposal = () => {
		const { order, fetchDateProposal } = this.props
		fetchDateProposal(order.orderKey)
	}

	handleSetAddress = (address) => {
		const { order } = this.props
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				customer: {
					...order.customer,
					...address
				}
			}
		})

		// Update callscript
		this.updateCallscript('floornumber', 'address')
	}

	handleSetCanElevatorBeUsed = (canElevatorBeUsed) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				characteristics: {
					canElevatorBeUsed,
					canStairsBeUsed: null,
					canScaffoldBeUsed: null,
					canExternalElevatorBeUsed: null
				}
			}
		})

		// Update callscript
		let step
		if (canElevatorBeUsed.isPresent && canElevatorBeUsed.isLargeEnough) {
			// - Elevator can be used, show remarks form
			step = 'remarks'
		} else {
			// - Elevator cannot be used, show stairs form when the floor number is 4 or lower, otherwise skip to external elevator form
			step = appointment.floorNumber > 4 ? 'externalelevator' : 'stairs'
		}
		this.updateCallscript(step, 'elevator')
	}

	handleSetCanExternalElevatorBeUsed = (canExternalElevatorBeUsed) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				characteristics: {
					...appointment.characteristics,
					canExternalElevatorBeUsed,
					canScaffoldBeUsed: null
				}
			}
		})

		// Update callscript
		let step
		if (
			canExternalElevatorBeUsed.fitsThroughWindow &&
			canExternalElevatorBeUsed.canBePlaced
		) {
			// - Scaffold can be used, show remarks form
			step = 'remarks'
		} else {
			// - Scaffold cannot be used, show abort form
			step = 'abort'
		}
		this.updateCallscript(step, 'externalelevator')
	}

	handleSetCanScaffoldBeUsed = (canScaffoldBeUsed) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				characteristics: {
					...appointment.characteristics,
					canScaffoldBeUsed,
					canExternalElevatorBeUsed: null
				}
			}
		})

		// Update callscript
		let step
		if (canScaffoldBeUsed.fitsThroughWindow && canScaffoldBeUsed.canBePlaced) {
			// - Scaffold can be used, show remarks form
			step = 'remarks'
		} else {
			// - Scaffold cannot be used, show abort form
			step = 'abort'
		}
		this.updateCallscript(step, 'scaffold')
	}

	handleSetCanStairsBeUsed = (canStairsBeUsed) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				characteristics: {
					...appointment.characteristics,
					canStairsBeUsed,
					canScaffoldBeUsed: null,
					canExternalElevatorBeUsed: null
				}
			}
		})

		// Update callscript
		let step
		if (canStairsBeUsed.isPresent && canStairsBeUsed.isLargeEnough) {
			// - Stairs can be used, show remarks form
			step = 'remarks'
		} else {
			// - Stairs cannot be used, show external elevator form when the floor number is greater than 1, otherwise show scaffold form
			step = appointment.floorNumber > 1 ? 'externalelevator' : 'scaffold'
		}
		this.updateCallscript(step, 'stairs')
	}

	handleSetDeliveryDate = (
		deliveryDate,
		earliestDateProposal,
		isDatepickerDate
	) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				deliveryDate,
				earliestDateProposal,
				isDatepickerDate
			}
		})

		// Update callscript
		// - Order date was selected, show address form
		this.updateCallscript('address', 'date')
	}

	handleSetFloorNumber = (floorNumber) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				floorNumber,
				characteristics: null
			}
		})

		// Update callscript
		let step
		if (floorNumber > 0) {
			// - Floor number was set to greater than 0 (not the first floor), show elevator form
			step = 'elevator'
		} else if (floorNumber === 0) {
			// - Floor number set to the first floor (0), show remarks form
			step = 'remarks'
		}
		this.updateCallscript(step, 'floornumber')
	}

	handleSetNote = (note) => {
		const { appointment } = this.state
		this.setState({
			appointment: {
				...appointment,
				note
			}
		})
	}

	handleSetRemarks = (bijzonderheden) => {
		const { appointment } = this.state

		// Update appointment
		this.setState({
			appointment: {
				...appointment,
				bijzonderheden
			}
		})

		// Update callscript
		// - Remarks were completed, show confirm form
		this.updateCallscript('confirm', 'remarks')
	}

	render() {
		const {
			dateProposals,
			metaDateProposals,
			order,
			meta,
			ownFullName,
			selectedOrderId,
			timeSlots,
			unavailableProposalMomentsForOrder
		} = this.props
		const { appointment, flow, selectedStep, step } = this.state

		// Is the order being updated?
		const isUpdating = meta ? meta.isUpdating : false

		// No order was selected
		if (!selectedOrderId) {
			return null
		}

		// A order was selected but it could not be found
		if (!order) {
			return null
		}

		// Full step flow
		const stepFlow = [...flow, step]

		// Optional navigation
		const navigateToNextStep =
			selectedStep !== step ? this.handleNavigateToNextStep : null
		const navigateToPreviousStep =
			selectedStep !== flow[0] && flow.length > 0
				? this.handleNavigateToPreviousStep
				: null

		// Get the date proposal
		const dateProposal = dateProposals.find(
			(proposal) => proposal.orderKey === order.orderKey
		)
		const orderMetaDateProposal = metaDateProposals.find(
			(metaDateProposal) => metaDateProposal.orderKey === order.orderKey
		)
		const dateProposalLoading = orderMetaDateProposal
			? orderMetaDateProposal.isLoading
			: false

		return (
			<OrderEdit
				appointment={appointment}
				confirm={this.handleConfirm}
				dateProposal={dateProposal}
				dateProposalLoading={dateProposalLoading}
				order={order}
				getProposal={this.handleGetProposal}
				isUpdating={isUpdating}
				navigateToNextStep={navigateToNextStep}
				navigateToPreviousStep={navigateToPreviousStep}
				noAnswer={this.handleNoAnswer}
				ownFullName={ownFullName}
				postpone={this.handlePostpone}
				reset={this.handleResetAppointment}
				selectedStep={selectedStep}
				setAddress={this.handleSetAddress}
				setCanElevatorBeUsed={this.handleSetCanElevatorBeUsed}
				setCanExternalElevatorBeUsed={this.handleSetCanExternalElevatorBeUsed}
				setCanScaffoldBeUsed={this.handleSetCanScaffoldBeUsed}
				setCanStairsBeUsed={this.handleSetCanStairsBeUsed}
				setDeliveryDate={this.handleSetDeliveryDate}
				setFloorNumber={this.handleSetFloorNumber}
				setNote={this.handleSetNote}
				setRemarks={this.handleSetRemarks}
				stepFlow={stepFlow}
				stop={this.handleStop}
				timeSlots={timeSlots}
				unavailableProposalMomentsForOrder={unavailableProposalMomentsForOrder}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		dateProposals: state.dateProposalState.dateProposals,
		meta: state.orderState.meta,
		metaDateProposals: state.dateProposalState.metaDateProposals,
		order: state.orderState.order,
		ownFullName: userSelectors.fullNameSelector(state.userState),
		timeSlots: state.orderState.timeSlots,
		unavailableProposalMomentsForOrder:
			unavailableProposalDatesSelectors.unavailableProposalMomentsForOrderSelector(
				state.ordersState,
				props.selectedOrderId,
				state.unavailableProposalDatesState
			)
	}),
	{
		fetchDateProposal: dateProposalActionCreators.fetch,
		confirmAppointment: orderActionCreators.confirmAppointment,
		noAnswerAppointment: orderActionCreators.noAnswerAppointment,
		postponeAppointment: orderActionCreators.postponeAppointment,
		saveAppointment: orderActionCreators.saveAppointment,
		unlock: orderActionCreators.unlock
	}
)

export default connector(OrderEditContainer)
