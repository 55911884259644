import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import HeaderContainer from './Header'
import ListContainer from './List'
import PostcodeAvailabilityContainer from './PostcodeAvailability'

const styles = (theme) => ({
	root: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	left: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16
		})
	},
	right: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 16,
			marginLeft: 16,
			width: 400
		})
	}
})

class PostcodeAdmin extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		showNew: PropTypes.bool
	}

	render() {
		const { classes, showNew } = this.props

		let rightContainer = null
		if (showNew) {
			rightContainer = (
				<Paper className={classes.right}>
					<PostcodeAvailabilityContainer />
				</Paper>
			)
		}

		return (
			<div className={classes.root}>
				<Paper className={classes.left}>
					<Typography variant="h5" gutterBottom>
						{i18n.t('app:postcodeadmin.Postcodes.title')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:postcodeadmin.Postcodes.description')}
					</Typography>
					<HeaderContainer />
					<ListContainer />
				</Paper>
				{rightContainer}
			</div>
		)
	}
}

export default withStyles(styles)(PostcodeAdmin)
