// validate a partial postcode
// this function will check if the current "state" of the postcode is valid
// this allows use to report invalid postcodes asap
const validatePartialCode = (country, postcode) => {
	if (country === 'NL') {
		// the first 4 characters must be digits and the first digit cannot be 0
		if (postcode.length <= 4 && /^[1-9][0-9]{0,3}$/.test(postcode)) {
			return true
		}
		// the first 4 characters must be digits, followed by an optional space and 2 letters
		if (
			postcode.length <= 7 &&
			/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{0,2}$/i.test(postcode)
		) {
			return true
		}
	} else if (country === 'BE') {
		// the first 4 characters must be digits and the first cannot be 0
		if (postcode.length <= 4 && /^[1-9][0-9]{0,3}$/.test(postcode)) {
			return true
		}
	} else if (country === 'DE') {
		// the first 5 characters must be digits
		if (postcode.length <= 5 && /^[0-9]{0,5}$/.test(postcode)) {
			return true
		}
	}

	return false
}

// validate a partial postcode that is part of a postcode range (from-till)
// this function will check if the current "state" of the postcode is valid to use in a range
// this allows use to report invalid postcodes asap
const validatePartialRange = (country, postcode) => {
	if (country === 'NL') {
		// the first 4 characters must be digits and the first digit cannot be 0
		if (postcode.length <= 4 && /^[1-9][0-9]{0,3}$/.test(postcode)) {
			return true
		}
	} else if (country === 'BE') {
		// the first 4 characters must be digits and the first cannot be 0
		if (postcode.length <= 4 && /^[1-9][0-9]{0,3}$/.test(postcode)) {
			return true
		}
	} else if (country === 'DE') {
		// the first 5 characters must be digits
		if (postcode.length <= 5 && /^[0-9]{0,5}$/.test(postcode)) {
			return true
		}
	}

	return false
}

// validate a postcode
const validateCode = (country, postcode) => {
	if (country === 'NL') {
		// the first 4 characters must be digits, followed by an optional space and 2 letters
		if (/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(postcode)) {
			return true
		}
	} else if (country === 'BE') {
		// the 4 characters must be digits and the first cannot be 0
		if (/^[1-9][0-9]{3}$/.test(postcode)) {
			return true
		}
	} else if (country === 'DE') {
		// the 5 characters must be digits
		if (/^[0-9]{5}$/.test(postcode)) {
			return true
		}
	}

	return false
}

// validate a postcode that is part of a postcode range (from-till)
const validateRange = (country, postcode) => {
	if (country === 'NL') {
		// the 4 characters must be digits
		if (/^[1-9][0-9]{3}$/i.test(postcode)) {
			return true
		}
	} else if (country === 'BE') {
		// the 4 characters must be digits and the first cannot be 0
		if (/^[1-9][0-9]{3}$/.test(postcode)) {
			return true
		}
	} else if (country === 'DE') {
		// the 5 characters must be digits
		if (/^[0-9]{5}$/.test(postcode)) {
			return true
		}
	}

	return false
}

const formatPostcode = (country, postcode) => {
	if (country === 'NL') {
		// take the digits and letters from the postcode and a space between them
		const digits = postcode.match(/[0-9]{4}/gi)[0]
		const letters = postcode.match(/[a-z]{2}/gi)[0]

		return `${digits} ${letters}`
	}

	return postcode
}

export {
	validatePartialCode,
	validatePartialRange,
	validateCode,
	validateRange,
	formatPostcode
}
