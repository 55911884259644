const actionTypes = {
	CLEAR_ERROR: 'elements/user/CLEAR_ERROR',
	FETCH_USER: 'elements/user/FETCH_USER',
	FETCH_USER_REQUEST: 'elements/user/FETCH_USER_REQUEST',
	FETCH_USER_FAILURE: 'elements/user/FETCH_USER_FAILURE',
	FETCH_USER_SUCCESS: 'elements/user/FETCH_USER_SUCCESS',
	NEWPASSWORDRESET: 'elements/user/NEWPASSWORDRESET',
	NEWPASSWORDRESET_REQUEST: 'elements/user/NEWPASSWORDRESET_REQUEST',
	NEWPASSWORDRESET_FAILURE: 'elements/user/NEWPASSWORDRESET_FAILURE',
	NEWPASSWORDRESET_SUCCESS: 'elements/user/NEWPASSWORDRESET_SUCCESS',
	RESETPASSWORD: 'elements/user/RESETPASSWORD',
	RESETPASSWORD_REQUEST: 'elements/user/RESETPASSWORD_REQUEST',
	RESETPASSWORD_FAILURE: 'elements/user/RESETPASSWORD_FAILURE',
	RESETPASSWORD_SUCCESS: 'elements/user/RESETPASSWORD_SUCCESS'
}

export default actionTypes
