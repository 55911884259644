import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	}
})

class DriverQualifications extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		qualifications: PropTypes.array.isRequired
	}

	render() {
		const { classes, qualifications } = this.props

		if (qualifications.length === 0) {
			return (
				<div className={classes.root}>
					<Typography variant="h6" paragraph>
						{i18n.t('app:vehicleplanning.DriverQualifications.title')}
					</Typography>
					<Typography variant="body2">
						{i18n.t('app:vehicleplanning.DriverQualifications.empty')}
					</Typography>
				</div>
			)
		}

		const qualificationItems = qualifications.map((qualification) => (
			<Typography key={`driver-qualif-item-${qualification}`} variant="body2">
				{qualification}
			</Typography>
		))

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.DriverQualifications.title')}
				</Typography>
				{qualificationItems}
			</div>
		)
	}
}

export default withStyles(styles)(DriverQualifications)
