import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import planningTypesSelector from '../../../../../redux/planningtypes/selectors'
import Planning from './Planning'

class PlanningContainer extends PureComponent {
	static propTypes = {
		availablePlanningTypes: PropTypes.array.isRequired,
		changePlanning: PropTypes.func.isRequired,
		changeMaxPeriod: PropTypes.func.isRequired,
		meta: PropTypes.object.isRequired,
		planningType: PropTypes.object,
		planningTypeKey: PropTypes.string,
		customVehicle: PropTypes.object
	}

	render() {
		const {
			availablePlanningTypes,
			changePlanning,
			changeMaxPeriod,
			meta,
			planningType,
			customVehicle
		} = this.props

		return (
			<Planning
				availablePlanningTypes={availablePlanningTypes}
				changePlanning={changePlanning}
				changeMaxPeriod={changeMaxPeriod}
				meta={meta}
				planningType={planningType}
				customVehicle={customVehicle}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	availablePlanningTypes: planningTypesSelector.availablePlanningTypesSelector(
		state.planningTypesState
	),
	planningType: planningTypesSelector.findPlanningTypeByKeySelector(
		state.planningTypesState,
		props.planningTypeKey
	)
}))

export default connector(PlanningContainer)
