import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import actionCreators from '../../../redux/vehicleUnavailability/actionCreators'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		remove: PropTypes.func.isRequired,
		unavailability: PropTypes.array.isRequired,
		vehicleId: PropTypes.string.isRequired
	}

	render() {
		const { remove, vehicleId, unavailability } = this.props

		return (
			<List
				vehicleId={vehicleId}
				unavailability={unavailability}
				remove={remove}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		unavailability: state.vehicleUnavailabilityState.unavailability
	}),
	{
		remove: actionCreators.remove
	}
)

export default connector(ListContainer)
