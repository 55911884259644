import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PlanningTypeAdmin from './PlanningTypeAdmin'

class PlanningTypeAdminContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired
	}

	render() {
		const { match } = this.props

		return (
			<PlanningTypeAdmin
				selectedPlanningTypeKey={match.params.planningTypeKey}
			/>
		)
	}
}

export default PlanningTypeAdminContainer
