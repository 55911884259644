import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

import QualificationListItem from './QualificationListItem'

const styleSheet = (theme) => ({
	header: {
		...theme.mixins.gutters({
			paddingTop: 16
		})
	}
})

class QualificationList extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		qualifications: PropTypes.array.isRequired
	}

	render() {
		const { classes, qualifications } = this.props

		let items
		if (qualifications.length === 0) {
			items = (
				<ListItem>
					<ListItemText
						disableTypography
						primary={
							<Typography variant="body2">
								{i18n.t(
									'app:driveradmin.Driver.Qualifications.noQualifications'
								)}
							</Typography>
						}
					/>
				</ListItem>
			)
		} else {
			items = qualifications.map((qualification) => (
				<QualificationListItem
					key={`${qualification.key}-${qualification.level}`}
					qualification={qualification}
				/>
			))
		}

		return (
			<>
				<div className={classes.header}>
					<Typography variant="h6">
						{i18n.t('app:driveradmin.Driver.Qualifications.title')}
					</Typography>
				</div>
				<List disablePadding>{items}</List>
			</>
		)
	}
}

export default withStyles(styleSheet)(QualificationList)
