import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Placeholder from '../../../Shared/Placeholder'
import UnscheduledOrderSelectionList from './List'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		height: '100%'
	},
	contentContainer: {
		flex: 1,
		position: 'relative'
	},
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class UnscheduledOrderSelection extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		select: PropTypes.func.isRequired,
		selectedPlanningType: PropTypes.object.isRequired,
		unscheduledOrders: PropTypes.array.isRequired
	}

	render() {
		const {
			classes,
			isLoading,
			select,
			selectedPlanningType,
			unscheduledOrders
		} = this.props

		if (isLoading) {
			const placeholders = [...Array(11)].map((row, index) => (
				<Placeholder key={`unscheduledorderlist-placeholder-${index}`} />
			))
			return (
				<div className={classes.root}>
					<div className={classes.contentContainer}>
						<List disablePadding className={classes.content}>
							{placeholders}
						</List>
					</div>
				</div>
			)
		}

		return (
			<div className={classes.root}>
				<div className={classes.contentContainer}>
					<UnscheduledOrderSelectionList
						select={select}
						selectedPlanningType={selectedPlanningType}
						unscheduledOrders={unscheduledOrders}
					/>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(UnscheduledOrderSelection)
