import { PureComponent } from 'react'
import Settings from './Settings'

class SettingsContainer extends PureComponent {
	handleChangeLanguage = (id) => {
		localStorage.setItem('language', id)
		window.location.reload()
	}

	render() {
		return <Settings changeLanguage={this.handleChangeLanguage} />
	}
}

export default SettingsContainer
