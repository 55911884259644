import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursActionCreators from '../../../redux/tours/actionCreators'
import toursSelectors from '../../../redux/tours/selectors'
import TourComparison from './TourComparison'

class TourComparisonContainer extends PureComponent {
	static propTypes = {
		confirm: PropTypes.func.isRequired,
		deselect: PropTypes.func.isRequired,
		finalize: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		selectedTours: PropTypes.array.isRequired,
		startManualPlanning: PropTypes.func.isRequired,
		tourNumbers: PropTypes.array.isRequired,
		undoConfirm: PropTypes.func.isRequired
	}

	render() {
		const {
			confirm,
			deselect,
			finalize,
			metaTours,
			ownUserId,
			selectedTours,
			startManualPlanning,
			tourNumbers,
			undoConfirm
		} = this.props

		return (
			<TourComparison
				confirm={confirm}
				deselect={deselect}
				finalize={finalize}
				metaTours={metaTours}
				ownUserId={ownUserId}
				selectedTours={selectedTours}
				startManualPlanning={startManualPlanning}
				tourNumbers={tourNumbers}
				undoConfirm={undoConfirm}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		metaTours: state.toursState.metaTours,
		ownUserId: state.userState.user.id,
		selectedTours: toursSelectors.selectedToursSelector(state.toursState),
		tourNumbers: toursSelectors.tourListNumbersSelector(state.toursState)
	}),
	{
		confirm: toursActionCreators.confirm,
		deselect: toursActionCreators.deselect,
		finalize: toursActionCreators.finalize,
		undoConfirm: toursActionCreators.undoConfirm
	}
)

export default connector(TourComparisonContainer)
