import { combineReducers } from 'redux'
import actionTypes from './actionTypes'

const initialDepotsState = []
const depotsReducer = (state = initialDepotsState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SUCCESS: {
			return action.payload.depots
		}

		case actionTypes.FETCH_FAILURE: {
			return initialDepotsState
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			}
		}

		case actionTypes.FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			}
		}

		default:
			return state
	}
}

export default combineReducers({
	depots: depotsReducer,
	meta: metaReducer
})
