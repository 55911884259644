import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	formRow: {
		display: 'flex',
		alignItems: 'center'
	},
	confirmButton: {
		marginTop: 16
	},
	textFieldRemarks: {
		flex: 1
	}
}

class Remarks extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		setRemarks: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			remarks: props.appointment.bijzonderheden
		}
	}

	handleChangeRemarks = (event) => {
		this.setState({
			remarks: event.target.value
		})
	}

	handleConfirm = () => {
		const { setRemarks } = this.props
		const { remarks } = this.state
		setRemarks(remarks)
	}

	render() {
		const { classes } = this.props
		const { remarks } = this.state

		return (
			<div className={classes.root}>
				<div className={classes.formRow}>
					<TextField
						placeholder={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryRemarks.remarks'
						)}
						className={classes.textFieldRemarks}
						value={remarks}
						onChange={this.handleChangeRemarks}
						margin="normal"
						multiline
						rows="4"
					/>
				</div>
				<Button
					color="primary"
					variant="contained"
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Remarks)
