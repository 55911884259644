import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, SvgIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ExclamationFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'

import DepotContainer from './Depot'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters({
			paddingTop: 16,
			paddingBottom: 24
		})
	},
	unknownAdressBlock: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		fontSize: 16,
		marginRight: 4
	}
})

class Depots extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired
	}

	render() {
		const { classes, vehicle } = this.props

		const { address, startDepot, endDepot, startAtDriverHome } = vehicle

		let addressItem = null
		if (startAtDriverHome) {
			if (address) {
				addressItem = (
					<Grid item xs={6}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Depots.homeAddress')}
						</Typography>
						<Typography variant="body2">{`${address.street} ${address.houseNum}`}</Typography>
						<Typography variant="body2">{`${address.postcode} ${address.city}`}</Typography>
					</Grid>
				)
			} else {
				addressItem = (
					<Grid item xs={6}>
						<span className={classes.unknownAdressBlock}>
							<SvgIcon className={classes.icon} color="secondary">
								<ExclamationFal />
							</SvgIcon>{' '}
							<Typography variant="body2" color="secondary">
								{i18n.t('app:vehicleplanning.Depots.unknownHomeAddress')}
							</Typography>
						</span>
					</Grid>
				)
			}
		}

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:vehicleplanning.Depots.title')}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<DepotContainer
							label={i18n.t('app:vehicleplanning.Depots.start')}
							plant={startDepot}
						/>
					</Grid>
					<Grid item xs={4}>
						<DepotContainer
							label={i18n.t('app:vehicleplanning.Depots.end')}
							plant={endDepot}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:vehicleplanning.Depots.startAtDriverHome')}
						</Typography>
						<Typography variant="body2">
							{startAtDriverHome
								? i18n.t('app:generic.yes')
								: i18n.t('app:generic.no')}
						</Typography>
					</Grid>
					{addressItem}
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(Depots)
