import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toursActionCreators from '../../../../redux/tours/actionCreators'
import toursSelectors from '../../../../redux/tours/selectors'
import { PLANNING_TYPE_MEERDAAGSE_RIT } from '../../../../utils/constants'
import withPlanningStatus from '../../../../hocs/withPlanningStatus'
import SelectionToolbar from './SelectionToolbar'
import { canConfirmTour, canFinalizeTour } from '../../../../utils/tour'

class SelectionToolbarContainer extends PureComponent {
	static propTypes = {
		confirmMultiple: PropTypes.func.isRequired,
		deselectAll: PropTypes.func.isRequired,
		finalizeMultiple: PropTypes.func.isRequired,
		metaTours: PropTypes.array,
		ownUserId: PropTypes.string.isRequired,
		planningIsProcessing: PropTypes.bool, // Provided by `withPlanningStatus` HOC
		selectedPlanningTypeKey: PropTypes.string.isRequired,
		selectedTours: PropTypes.array.isRequired,
		selectMultiple: PropTypes.func.isRequired,
		startManualPlanning: PropTypes.func.isRequired,
		tourIds: PropTypes.array.isRequired
	}

	canConfirmTour = (selectedTour) => {
		const { metaTours, ownUserId } = this.props
		const selectedMetaTour = metaTours.find(
			(metaTour) => metaTour.id === selectedTour.id
		)
		return canConfirmTour(selectedTour, selectedMetaTour, ownUserId)
	}

	canFinalizeTour = (selectedTour) => {
		const { metaTours, ownUserId } = this.props
		const selectedMetaTour = metaTours.find(
			(metaTour) => metaTour.id === selectedTour.id
		)
		return canFinalizeTour(selectedTour, selectedMetaTour, ownUserId)
	}

	handleConfirm = () => {
		const { confirmMultiple, selectedTours } = this.props
		const confirmableTourIds = selectedTours
			.filter(this.canConfirmTour)
			.map((selectedTour) => selectedTour.id)
		confirmMultiple(confirmableTourIds)
	}

	handleDeselect = () => {
		const { deselectAll } = this.props
		deselectAll()
	}

	handleSelectAll = () => {
		const { selectMultiple, tourIds } = this.props
		selectMultiple(tourIds)
	}

	handleFinalize = () => {
		const { finalizeMultiple, selectedTours } = this.props
		const finalizableTourIds = selectedTours
			.filter(this.canFinalizeTour)
			.map((selectedTour) => selectedTour.id)
		finalizeMultiple(finalizableTourIds)
	}

	render() {
		const {
			ownUserId,
			planningIsProcessing,
			selectedPlanningTypeKey,
			selectedTours,
			startManualPlanning,
			tourIds
		} = this.props

		const tourCount = selectedTours.length

		const disableConfirmButton = planningIsProcessing || tourCount === 0
		const disableFinalizeButton = planningIsProcessing || tourCount === 0
		const disableSelectAllButton = tourIds.length === 0

		// Cannot start manual planning if the selection contains tours which are locked by another user,
		// or when the selection contains tours which are confirmed or finalized
		const selectionHasLocksFromOtherUser = selectedTours.some(
			(tour) => tour.lock && tour.lock.userId !== ownUserId
		)
		const selectionHasConfirmedOrFinalizedTours = selectedTours.some(
			(tour) => tour.confirmedAt || tour.finalizedAt
		)
		let disableStartManualPlanningButton =
			planningIsProcessing ||
			selectionHasLocksFromOtherUser ||
			selectionHasConfirmedOrFinalizedTours ||
			tourCount === 0
		// Also, for planning type Z06 the manual planning can only start when exactly one tour is selected
		if (
			selectedPlanningTypeKey === PLANNING_TYPE_MEERDAAGSE_RIT &&
			selectedTours.length !== 1
		) {
			disableStartManualPlanningButton = true
		}

		return (
			<SelectionToolbar
				disableConfirmButton={disableConfirmButton}
				disableFinalizeButton={disableFinalizeButton}
				disableSelectAllButton={disableSelectAllButton}
				disableStartManualPlanningButton={disableStartManualPlanningButton}
				confirm={this.handleConfirm}
				deselect={this.handleDeselect}
				finalize={this.handleFinalize}
				selectAll={this.handleSelectAll}
				startManualPlanning={startManualPlanning}
				tourCount={tourCount}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		metaTours: state.toursState.metaTours,
		ownUserId: state.userState.user.id,
		selectedPlanningTypeKey: state.toursState.selectedPlanningTypeKey,
		selectedTours: toursSelectors.selectedToursSelector(state.toursState),
		tourIds: toursSelectors.tourListIdsSelector(state.toursState)
	}),
	{
		confirmMultiple: toursActionCreators.confirmMultiple,
		deselectAll: toursActionCreators.deselectAll,
		finalizeMultiple: toursActionCreators.finalizeMultiple,
		selectMultiple: toursActionCreators.selectMultiple
	}
)

export default withPlanningStatus(connector(SelectionToolbarContainer))
