import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Switch,
	FormControl,
	FormControlLabel,
	MenuItem,
	InputLabel,
	Select
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import localizedDescription from '../../../../utils/localizedDescription'

const styleSheet = (theme) => ({
	root: {
		...theme.mixins.gutters({
			display: 'flex',
			flexDirection: 'column',
			padding: 16
		})
	},
	formControl: {
		flex: 1,
		paddingBottom: 8
	},
	filterButtons: {
		display: 'flex'
	},
	filterButtonLeft: {
		marginTop: 16,
		marginRight: 8
	},
	filterButtonRight: {
		marginTop: 16,
		marginLeft: 8
	},
	selected: {
		fontWeight: theme.typography.fontWeightMedium
	}
})

class Filter extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		applyFilter: PropTypes.func.isRequired,
		clearFilter: PropTypes.func.isRequired,
		closeFilter: PropTypes.func.isRequired,
		onlyUnavailable: PropTypes.bool.isRequired,
		onlyAvailable: PropTypes.bool.isRequired,
		availablePlanningTypes: PropTypes.array.isRequired,
		selectedPlanningTypeKeys: PropTypes.array.isRequired,
		availableVehicleTypes: PropTypes.array.isRequired,
		selectedVehicleTypes: PropTypes.array.isRequired
	}

	handleToggleOnlyUnavailable = () => {
		const { onlyUnavailable } = this.props
		this.applyFilter({
			onlyUnavailable: !onlyUnavailable,
			onlyAvailable: false
		})
	}

	handleToggleOnlyAvailable = () => {
		const { onlyAvailable } = this.props
		this.applyFilter({
			onlyAvailable: !onlyAvailable,
			onlyUnavailable: false
		})
	}

	handleChangeSelectedPlanningTypeKeys = (event) => {
		this.applyFilter({
			selectedPlanningTypeKeys: event.target.value
		})
	}

	handleChangeSelectedVehicleTypes = (event) => {
		this.applyFilter({
			selectedVehicleTypes: event.target.value
		})
	}

	applyFilter(filter) {
		const { applyFilter } = this.props
		const {
			onlyAvailable,
			onlyUnavailable,
			selectedPlanningTypeKeys,
			selectedVehicleTypes
		} = this.props
		const newFilter = {
			onlyAvailable,
			onlyUnavailable,
			selectedPlanningTypeKeys,
			selectedVehicleTypes,
			...filter
		}
		applyFilter(newFilter)
	}

	handleClearFilter = () => {
		const { clearFilter } = this.props
		clearFilter()
	}

	handleCloseFilter = () => {
		const { closeFilter } = this.props
		closeFilter()
	}

	render() {
		const {
			classes,
			onlyUnavailable,
			onlyAvailable,
			availablePlanningTypes,
			selectedPlanningTypeKeys,
			availableVehicleTypes,
			selectedVehicleTypes
		} = this.props

		const planningTypeItems = availablePlanningTypes.map(
			(availablePlanningType) => {
				const className = selectedPlanningTypeKeys.some(
					(selectedPlanningTypeKey) =>
						selectedPlanningTypeKey === availablePlanningType.planningTypeKey
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availablePlanningType-${availablePlanningType.planningTypeKey}`}
						value={availablePlanningType.planningTypeKey}
						className={className}
					>
						{localizedDescription(availablePlanningType.descriptions)}
					</MenuItem>
				)
			}
		)

		const vehicleTypeItems = availableVehicleTypes.map(
			(availableVehicleType) => {
				const className = selectedVehicleTypes.some(
					(selectedVehicleType) => selectedVehicleType === availableVehicleType
				)
					? classes.selected
					: ''
				return (
					<MenuItem
						key={`availableVehicleType-${availableVehicleType}`}
						value={availableVehicleType}
						className={className}
					>
						{availableVehicleType}
					</MenuItem>
				)
			}
		)

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:vehicleplanning.Vehicles.Filter.planningType')}
					</InputLabel>
					<Select
						multiple
						value={selectedPlanningTypeKeys}
						onChange={this.handleChangeSelectedPlanningTypeKeys}
					>
						{planningTypeItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{i18n.t('app:vehicleplanning.Vehicles.Filter.vehicleType')}
					</InputLabel>
					<Select
						multiple
						value={selectedVehicleTypes}
						onChange={this.handleChangeSelectedVehicleTypes}
					>
						{vehicleTypeItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								checked={onlyAvailable}
								color="primary"
								onChange={this.handleToggleOnlyAvailable}
							/>
						}
						label={i18n.t('app:vehicleplanning.Vehicles.Filter.onlyAvailable')}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								checked={onlyUnavailable}
								color="primary"
								onChange={this.handleToggleOnlyUnavailable}
							/>
						}
						label={i18n.t(
							'app:vehicleplanning.Vehicles.Filter.onlyUnavailable'
						)}
					/>
				</FormControl>
				<div className={classes.filterButtons}>
					<Button
						color="default"
						fullWidth
						variant="outlined"
						onClick={this.handleClearFilter}
						className={classes.filterButtonLeft}
					>
						{i18n.t('app:vehicleplanning.Vehicles.Filter.clearFilter')}
					</Button>
					<Button
						color="primary"
						fullWidth
						variant="outlined"
						onClick={this.handleCloseFilter}
						className={classes.filterButtonRight}
					>
						{i18n.t('app:vehicleplanning.Vehicles.Filter.applyFilter')}
					</Button>
				</div>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Filter)
