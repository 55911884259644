import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List as MaterialList } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import ListItem from './ListItem'

const styleSheet = {
	content: {
		position: 'absolute',
		overflowY: 'auto',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

class List extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		planningTypes: PropTypes.array.isRequired,
		selectedPlanningTypeKey: PropTypes.string
	}

	render() {
		const { planningTypes, selectedPlanningTypeKey, classes } = this.props

		const items = planningTypes.map((planningType) => (
			<ListItem
				key={planningType.planningTypeKey}
				planningType={planningType}
				isSelected={planningType.planningTypeKey === selectedPlanningTypeKey}
			/>
		))

		return (
			<MaterialList disablePadding className={classes.content}>
				{items}
			</MaterialList>
		)
	}
}

export default withStyles(styleSheet)(List)
