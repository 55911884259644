import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import List from './List'

class ListContainer extends PureComponent {
	static propTypes = {
		isLoading: PropTypes.bool,
		orders: PropTypes.array.isRequired,
		select: PropTypes.func.isRequired
	}

	render() {
		const { isLoading, orders, select } = this.props

		return <List isLoading={isLoading} orders={orders} select={select} />
	}
}

const connector = connect((state) => ({
	isLoading: state.ordersState.search.isLoading,
	orders: state.ordersState.search.orders
}))

export default connector(ListContainer)
