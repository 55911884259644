import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { default as i18n } from 'i18next'
import { formatMoney } from '../../../utils/currency'

const styleSheet = {
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 24
	}
}

class Amounts extends PureComponent {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired
	}

	render() {
		const { classes, order } = this.props

		return (
			<div className={classes.root}>
				<Typography variant="h6" paragraph>
					{i18n.t('app:appointmentscheduler.Order.amounts')}
				</Typography>
				<Grid container>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.orderAmount')}
						</Typography>
						<Typography variant="body2">
							{formatMoney(order.orderAmount)}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.openAmount')}
						</Typography>
						<Typography variant="body2">
							{formatMoney(order.openAmount)}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="caption" color="primary">
							{i18n.t('app:appointmentscheduler.Order.callAmount')}
						</Typography>
						<Typography variant="body2">
							{formatMoney(order.callAmount)}
						</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styleSheet)(Amounts)
