import { all, call, fork, put, take } from 'redux-saga/effects'
import { default as i18n } from 'i18next'

import apiPlanningTypes from '../../services/apiPlanningTypes'
import actionTypes from './actionTypes'
import planningTypesActionCreators from './actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'

// Will fetch all planning types
function* fetch() {
	while (true) {
		yield take(actionTypes.FETCH)
		yield put(planningTypesActionCreators.fetchRequest())
		try {
			const planningTypes = yield call(apiPlanningTypes.getAll)
			yield put(planningTypesActionCreators.fetchSuccess(planningTypes))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t(
				'app:planningtypeadmin.PlanningTypes.Error.fetch'
			)
			yield put(
				planningTypesActionCreators.fetchFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

// Will update a planning type
function* update() {
	while (true) {
		const action = yield take(actionTypes.UPDATE)
		yield put(planningTypesActionCreators.updateRequest())
		try {
			const updatedPlanningType = yield call(
				apiPlanningTypes.update,
				action.payload.planningType
			)
			yield put(planningTypesActionCreators.updateSuccess(updatedPlanningType))

			const successMessage = i18n.t(
				'app:planningtypeadmin.PlanningType.updated'
			)
			yield put(notificationsActionCreators.addNotification(successMessage))
		} catch (error) {
			const techMessage = error
				? error.message
				: i18n.t('app:elements.Error.unknown')
			const userMessage = i18n.t(
				'app:planningtypeadmin.PlanningType.Error.update'
			)
			yield put(
				planningTypesActionCreators.updateFailure({
					userMessage,
					techMessage
				})
			)
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

export default function* rootSaga() {
	yield all([fetch, update].map((saga) => fork(saga)))
}
